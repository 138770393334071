import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDataContext } from '../../context/DataContext'
import ReturnToDirectoTransportRoute from './ReturnToDirectoTransportRoute'
import '../../styles/transport-route-list.css'
import { Button, Card, CardContent, Typography } from '@mui/material'
import { styled } from '@mui/system'

const StyledCard = styled(Card, {
    name: "StyledCard",
    slot: "Wrapper"
})({
    margin: "0.5rem",
    width: "90%",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    "&:hover": {
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
    },
    "@media (max-width: 1920px)": {
        width: "400px",
    },
    "@media (max-width: 1440px)": {
        width: "300px",
    },
    "@media (max-width: 1024px)": {
        width: "225px",
    },
    "@media (max-width: 768px)": {
        width: "175px",
    },
    ".MuiButton-root": {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "3px",
        marginBottom: "3px",
        color: "#fff",
        backgroundColor: "#c00",
        "&:hover": {
            backgroundColor: "#af0202",
        }
    }
})

const TransportRoutesCards = () => {
    const { transportRouteList } = useDataContext();
    const navigate = useNavigate();
    const handleLoadTransportRoute = (id: number) => {
        if (id === undefined) return
        navigate(("/transport-route/details/" + id))
    }

    return (

        <div className='consignment-cards--root'>
            {transportRouteList.map((transportRoute, idx) => {
                return (
                    <StyledCard key={idx} onClick={() => handleLoadTransportRoute(transportRoute.id)}>
                        <CardContent sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            paddingBottom: "16px !important",
                        }}>
                            <Typography variant="h6" sx={{
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                                marginBottom: "0.5rem",
                            }}>
                                Koondveotellimus: {transportRoute.directoCode}
                            </Typography>
                            <Typography variant="body1">
                                Koondsaatelehti: {transportRoute.consignment.length}
                            </Typography>
                            <Typography variant="body1">
                                Looja: {transportRoute.consignment[0].consignmentCreator}
                            </Typography>

                            {transportRoute.directoCode && (
                                <div className='consignment-cards--link'>
                                    <ReturnToDirectoTransportRoute
                                        returnId={transportRoute.directoCode}
                                    />
                                </div>
                            )}
                            <Button
                                variant="contained"
                                onClick={() => handleLoadTransportRoute(transportRoute.id)}
                            >
                                Laadi
                            </Button>
                        </CardContent>

                        <CardContent>
                            {transportRoute.consignment.map((consignment, idx) => (
                                <React.Fragment key={idx}>
                                    {consignment !== undefined && (
                                        <Card sx={{ marginTop: "1rem", borderRadius: "10px", backgroundColor: "#eee" }}>
                                            <CardContent>
                                                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                                    Veotellimus {idx + 1}:
                                                </Typography>
                                                <Typography variant="body1">
                                                    Vedaja: {consignment.carrierName} - {consignment.carrierRepresentative}
                                                </Typography>
                                                <Typography variant="body1">
                                                    Pealelaadimise kuupäev: {consignment.cargosonData.pickups[0].collection_date}
                                                </Typography>
                                                <Typography variant="body1">
                                                    Pealelaadimiskoht: {consignment.cargosonData.pickups[0].collection_city}
                                                </Typography>

                                                <Typography variant="body1">
                                                    Mahalaadimise kuupäev: {consignment.cargosonData.delivery_date}
                                                </Typography>
                                                <Typography variant="body1">
                                                    Sihtkoht: {consignment.cargosonData.delivery_city}
                                                </Typography>
                                                <Typography variant="body1">
                                                    Cargosonis: {consignment.cargosonData.cargosonReturnId ? "On" : "Ei ole"}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    )}
                                </React.Fragment>
                            ))}
                        </CardContent>
                    </StyledCard>
                );
            })}
        </div>


    )
}

export default TransportRoutesCards    
