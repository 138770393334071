import { Button, IconButton } from '@mui/material';
import { useDataContext } from '../../context/DataContext';
import { ReactComponent as CargosonLogo } from '../../assets/logo-cargoson.svg';

const ReturnToCargoson = (props: { returnId: string, listIndex: number }) => {
  const { consignmentList } = useDataContext();
  const returnId = consignmentList[props.listIndex].cargosonData.cargosonReturnId;
  const id = returnId ? returnId : props.returnId;

  const goToCargoson = () => {
    window.open(`https://cargoson-staging.herokuapp.com/et/queries/${id}`, "_blank");
  }

  return (
    <>
      {(id !== null && id !== undefined && id !== "") &&
        <IconButton style={{ marginTop: "1%", cursor: "pointer" }} onClick={goToCargoson}>
          <CargosonLogo style={{ height: "20px" }} />
        </IconButton>
      }
    </>
  );
};
export default ReturnToCargoson