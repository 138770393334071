import { ConsignmentStatus } from '../../../Types/delivery-app/InHouseConsignment';

export const HandleGetInHouseConsignmentStatus = ({ statusNumber }: { statusNumber: ConsignmentStatus }) => {
  /* 1 == koostamata ja punane */
  /* 2 == koostatud ja kui kõik lähetused on komplekteeritud */
  /* 3 == sõidus */
  /* 4 == kohal */
  /* 5 == üle antud */
  /* 6 == lõpetatud */
  /* 7 == allkirjastamata */
  if (statusNumber === 1) {
    return <div className="order-status status-scheduled">Planeeritud</div>;
  }
  if (statusNumber === 5) {
    return <div className="order-status status-loaded">Peale laetud</div>;
  }
  if (statusNumber === 0) {
    return <div className="order-status status-cancelled">Tühistatud</div>;
  }
  if (statusNumber === 2) {
    return <div className="order-status status-in-process">Sõidus</div>;
  }
  if (statusNumber === 3) {
    return <div className="order-status status-delivered">Kohal</div>;
  }
  if (statusNumber === 4) {
    return <div className="order-status status-delivered">Üle antud</div>;
  }
  if (statusNumber === 6) {
    return <div className="order-status status-loaded">Allkirjastamata</div>;
  }
  if (statusNumber === 7) {
    return <div className="order-status status-delivered">Lõpetatud</div>;
  }
  if (statusNumber === 8) {
    return <div className="order-status status-delivered">Koostatud</div>;
  }
  if (statusNumber === 9) {
    return <div className="order-status status-cancelled">Koostamata</div>;
  } else {
    return <></>;
  }
};
