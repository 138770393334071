import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import '../../styles/consignment-overview.css'
import '../../styles/tuusula-view.css'
import { ConvertDate } from '../../components/common/ConvertDate';
import CreateNewTransportOrder from '../../components/sweden-transport/CreateSwedenTransportOrder';

import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import EastIcon from '@mui/icons-material/East';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { UpdateConsignmentProperty } from '../../queries/queries';
import { UpdateTransportRouteProperty, GetGroupedTransportRoutesAndConsignments } from '../../queries/transport-route-queries';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangePicker from '../../components/finland-transport/DateRangePicker';
import { useCustomer } from '../../components/login/user';
import { TuusulaConsignmentList } from '../../Types/TuusulaConsignmentList';
import { ConsignmentCalendar } from '../../Types/ConsignmentCalendar';
import { GetDateFromAndTo } from '../../components/helpers/GroupConsignments';

const SwedenTransportOrders = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsCount, setItemsCount] = useState<number>(1);
    const itemsPerPage = 350;
    const [sortBy, setSortBy] = useState("collection_date"); // Default sorting field
    const [isDescending, setIsDescending] = useState(false); // Default sorting order
    const { permissions } = useCustomer();
    const [groupedConsignments, setGroupedConsignments] = useState<Record<number, Record<number, Record<number, ConsignmentCalendar[]>>>>();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const originCountry = "SE"
    const [calendarDateFrom, setCalendarDateFrom] = useState<Date | null>(null);
    const [calendarDateTo, setCalendarDateTo] = useState<Date | null>(null);
    const datePickerRef = useRef<HTMLDivElement>(null);
    const [showCalendar, setShowCalendar] = useState<boolean>(false)
    const [calendarPosition, setCalendarPosition] = useState<{ "top": number, "left": number }>({ top: 0, left: 0 })
    const isArchived = false;

    const handleSearchConsignment = (e: any) => {
        e.preventDefault()
        let dateFrom = ""
        let dateTo = ""
        if (calendarDateFrom && calendarDateTo && searchTerm !== null) {
            dateFrom = calendarDateFrom.toLocaleDateString("et-ET")
            dateTo = calendarDateTo.toLocaleDateString("et-ET")
        }
        GetGroupedTransportRoutesAndConsignments(currentPage, itemsPerPage, isArchived, sortBy, isDescending, dateFrom, dateTo, originCountry, searchTerm).then((response: any) => {
            setGroupedConsignments(response.data)
        }).catch((error) => { console.log(error) })
    }

    const fetchAll = async (dateFrom: string, dateTo: string) => {
        if (calendarDateFrom && calendarDateTo) {
            GetGroupedTransportRoutesAndConsignments(currentPage, itemsPerPage, isArchived, sortBy, isDescending, calendarDateFrom.toDateString(), calendarDateTo.toDateString(), originCountry, searchTerm).then((response: any) => {
                setGroupedConsignments(response.data)
            }).catch((error) => { console.log(error) })
        }
        else if (dateFrom && dateTo) {
            GetGroupedTransportRoutesAndConsignments(currentPage, itemsPerPage, isArchived, sortBy, isDescending, dateFrom, dateTo, originCountry, searchTerm).then((response: any) => {
                setGroupedConsignments(response.data)
            }).catch((error) => { console.log(error) })
        }
    }

    const handleLoadConsignment = (consignmentId: number) => {
        navigate(("/sweden/transport-orders/" + consignmentId))
    }

    const handleConsignmentBackground = (consignment: TuusulaConsignmentList) => {
        if (consignment.consignmentStatus === "ULD_FINISH") {
            return "background-finished";
        }
        if (consignment.deliveryWithCrane === true) {
            return "background-with-crane";
        }

        if (consignment.consignmentStatus === "TR_ORDER") {
            return "background-incomplete";
        }

        if (!consignment.directoCode) {
            return "background-incomplete";
        }
        return;
    }

    const handleTransportRouteBackground = (calenderObject: ConsignmentCalendar) => {
        if (calenderObject.transportRouteStatus === "ULD_FINISH") {
            return "background-finished"
        }
        if (calenderObject.consignmentGroup.find(x => x.deliveryWithCrane === true)) {
            return "background-with-crane";
        }

        if (calenderObject.transportRouteStatus === "TR_ORDER") {
            return "background-incomplete"
        }
        return;
    }

    const handleConsignmentStatusChange = async (event: any, consignmentId: number) => {
        //make call to api to change consignment's status
        event.stopPropagation();
        await UpdateConsignmentProperty(consignmentId, "ConsignmentStatus", "ULD_FINISH")
        var dates = GetDateFromAndTo();
        fetchAll(dates.dateFrom, dates.dateTo);
    }

    const handleTransportRouteStatusChange = async (event: any, transportRouteId: number) => {
        event.stopPropagation();
        await UpdateTransportRouteProperty(transportRouteId, "Status", "ULD_FINISH")
        var dates = GetDateFromAndTo();
        fetchAll(dates.dateFrom, dates.dateTo);
    }

    const returnDayWithDate = (dateString: string): string => {
        dateString = ConvertDate(dateString).toLocaleDateString('et-EE')
        const dateStringWithoutYear = (dateString).split('.')[0] + '.' + (dateString).split('.')[1];
        const deliveryDate: Date = ConvertDate(dateString);
        const weekDayNumber = deliveryDate.getDay();
        if (weekDayNumber === 1) {
            return `Monday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 2) {
            return `Tuesday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 3) {
            return `Wednesday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 4) {
            return `Thursday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 5) {
            return `Friday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 6) {
            return `Saturday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 0) {
            return `Sunday ${dateStringWithoutYear}`
        }
        return dateStringWithoutYear
    }

    const getCollectionTime = (collectionTimeFrom: string, collectionTimeTo: string) => {
        let timeStringToReturn = ""
        if (collectionTimeFrom !== null) {
            timeStringToReturn += removeSecondsFromTimeString(collectionTimeFrom);
            if (collectionTimeTo !== null) {
                timeStringToReturn += `-${removeSecondsFromTimeString(collectionTimeTo)}`
            }
        }
        return timeStringToReturn;
    }

    const removeSecondsFromTimeString = (timeString: string) => {
        try {
            // Split the time string by ":" to get hours, minutes, and seconds.
            const [hours, minutes, seconds] = timeString.split(':');

            // Remove the seconds part from the time string.
            const formattedTimeString = `${hours}:${minutes}`;

            return formattedTimeString;
        } catch (error) {
            return timeString;
        }
    }

    const onClose = () => {
        setShowCalendar(!showCalendar)
    }

    const concatDateRange = (dateFrom: Date | null, dateTo: Date | null) => {
        if (dateFrom !== null && dateTo !== null) {
            const dateRangeString = `${dateFrom.toLocaleDateString("et-ET")} - ${dateTo.toLocaleDateString("et-ET")}`
            return dateRangeString;
        }
        else return "";
    }

    const handleOpenCalendar = () => {
        if (datePickerRef.current) {
            const componentRect = datePickerRef.current.getBoundingClientRect();

            // Calculate position for the DatePicker
            const datePickerTop = componentRect.bottom + window.scrollY;
            const datePickerLeft = componentRect.left + window.scrollX;

            // Set position for the DatePicker
            setCalendarPosition({ top: datePickerTop, left: datePickerLeft });
            setShowCalendar(!showCalendar)
        }
    }

    useEffect(() => {
        var dates = GetDateFromAndTo()
        fetchAll(dates.dateFrom, dates.dateTo);
    }, [currentPage, itemsPerPage, sortBy, isDescending, calendarDateFrom, calendarDateTo]);

    if (!permissions.includes("PViewSwedishTransportOrders")) {
        return <Navigate to="/unauthorized" />
    }
    return (
        <div className='page__center-column-flexbox'>
            <div className='all-consignments--container' style={{ overflowX: "auto" }}>
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", paddingBottom: "10px", gap: "5%", width: "100%" }}>
                    <div>
                        <CreateNewTransportOrder alignItems='flex-start' />
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                        <form onSubmit={handleSearchConsignment} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "5px" }}>
                            <input className="input-addressForm" style={{ height: "40px", width: "unset", boxSizing: "unset" }} placeholder={t("Otsi veotellimusi")} onChange={e => setSearchTerm(e.target.value)} />
                            <IconButton onClick={handleSearchConsignment}>
                                <EastIcon style={{ color: "gray" }} />
                            </IconButton>
                        </form>
                        <div ref={datePickerRef} style={{ display: "flex", alignItems: "center" }}>
                            <input className='input-addressForm' style={{ height: "40px", width: "unset", boxSizing: "unset" }}
                                value={concatDateRange(calendarDateFrom, calendarDateTo) ?? ""} onClick={handleOpenCalendar} readOnly={true} />
                            <IconButton onClick={handleOpenCalendar} title="Archive" style={{ height: "35px" }}>
                                <CalendarMonthIcon />
                            </IconButton>

                            {showCalendar &&
                                <DateRangePicker setShowCalendar={setShowCalendar} fromDate={calendarDateFrom} toDate={calendarDateTo} setFromDate={setCalendarDateFrom} setToDate={setCalendarDateTo} position={calendarPosition} onClose={onClose} />
                            }
                        </div>
                    </div>
                </div>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                        <tr>
                            <td className="consignment-overview--thead">
                                <h2 className="page__heading--subheading1 left">{t("Veotellimuse number")}</h2>
                            </td>
                            <td className="consignment-overview--thead">
                                <h2 className="page__heading--subheading1 left">{t("Pealelaadimise kuupäev")}</h2>
                            </td>
                            <td className="consignment-overview--thead">
                                <h2 className="page__heading--subheading1 left">{t("Mahalaadimise kuupäev")}</h2>
                            </td>
                            <td className="consignment-overview--thead">
                                <h2 className="page__heading--subheading1 left">{t("Sihtkoht")}</h2>
                            </td>
                            <td className="consignment-overview--thead" >
                                <h2 className="page__heading--subheading1 left">{t("Autojuht")}</h2>
                            </td>
                            <td className="consignment-overview--thead"></td>
                        </tr>

                    </thead>
                    <tbody>
                        {(groupedConsignments !== null && groupedConsignments !== undefined) && Object.entries(groupedConsignments!).map(([year, yearConsignments]) => (
                            <React.Fragment key={year}>
                                {Object.entries(yearConsignments).map(([weekNumber, weekConsignments]) => (
                                    <React.Fragment key={weekNumber}>
                                        <tr>
                                            <td style={{ color: "gray", fontSize: "25px", marginLeft: "10px", fontFamily: "helvetica", padding: "10px", paddingLeft: "5px", fontWeight: "bold" }}>{t("Nädal")}{` ${weekNumber}`}</td>
                                        </tr>
                                        {Object.entries(weekConsignments).map(([weekDayNumber, consignmentGroups]) =>
                                        (
                                            <React.Fragment key={weekDayNumber}>

                                                {Object.values(consignmentGroups).map((calenderObj, idx) =>
                                                (
                                                    <React.Fragment key={idx}>
                                                        {calenderObj.isTransportRoute === true ?
                                                            <React.Fragment key={idx}>
                                                                <tr className={`consignment-overview-tv--tr ${handleTransportRouteBackground(calenderObj)}`} onClick={() => handleLoadConsignment(calenderObj.consignmentGroup[0].id)}>
                                                                    <td className='gray tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                        {calenderObj.consignmentGroup.find(x => x.deliveryWithCrane === true) &&
                                                                            <IconButton aria-readonly={true} title={t("Kraanaga auto")}>
                                                                                <PrecisionManufacturingIcon fontSize='large' style={{ color: "gray" }} />
                                                                            </IconButton>
                                                                        }
                                                                        {calenderObj.isTransportRoute ? t("Veoring") : t("Veotellimus")} {calenderObj.id} -&#160;
                                                                        {calenderObj.transportRouteTitle}
                                                                    </td>
                                                                    <td className='gray tv-view--td' style={{ borderRight: "1px solid #c00" }}>

                                                                        {returnDayWithDate(calenderObj.consignmentGroup[0].collectionDate) ?? "-"} {getCollectionTime(calenderObj.consignmentGroup[0].collectionTimeFrom, calenderObj.consignmentGroup[0].collectionTimeTo)}
                                                                    </td>

                                                                    <td className='gray tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                        {calenderObj.consignmentGroup.map((x, idx4) =>
                                                                        (
                                                                            <React.Fragment key={idx4}>
                                                                                {x.deliveryDate &&
                                                                                    <>
                                                                                        {idx4 + 1}: {returnDayWithDate(x.deliveryDate)} {getCollectionTime(x.deliveryTimeFrom, x.deliveryTimeTo)}
                                                                                        <br></br>
                                                                                    </>
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                        )}
                                                                    </td>

                                                                    <td className='gray tv-view--td' style={{ borderRight: "1px solid #c00" }}>{calenderObj.consignmentGroup.map((x, idx4) =>
                                                                    (
                                                                        <React.Fragment key={idx4}>
                                                                            {idx4 + 1}:{x.collectionAddressRow1} - {x.deliveryCity} {x.orderNumbers && x.orderNumbers + ""}  {x.totalLdm > 0 && " " + (x.totalLdm + " LDM")}
                                                                            {x.internalComment !== null && (" " + x.internalComment)}
                                                                            <br></br>
                                                                        </React.Fragment>
                                                                    )
                                                                    )}
                                                                    </td>

                                                                    <td className='gray tv-view--td' >{calenderObj.consignmentGroup[0].carrierDriver ?? "-"}</td>
                                                                    <td className='gray tv-view--td' >
                                                                        <IconButton onClick={(e: any) => handleTransportRouteStatusChange(e, calenderObj.id)}>
                                                                            {calenderObj.transportRouteStatus === "ULD_FINISH" ?
                                                                                <ThumbUpAltIcon fontSize='large' />
                                                                                :
                                                                                <ThumbUpOffAltIcon fontSize='large' />
                                                                            }
                                                                        </IconButton>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment >
                                                            :
                                                            <React.Fragment key={calenderObj.id}>
                                                                {calenderObj.consignmentGroup.map((x, idx3) => (
                                                                    <React.Fragment key={idx3}>
                                                                        {calenderObj.isTransportRoute === false &&
                                                                            <tr className={`consignment-overview-tv--tr ${handleConsignmentBackground(x)}`} onClick={() => handleLoadConsignment(x.id)} >
                                                                                <td className='gray tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                                    {x.deliveryWithCrane === true &&
                                                                                        <IconButton aria-readonly={true} title={t("Kraanaga auto")}>
                                                                                            <PrecisionManufacturingIcon fontSize='large' style={{ color: "gray" }} />
                                                                                        </IconButton>
                                                                                    }
                                                                                    {calenderObj.isTransportRoute ? t("Veoring") : t("Veotellimus")} {calenderObj.id}
                                                                                </td>
                                                                                <td className='gray tv-view--td' style={{ borderRight: "1px solid #c00" }}>

                                                                                    {returnDayWithDate(x.collectionDate) ?? "-"} {getCollectionTime(calenderObj.consignmentGroup[0].collectionTimeFrom, calenderObj.consignmentGroup[0].collectionTimeTo)}
                                                                                </td>
                                                                                <td className='gray tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                                    {returnDayWithDate(x.deliveryDate) ?? "-"} {getCollectionTime(x.deliveryTimeFrom, x.deliveryTimeTo)}
                                                                                </td>
                                                                                <td className='gray tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                                    {x.collectionAddressRow1} - {x.deliveryCity} {x.orderNumbers && x.orderNumbers + ""}
                                                                                    {x.totalLdm > 0 && " " + (x.totalLdm + " LVM")}
                                                                                    {x.internalComment !== null && (" " + x.internalComment)}
                                                                                </td>
                                                                                <td className='gray tv-view--td' >{x.carrierDriver ?? "-"}</td>
                                                                                <td className='gray tv-view--td' >

                                                                                    <IconButton onClick={(e: any) => handleConsignmentStatusChange(e, x.id)}>
                                                                                        {x.consignmentStatus === "ULD_FINISH" ?
                                                                                            <ThumbUpAltIcon fontSize='large' />
                                                                                            :
                                                                                            <ThumbUpOffAltIcon fontSize='large' />
                                                                                        }
                                                                                    </IconButton>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </React.Fragment>
                                                                ))}
                                                            </React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                )
                                                )}

                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div >
        </div >
    )
}

export default SwedenTransportOrders