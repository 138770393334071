import { useMediaQuery } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import DateTimeCalendar from '../common/DateTimeCalendar';

type Props = {
    listIndex: number,
    deliveryDate: Date,
    setDeliveryDate: React.Dispatch<React.SetStateAction<Date>>,
    timeFrom: Date | null,
    setTimeFrom: React.Dispatch<React.SetStateAction<Date | null>>,
    timeTo: Date | null,
    setTimeTo: React.Dispatch<React.SetStateAction<Date | null>>,
    deliveryTimePresetValue: string,
    setDeliveryTimePresetValue: React.Dispatch<React.SetStateAction<string>>,
    isUserPermitted: boolean
}

const DropdownAddressFinland = ({ listIndex, deliveryDate, setDeliveryDate, timeFrom, setTimeFrom, timeTo, setTimeTo, deliveryTimePresetValue, setDeliveryTimePresetValue, isUserPermitted }: Props) => {
    const { updateCargosonDataConsignment, consignmentList, setConsignmentList } = useDataContext();
    const cargosonData = consignmentList[listIndex].cargosonData;
    const { t } = useTranslation();
    const isWideScreen = useMediaQuery('(min-width:500px)');

    const handleDeliveryDate = (date: Date) => {
        setDeliveryDate(date)
        updateCargosonDataConsignment(listIndex, "delivery_date", date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' }))
    }

    const handleTimeFrom = (date: Date) => {
        setTimeFrom(date)
        updateCargosonDataConsignment(listIndex, "delivery_time_from", date?.toLocaleTimeString("et-EE"))
    }

    const handleTimeTo = (date: Date) => {
        setTimeTo(date)
        updateCargosonDataConsignment(listIndex, "delivery_time_to", date?.toLocaleTimeString("et-EE"))
    }

    const handleDynamicChange = (event: any) => {
        updateCargosonDataConsignment(listIndex, event.target.name, event.target.value)
    };

    const handleCheckbox = (event: any) => {
        if (event.target.checked) {
            updateCargosonDataConsignment(listIndex, event.target.name, true)

        } else {
            updateCargosonDataConsignment(listIndex, event.target.name, false)
        }
    }

    const handleDeliveryCityChange = (event: any) => {
        //change incoterm city value to delivery city value
        var tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
            ...item,
            cargosonData: { ...item.cargosonData, "incoterm_city": event.target.value, "delivery_city": event.target.value }
        } : item);
        setConsignmentList(tempArr);
    }

    const handleTimeChangePreset = (presetName: string) => {
        if (presetName === "Day") {
            const fromTime = new Date();
            fromTime.setHours(8, 0, 0, 0);

            const toTime = new Date();
            toTime.setHours(15, 0, 0, 0);

            setTimeFrom(fromTime);
            setTimeTo(toTime);
            setDeliveryTimePresetValue("Day")
            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: { ...item.cargosonData, delivery_time_from: fromTime?.toLocaleTimeString("et-EE"), delivery_time_to: toTime?.toLocaleTimeString("et-EE") }
            } : item);
            setConsignmentList(tempArr);
        }

        if (presetName === "7:00") {
            const fromTime = new Date();
            fromTime.setHours(7, 0, 0, 0);

            /* const toTime = new Date();
            toTime.setHours(7, 30, 0, 0); */

            setTimeFrom(fromTime);
            setDeliveryTimePresetValue("7:00")
            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: { ...item.cargosonData, delivery_time_from: fromTime?.toLocaleTimeString("et-EE") }
            } : item);
            setConsignmentList(tempArr);
        }

        if (presetName === "7:30") {
            const fromTime = new Date();
            fromTime.setHours(7, 30, 0, 0);

            /*  const toTime = new Date();
             toTime.setHours(8, 0, 0, 0); */

            setTimeFrom(fromTime);
            setDeliveryTimePresetValue("7:30")
            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: { ...item.cargosonData, delivery_time_from: fromTime?.toLocaleTimeString("et-EE") }
            } : item);
            setConsignmentList(tempArr);
        }
        if (presetName === "8:00") {
            const fromTime = new Date();
            fromTime.setHours(8, 0, 0, 0);

            /* const toTime = new Date();
            toTime.setHours(8, 0, 0, 0); */

            setTimeFrom(fromTime);
            setDeliveryTimePresetValue("8:00")
            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: { ...item.cargosonData, delivery_time_from: fromTime?.toLocaleTimeString("et-EE") }
            } : item);
            setConsignmentList(tempArr);
        }
    }

    return (
        <div className="cargoson-container__address-form-wrapper" style={{ backgroundColor: "#f8f8f8" }}>
            <div className='flex flex-wrap justify-start items-center' style={{ gap: "1%" }}>
                <h2 className="cargoson-container__address-form--title title-width">
                    {t("Mahalaadimiskoht")}
                </h2>
                <div className='flex justify-start transport-order-block--seletions-box'>
                    <div>
                        <label>{t("Tagaluuk")}</label>
                        <input className="cargoson-container__address-form--checkbox" disabled={!isUserPermitted} name="delivery_with_tail_lift" type="checkbox" checked={cargosonData.delivery_with_tail_lift} onChange={handleCheckbox}></input>
                    </div>
                    <div>
                        <label>{t("Ettehelistamine")}</label>
                        <input className="cargoson-container__address-form--checkbox" disabled={!isUserPermitted} name="delivery_prenotification" type="checkbox" checked={cargosonData.delivery_prenotification} onChange={handleCheckbox}></input>
                    </div>
                    <div>
                        <label>{t("Kraanaga auto")}</label>
                        <input className="cargoson-container__address-form--checkbox" disabled={!isUserPermitted} name="delivery_with_crane" type="checkbox" checked={cargosonData.delivery_with_crane} onChange={handleCheckbox}></input>
                    </div>
                </div>
            </div>

            <div className="cargoson-container__address-form">
                <table className='cargoson-dropdown-table'>
                    <tbody>
                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Mahalaadimise aeg")}</td>}
                            <td>
                                <DateTimeCalendar handleChange={handleDeliveryDate} currentDate={deliveryDate} isUserPermitted={isUserPermitted} />
                            </td>

                            <td>
                                <DateTimeCalendar handleChange={handleTimeFrom} currentDate={timeFrom} showTimeSelectOnly={true} placeHolder={t("Alates")} isUserPermitted={isUserPermitted} />
                            </td>
                            <td>
                                <DateTimeCalendar handleChange={handleTimeTo} currentDate={timeTo} showTimeSelectOnly={true} placeHolder={t("Kuni")} isUserPermitted={isUserPermitted} />
                            </td>
                            <td>
                                <select disabled={!isUserPermitted} className="cargoson-container__address-form--input" placeholder={t("Eeltäidetud kellaajad")} value={deliveryTimePresetValue ?? ""} onChange={(e: any) => handleTimeChangePreset(e.target.value)}>
                                    <option value="" disabled hidden>{t("Eeltäidetud kellaajad")}</option>
                                    <option value="Day">Terve päev</option>
                                    <option value="7:00">7:00</option>
                                    <option value="7:30">7:30</option>
                                    <option value="8:00">8:00</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Ettevõte")}</td>}
                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Ettevõte")} name="delivery_company_name" value={cargosonData.delivery_company_name || ""} onChange={handleDynamicChange}></input>
                            </td>
                        </tr>
                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Aadress")}</td>}
                            <td>
                                <input className="cargoson-container__address-form--input" placeholder={t("Tänav")} readOnly={!isUserPermitted} name="delivery_address_row_1" value={cargosonData.delivery_address_row_1 || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" placeholder={t("Aadressi lisa")} readOnly={!isUserPermitted} name="delivery_address_row_2" value={cargosonData.delivery_address_row_2 || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" placeholder={t("Postiindeks")} readOnly={!isUserPermitted} name="delivery_postcode" value={cargosonData.delivery_postcode || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" placeholder={t("Linn")} readOnly={!isUserPermitted} name="delivery_city" value={cargosonData.delivery_city || ""} onChange={handleDeliveryCityChange}></input>
                            </td>
                            <td>
                                <select className="cargoson-container__address-form--input" placeholder={t("Riik")} disabled={!isUserPermitted} name="delivery_country" value={cargosonData.delivery_country || ""} onChange={handleDynamicChange}>
                                    <option value="" disabled hidden>{t("Vali riik")}</option>
                                    <option value="EE">{t("Estonia")}</option>
                                    <option value="FI">{t("Finland")}</option>
                                    <option value="LV">{t("Latvia")}</option>
                                    <option value="LT">{t("Lithuania")}</option>
                                    <option value="SE">{t("Sweden")}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Kontakt")}</td>}

                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Ees- ja perekonnanimi")} name="delivery_contact_name" value={cargosonData.delivery_contact_name || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Telefoni nr")} name="delivery_contact_phone" value={cargosonData.delivery_contact_phone || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td colSpan={2} style={{ textAlign: 'left' }}>
                                <input className="cargoson-container__address-form--input"
                                    readOnly={!isUserPermitted}
                                    placeholder={t("E-mail")}
                                    name="delivery_contact_email"
                                    value={cargosonData.delivery_contact_email || ""}
                                    onChange={handleDynamicChange}
                                ></input>
                            </td>
                        </tr>
                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Kommentaar")}</td>}

                            <td colSpan={2} style={{ textAlign: "left" }}>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Kommentaar")} name="delivery_comment" value={cargosonData.delivery_comment || ""} onChange={handleDynamicChange}></input>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DropdownAddressFinland   