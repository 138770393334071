import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConvertDate } from '../../../components/common/ConvertDate';
import DeliveryOrder from '../../../components/tracking-app/driver/DeliveryOrderNew';
import { CompleteDelivery, GetDeliveryDriverTransportRoute, StartDelivery, UpdateInHouseConsignmentStatus, UpdateMultipleDeliveries } from '../../../queries/deliveryDriverQueries';
import { DeliveryDriver } from '../../../Types/delivery-app/DeliveryDriver';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrow';
import { HandleGetInHouseConsignmentStatus } from '../../../components/tracking-app/driver/DeliveryStatusBadge';
import CreateIcon from '@mui/icons-material/Create';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ReactComponent as UpdateIcon } from '../../../assets/tracking-app/update-icon.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/tracking-app/arrow-left.svg';
import CompletedIcon from '@mui/icons-material/Done';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../../components/login/user';

const DriverTransportRoute = () => {
  const location = useLocation();
  const { permissions } = useCustomer();
  const queryParams = new URLSearchParams(location.search);
  const calendardate = queryParams.get('calendardate');
  const [calendarDate, setCalendarDate] = useState<Date>(calendardate ? ConvertDate(calendardate) : new Date());
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [deliveryDriver, setDeliveryDriver] = useState<DeliveryDriver>({} as DeliveryDriver);
  const [consignmentsLoaded, setConsignmentsLoaded] = useState<InHouseConsignment[]>(deliveryDriver.consignmentsLoaded);
  const formattedDate = calendarDate.toLocaleString('et-EE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  const [updateTransportRoute, setUpdateTransportRoute] = useState<boolean>(false);

  const handleGetDeliveryDriver = () => {
    if (id === undefined) return;
    var date = calendarDate.toLocaleString('et-EE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    GetDeliveryDriverTransportRoute(+id, date)
      .then((res) => {
        setDeliveryDriver(() => res.data);
      })
      .catch((err) => {
        console.error();
      });
  };

  const handleStatusChange = (consignmentId: number, status: ConsignmentStatus) => {
    if (status === ConsignmentStatus.InTransit && id !== undefined) {
      StartDelivery(consignmentId, +id).then((response: any) => {
        navigate(`/carrier-driver/delivery-details/${consignmentId}`);
      });
    }
    if (status === ConsignmentStatus.Finished && id !== undefined) {
      CompleteDelivery(consignmentId);
      handleGetDeliveryDriver();
      navigate(`/carrier-driver/${id}?calendardate=${formattedDate}`)

    }
    UpdateInHouseConsignmentStatus(consignmentId, status)
      .then((response: any) => {
        handleGetDeliveryDriver();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleNavigate = (deliveryId: number) => {
    const deliveryToNavigate = consignmentsLoaded.find((x) => x.id === deliveryId);
    if (deliveryToNavigate?.consignmentStatus === ConsignmentStatus.InTransit || deliveryToNavigate?.consignmentStatus === ConsignmentStatus.WaitingClient) {
      navigate(`/carrier-driver/delivery-details/${deliveryId}`);
    } else {
      navigate(`/carrier-driver/delivery-details/${deliveryId}`);
    }
  };

  const handleUpdateArray = (e: any, deliveryToAdd: InHouseConsignment) => {
    e.stopPropagation();
    if (e.target.checked) {
      setConsignmentsLoaded((prevConsignments) => [...prevConsignments, deliveryToAdd]);
    } else {
      setConsignmentsLoaded((prevConsignments) => prevConsignments.filter((consignment) => consignment.id !== deliveryToAdd.id));
    }
  };

  const handleUpdateButtonClick = async () => {
    if (updateTransportRoute) {
      if (!id) return;
      setUpdateTransportRoute(false);
      const multipleDeliveries = {
        deliveryDriverId: +id,
        consignmentIds: consignmentsLoaded.map((x) => x.id)
      };
      await UpdateMultipleDeliveries(multipleDeliveries);
      handleGetDeliveryDriver();
      return;
    } else if (!updateTransportRoute) {
      setUpdateTransportRoute(true);
    }
  };

  const handleDropOffDelivery = (deliveryData: InHouseConsignment) => {
    if (deliveryData.type === "Export") {
      navigate(`/carrier-driver/end-delivery/${deliveryData.id}`)
      return;
    }
    if (deliveryData.type === "Import") {
      navigate(`/carrier-driver/delivery-details/${deliveryData.id}`);
    }
  }

  useEffect(() => {
    handleGetDeliveryDriver();
  }, [calendarDate]);

  useEffect(() => {
    setConsignmentsLoaded(deliveryDriver.consignmentsLoaded);
  }, [deliveryDriver.consignmentsLoaded]);
  if (!permissions.includes("PInHouseDriver")) {
    return <Navigate to="/unauthorized" />
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <header className="delivery-driver--header">
        <nav className="flex justify-between">
          <div
            className="flex flex-row justify-between items-center"
            style={{
              width: '100%',
              justifyContent: 'space-between',
              padding: '2rem',
              paddingLeft: "3rem",
              paddingRight: "3rem"
            }}
          >
            <div className="flex flex-col justify-start items-center">
              <IconButton onClick={() => navigate(`/carrier-driver/${id}?calendardate=${formattedDate}`)}>
                <ArrowLeftIcon />
              </IconButton>
              <label className="header--button-label">Tänased veod</label>
            </div>

            <div className="header--title">Veoring 1</div>

            {deliveryDriver.consignmentsLoaded?.length > 0 ? (
              <div className="flex flex-col justify-start items-center">
                <IconButton onClick={handleUpdateButtonClick}>
                  <UpdateIcon />
                </IconButton>
                <label className="header--button-label">{updateTransportRoute ? 'Kinnita muudatus' : 'Muuda'}</label>
              </div>
            ) : (
              <div className="flex flex-col justify-start"></div>
            )}
          </div>
        </nav>
      </header>

      <div style={{ maxWidth: '1000px', width: '100%' }}>
        {!updateTransportRoute && (
          <ul className="m-top-neg-75">
            {deliveryDriver?.consignmentsLoaded && deliveryDriver?.consignmentsLoaded.length > 0 ? (
              deliveryDriver?.consignmentsLoaded.map((x, idx) => (
                <React.Fragment key={idx}>
                  <li className="flex items-center justify-end">
                    {updateTransportRoute && (
                      <div className="flex flex-col items-center justify-center">
                        <input
                          type="checkbox"
                          style={{
                            width: '30px',
                            height: '30px',
                            cursor: 'pointer'
                          }}
                          checked={deliveryDriver.consignmentsLoaded?.includes(x)}
                          onChange={(e: any) => handleUpdateArray(e, x)}
                        />
                      </div>
                    )}
                    <DeliveryOrder deliveryData={x} handleNavigate={handleNavigate} />
                    <div className="flex flex-col items-start gap-xs" style={{ padding: '1rem', paddingLeft: '0', paddingRight: '0', background: '#cbcbcb', width: '150px', marginTop: '-5rem' }}>
                      <HandleGetInHouseConsignmentStatus statusNumber={x.consignmentStatus} />
                      {x.consignmentStatus === ConsignmentStatus.Loaded && (
                        <div className="flex flex-col items-center m-auto">
                          <div className="flex flex-col gap-xs justify-center">
                            <IconButton
                              onClick={() => {
                                handleStatusChange(x.id, ConsignmentStatus.InTransit);
                              }}
                              style={{ background: '#fff', borderRadius: '10px' }}
                            >
                              <PlayArrowOutlinedIcon fontSize="large" htmlColor="gray" />
                            </IconButton>
                            <label style={{ color: '#737372', fontSize: '13px', marginLeft: '7px', fontWeight: 'bold' }}>Alusta</label>
                          </div>
                        </div>
                      )}

                      {x.consignmentStatus === ConsignmentStatus.InTransit && (
                        <div className="flex flex-col items-center m-auto">
                          <div className="flex flex-col gap-xs justify-center">
                            <IconButton
                              style={{ background: '#fff', borderRadius: '10px' }}
                              onClick={() => {
                                handleStatusChange(x.id, ConsignmentStatus.WaitingClient);
                                navigate(`/carrier-driver/delivery-details/${x.id}`);
                              }}
                            >
                              <LocationOnIcon fontSize="large" htmlColor="gray" />
                            </IconButton>
                            <label style={{ color: '#737372', fontSize: '13px', marginLeft: '7px', fontWeight: 'bold' }}>Olen kohal</label>
                          </div>
                        </div>
                      )}

                      {x.consignmentStatus === ConsignmentStatus.WaitingClient && (
                        <div className="flex flex-col items-center m-auto">
                          <div className="flex flex-col gap-xs justify-center">
                            <IconButton
                              style={{ background: '#fff', borderRadius: '10px' }}
                              onClick={() => {
                                handleDropOffDelivery(x)
                              }}
                            >
                              <CreateIcon fontSize="large" htmlColor="gray" />
                            </IconButton>
                            <label style={{ color: '#737372', fontSize: '13px', marginLeft: '7px', fontWeight: 'bold' }}>Anna üle</label>
                          </div>
                        </div>
                      )}
                      {(x.consignmentStatus === ConsignmentStatus.Delivered || x.consignmentStatus === ConsignmentStatus.UnSigned) && (
                        <div className="flex flex-col items-center m-auto">
                          <div className="flex flex-col gap-xs justify-center items-center">
                            <IconButton
                              style={{ background: '#fff', borderRadius: '10px' }}
                              onClick={() => {
                                handleStatusChange(x.id, ConsignmentStatus.Finished);
                              }}
                            >
                              <CompletedIcon htmlColor="gray" fontSize="large" />
                            </IconButton>
                            <label className="delivery-calendar-view--menu-button-text">Lõpeta</label>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                </React.Fragment>
              ))
            ) : (
              <div className="flex justify-center items-center w-100" style={{ marginTop: '10%' }}>
                <div style={{ fontWeight: '500', color: '#cbcbcb', textAlign: 'center', width: '250px' }}>Veod puuduvad</div>
              </div>
            )}
          </ul>
        )}

        {updateTransportRoute && (
          <ul className="m-top-neg-75">
            {deliveryDriver?.consignments && deliveryDriver?.consignments.length > 0 ? (
              deliveryDriver?.consignments.map((x, idx) => (
                <React.Fragment key={idx}>
                  {x.consignmentStatus !== ConsignmentStatus.Delivered && (
                    <li
                      style={{
                        display: 'flex',
                        marginBottom: '1em',
                        gap: '0.5em'
                      }}
                    >
                      <div className="flex flex-col items-center justify-center">
                        <input
                          type="checkbox"
                          style={{
                            width: '30px',
                            height: '30px',
                            cursor: 'pointer'
                          }}
                          checked={consignmentsLoaded?.some((consignment) => consignment.id === x.id)}
                          onChange={(e: any) => handleUpdateArray(e, x)}
                        />
                      </div>
                      <DeliveryOrder deliveryData={x} handleNavigate={() => { }} />
                    </li>
                  )}
                </React.Fragment>
              ))
            ) : (
              <div className="flex justify-center items-center w-100" style={{ marginTop: '10%' }}>
                <div style={{ fontWeight: '500', color: '#cbcbcb', textAlign: 'center', width: '250px' }}>Veod puuduvad</div>
              </div>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
export default DriverTransportRoute;
