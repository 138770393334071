import { Consignment } from '../Types/Consignment';
import { apiUrl } from "../config/apiconfig";
import { instance } from './auth'
import { TransportRoute } from '../Types/TransportRoute';

export const UpdateCustomerReference = async (transportRouteId:number, transportRouteDirectoCode:string)=>{
    return instance.post<TransportRoute>(`${apiUrl}/api/transportRoute/update/customer_reference?transportRouteId=${transportRouteId}&transportRouteDirectoCode=${transportRouteDirectoCode}`, { headers: { "Content-Type": "application/json" } });
}

export const SaveTransportRoute = async (dto: TransportRoute)=>{
    return instance.post<TransportRoute>(`${apiUrl}/api/transportRoute/save/`, JSON.stringify(dto), { headers: { "Content-Type": "application/json" } });
}

export const ArchiveTransportRoute = async (transportRouteId: number)=>{
    return instance.get(`${apiUrl}/api/transportRoute/archive?transportRouteId=${transportRouteId}`, { headers: { "Content-Type": "application/json" } });
}

export const DeleteTransportRoute = async (transportRouteId: number)=>{
    return instance.delete(`${apiUrl}/api/transportRoute/delete?transportRouteId=${transportRouteId}`, { headers: { "Content-Type": "application/json" } });
}

export const CreateTransportRouteFromPreset = async (transportRouteId:number,presetValue:number) => {
    return instance.get(`${apiUrl}/api/transportroute/create/preset?transportRouteId=${transportRouteId}&presetValue=${presetValue}`,{ headers: { "Content-Type": "application/json" } });
}

export const SearchAndSortTransportRoutes = async (searchTerm:string,page: number, pageSize:number, isArchived:boolean, sortBy:string, isDescending:boolean, originCountry:string ) => {
    return instance.get(`${apiUrl}/api/transportroute/search/all?searchTerm=${searchTerm}&page=${page}&pageSize=${pageSize}&isArchived=${isArchived}&sortBy=${sortBy}&isDescending=${isDescending}&originCountry=${originCountry}`, { headers: { "Content-Type": "application/json" } });
}

export const SearchFinlandTransportRoutes = async (searchTerm:string,page: number, pageSize:number, originCountry:string) => {
    return instance.get(`${apiUrl}/api/transportroute/search/finland/all?searchTerm=${searchTerm}&page=${page}&pageSize=${pageSize}&originCountry=${originCountry}`, { headers: { "Content-Type": "application/json" } });
}

export const RemoveConsignmentFromTransportRoute = async (consignmentId: number, transportRouteId:number) =>{
    return instance.delete(`${apiUrl}/api/transportroute/remove/consignment?consignmentId=${consignmentId}&transportRouteId=${transportRouteId}`, { headers: { "Content-Type": "application/json" } });
}

export const GetRecommendedTransportRoutes = async (pickupDate:string, originCountry:string) => {
    return instance.get(`${apiUrl}/api/transportroute/get/collectionDate?pickupDate=${pickupDate}&originCountry=${originCountry}`, { headers: { "Content-Type": "application/json" } });
}

export const CreateTransportRoute = async (userName:string, userEmail:string, originCountry:string) =>{
    return instance.post(`${apiUrl}/api/transportroute/create?userName=${userName}&userEmail=${userEmail}&originCountry=${originCountry}`, { headers: { "Content-Type": "application/json" } });
}

export const GetTransportRoute = async (transportRouteId:number) =>{
    return instance.get(`${apiUrl}/api/transportroute/get?transportRouteId=${transportRouteId}`, { headers: { "Content-Type": "application/json" } });
}

export const UpdateTransportRouteProperty = async (transportRouteId:number, propertyName:string, propertyValue:string) =>{
    return instance.put<TransportRoute>(`${apiUrl}/api/transportroute/update/property?transportRouteId=${transportRouteId}&propertyName=${propertyName}&propertyValue=${propertyValue}`, { headers: { "Content-Type": "application/json" } });
}

export const GetTransportRouteByDirectoCode = async (directoCode:string) =>{
    return instance.get(`${apiUrl}/api/transportroute/get/directo?directoCode=${directoCode}`, { headers: { "Content-Type": "application/json" } });
}

export const AddConsignmentToNewTransportRoute = async (consignmentId: number, creatorName:string, creatorEmail:string, title:string, originCountry:string) =>{
    // create new route and add consigment there
    return instance.post(`${apiUrl}/api/transportroute/create/add?consignmentId=${consignmentId}&creatorName=${creatorName}&creatorEmail=${creatorEmail}&title=${title}&originCountry=${originCountry}`, { headers: { "Content-Type": "application/json" } });
}

export const AddConsignmentToTransportRoute = async (consignmentId: number, transportRouteId: number) => {
    // create new route and add consigment there
    return instance.post(`${apiUrl}/api/transportroute/add?consignmentId=${consignmentId}&transportRouteId=${transportRouteId}`, { headers: { "Content-Type": "application/json" } });
}

export const AddNewConsignmentToTransportRoute = async (transportRouteId: number, country:string) => {
    // create new finland consigment and it to transport route
    return instance.post(`${apiUrl}/api/transportroute/add/new?transportRouteId=${transportRouteId}&country=${country}`, { headers: { "Content-Type": "application/json" } });
}

export const AddConsignmentListToTransportRoute = async (transportRouteId:number, consignmentList: Consignment[]) => {
    // add all consigments to transport route
    return instance.post(`${apiUrl}/api/transportroute/add/list?transportRouteId=${transportRouteId}`, JSON.stringify(consignmentList),{ headers: { "Content-Type": "application/json" } });
}

export const SaveTransportRouteToDirecto = async (transportRouteDto: TransportRoute) => {
    return instance.post(`${apiUrl}/api/directo/post/transportRoute`, JSON.stringify(transportRouteDto), { headers: { "Content-Type": "application/json" } });
}

export const UpdateCollectionDateAllConsignments = async ( transportRouteId:number, collectionDate:string)=>{
    return instance.get(`${apiUrl}/api/transportroute/update/collection-date?transportRouteId=${transportRouteId}&collectionDate=${collectionDate}`, { headers: { "Content-Type": "application/json" } });
}

export const GetGroupedTransportRoutesAndConsignments = async (  page:number, pageSize:number, isArchived:boolean, sortBy:string, isDescending:boolean, dateFrom:string, dateTo:string, originCountry:string, searchTerm?:string)=>{
    return instance.get(`${apiUrl}/api/transportroute/get/fin/calendar?searchTerm=${searchTerm}&page=${page}&pageSize=${pageSize}&isArchived=${isArchived}&sortBy=${sortBy}&isDescending=${isDescending}&dateFrom=${dateFrom}&dateTo=${dateTo}&originCountry=${originCountry}`, { headers: { "Content-Type": "application/json" } });
}