import React from 'react'
import LoaderDeskdopCalendarView from '../../../components/tracking-app/loader/LoaderDeskdopCalendarView';
import LoaderMobileCalendarView from '../../../components/tracking-app/loader/LoaderMobileCalendarView';

const LoaderCalendarView = () => {
    const screenWidth = window.screen.width;
    const renderNavbar = () => {
        if (screenWidth <= 800) {
            return <LoaderMobileCalendarView />;
        }
        else {
            return <LoaderDeskdopCalendarView />
        }
    }
    return (
        <div>
            {renderNavbar()}
        </div>
    )
}

export default LoaderCalendarView