import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as LetsGoLogo } from '../assets/tracking-app/LetsGoLogo.svg';
import { useCustomer } from '../components/login/user';
import { useDataContext } from '../context/DataContext';
import { CargosonChild, CargosonQuery } from '../Types/cargoson/CargosonQuery';
import { Package } from '../Types/cargoson/Package';
import { Consignment } from '../Types/Consignment';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import { ReactComponent as TruckLogo } from '../assets/truck-logo.svg';
import { ReactComponent as EstonianFlag } from '../assets/estonia-flag-icon.svg';
import { ReactComponent as FinnishFlag } from '../assets/finland-flag-icon.svg';
import { ReactComponent as UKFlag } from '../assets/united-kingdom-flag-icon.svg';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TvIcon from '@mui/icons-material/Tv';
import { useTranslation } from 'react-i18next';
import { AppBar, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, useMediaQuery } from '@mui/material';
import '../styles/navbar.css';
import Popover from '@mui/material/Popover';
import SettingsIcon from '@mui/icons-material/Settings';
import LanguageIcon from '@mui/icons-material/Language';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { useEffect, useRef, useState } from 'react';
import DefaultNavbar from './DefaultNavbar';
import TrackingAppNavbar from './TrackingAppNavbar';

const Navbar: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [userMenuPopoverOpen, setUserMenuPopoverOpen] = React.useState(false);
  const [languagePopoverOpen, setLanguagePopoverOpen] = React.useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { setConsignmentList, setConsignmentId, setTransportRouteId } = useDataContext();
  const { logout, permissions, isAdmin, isFinnishUserPermitted } = useCustomer();
  const isWideScreen = useMediaQuery('(min-width:768px)');
  const [showNavbar, setShowNavbar] = useState(true);
  const location = useLocation();
  const navbarRef = useRef(null);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }

    setIsOpen(open);
  };

  const openPopover = (event: any, popoverType: string) => {
    if (popoverType === 'languageMenu') {
      setLanguagePopoverOpen(true);
      setLanguageMenuAnchorEl(event.currentTarget);
    }
    if (popoverType === 'userMenu') {
      setUserMenuPopoverOpen(true);
      setUserMenuAnchorEl(event.currentTarget);
    }
  };

  const closePopover = () => {
    setUserMenuPopoverOpen(false);
    setLanguagePopoverOpen(false);
  };

  const viewFinnishPagesPermission = () => {
    if (permissions.includes('PFinnishUser') === true) {
      return true;
    }
    if (permissions.includes('PTransportCompanyUser') === true) {
      return true;
    }
    return false;
  };

  const viewSwedishPagesPermission = () => {
    if (permissions.includes('PSwedishUser') === true) {
      return true;
    }
    if (permissions.includes('PManageUsers') === true) {
      return true;
    }
    return false;
  };

  const viewFinnishTvPagePermission = () => {
    if (permissions.includes('PFinnishTvViewer') === true) {
      return true;
    }
    if (permissions.includes('PFinnishUser') === true) {
      return true;
    }
    if (permissions.includes('PTransportCompanyUser') === true) {
      return true;
    }
    return false;
  };

  const sideList = () => (
    <div
      style={{
        background: 'linear-gradient(180deg, #F5F5F5 0%, #EEEEEE 100%)',
        backgroundColor: '#fff',
        height: '100%'
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {permissions.includes('PEstonianUser') === true && (
          <ListItem className="navbar--side-list-item" onClick={() => handleNavigation('consignment')}>
            {isWideScreen && (
              <ListItemIcon>
                <TruckLogo />
              </ListItemIcon>
            )}
            <ListItemText primary={t('Eesti veotellimus')} />
          </ListItem>
        )}

        {permissions.includes('PEstonianUser') === true && (
          <ListItem className="navbar--side-list-item" onClick={() => handleNavigation('transport-route')}>
            {isWideScreen && (
              <ListItemIcon>
                <AirlineStopsIcon style={{ fill: 'rgb(77, 77, 77)' }} />
              </ListItemIcon>
            )}
            <ListItemText primary={t('Eesti koondveotellimus')} />
          </ListItem>
        )}

        {viewFinnishPagesPermission() === true && (
          <ListItem className="navbar--side-list-item" onClick={() => handleNavigation('/overview')}>
            {isWideScreen && (
              <ListItemIcon>
                <DateRangeIcon style={{ fill: 'rgb(77, 77, 77)' }} />
              </ListItemIcon>
            )}
            <ListItemText primary={t('Tuusula veotellimus')} />
          </ListItem>
        )}

        {viewFinnishTvPagePermission() === true && (
          <ListItem className="navbar--side-list-item" onClick={() => handleNavigation('/tv')}>
            {isWideScreen && (
              <ListItemIcon>
                <TvIcon style={{ fill: 'rgb(77, 77, 77)' }} />
              </ListItemIcon>
            )}
            <ListItemText primary={t('Tuusula televiisori vaade')} />
          </ListItem>
        )}

        {viewSwedishPagesPermission() === true && (
          <ListItem className="navbar--side-list-item" onClick={() => handleNavigation('/sweden/transport-orders')}>
            {isWideScreen && (
              <ListItemIcon>
                <DateRangeIcon style={{ fill: 'rgb(77, 77, 77)' }} />
              </ListItemIcon>
            )}
            <ListItemText primary={t('Rootsi veotellimus')} />
          </ListItem>
        )}

        {viewSwedishPagesPermission() === true && (
          <ListItem className="navbar--side-list-item" onClick={() => handleNavigation('/sweden/tv')}>
            {isWideScreen && (
              <ListItemIcon>
                <TvIcon style={{ fill: 'rgb(77, 77, 77)' }} />
              </ListItemIcon>
            )}
            <ListItemText primary={t('Rootsi televiisori vaade')} />
          </ListItem>
        )}
        {permissions.includes('PInHouseDriver') === true && (
          <ListItem className="navbar--side-list-item" onClick={() => handleNavigation('/drivers/all')}>
            {isWideScreen && (
              <ListItemIcon>
                <CoPresentIcon style={{ fill: 'rgb(77, 77, 77)' }} />
              </ListItemIcon>
            )}
            <ListItemText primary="Autojuhi vaade" />
          </ListItem>
        )}
        {permissions.includes('PInHouseDriverManager') === true && (
          <ListItem className="navbar--side-list-item" onClick={() => handleNavigation('/delivery')}>
            {isWideScreen && (
              <ListItemIcon>
                <CoPresentIcon style={{ fill: 'rgb(77, 77, 77)' }} />
              </ListItemIcon>
            )}
            <ListItemText primary="Müügivaade" />
          </ListItem>
        )}
        {permissions.includes('PEstonianWareHouseWorker') === true && (
          <ListItem className="navbar--side-list-item" onClick={() => handleNavigation('/loader')}>
            {isWideScreen && (
              <ListItemIcon>
                <CoPresentIcon style={{ fill: 'rgb(77, 77, 77)' }} />
              </ListItemIcon>
            )}
            <ListItemText primary="Laadija vaade" />
          </ListItem>
        )}
      </List>
    </div>
  );

  const handleNavigation = (pageName: string) => {
    if (pageName === 'consignment') {
      setConsignmentId('');
      setTransportRouteId('');
      setConsignmentList([{ cargosonData: { pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery } as Consignment] as Consignment[]);
      navigate('/');
      return;
    }
    if (pageName === 'transport-route') {
      setTransportRouteId('');
      navigate('/transport-route');
      return;
    }
    if (pageName === 'admin') {
      setUserMenuPopoverOpen(false);
      navigate('/admin');
      return;
    } else {
      navigate(`${pageName}`);
    }
  };

  const handleBackButton = () => {
    if (window.location.pathname.startsWith('/consignmentDetails')) {
      setConsignmentId('');
      setTransportRouteId('');
      setConsignmentList([{ cargosonData: { pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery } as Consignment] as Consignment[]);
      navigate('/');
      return;
    }
    if (window.location.pathname.startsWith('/transport-route/details')) {
      setConsignmentId('');
      setTransportRouteId('');
      setConsignmentList([{ cargosonData: { pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery } as Consignment] as Consignment[]);
      navigate('/transport-route');
      return;
    }
    if (window.location.pathname === '/transport-route') {
      setTransportRouteId('');
      return;
    }
    if (window.location.pathname.startsWith('/tuusula')) {
      setConsignmentId('');
      setTransportRouteId('');
      setConsignmentList([{ cargosonData: { pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery } as Consignment] as Consignment[]);
      navigate('/overview');
      return;
    }
    if (window.location.pathname.startsWith('/sweden')) {
      setConsignmentId('');
      setTransportRouteId('');
      setConsignmentList([{ cargosonData: { pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery } as Consignment] as Consignment[]);
      navigate('/sweden/transport-orders');
      return;
    } else {
      navigate(-1);
    }
  };

  const handlechangeLanguage = (languageValue: string) => {
    i18n.changeLanguage(languageValue);
    localStorage.setItem('i18nextLng', languageValue);
    setLanguagePopoverOpen(false);
  };

  const userIconSx = {
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#c00'
    }
  };
  var isTrackingApp = (location.pathname.startsWith('/delivery') || location.pathname.startsWith('/loader') || location.pathname.startsWith('/drivers')) ? true : false

  const renderNavbar = () => {
    if (location.pathname.startsWith('/carrier-driver')) {
      return <TrackingAppNavbar />;
    }
    return (
      <>
        <AppBar
          position="fixed"
          style={{
            background: isTrackingApp ? "#fff" : "linear-gradient(360deg, #0C0C0C 0%, #3D3C38 100%)",
            boxShadow: 'none',
            top: showNavbar ? '0' : '-64px',
            transition: 'top 0.5s'
          }}
          ref={navbarRef}
        >
          <Toolbar style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div className="w-25">
              <IconButton edge="start" sx={{ marginRight: '3px' }} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon style={{ color: isTrackingApp ? "#737372" : "#fff" }} />
              </IconButton>
            </div>

            <div className="justify-center" style={{ display: 'flex', gap: '50px', flexGrow: '1' }}>
              <LetsGoLogo />
            </div>

            <div className="navbar--user-button-group">
              <IconButton sx={{ fontSize: '15px' }} color="inherit" onClick={handleBackButton}>
                <ArrowBackIosIcon style={{ color: isTrackingApp ? "#737372" : "#fff" }} />
              </IconButton>

              <IconButton edge="start" sx={{ fontSize: '15px' }} color="inherit" title="Language" onClick={(e: any) => openPopover(e, 'languageMenu')}>
                <LanguageIcon style={{ color: isTrackingApp ? "#737372" : "#fff" }} />
              </IconButton>
              <IconButton edge="start" sx={{ fontSize: '15px' }} color="inherit" title="Settings" onClick={(e: any) => openPopover(e, 'userMenu')}>
                <PersonIcon style={{ color: isTrackingApp ? "#737372" : "#fff" }} />
              </IconButton>

              <IconButton edge="start" sx={{ fontSize: '15px' }} title="Logout" color="inherit" onClick={logout}>
                <ExitToAppIcon style={{ color: isTrackingApp ? "#737372" : "#fff" }} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer open={isOpen} onClose={toggleDrawer(false)}>
          {sideList()}
        </Drawer>

        <Popover
          open={languagePopoverOpen}
          anchorEl={languageMenuAnchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <List>
            <ListItem sx={userIconSx} onClick={() => handlechangeLanguage('et')}>
              <ListItemIcon>
                <EstonianFlag style={{ width: '25px', height: '25px' }} />
              </ListItemIcon>
              <ListItemText primary="Eesti" />
            </ListItem>
            <ListItem sx={userIconSx} onClick={() => handlechangeLanguage('en')}>
              <ListItemIcon>
                <UKFlag style={{ width: '25px', height: '25px' }} />
              </ListItemIcon>
              <ListItemText primary="English" />
            </ListItem>
            <ListItem sx={userIconSx} onClick={() => handlechangeLanguage('fi')}>
              <ListItemIcon>
                <FinnishFlag style={{ width: '25px', height: '25px' }} />
              </ListItemIcon>
              <ListItemText primary="Suomi" />
            </ListItem>
          </List>
        </Popover>

        <Popover
          open={userMenuPopoverOpen}
          anchorEl={userMenuAnchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <List>
            <ListItem sx={userIconSx} onClick={() => setUserMenuPopoverOpen(false)}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Isiklikud sätted" secondary="" />
            </ListItem>
            {permissions.includes('PManageUsers') && (
              <ListItem sx={userIconSx} onClick={() => handleNavigation('admin')}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Admin" secondary="" />
              </ListItem>
            )}
          </List>
        </Popover>
      </>
    )
  };

  return (
    <div style={{ flexGrow: '1' }}>
      {renderNavbar()}
    </div>
  );
};

export default Navbar;
