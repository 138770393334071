import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../../styles/consignment-overview.css'
import '../../styles/tuusula-view.css'
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { ArchiveTransportRoute, DeleteTransportRoute } from '../../queries/transport-route-queries';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { clientUrl } from '../../config/apiconfig';

type Props = {
    onDelete: () => void;
    handleSortingChange: (field: string, setSortingState: React.Dispatch<React.SetStateAction<boolean>>, sortingState: boolean) => void
}
const TransportRoutesTable = ({ handleSortingChange, onDelete }: Props) => {
    const { t } = useTranslation();
    const [isDescendingCollectionDate, setIsDescendingCollectionDate] = useState(false);
    const [isDescendingDirectoCode, setIsDescendingDirectoCode] = useState(false);
    const navigate = useNavigate();
    const { transportRouteList } = useDataContext();

    const handleLoadTransportRoute = (id: number) => {
        if (id === undefined) return
        navigate(("/transport-route/details/" + id))
    }

    const handleArhciveTRoute = async (e: any, transportRouteId: number) => {
        e.stopPropagation();
        if (window.confirm(`Arhiveeri veoring?`)) {
            ArchiveTransportRoute(transportRouteId);
            onDelete()
        }
    }

    const handleUnArhciveTRoute = async (e: any, transportRouteId: number) => {
        e.stopPropagation();
        ArchiveTransportRoute(transportRouteId);
        onDelete()
    }

    const handleRemoveTRoute = async (e: any, transportRouteId: number) => {
        e.stopPropagation();
        if (window.confirm(`Kustuta veoring?`)) {
            DeleteTransportRoute(transportRouteId)
            onDelete()
        }
    }

    const goToDirecto = (e: any, directoCode: string) => {
        e.stopPropagation();
        if (directoCode) {
            window.open(`https://login.directo.ee/ocra_ets_nord/event.asp?KOOD=${directoCode}`, '_blank');
        }
        return;
    }

    return (
        <div className='consignment-table--container'>
            <table className="consignment-table--table">
                <thead>
                    <tr>
                        <td className='consignment-table--thead thead-left'>
                            <div className='flex-r-start'>
                                <div>Veoringi number</div>
                                {isDescendingDirectoCode ?
                                    <IconButton onClick={() => handleSortingChange("directoCode", setIsDescendingDirectoCode, isDescendingDirectoCode)}><NorthIcon /></IconButton>
                                    :
                                    <IconButton onClick={() => handleSortingChange("directoCode", setIsDescendingDirectoCode, isDescendingDirectoCode)}><SouthIcon /></IconButton>
                                }
                            </div>
                        </td>

                        <td className='consignment-table--thead left'>Vedaja</td>
                        <td className='consignment-table--thead left'>Tüüp</td>
                        <td className='consignment-table--thead left'>
                            <div className='flex-r-start'>
                                <div>
                                    Pealelaadimine
                                </div>
                                {isDescendingCollectionDate ?
                                    <IconButton onClick={() => handleSortingChange("collection_date", setIsDescendingCollectionDate, isDescendingCollectionDate)}><NorthIcon /></IconButton>
                                    :
                                    <IconButton onClick={() => handleSortingChange("collection_date", setIsDescendingCollectionDate, isDescendingCollectionDate)}><SouthIcon /></IconButton>
                                }
                            </div>
                        </td>
                        <td className='consignment-table--thead left'>Mahalaadimine</td>
                        <td className='consignment-table--thead left'>Sihtkoht</td>
                        <td className='consignment-table--thead left'>Tellija</td>
                        <td className='consignment-table--thead left'>Looja</td>
                        <td className='consignment-table--thead center'>Staatus</td>
                        <td className='consignment-table--thead center'></td>
                    </tr>
                </thead>

                <tbody>
                    {transportRouteList.map((transportRoute, idx) => (
                        <React.Fragment key={idx}>
                            <tr className="consignment-overview--tr">
                                <td className='text-regular left' style={{ fontSize: "20px", padding: "5px" }}>
                                    {transportRoute.directoCode !== null ?
                                        <IconButton title="Directosse" onClick={(e) => goToDirecto(e, transportRoute.directoCode)}><OpenInNewIcon /></IconButton> : ""
                                    }
                                    <a className='consignment-table--a' rel="noreferrer" target="_blank" href={`${clientUrl}/transport-route/details/${transportRoute.id}`}>
                                        {transportRoute.directoCode ? transportRoute.directoCode : "Pooleli"}
                                    </a>
                                </td>
                                <td className='text-regular left' style={{ fontSize: "20px", padding: "10px" }}>{transportRoute.consignment[0].carrierName ?? "-"}</td>
                                <td className='text-regular left' style={{ fontSize: "20px", padding: "10px" }}>{(transportRoute.consignment[0].type) ?? "-"}</td>
                                <td className='text-regular left' style={{ fontSize: "20px", padding: "10px" }}>
                                    {((transportRoute.consignment[0].cargosonData.pickups[0]?.collection_date) ?? "-") + " " +
                                        transportRoute.consignment[0].cargosonData.pickups[0].collection_city}
                                </td>
                                <td className='text-regular left' style={{ fontSize: "20px", padding: "10px" }}>
                                    {transportRoute.consignment.map((x, idx2) =>
                                    (
                                        <React.Fragment key={idx2}>
                                            {x.cargosonData.delivery_date &&
                                                <>
                                                    {idx2 + 1}: {(x.cargosonData.delivery_date)}
                                                    <br></br>
                                                </>
                                            }
                                        </React.Fragment>
                                    )
                                    )}
                                </td>
                                <td className='text-regular left' style={{ fontSize: "20px", padding: "10px" }}>{transportRoute.consignment.map((x, idx3) =>
                                (
                                    <React.Fragment key={idx3}>
                                        {x.cargosonData.delivery_city &&
                                            <>
                                                {idx3 + 1}: {x.cargosonData.delivery_city} {x.orderNumbers && x.orderNumbers + ""}  {x.cargosonData.pickups[0]?.rows_attributes[0]?.ldm > 0 && " " + (x.cargosonData.pickups[0]?.rows_attributes[0]?.ldm + " LDM")}
                                                <br></br>
                                            </>
                                        }
                                    </React.Fragment>
                                )
                                )}</td>
                                <td className='text-regular left' style={{ fontSize: "20px", padding: "10px" }}>{transportRoute.consignment.map((x, idx3) =>
                                (
                                    <React.Fragment key={idx3}>
                                        {x.cargosonData.delivery_company_name &&
                                            <>
                                                {x.cargosonData.delivery_company_name}
                                                <br></br>
                                            </>
                                        }
                                    </React.Fragment>
                                )
                                )}</td>

                                <td className='text-regular left' style={{ fontSize: "20px", padding: "10px" }}>{transportRoute.consignment[0].consignmentCreator}</td>

                                <td className='text-regular center' style={{ fontSize: "20px", padding: "10px" }}>
                                    <div style={{ fontSize: "15px", display: "flex", justifyContent: "center", gap: "5px", flexDirection: "column", alignContent: "center", }}>
                                        {transportRoute.consignment[0].consignmentStatus === "TR_ORDER" && transportRoute.consignment[0].isEmailSent === false && transportRoute.consignment[0].directoCode !== null &&
                                            <div style={{ background: "#e8f7f0", borderRadius: "20px", color: "#3eb668", whiteSpace: "nowrap", marginRight: "auto", marginLeft: "auto", width: "90%" }}>Directos
                                            </div>
                                        }
                                        {transportRoute.directoCode === null &&
                                            <div style={{ background: "#fbfbde", borderRadius: "20px", color: "#be5c23", whiteSpace: "nowrap", marginRight: "auto", marginLeft: "auto", width: "90%" }}>Pooleli
                                            </div>
                                        }
                                        {transportRoute.consignment[0].consignmentStatus === "TR_ORDER" && transportRoute.consignment[0].isEmailSent === true &&
                                            <div style={{ background: "#3eb668", borderRadius: "20px", color: "#fff", whiteSpace: "nowrap", marginRight: "auto", marginLeft: "auto", width: "90%" }}>Email
                                            </div>
                                        }
                                        {transportRoute.consignment[0].consignmentStatus === "TR_ORDER" && transportRoute.consignment[0].cargosonData.cargosonReturnId !== null &&
                                            <div style={{ background: "#3eb668", borderRadius: "20px", color: "#fff", whiteSpace: "nowrap", marginRight: "auto", marginLeft: "auto", width: "90%" }}>Cargosonis
                                            </div>
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        {transportRoute.directoCode === null &&
                                            <IconButton title="Kustuta" onClick={(e: any) => handleRemoveTRoute(e, +transportRoute.id)}>
                                                <CloseIcon />
                                            </IconButton>
                                        }
                                        {(transportRoute.directoCode !== null && transportRoute.isArchived === false) && (
                                            <IconButton title="Arhiveeri" onClick={(e: any) => handleArhciveTRoute(e, +transportRoute.id)}>
                                                <CloseIcon />
                                            </IconButton>)
                                        }
                                        {(transportRoute.isArchived === true) && (
                                            <IconButton title="Võta tagasi" onClick={(e: any) => handleUnArhciveTRoute(e, +transportRoute.id)}>
                                                <AddIcon />
                                            </IconButton>)
                                        }
                                    </div>
                                </td>
                            </tr>

                        </React.Fragment>
                    )
                    )}


                </tbody>

            </table>
        </div >
    )
}

export default TransportRoutesTable