import { useEffect } from 'react'
import Loading from '../common/Loading';
import { useCustomer } from './user';

const LoginRedirect = () => {
    const path = window.location.pathname;
    const guid = path.substring(path.lastIndexOf("/") + 1, path.length);
    const { login } = useCustomer();

    useEffect(() => {
        login(guid)
        // eslint-disable-next-line
    }, [])

    return <Loading />
}
export default LoginRedirect



