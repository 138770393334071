import '../../styles/CargosonComponent.css';
import DropdownAddressForm from "./DropdownAddressForm";
import PickupAddressForm from "./PickupAddressForm";
import AddPackageComponent from './package/AddPackageComponent';

const AddressForm = (props: { listIndex: number }) => {
  return (
    <>
      <div className='address-form-wrapper'>
        <PickupAddressForm idx={0} listIndex={props.listIndex} />
        <DropdownAddressForm listIndex={props.listIndex} />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <AddPackageComponent pickupIdx={0} listIndex={props.listIndex} />
      </div>
    </>
  );
};

export default AddressForm;