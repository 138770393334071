import { Button } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DeliveriesTable from '../../../components/consignment/Directo/DeliveriesTable';
import PackageRecieved from '../../../components/tracking-app/client/PackageRecieved';
import { FetchDeliveryDriverLocation, GetInHouseConsignmentWithDeliveryUrl } from '../../../queries/deliveryDriverQueries';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import TrackingDeliveryDriver from '../../../components/tracking-app/TrackingDeliveryDriver';
import Modal from '../../../components/common/Modal';
import { ClientInHouseConsignment } from '../../../Types/delivery-app/ClientInHouseConsignment';
import '../../../styles/tracking-app/active-delivery-details.css';

const ClientPage = () => {
  const { id } = useParams<{ id: string }>();
  const [deliveryData, setDeliveryData] = useState<ClientInHouseConsignment>({} as ClientInHouseConsignment);
  const [deliveryDriverLocation, setDeliveryDriverLocation] = useState<GeolocationPosition | null>(null);
  const [deliveryLocation, setDeliveryLocation] = useState<GeolocationPosition | null>(null);
  const [isDeliveryTableOpen, setIsDeliveryTableOpen] = useState<boolean>(false);
  const [endDeliveryOpen, setEndDeliveryOpen] = useState<boolean>(false);

  const handleLoadData = async () => {
    if (id !== undefined) {
      await GetInHouseConsignmentWithDeliveryUrl(id)
        .then((response: AxiosResponse<InHouseConsignment>) => {
          setDeliveryData(response.data);
          const deliveryGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.deliveryLatitude,
              longitude: response.data.deliveryLongitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: Date.now()
          };
          const driverGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.deliveryDriver.latitude,
              longitude: response.data.deliveryDriver.longitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: response.data.deliveryDriver.locationUpdateTime
          };

          setDeliveryLocation(deliveryGeolocationPosition);
          setDeliveryDriverLocation(driverGeolocationPosition);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const fetchDeliveryDriverLocation = async () => {
    if (id === undefined) return;
    FetchDeliveryDriverLocation(id).then((response: any) => {
      const driverGeolocationPosition: GeolocationPosition = {
        coords: {
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          altitude: null,
          accuracy: 0,
          altitudeAccuracy: null,
          heading: null,
          speed: null
        },
        timestamp: response.data.locationUpdateTime
      };
      setDeliveryDriverLocation(driverGeolocationPosition);
    });
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <div className="cargoson-container__address-form--title" style={{ fontSize: '24px', marginLeft: '0', padding: '2%', paddingTop: '1%' }}>
      {deliveryData.consignmentStatus === ConsignmentStatus.Cancelled && <div style={{ fontSize: '30px', marginBottom: '1%', width: '100%', display: 'flex', justifyContent: 'center' }}>Vedu on tühistatud</div>}
      {deliveryData.consignmentStatus === ConsignmentStatus.WaitingClient && <div style={{ fontSize: '30px', marginBottom: '1%', width: '100%', display: 'flex', justifyContent: 'center' }}>Kuller on teie juures</div>}
      {deliveryData.consignmentStatus === ConsignmentStatus.InTransit && <div style={{ fontSize: '30px', marginBottom: '1%', width: '100%', display: 'flex', justifyContent: 'center' }}>Kaup on teie poole teel</div>}
      {deliveryData.consignmentStatus === ConsignmentStatus.Delivered && <div style={{ fontSize: '30px', marginBottom: '1%', width: '100%', display: 'flex', justifyContent: 'center' }}>Kaup on kohale toimetatud</div>}
      <div style={{ fontSize: '25px' }}>Tellimuse info</div>

      <div className="cargoson-container__address-form--title" style={{ fontSize: '22px' }}>
        Kohaletoimetamise aeg: {deliveryData.deliveryDate} {deliveryData.deliveryTimeFrom} {deliveryData.deliveryTimeTo}
      </div>
      <div className="cargoson-container__address-form--title" style={{ fontSize: '22px' }}>
        Sihtkoha aadress: {deliveryData.deliveryAddressRow1}
      </div>

      <div className="cargoson-container__address-form--title" style={{ fontSize: '22px' }}>
        Lähetused:{' '}
        {deliveryData.directoDeliveryNumbers?.map((x, idx) => (
          <div
            key={idx}
            onClick={() => {
              setIsDeliveryTableOpen(!isDeliveryTableOpen);
            }}
            style={{ cursor: 'pointer' }}
          >
            {x}
            <DeliveriesTable deliveryNumber={x} setIsOpen={setIsDeliveryTableOpen} isOpen={isDeliveryTableOpen} />
          </div>
        ))}
      </div>
      <div className="cargoson-container__address-form--title" style={{ fontSize: '22px' }}>
        Müügitellimused:{' '}
        {deliveryData.directoOrderNumbers?.map((x, idx) => (
          <div key={idx}>{x}</div>
        ))}
      </div>
      {deliveryData.consignmentStatus !== 4 && (
        <div className="active-delivery--map-buttons-container">
          <div style={{ fontSize: '25px', marginTop: '1%' }}>Auto asukoht:</div>
          {/*  <TrackingDeliveryDriver fetchDeliveryDriverLocation={fetchDeliveryDriverLocation}
                        deliveryDriverLocation={deliveryDriverLocation} setDeliveryDriverLocation={setDeliveryDriverLocation} deliveryLocation={deliveryLocation} setDeliveryLocation={setDeliveryLocation} /> */}
        </div>
      )}

      {endDeliveryOpen && (
        <Modal maxWidth="80%" show={endDeliveryOpen} onClose={() => setEndDeliveryOpen(false)}>
          <PackageRecieved deliveryData={deliveryData} setDeliveryData={setDeliveryData} setEndDeliveryOpen={setEndDeliveryOpen} />
        </Modal>
      )}
      {deliveryData.consignmentStatus === 3 && !endDeliveryOpen && (
        <div style={{ display: 'flex', justifyContent: 'center', width: '50%', marginTop: '2%' }}>
          <Button variant="contained" style={{ color: '#fff', background: '#c00', fontWeight: 'bold', height: '55px' }} onClick={() => setEndDeliveryOpen(true)}>
            Olen kauba kätte saanud
          </Button>
        </div>
      )}
      {deliveryData.consignmentStatus === 4 && <PackageRecieved deliveryData={deliveryData} setDeliveryData={setDeliveryData} setEndDeliveryOpen={setEndDeliveryOpen} />}
    </div>
  );
};

export default ClientPage;
