import CargosonPriceListComponent from "./CargosonPriceListComponent";
import CargosonQueryError from "./CargosonQueryError";
import { useDataContext } from "../../context/DataContext";
import '../../styles/containers.css';
import CargosonHeaderFormNew from "./CargosonHeaderFormNew";
import AddressFormNew from "./AddressFormNew";

const CargosonComponent = (props: { listIndex: number }) => {
  const { cargosonQueryResponse, cargosonPricelist, isSearchOpen, consignmentList } = useDataContext();
  const consignment = consignmentList[props.listIndex];
  return (
    <>
      {(isSearchOpen && consignment.type !== ("" || undefined)) &&
        <div className="transport-fields-containers">
          <div>
            <CargosonHeaderFormNew listIndex={props.listIndex} />
            <AddressFormNew listIndex={props.listIndex} />
          </div>
          <div>
            {(cargosonQueryResponse).length > 0 ?
              <CargosonQueryError response={cargosonQueryResponse} listIndex={props.listIndex} /> : ""}
            {(cargosonPricelist).length > 0 ?
              <CargosonPriceListComponent priceListData={cargosonPricelist} listIndex={props.listIndex} /> : ""}
          </div>
        </div>
      }
    </>
  )
}

export default CargosonComponent;
