import { AxiosResponse } from 'axios';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from '../../components/common/Loading';
import { useDataContext } from '../../context/DataContext';
import { GetConsignmentByDirectoCode, LoadConsignmentRequest } from '../../queries/queries';
import { Consignment } from '../../Types/Consignment';
import '../../styles/Consignment.css';
import '../../styles/CargosonComponent.css';
import '../../styles/transport-route.css'
import '../../styles/directo-component.css'
import '../../styles/buttons.css'
import '../../styles/main.css'
import { useCustomer } from '../../components/login/user';
import { Navigate } from 'react-router-dom';
import ConsignmentOptions from '../../components/consignment/ConsignmentOptions';
import DirectoComponent from '../../components/consignment/DirectoComponent';
import CargosonComponent from '../../components/cargoson/CargosonComponent';

const ConsignmentDetails = () => {
    const { consignmentId, directoCode } = useParams<{ consignmentId: string, directoCode: string }>();
    const { setConsignmentList, setConsignmentId, consignmentList, setIsSearchOpen } = useDataContext();
    const { logged, permissions } = useCustomer();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleConsignmentRequest = async () => {

        if (directoCode !== undefined) {
            GetConsignmentByDirectoCode(directoCode).then((response: AxiosResponse<Consignment>) => {
                var arr = consignmentList.map((item, idx) => idx === 0 ? response.data : item);
                setConsignmentId(response.data.id.toString())
                setConsignmentList(arr)
                setIsSearchOpen(true)
            }).catch((error) => { console.error(error) })
        }
        if (consignmentId !== undefined) {

            setIsLoading(true)
            await LoadConsignmentRequest(+consignmentId).then((response: AxiosResponse<Consignment>) => {
                var arr = consignmentList.map((item, idx) => idx === 0 ? response.data : item);
                setConsignmentId(response.data.id.toString())
                setConsignmentList(arr)
                setIsSearchOpen(true)

            }).catch((error) => { console.error(error) }).finally(() => { setIsLoading(false) })
        }
    }

    useEffect(() => {
        handleConsignmentRequest()
    }, []);


    /* if (!consignment) {
        return <div><Loading /></div>;
    } */
    if (isLoading) {
        return <div><Loading /></div>;
    }
    if (!logged) {
        return <Navigate to="/login" />
    }
    if (!permissions.includes("PEstonianUser")) {
        return <Navigate to="/unauthorized" />
    }

    return (
        <div className="page__container-outer-wrapper">
            <div className="page__container-wrapper--flexbox">
                <div className="page__center-column-flexbox">
                    <div className='page__heading-title'>
                        Veotellimuse loomine {/* {consignment.directoCode} */}
                    </div>
                    <div className='page-wrapper'>
                        <DirectoComponent listIndex={0} />
                        <CargosonComponent listIndex={0} />
                        <ConsignmentOptions listIndex={0} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConsignmentDetails