import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas';
import { GetPrintoutFromDirecto } from '../../../queries/queries';
import { ConsignmentStatus } from '../../../Types/delivery-app/InHouseConsignment';
import EtsNordLogo from '../../../assets/logo.png'
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { SendSignedPrintoutToDirecto, CompleteDelivery } from '../../../queries/deliveryDriverQueries';
import { ClientInHouseConsignment } from '../../../Types/delivery-app/ClientInHouseConsignment';
import Loading from '../../common/Loading';

type Props = {
    deliveryData: ClientInHouseConsignment
    setDeliveryData: React.Dispatch<React.SetStateAction<ClientInHouseConsignment>>
    setEndDeliveryOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const PackageRecieved = ({ deliveryData, setDeliveryData, setEndDeliveryOpen }: Props) => {
    const signatureCanvasRef = useRef<any>(null);
    const [signature, setSignature] = useState<string>(deliveryData.signatureBase64 ?? "");
    const [receiverName, setReceiverName] = useState<string>(deliveryData.packageReceiverName ?? "")
    const signatureBoxWidth = (window.innerWidth) * 0.78;
    const [pic, setPic] = useState<string>("")
    const contentRef = useRef<any>(null);
    const [htmlDocument, setHtmlDocument] = useState<any>("");
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClearSignature = () => {
        setPic("")
        setSignature("")
        if (signatureCanvasRef.current === null) return;
        signatureCanvasRef.current.clear();
    };

    const handleSaveSignature = () => {
        if (signatureCanvasRef.current === null) return;
        const signatureImage = signatureCanvasRef.current.toDataURL();
        setSignature(signatureImage);
        setDeliveryData({ ...deliveryData, signatureBase64: signatureImage })
        return;
    };

    const pdfToBase64String = async (pdfBlob: Blob) => {
        return new Promise<string | null>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Check if reader.result is truthy and then split the result to get the base64 part
                const result = reader.result as string;
                const base64String = result ? result.split(',')[1] : null;
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(pdfBlob);
        });
    };

    const htmlStringToPdf = async (htmlString: string, downloadPdf: boolean = false) => {
        const iframeId = 'uniqueIframeId';
        let iframe = document.getElementById(iframeId) as HTMLIFrameElement;

        // If the iframe doesn't exist, create it and append it to the body
        if (!iframe) {
            iframe = document.createElement("iframe");
            iframe.id = iframeId; // Assign the unique ID to the iframe
            iframe.style.visibility = "hidden";
            document.body.appendChild(iframe);
        }

        let iframedoc = iframe.contentDocument!;
        iframedoc.body.innerHTML = htmlString;

        let canvas = await html2canvas(iframedoc.body, {});
        let imgData = canvas.toDataURL("image/png");

        // Create a PDF document and add the image as a page.
        const doc = new JsPDF({
            format: "a4",
            unit: "mm",
        });
        doc.addImage(imgData, "PNG", 0, 0, 210, 297);

        // Get the file as blob output.
        let blob = doc.output("blob");

        var base64String = await pdfToBase64String(blob);

        if (downloadPdf) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Saateleht ${deliveryData.directoCode}.pdf`;
            link.click();
            URL.revokeObjectURL(link.href);
        }
        if (base64String) {
            return base64String;
        } else {
            console.error('Error generating PDF base64 string');
        }
        document.body.removeChild(iframe); // Clean up
    };

    const sendHtmlWithSignature = (originalHtml: string) => {
        const removeScriptsBeggining = originalHtml.slice(originalHtml.indexOf('<html xmlns:fo="http://www.w3.org/1999/XSL/Format"'));
        var removeScriptsAfter = removeScriptsBeggining.substring(0, removeScriptsBeggining.indexOf('</html>'));
        if (signature !== "" && signature !== null) {
            removeScriptsAfter = removeScriptsAfter.replace(
                '<img sign="signhere" width="600" height="100" border="0" align="right">',
                `<img sign="signhere" width="600" height="100" border="0" align="right" src="${signature}" alt="Signature" />`
            );
        }
        if (receiverName !== "") {
            removeScriptsAfter = removeScriptsAfter.replace(
                '<td id="signature_name" class="b bottom2 td">',
                `<td id="signature_name" class="b bottom2 td">${receiverName}, ${new Date().toLocaleDateString("et-EE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                })}`
            );
        }
        if (deliveryData.deliveryDriverName) {
            removeScriptsAfter = removeScriptsAfter
                .replace(
                    '<td id="driver_name" class="b bottom2 td">',
                    `<td id="driver_name" class="b bottom2 td">${deliveryData.deliveryDriverName}`
                );
        }

        const removePageNr = removeScriptsAfter.replace('Lk: {pdf:page_number}/{pdf:page_count}', '')
        const removeLogo = removePageNr.replace('<img border="0" height="70" src="/logos/files/ets_nord_9.jpg">', `<img border="0"  width="150" src="${EtsNordLogo}">`)
        const increasePaddingStart = removeLogo.replace('<body style="display:block; text-align:-webkit-center;">', '<body style="display:block; text-align:-webkit-center;"><div style="padding:50px; width:700px;">')
        const increasePaddingEnd = increasePaddingStart.replace('</body>', '</div></body>')
        return increasePaddingEnd;
    };

    const handleGetPrintout = async () => {
        const directoCode = deliveryData.directoCode;
        var CMRPrintoutNumber = 1666;
        try {
            const response = await GetPrintoutFromDirecto(directoCode, CMRPrintoutNumber);
            const documentWithSignature = sendHtmlWithSignature(response.data);
            setHtmlDocument(documentWithSignature);
            const base64String = await htmlStringToPdf(documentWithSignature);
            return base64String

        } catch (err) {
            console.log(err);
        }
    };

    const handleDownloadPrintout = async () => {
        const directoCode = deliveryData.directoCode;
        var CMRPrintoutNumber = 1666;
        try {
            const response = await GetPrintoutFromDirecto(directoCode, CMRPrintoutNumber);
            const documentWithSignature = sendHtmlWithSignature(response.data);
            const base64String = await htmlStringToPdf(documentWithSignature, true);
            return base64String

        } catch (err) {
            console.log(err);
        }
    }

    const handleNameChange = (e: any) => {
        setReceiverName(e.target.value)
        setDeliveryData({ ...deliveryData, packageReceiverName: e.target.value })
    }

    const handleSaveToDirecto = async () => {
        if (!receiverName || !signature) {
            alert("Please fill out all fields.");
            return;
        }
        const base64String = await handleGetPrintout();
        if (base64String) {
            try {
                setIsLoading(true)
                const updatedDeliveryData = { ...deliveryData, attachmentBase64: base64String, consignmentStatus: ConsignmentStatus.Delivered };

                var response = await SendSignedPrintoutToDirecto(updatedDeliveryData);
                setDeliveryData({ ...deliveryData, consignmentStatus: response.data.ConsignmentStatus })
                setIsLoading(false)
                alert("Kinnitatud")
                setEndDeliveryOpen(false)
            } catch (error) {
                console.error("Error sending to Directo:", error);
                alert("Failed to send data. Please try again.");
            }

        } else {
            console.error('attachmentBase64 is not set.');
            alert("Failed to generate delivery document. Please try again.");
        }
        await CompleteDelivery(deliveryData.id)

    };

    useEffect(() => {
        setPic(deliveryData.signatureBase64)
        setSignature(deliveryData.signatureBase64)
        handleGetPrintout()
    }, [])

    useEffect(() => {
        setIsButtonDisabled(!(receiverName?.trim() && signature?.trim()));
    }, [receiverName, signature]);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

            {deliveryData.consignmentStatus !== ConsignmentStatus.Delivered ?
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                    <div ref={contentRef} style={{ display: "flex", justifyContent: "center" }}>
                        <div dangerouslySetInnerHTML={{ __html: htmlDocument }} />
                    </div>

                    <div style={{ marginTop: "2%", fontSize: "25px" }}>Kliendi allkiri ja nimi</div>
                    <input className="input-addressForm" name="deliveryContactName" style={{ width: `${signatureBoxWidth}px` }} placeholder='Sisestage oma nimi' value={receiverName || ""} onChange={(e: any) => handleNameChange(e)} />

                    <div style={{ marginTop: "2%", border: "1px solid #dee2e6", borderRadius: ".25rem", height: "200px", cursor: "pointer", width: signatureBoxWidth }}>
                        {(pic !== "" && pic !== null && pic !== undefined) ?
                            <img src={`${pic}`} alt="Signature" />
                            :
                            <SignatureCanvas ref={signatureCanvasRef} canvasProps={{ height: 200, width: signatureBoxWidth }} onEnd={handleSaveSignature} />
                        }
                    </div>

                    <div style={{ marginTop: '2%', gap: "5px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        {<Button variant="contained" style={{ backgroundColor: "#c00", color: "#fff", fontWeight: "bold" }} onClick={handleClearSignature}>Tühjenda allkiri</Button>}
                        <Button style={{ color: "#fff", background: "#c00", fontWeight: "bold" }} onClick={handleDownloadPrintout}>Saateleht</Button>
                        {<Button className="consignment-options--button" variant="contained" style={{ backgroundColor: "green", color: "#fff", fontWeight: "bold" }} onClick={handleSaveToDirecto} disabled={isButtonDisabled}>
                            Kinnita ja lõpeta vedu
                        </Button>}
                    </div>
                </div>
                :
                <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "10px", marginTop: "2%" }}>
                    <Button style={{ color: "#fff", background: "#c00", fontWeight: "bold" }} onClick={handleDownloadPrintout}>Saateleht</Button>
                </div>
            }
        </div >
    )
}

export default PackageRecieved

