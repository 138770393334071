import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, Suggestion } from 'react-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Button } from '@mui/material';
import Modal from '../../common/Modal';

type SelectPlaceInputProps = {
    addressSuggestions: Suggestion[];
    setAddressSuggestions: React.Dispatch<React.SetStateAction<Suggestion[]>>;
    inputRef: React.RefObject<HTMLInputElement>;
    showMapModal: boolean;
    setShowMapModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleAddressData: (address: string, latitude: number, longitude: number) => void;
    inputAddress: string;
    inputLatitude: number;
    inputLongitude: number;
    title: string
};

const SelectPlaceInputModal: React.FC<SelectPlaceInputProps> = ({
    addressSuggestions,
    setAddressSuggestions,
    inputRef,
    showMapModal,
    setShowMapModal,
    inputAddress,
    inputLatitude,
    inputLongitude,
    handleAddressData,
    title
}) => {
    const [address, setAddress] = useState<string>(inputAddress);
    const [latitude, setLatitude] = useState<number>(inputLatitude);
    const [longitude, setLongitude] = useState<number>(inputLongitude);

    const handleSelect = async (address: string) => {
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);
            setAddress(address);
            setLatitude(latLng.lat);
            setLongitude(latLng.lng);
            setShowMapModal(true);
            // Pass the selected data to the parent component
            handleAddressData(address, latLng.lat, latLng.lng);
        } catch (error) {
            console.error('Error selecting address:', error);
        }
    };

    const handleMapClose = () => {
        handleAddressData(address, latitude, longitude)
        setShowMapModal(false);
        setAddressSuggestions([]);
        setAddress("");
        setLatitude(0);
        setLongitude(0);
    };

    useEffect(() => {
        setAddress(inputAddress);
        setLatitude(inputLatitude);
        setLongitude(inputLongitude);
    }, [inputAddress, inputLatitude, inputLongitude]);

    useEffect(() => {
        setAddressSuggestions([]);
    }, [address]);

    return (
        <Modal show={showMapModal} onClose={handleMapClose}>
            <PlacesAutocomplete
                value={address}
                onChange={(address) => setAddress(address)}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <div style={{ color: "#6c757d", marginBottom: "10px" }}>{title}</div>
                        <input
                            ref={inputRef}
                            {...getInputProps({
                                placeholder: 'Type address',
                                className: 'map-input',
                            })}
                        />
                        {loading && <div>Loading...</div>}
                        {addressSuggestions.length > 0 && (
                            <div className='map-input--suggestions'>
                                {addressSuggestions.map((suggestion: Suggestion) => {
                                    const style = {
                                        backgroundColor: suggestion.active ? '#c00' : '#fff',
                                        cursor: 'pointer',
                                        color: suggestion.active ? '#fff' : '#6c757d',
                                        fontSize: "15px",
                                    };

                                    return (
                                        <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                            {suggestion.description}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {suggestions.length > 0 && (
                            <div className='map-input--suggestions'>
                                {suggestions.map((suggestion: Suggestion) => {
                                    const style = {
                                        backgroundColor: suggestion.active ? '#c00' : '#fff',
                                        cursor: 'pointer',
                                        color: suggestion.active ? '#fff' : '#6c757d',
                                        fontSize: "15px",
                                    };

                                    return (
                                        <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                            {suggestion.description}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </PlacesAutocomplete>
            {latitude !== 0 && longitude !== 0 && (
                <>
                    <GoogleMap
                        mapContainerStyle={{ height: '400px', width: '100%', marginTop: "1%" }}
                        center={{ lat: latitude, lng: longitude }}
                        zoom={15}
                    >
                        <Marker
                            position={{ lat: latitude, lng: longitude }}
                            draggable={true}
                            onDragEnd={(e) => {
                                setLatitude(e.latLng!.lat());
                                setLongitude(e.latLng!.lng());
                            }}
                        />
                    </GoogleMap>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ color: '#e8f7f0', background: '#3eb668', marginTop: "20px" }} onClick={handleMapClose}>
                            Kinnita asukoht
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default SelectPlaceInputModal;
