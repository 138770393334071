/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import '../../../styles/Consignment.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { SearchDeliveriesWithDeliveryNumber } from '../../../queries/directo-queries';
import { Deliveries } from '../../../Types/directo/Deliveries';

type Props = {
    deliveryNumber: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeliveriesTable = ({ deliveryNumber, isOpen, setIsOpen }: Props) => {
    const [deliveriesData, setDeliveriesData] = useState<Deliveries[]>([]);
    useEffect(() => {
        const handleDeliverySearch = (deliveryNumber: string) => {
            SearchDeliveriesWithDeliveryNumber(deliveryNumber).then(response => {
                setDeliveriesData(response.data)
                setIsOpen(true);
            });
        }
        handleDeliverySearch(deliveryNumber)
    }, [])

    const handleCloseMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "800px" }}>
            {isOpen &&
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    {!isOpen ? <ExpandMoreIcon className="expandMoreIcon" onClick={handleCloseMenu} /> :
                        <ExpandLessIcon className="expandMoreIcon" onClick={handleCloseMenu} />
                    }
                </div>}
            {isOpen &&
                <>
                    {Object.values(deliveriesData).map((value, idx) => (
                        <table key={idx} style={{ borderCollapse: "collapse", width: "100%", color: "#6c757d", borderTop: "1px solid #dee2e6" }} >
                            <thead>
                                <tr className="deliveriesTable-head-tr">
                                    <td className="search-table-content__table-thead--td left padding-xs" >Item Code</td>
                                    <td className="search-table-content__table-thead--td left padding-xs" >Description</td>
                                    <td className="search-table-content__table-thead--td left padding-xs" >Quantity</td>
                                </tr>
                            </thead>
                            <tbody >
                                {Object.values(value.items).map((x, idx) => (
                                    <tr key={idx} className="search-table-content__table--tr" >
                                        <td className="search-table-content__table--outer-td left padding-xs">
                                            {x.itemCode}
                                        </td>
                                        <td className='left padding-xs'>
                                            {x.description}
                                        </td>
                                        <td className="search-table-content__table--outer-td left padding-xs">
                                            {x.quantity}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ))}

                </>
            }
        </div>

    );
}

export default DeliveriesTable;
