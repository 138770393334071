import { IconButton } from '@mui/material';
import React, { useState } from 'react'
import { useDataContext } from '../../../../context/DataContext';
import '../../../../styles/Consignment.css'
import ClearIcon from '@mui/icons-material/Clear';
import EastIcon from '@mui/icons-material/East';
import { Consignment } from '../../../../Types/Consignment';
import { AxiosResponse } from 'axios';
import { GetStockOrder, AddStockOrderToConsignment, RemoveStockOrderFromConsignment } from '../../../../queries/directo-queries';

const AddStockOrderNew = (props: { listIndex: number }) => {
    const [stockOrderNumber, setStockOrderNumber] = useState<string>();
    const { consignmentList, updateConsignment, setConsignmentList } = useDataContext();
    const consignment = consignmentList[props.listIndex];

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!stockOrderNumber) return;
        if (consignmentList[props.listIndex].stockOrderNumbers.includes(stockOrderNumber)) return;
        try {
            GetStockOrder(stockOrderNumber).then((response: any) => {
                setStockOrderNumber("")

                AddStockOrderToConsignment(stockOrderNumber, +consignment.id).then(
                    (response: AxiosResponse<Consignment>) => {
                        setConsignmentList(() => consignmentList.map((item, idx) => idx === props.listIndex ? response.data : item))
                        setStockOrderNumber(() => "")
                    }
                ).catch(err => { console.log(err); alert("Ei saanud laotellimust lisada") });
            }).catch((error) => {
                alert("Ei leitud laotellimust")
            });

        } catch (error) {
            alert("Ei leitud laotellimust");
        }
    };

    const handleRemoveStockOrder = (stockOrderNumber: string) => {
        RemoveStockOrderFromConsignment(stockOrderNumber, +consignment.id).then(
            (response: AxiosResponse<Consignment>) => {
                updateConsignment(props.listIndex, response.data)
            }
        ).catch(err => { console.log(err); alert("Eemaldamine ebaõnnestus") });
    }

    return (
        <div style={{ border: "1px solid #e2e2e2", padding: "20px", minHeight: "130px", backgroundColor: "#fff" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <input type="number" style={{ border: "none", borderBottom: "1px solid #e2e2e2" }} placeholder='Lisa laotellimus' onChange={(e) => setStockOrderNumber(e.target.value)} />
                    <IconButton type="submit">
                        <EastIcon style={{ color: "gray" }} />
                    </IconButton>
                </div>
            </form>
            <div >
                {consignmentList[props.listIndex].stockOrderNumbers?.map((x, idx) => (
                    <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #e2e2e2" }}>
                        <div >{x}</div>
                        <IconButton title="Eemalda" onClick={() => handleRemoveStockOrder(x)}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AddStockOrderNew