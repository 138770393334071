import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Define a type for the select options
type SelectOption = {
    value: string;
    label: string;
};

// Define the options (predefined and custom)
const predefinedOptions: SelectOption[] = [
    { value: 'Ventilatsiooni detailid', label: 'Ventilatisooni detailid' },
    { value: 'Ventilation details', label: 'Ventilation details' },
];

type Props = {
    handleCustomInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    customInputValue: string,
    setCustomInput: React.Dispatch<React.SetStateAction<string>>,
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>,
    selectedOption: string,
}
const CustomSelect: React.FC<Props> = ({ handleCustomInputChange, customInputValue, setCustomInput, selectedOption, setSelectedOption }) => {
    const isNonEstonianPage = window.location.pathname.startsWith("/tuusula") || window.location.pathname.startsWith("/sweden")
    const customInputRef = useRef<HTMLInputElement>(null); // Create a ref for the custom input
    const { t } = useTranslation()

    useEffect(() => {
        // When selectedOption changes and it's 'custom', focus on the custom input box
        if (selectedOption === 'custom' && isNonEstonianPage === false) {
            customInputRef.current?.focus();
            return;
        }
    }, [selectedOption]);

    useEffect(() => {
        customInputRef.current?.blur();
    }, [])


    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setCustomInput(selectedValue)

        // Reset custom input when predefined option is selected
        if (selectedValue === 'custom') {
            setCustomInput('');
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
            <select
                id="customInput"
                value={selectedOption}
                onChange={handleSelectChange}
                className="cargoson-container__address-form--input"
            >
                <option value="default" disabled hidden></option>
                {predefinedOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
                <option value="custom">{t("Kirjuta ise")}</option>
            </select>

            {selectedOption === 'custom' && (
                <input
                    className='input-addressForm'
                    ref={customInputRef} // Set the ref for the custom input
                    type="text"
                    id="customTextInput"
                    value={customInputValue}
                    onChange={handleCustomInputChange}
                    placeholder=""
                />
            )}
        </div>
    );
};

export default CustomSelect;