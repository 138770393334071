import { Dispatch, SetStateAction, useEffect } from 'react'

type ErrorServiceProps = {
    message: string,
    error: boolean,
    setError: Dispatch<SetStateAction<boolean>>
}
const ErrorService = ({ message, error, setError }: ErrorServiceProps) => {

    useEffect(() => {
        setTimeout(() => {
            setError(false);
        }, 3000);
    }, [])

    return (
        <>
            {error &&
                <div className="add-package__error-popup" style={{ color: "#fff", paddingTop: "50px" }}>
                    {message}
                    <button className="add-package__error-popup-close-button" onClick={() => setError(false)}>X</button>
                </div>
            }
        </>
    )
}

export default ErrorService