import * as React from 'react';
import { ReactComponent as LetsGoLogo } from '../assets/tracking-app/LetsGoLogo.svg';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LanguageIcon from '@mui/icons-material/Language';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { useCustomer } from '../components/login/user';
import { useDataContext } from '../context/DataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as EstonianFlag } from '../assets/estonia-flag-icon.svg';
import { ReactComponent as FinnishFlag } from '../assets/finland-flag-icon.svg';
import { ReactComponent as UKFlag } from '../assets/united-kingdom-flag-icon.svg';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import '../styles/navbar.css';
import Popover from '@mui/material/Popover';


const TrackingAppNavbar: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [userPopoverOpen, setUserPopoverOpen] = React.useState(false);
    const [userPopoverAnchorEl, setUserPopoverAnchorEl] = React.useState(null);
    const [languagePopoverOpen, setLanguagePopoverOpen] = React.useState(false);
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = React.useState(null);
    const { logout } = useCustomer();

    const handlePopoverOpen = (event: any, popoverType: string) => {
        if (popoverType === 'languageMenu') {
            setLanguagePopoverOpen(true);
            setLanguageMenuAnchorEl(event.currentTarget);
        }
        if (popoverType === 'userMenu') {
            setUserPopoverAnchorEl(event.currentTarget);
            setUserPopoverOpen(true);
        }
    };

    const handleUserPopoverClose = () => {
        setUserPopoverOpen(false);
    };
    const closePopover = () => {
        setLanguagePopoverOpen(false);
    };

    const handlechangeLanguage = (languageValue: string) => {
        i18n.changeLanguage(languageValue);
        localStorage.setItem('i18nextLng', languageValue);
        setLanguagePopoverOpen(false);
    };

    const userIconSx = {
        cursor: 'pointer',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#c00'
        }
    };
    const userPopoverContent = (
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', height: '130px', color: '#737372' }}>
            <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center" }}>
                <LanguageIcon style={{ fontSize: '3rem' }} onClick={(e: any) => handlePopoverOpen(e, 'languageMenu')} />
                <Typography variant="caption">{t('Vali keel')}</Typography>
            </div>
            {/* <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center" }}>
                <EditIcon style={{ fontSize: '3rem' }} />
                <Typography variant="caption">{t('Muuda andmeid')}</Typography>
            </div> */}
            <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center" }} onClick={logout}>
                <ExitToAppIcon style={{ fontSize: '3rem' }} />
                <Typography variant="caption">{t('Logi välja')}</Typography>
            </div>
        </div>
    );
    return (
        <AppBar position="fixed" style={{ background: "#fff", boxShadow: 'none' }}>
            <Toolbar style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <div className="w-25">
                    {/* <IconButton edge="start" color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton> */}
                </div>
                <div className="justify-center" style={{ display: 'flex', gap: '50px', flexGrow: '1' }}>
                    <LetsGoLogo />
                </div>
                <div className="navbar--user-button-group">
                    <IconButton color="inherit" title="Settings" style={{ color: `${userPopoverOpen ? "#e10008" : "#737372 "}` }} onClick={(e: any) => handlePopoverOpen(e, 'userMenu')}>
                        <PersonIcon fontSize='large' />
                    </IconButton>
                </div>
            </Toolbar>

            <Popover
                open={userPopoverOpen}
                anchorEl={userPopoverAnchorEl}
                onClose={handleUserPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                PaperProps={{
                    style: {
                        width: '100%',
                        marginTop: '5px',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                }}
            >
                {userPopoverContent}
            </Popover>

            <Popover
                open={languagePopoverOpen}
                anchorEl={languageMenuAnchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                PaperProps={{
                    style: {
                        marginLeft: '80px'
                    },
                }}
            >
                <List>
                    <ListItem sx={userIconSx} onClick={() => handlechangeLanguage('et')}>
                        <ListItemIcon>
                            <EstonianFlag style={{ width: '25px', height: '25px' }} />
                        </ListItemIcon>
                        <ListItemText primary="Eesti" />
                    </ListItem>
                    <ListItem sx={userIconSx} onClick={() => handlechangeLanguage('en')}>
                        <ListItemIcon>
                            <UKFlag style={{ width: '25px', height: '25px' }} />
                        </ListItemIcon>
                        <ListItemText primary="English" />
                    </ListItem>
                    <ListItem sx={userIconSx} onClick={() => handlechangeLanguage('fi')}>
                        <ListItemIcon>
                            <FinnishFlag style={{ width: '25px', height: '25px' }} />
                        </ListItemIcon>
                        <ListItemText primary="Suomi" />
                    </ListItem>
                </List>
            </Popover>
        </AppBar>
    );
};

export default TrackingAppNavbar;
