import { useTranslation } from "react-i18next";
import "../../../styles/package-component.css"
import AddPackage from "./AddPackage";
import ReturnPackages from "./ReturnPackages";

const AddPackageComponent = (props: { pickupIdx: number, listIndex: number }) => {
  const { t } = useTranslation();

  return (
    <div className="add-package-container" >
      <h2 className="cargoson-container__address-form--title" style={{ textAlign: "left" }}>{t("Kaubad")}</h2>
      <div className="add-package-container__wrapper" style={{ width: "100%", overflowX: "auto" }}>
        <AddPackage pickupIdx={props.pickupIdx} listIndex={props.listIndex} />
        <ReturnPackages pickupIdx={props.pickupIdx} listIndex={props.listIndex} />
      </div>
    </div>
  );
};

export default AddPackageComponent;

