import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import EastIcon from '@mui/icons-material/East';
import { AxiosResponse } from 'axios';
import { OrdersResponse } from '../../Types/directo/OrdersResponse';
import ClearIcon from '@mui/icons-material/Clear';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useDataContext } from '../../context/DataContext';
import { Consignment } from '../../Types/Consignment';
import { ConvertDate } from '../common/ConvertDate';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { SearchOrderWithOrderNumber, AddOrderToConsignment, AddOrderInfoToHeader, RemoveOrderFromConsignment } from '../../queries/directo-queries';
import { SaveConsignmentList } from '../../queries/queries';
import { useCustomer } from '../login/user';

type Props = {
    setDeliveryDate: Dispatch<SetStateAction<Date>>
}
const AddSalesOrder = ({ setDeliveryDate }: Props) => {
    const [salesOrder, setSalesOrder] = useState<string>();
    const { consignmentList, setConsignmentList } = useDataContext();
    const consignment = consignmentList[0];
    const { t } = useTranslation()
    const customInputRef = useRef<HTMLInputElement>(null); // Create a ref for the salesorder input
    const { isFinnishUserPermitted } = useCustomer();
    const directoCountry = "EST"
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isFinnishUserPermitted() === false) return;
        if (!salesOrder) return;
        if (consignmentList[0].orderNumbers.includes(salesOrder)) return;
        try {
            const response: AxiosResponse<OrdersResponse[]> = await SearchOrderWithOrderNumber(salesOrder);
            await SaveConsignmentList(consignmentList)
            AddOrderToConsignment(salesOrder, +consignment.id, response.data[0], directoCountry).then(
                (response) => {
                    setSalesOrder("")
                    if (consignmentList[0].orderNumbers.length === 0) {
                        AddOrderInfoToHeader(salesOrder, +consignment.id).then(
                            (response: AxiosResponse<Consignment>) => {
                                setDeliveryDate(() => ConvertDate(response.data.cargosonData?.delivery_date))
                                setConsignmentList(() => consignmentList.map((item, idx) => idx === 0 ? response.data : item))
                                return;
                            }
                        ).catch(err => {
                            console.log(err);
                            alert(t("salesOrderHeaderAlert", { salesOrder }))
                        });
                    }
                    else {
                        setConsignmentList(consignmentList.map((item, idx) => idx === 0 ? {
                            ...item,
                            orderNumbers: response.data.orderNumbers,
                            orders: response.data.orders
                        } : item));
                    }
                }
            ).catch(err => { console.log(err); alert(err.response.data) });

        } catch (error) {
            alert(t("Ei leidnud müügitellimust"));
        }
    };

    const handleAddOrderInfoToHeader = async (orderNumber: string) => {
        if (isFinnishUserPermitted() === false) return;
        await SaveConsignmentList(consignmentList)
        AddOrderInfoToHeader(orderNumber, +consignment.id).then(
            (response: AxiosResponse<Consignment>) => {
                setDeliveryDate(() => ConvertDate(response.data.cargosonData?.delivery_date))
                setConsignmentList(() => consignmentList.map((item, idx) => idx === 0 ? response.data : item))
                alert(t("salesOrderHeaderSuccess", { salesOrder }))
            }
        ).catch(err => { console.log(err); alert(t("salesOrderHeaderAlert", { salesOrder })) });
    }

    const handleRemoveSalesOrder = (orderNumber: string) => {
        if (isFinnishUserPermitted() === false) return;
        RemoveOrderFromConsignment(orderNumber, +consignment.id).then(
            (response: AxiosResponse<Consignment>) => {
                setConsignmentList(consignmentList.map((item, idx) => idx === 0 ? {
                    ...item,
                    orderNumbers: response.data.orderNumbers,
                    orders: response.data.orders
                } : item));
            }
        ).catch(err => { console.log(err); alert("Ei saanud müügitellimust eemaldada") });
    }



    useEffect(() => {
        // When selectedOption changes and it's 'custom', focus on the custom input box
        customInputRef.current?.focus();
        return;
    }, []);


    return (
        <div className='quick-menu-form--wrapper'>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <input className='input-addressForm' readOnly={!isFinnishUserPermitted()} ref={customInputRef} type="number" onWheel={event => event.currentTarget.blur()}
                        style={{ border: "none", borderBottom: "1px solid #e2e2e2" }} placeholder={t("Lisa müügitellimus")} onChange={(e) => setSalesOrder(e.target.value)} value={salesOrder ?? ""} />
                    <IconButton type="submit">
                        <EastIcon style={{ color: "gray" }} />
                    </IconButton>
                </div>
            </form>
            <div >
                {/* Render addedSalesOrders array */}
                {consignmentList[0].orderNumbers?.map((x, idx) => (
                    <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #e2e2e2" }}>
                        <a className="addedDeliveires__anchor-tag" target="_blank" rel="noreferrer" href={`https://login.directo.ee/ocra_ets_nord/tell_tellimus.asp?NUMBER=${x}`} >
                            {x}
                        </a>
                        <IconButton title="Võta põhjaks" onClick={() => handleAddOrderInfoToHeader(x)}>
                            <PlaylistAddIcon />
                        </IconButton>
                        <IconButton title="Eemalda" onClick={() => handleRemoveSalesOrder(x)}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AddSalesOrder