/* export const apiUrl = process.env.NODE_ENV === "development" ? "https://localhost:44398" : "https://letsgoapi.etsnord.com";  */
export const apiUrl =
  process.env.NODE_ENV === "development"
    ? "https://localhost:7299"
    : "https://letsgoapi.etsnord.com";
export const clientUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://letsgo.etsnord.com";
export const mapsApiKey = "AIzaSyCqeFVOgCvGLTIqkOBUZz3-kEOpt95iT_s";
