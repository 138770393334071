import { instance } from './auth'
import { apiUrl } from "../config/apiconfig";
import { User } from '../Types/user/User';
import { DeliveryDriver } from '../Types/delivery-app/DeliveryDriver';
import { AddDeliveryDriver } from '../Types/admin/AddDeliveryDriver';

export const addUser = (addUserRequest: User) => {
    return instance.post(`${apiUrl}/admin/add/user`, JSON.stringify(addUserRequest), { headers: { "Content-Type": "application/json" } })
}

export const getUsers = () => {
    return instance.get<User[]>(`${apiUrl}/admin/get/users`, { headers: { "Content-Type": "application/json" } })
}

export const getUserDetails = (userId: string) => {
    return instance.get<User>(`${apiUrl}/admin/get/user?userId=${userId}`, { headers: { "Content-Type": "application/json" } })
}

export const removeUser = (userId: string) => {
    return instance.delete(`${apiUrl}/admin/remove/user?userId=${userId}`, { headers: { "Content-Type": "application/json" } })
}

export const updateUser = (userDto: User) => {
    return instance.post(`${apiUrl}/admin/update/user`, JSON.stringify(userDto), { headers: { "Content-Type": "application/json" } })
}

export const getUsersByName = (fullName: string) => {
    return instance.get<User[]>(`${apiUrl}/admin/get/user/name?fullName=${fullName}`, { headers: { "Content-Type": "application/json" } })
}
export const getDeliveryDrivers = () => {
    return instance.get<User[]>(`${apiUrl}/admin/get/delivery-drivers`, { headers: { "Content-Type": "application/json" } })
}

export const addDeliveryDriver = (deliveryDriver: AddDeliveryDriver) => {
    return instance.post(`${apiUrl}/api/deliveryDriver/create`, JSON.stringify(deliveryDriver), { headers: { "Content-Type": "application/json" } })
}

export const updateDeliveryDriver = (deliveryDriver: DeliveryDriver) => {
    return instance.post(`${apiUrl}/api/deliveryDriver/update`, JSON.stringify(deliveryDriver), { headers: { "Content-Type": "application/json" } })
}
