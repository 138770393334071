import { useState } from 'react'
import { useDataContext } from '../../context/DataContext'
import { SaveConsignmentList, SaveConsignmentToDirecto, } from '../../queries/queries'
import ErrorService from '../common/errors/ErrorService'
import '../../styles/consignment-options.css'
import EmailPreviewModal from '../email/EmailPreviewModal'
import ReturnToDirecto from '../consignment/ReturnToDirecto'
import { Consignment } from '../../Types/Consignment'
import { useTranslation } from 'react-i18next'

type Props = {
    showOptions: boolean,
    listIndex: number,
    initialConsignment: Consignment | null,
}
const FinlandConsignmentOptions = ({ showOptions, listIndex, initialConsignment }: Props) => {
    const { setConsignmentList, consignmentList, transportRouteId, setIsLoading, setReturnDirectoCode } = useDataContext();
    const [isError, setIsError] = useState<boolean>(false);
    const [errorPropName, setErrorPropName] = useState<string>("");
    const { t } = useTranslation();
    const isTuusulaPage = window.location.pathname.startsWith("/tuusula")
    /* const isSwedenPage = window.location.pathname.startsWith("/sweden") */

    if (isError) {
        setTimeout(() => {
            setIsError(false);
        }, 4500);
    }

    const hasUnsavedChanges = () => {
        if (initialConsignment === null) {
            return false;
        }
        // Compare the initial data with the current state
        return JSON.stringify(initialConsignment) !== JSON.stringify(consignmentList[0]);
    };


    const handleSendConsignmentList = () => {
        /* if (!validateCargosonFields()) {
            return;
        } */

        setIsLoading(true)
        SaveConsignmentList(consignmentList).then(() => {

            SaveConsignmentToDirecto(consignmentList).then((response) => {
                setReturnDirectoCode(response.data.consignmentDirectoCode)

                var tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                    ...item,
                    "directoCode": response.data.consignmentDirectoCode,
                    cargosonData: { ...item.cargosonData, "customer_reference": response.data.consignmentDirectoCode }
                } : item);

                setConsignmentList(tempArr);

                alert("Success")
            }).catch(err => console.log(err));
        }).catch(err => console.log(err));

        setTimeout(() => {
            setIsLoading(false)
        }, 2500);
    }

    const handleSaveConsignmentList = () => {

        setIsLoading(true)
        SaveConsignmentList(consignmentList).then(() => {
        }).catch(err => console.log(err));

        setTimeout(() => {
            setIsLoading(false)
        }, 2500);
    }

    return (
        <div className='consignment-options--footer'>
            <ErrorService message={errorPropName} error={isError} setError={setIsError} />

            {showOptions && (<div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                {isTuusulaPage && <EmailPreviewModal title={t('Emaili eelvaade')} />}

                {hasUnsavedChanges() && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{t("There are unsaved changes in the document.")}</div>}
                {/* {(!transportRouteId || transportRouteId === "") &&
                    <button className="consignment-options--button" style={{ backgroundColor: "#1522a0" }} onClick={handleSendConsignmentList}>{t("Salvesta Directosse")}</button>
                } */}

                {(!transportRouteId || transportRouteId === "") &&
                    <button className="consignment-options--button" style={{ backgroundColor: "#1522a0" }} onClick={handleSaveConsignmentList}>{t("Salvesta")}</button>
                }
                {/*  {!transportRouteId && <ReturnToDirecto listIndex={listIndex} />} */}
            </div>)}
        </div>
    )
}

export default FinlandConsignmentOptions