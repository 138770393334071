import { Pagination, Stack } from '@mui/material';

type PaginatorProps = {
    currentPage: number;
    totalItems?: number;
    itemsPerPage?: number;
    onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const Paginator: React.FC<PaginatorProps> = ({ currentPage, totalItems, itemsPerPage, onChange }) => {
    const totalPageCount = Math.ceil(totalItems! / itemsPerPage!);
    return (
        <Stack spacing={2} direction="row" justifyContent="center">
            <Pagination count={totalPageCount} page={currentPage} onChange={onChange} size="large" />
        </Stack>
    );
};

export default Paginator;
