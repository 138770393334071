import '../../styles/CargosonComponent.css';
import { useDataContext } from "../../context/DataContext";
import { useTranslation } from 'react-i18next';
import { useCustomer } from '../login/user';

type Props = {
  listIndex: number,
  containerPadding?: string,
  isUserPermitted: boolean
}
const GeneralTransportInfo = ({ listIndex, containerPadding = "1% 1% 0 1%", isUserPermitted }: Props) => {
  const { updateCargosonDataConsignment, consignmentList, setConsignmentList } = useDataContext();
  const cargosonData = consignmentList[listIndex].cargosonData;
  const { t } = useTranslation();

  const handleDynamicChange = (event: any) => {
    updateCargosonDataConsignment(listIndex, event.target.name, event.target.value)
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputPrice = event.target.value;
    // Use regular expression to check for non-numeric characters (excluding dots and commas)
    if (/^[0-9,.]*$/.test(inputPrice)) {
      var tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
        ...item,
        cargosonData: { ...item.cargosonData, [event.target.name]: event.target.value }
      } : item);
      setConsignmentList(tempArr);
    }
  };

  return (
    <div style={{ padding: containerPadding, marginTop: "1%", width: "100%", overflowX: "auto" }}>
      <h2 className="page__heading" style={{ textAlign: "left", marginBottom: "2%" }}>{t("Transpordi info")}</h2>

      <table style={{ width: "800px" }}>
        <tbody>
          <tr>
            <td style={{ textAlign: 'left', width: "150px", padding: "5px" }}>{t("Veotellimuse viide")}:</td>
            <td style={{ textAlign: 'left', width: "250px", padding: "5px" }}>
              <input className="input-addressForm"
                readOnly={true}
                name="customer_reference"
                value={cargosonData.customer_reference || ""}
                onChange={handleDynamicChange}
                style={{ width: "91%", background: "inherit", border: "none", fontWeight: "bold", fontSize: "18px" }}
              ></input>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'left', width: "150px", padding: "5px" }}>{t("Tarneklausel")}:</td>
            <td style={{ textAlign: 'left', width: "250px", padding: "5px" }}>
              <select className="input-addressForm" style={{ width: "100%" }} disabled={!isUserPermitted} name="incoterm_code" onChange={handleDynamicChange} value={cargosonData.incoterm_code || ""}>
                <option value="EXW">EXW - Ex Works</option>
                <option value="FCA">FCA - Free Carrier</option>
                <option value="CPT">CPT - Carriage Paid to</option>
                <option value="CIP">CIP - Carriage and Insurance Paid to</option>
                <option value="DAT">DAT - Delivered at Terminal</option>
                <option value="DPU">DPU - Delivered at Place Unloaded</option>
                <option value="DAP">DAP - Delivered at Place</option>
                <option value="DDP">DDP - Delivery Duty Paid</option>
                <option value="FAS">FAS - Free Alongside Ship</option>
                <option value="FOB">FOB - Free on Board</option>
                <option value="CFR">CFR - Cost and Freight</option>
                <option value="CIF">CIF - Cost Insurance and Freight</option>
              </select>
            </td>
            <td style={{ textAlign: 'left', width: "150px", padding: "5px" }}>
              <input className="input-addressForm" placeholder={t("Linn")} readOnly={!isUserPermitted} style={{ width: "100%" }} name="incoterm_city" value={cargosonData.incoterm_city || ""} onChange={handleDynamicChange} ></input>
            </td>
          </tr>
          <tr >
            <td style={{ textAlign: 'left', width: "150px", padding: "5px" }}>{t("Täiendavad märkused vedajale")}:</td>
            <td colSpan={2} style={{ textAlign: 'left', width: "400px", padding: "5px" }}>
              <input className="input-addressForm" name="customer_remark" readOnly={!isUserPermitted} onChange={handleDynamicChange} value={cargosonData.customer_remark || ""} style={{ width: "100%" }}></input>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'left', width: "150px", padding: "5px" }}>{t("Veomaksumus")}:</td>
            <td style={{ textAlign: 'left', width: "250px", padding: "5px" }}>
              <input className="input-addressForm" name="total_price" readOnly={!isUserPermitted} type="text" min="0" onChange={handlePriceChange} value={cargosonData.total_price || ""} onWheel={event => event.currentTarget.blur()} style={{ width: "91%" }} placeholder={t("Hind")}></input>
            </td>
            <td style={{ textAlign: 'left', width: "150px", padding: "5px" }}>
              <input className="input-addressForm" readOnly={true} name="currency" onChange={handleDynamicChange} value={cargosonData.currency || ""} style={{ width: "100%" }} placeholder="EUR"></input>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GeneralTransportInfo;
