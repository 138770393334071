import { useMediaQuery } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import AddPackageComponent from '../cargoson/package/AddPackageComponent';
import DateTimeCalendar from '../common/DateTimeCalendar';
import { useCustomer } from '../login/user';

type Props = {
    idx: number,
    listIndex: number
    collectionDate: Date,
    setCollectionDate: React.Dispatch<React.SetStateAction<Date>>,
    timeFrom: Date | null,
    setTimeFrom: React.Dispatch<React.SetStateAction<Date | null>>,
    timeTo: Date | null,
    setTimeTo: React.Dispatch<React.SetStateAction<Date | null>>,
    isUserPermitted: boolean
}

const PickupAddressFinland = ({ idx, listIndex, collectionDate, setCollectionDate, timeFrom, setTimeFrom, timeTo, setTimeTo, isUserPermitted }: Props) => {
    const { updatePickups, consignmentList, setConsignmentList } = useDataContext();
    const { t } = useTranslation();
    const pickup = consignmentList[listIndex].cargosonData.pickups[idx]
    const [pickupTimePresetValue, setPickupPresetValue] = useState<string>("");
    const isWideScreen = useMediaQuery('(min-width:500px)');

    const handleDynamicChange = (event: any) => {
        updatePickups(idx, listIndex, event.target.name, event.target.value)
    };

    const handleCollectionDate = (date: Date) => {
        setCollectionDate(date)
        updatePickups(idx, listIndex, "collection_date", date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' }))
    }

    const handleTimeFrom = (date: Date) => {
        setTimeFrom(date)
        updatePickups(idx, listIndex, "collection_time_from", date?.toLocaleTimeString("et-EE"))
    }
    const handleTimeTo = (date: Date) => {
        setTimeTo(date)
        updatePickups(idx, listIndex, "collection_time_to", date?.toLocaleTimeString("et-EE"))
    }

    const handleSelectContactOptions = (event: any) => {
        if (event.target.value === "KuidoJaaksaar") {
            setConsignmentList(consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === idx ? {
                            ...pickup,
                            "collection_contact_name": "Kuido Jaaksaar",
                            "collection_contact_phone": "+372 5294670",
                            "collection_contact_email": "kuido.jaaksaar@etsnord.com",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "MerikeHelistvee") {
            setConsignmentList(consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === idx ? {
                            ...pickup,
                            "collection_contact_name": "Merike Helistvee",
                            "collection_contact_phone": "+372 58556362",
                            "collection_contact_email": "merike.helistvee@etsnord.com",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "JanarVoltein") {
            setConsignmentList(consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === idx ? {
                            ...pickup,
                            "collection_contact_name": "Janar Voltein",
                            "collection_contact_phone": "+372 51963835",
                            "collection_contact_email": "janar.voltein@etsnord.com",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "Myself") {
            setConsignmentList(consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === idx ? {
                            ...pickup,
                            "collection_contact_name": `${localStorage.getItem("user")}`,
                            "collection_contact_phone": "",
                            "collection_contact_email": `${localStorage.getItem("email")}`
                        } : pickup)
                    ]
                }
            } : item))
        }
    }

    const handleCheckbox = (event: any) => {
        if (event.target.checked) {
            updatePickups(idx, listIndex, event.target.name, true)

        } else {
            updatePickups(idx, listIndex, event.target.name, false)
        }
    }

    const handleTimeChangePreset = (presetName: string) => {
        setPickupPresetValue(presetName)
        if (presetName === "Day") {
            const fromTime = new Date();
            fromTime.setHours(8, 0, 0, 0);

            const toTime = new Date();
            toTime.setHours(15, 0, 0, 0);

            setTimeFrom(fromTime);
            setTimeTo(toTime);

            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === 0 ? {
                            ...pickup,
                            collection_time_from: fromTime?.toLocaleTimeString("et-EE"), collection_time_to: toTime?.toLocaleTimeString("et-EE")
                        } : pickup)
                    ]
                }
            } : item);
            setConsignmentList(tempArr);
        }

        if (presetName === "7:00") {
            const fromTime = new Date();
            fromTime.setHours(7, 0, 0, 0);

            const toTime = new Date();
            toTime.setHours(7, 30, 0, 0);

            setTimeFrom(fromTime);
            setTimeTo(toTime);

            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === 0 ? {
                            ...pickup,
                            collection_time_from: fromTime?.toLocaleTimeString("et-EE"), collection_time_to: toTime?.toLocaleTimeString("et-EE")
                        } : pickup)
                    ]
                }
            } : item);
            setConsignmentList(tempArr);
        }

        if (presetName === "7:30") {
            const fromTime = new Date();
            fromTime.setHours(7, 30, 0, 0);

            const toTime = new Date();
            toTime.setHours(8, 0, 0, 0);

            setTimeFrom(fromTime);
            setTimeTo(toTime);

            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === 0 ? {
                            ...pickup,
                            collection_time_from: fromTime?.toLocaleTimeString("et-EE"), collection_time_to: toTime?.toLocaleTimeString("et-EE")
                        } : pickup)
                    ]
                }
            } : item);
            setConsignmentList(tempArr);
        }
    }

    return (
        <div className="cargoson-container__address-form-wrapper" style={{ backgroundColor: "#f8f8f8" }}>
            <div className='flex flex-wrap justify-start items-center' style={{ gap: "1%" }}>
                <h2 className="cargoson-container__address-form--title title-width" >
                    {t("Pealelaadimiskoht")}
                </h2>
                <div className='flex justify-start transport-order-block--seletions-box'>
                    <div>
                        <label>{t("Tagaluuk")}</label>
                        <input className="cargoson-container__address-form--checkbox" name="collection_with_tail_lift" disabled={!isUserPermitted} type="checkbox" checked={pickup.collection_with_tail_lift} /* value={pickup.collection_with_tail_lift} */ onChange={handleCheckbox}></input>
                    </div>
                    <div>
                        <label>{t("Ettehelistamine")}</label>
                        <input className="cargoson-container__address-form--checkbox" name="collection_prenotification" disabled={!isUserPermitted} type="checkbox" checked={pickup.collection_prenotification} /* value={pickup.collection_prenotification} */ onChange={handleCheckbox}></input>
                    </div>

                </div>
            </div>
            <div className="cargoson-container__address-form">
                <table className='cargoson-dropdown-table'>
                    <tbody>
                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Pealelaadimise aeg")}</td>}
                            <td>
                                <DateTimeCalendar handleChange={handleCollectionDate} currentDate={collectionDate} />
                            </td>
                            <td>
                                <DateTimeCalendar handleChange={handleTimeFrom} currentDate={timeFrom} showTimeSelectOnly={true} placeHolder={t("Alates")} />
                            </td>
                            <td>
                                <DateTimeCalendar handleChange={handleTimeTo} currentDate={timeTo} showTimeSelectOnly={true} placeHolder={t("Kuni")} />
                            </td>
                            <td>
                                <select className="cargoson-container__address-form--input" placeholder={t("Eeltäidetud kellaajad")} value={pickupTimePresetValue} onChange={(e: any) => handleTimeChangePreset(e.target.value)}>
                                    <option value="" disabled hidden>{t("Eeltäidetud kellaajad")}</option>
                                    <option value="Day">{t("Terve päev")}</option>
                                    <option value="7:00">7:00-7:30</option>
                                    <option value="7:30">7:30-8:00</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Ettevõte")}</td>}
                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Ettevõte")} name="collection_company_name" value={pickup.collection_company_name || "Ets Nord"} onChange={handleDynamicChange}></input>
                            </td>
                        </tr>

                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Aadress")}</td>}
                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Tänav")} name="collection_address_row_1" value={pickup.collection_address_row_1 || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Aadressi lisa")} name="collection_address_row_2" value={pickup.collection_address_row_2 || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Postiindeks")} name="collection_postcode" value={pickup.collection_postcode || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Linn")} name="collection_city" value={pickup.collection_city || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <select className="cargoson-container__address-form--input" disabled={!isUserPermitted} placeholder={t("Riik")} name="collection_country" value={pickup.collection_country || "FI"} onChange={handleDynamicChange}>
                                    <option value="" disabled hidden>{t("Vali riik")}</option>
                                    <option value="EE">{t("Estonia")}</option>
                                    <option value="FI">{t("Finland")}</option>
                                    <option value="LV">{t("Latvia")}</option>
                                    <option value="LT">{t("Lithuania")}</option>
                                    <option value="SE">{t("Sweden")}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Kontakt")}</td>}
                            <td>
                                <select className="cargoson-container__address-form--input" disabled={!isUserPermitted} placeholder={t("Kontakt")} defaultValue="" onChange={handleSelectContactOptions}>
                                    <option value="" disabled hidden>{t("Vali eeltäidetud")}</option>
                                    <option value="KuidoJaaksaar">Kuido Jaaksaar</option>
                                    <option value="MerikeHelistvee">Merike Helistvee</option>
                                    <option value="JanarVoltein">Janar Voltein</option>
                                    <option value="Myself">Vali ennast</option>
                                </select>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Ees- ja perekonnanimi")} name="collection_contact_name" value={pickup.collection_contact_name || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Telefoni nr")} name="collection_contact_phone" value={pickup.collection_contact_phone || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td colSpan={2} style={{ textAlign: 'left' }}>
                                <input className="cargoson-container__address-form--input"
                                    readOnly={!isUserPermitted}
                                    placeholder={t("E-mail")}
                                    name="collection_contact_email"
                                    value={pickup.collection_contact_email || ""}
                                    onChange={handleDynamicChange}
                                ></input>
                            </td>
                        </tr>
                        <tr>
                            {isWideScreen && <td className="cargoson-container__address-form--first-td">{t("Kommentaar")}</td>}
                            <td colSpan={2} style={{ textAlign: "left" }}>
                                <input className="cargoson-container__address-form--input" readOnly={!isUserPermitted} placeholder={t("Kommentaar")} name="collection_comment" onChange={handleDynamicChange} value={pickup.collection_comment || ""} ></input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <AddPackageComponent pickupIdx={idx} listIndex={listIndex} />
        </div>
    )
}

export default PickupAddressFinland