import React from 'react'
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment'
import { handleGetInHouseConsignmentStatus } from '../Common'
type Props = {
    index: number
    car: InHouseConsignment[]
    handleRowClick: (id: number) => void
}
const CarAssignedDeliveriesLoader = ({ car, index, handleRowClick }: Props) => {
    return (
        <div key={car[0].deliveryDriverId} style={{ background: "#efefef", borderRadius: "30px 0 0 30px", padding: "1%", marginBottom: "15px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead style={{ color: "#737372", fontFamily: "Helvetica-Roman" }}>
                    <tr>
                        <td style={{ fontFamily: 'Helvetica-Bd' }}>
                            {car[0].deliveryDriver.carName}
                            -
                            {car[0].deliveryDriver.carLicenseNumber}
                        </td>
                        <td>Klient</td>
                        <td>Objekt</td>
                        <td>Lähetused</td>
                        <td>Staatus</td>
                    </tr>
                </thead>

                <React.Fragment key={index}>
                    {Object.values(car).map((calenderObj, idx) => (
                        <React.Fragment key={idx}>
                            {(calenderObj.consignmentStatus !== ConsignmentStatus.Delivered && calenderObj.consignmentStatus !== ConsignmentStatus.Finished) &&
                                <>
                                    <tr style={{ background: "#fff", color: "#737372", fontFamily: "Helvetica-Roman", borderRadius: "10px", cursor: "pointer" }} onClick={() => handleRowClick(calenderObj.id)}>
                                        <td style={{ borderRadius: "10px 0px 0px 0px", padding: "10px", paddingTop: "15px", paddingBottom: "0px", width: "20%" }}>{calenderObj.deliveryTimeFrom} {calenderObj.deliveryTimeTo && `- ${calenderObj.deliveryTimeTo}`}</td>
                                        <td style={{ paddingTop: "15px", paddingBottom: "0px", width: "28%" }}>{calenderObj.deliveryCompanyName} {calenderObj.deliveryCompanyCustomerCode}</td>
                                        <td style={{ paddingTop: "15px", paddingBottom: "0px", width: "13%" }}>{calenderObj.deliveryObject}</td>
                                        <td style={{ paddingTop: "15px", paddingBottom: "0px", width: "25%" }}>
                                            {calenderObj.deliveries && calenderObj.deliveries.map((x, idx) => (
                                                <div key={idx} style={{ textDecoration: ` ${x.confirmed === "1" ? "line-through" : ""}` }}>{x.deliveryNumber} ({x.deliveryStatus}) </div>
                                            ))}
                                        </td>
                                        <td style={{ borderRadius: "0px 10px 0px 0px", paddingTop: "15px", paddingBottom: "0px", width: "14%" }}>{handleGetInHouseConsignmentStatus(calenderObj.consignmentStatus)}</td>
                                    </tr>
                                    {calenderObj.internalComment && <tr style={{ background: "#fff", color: "#9c9c9c", fontFamily: "Helvetica-Roman", cursor: "pointer" }} onClick={() => handleRowClick(calenderObj.id)}>
                                        <td colSpan={5} style={{ padding: "10px", paddingTop: "0px", paddingBottom: "5px", }}>{calenderObj.internalComment}</td>
                                    </tr>}
                                    {calenderObj.deliveryAddressRow1 && <tr style={{ background: "#fff", color: "#9c9c9c", fontFamily: "Helvetica-Roman", cursor: "pointer" }} onClick={() => handleRowClick(calenderObj.id)}>
                                        <td colSpan={5} style={{ padding: "10px", paddingTop: "0px", paddingBottom: "5px", borderRadius: "0 0 10px 10px" }}>{calenderObj.deliveryAddressRow1}</td>
                                    </tr>}
                                    <tr style={{ background: "#efefef" }}>
                                        <td colSpan={5} style={{ padding: "3px", borderRadius: "0 0 10px 10px" }}></td>
                                    </tr>
                                </>
                            }
                        </React.Fragment>
                    ))}
                </React.Fragment>
            </table>
        </div >
    )
}

export default CarAssignedDeliveriesLoader