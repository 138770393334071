import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { COUNTRIES } from '../../assets/countries/countries';
import { useDataContext } from '../../context/DataContext';

interface CountryOption {
    value: string;
    label: string;
}

interface SelectCountryProps {
    propName: string,
    listIndex: number
}

const customStyles = {
    control: (provided: any) => ({
        ...provided,
        width: '100%',
        minWidth: "200px",
        color: "#5c5f63",
        textAlign: "left",
        fontSize: ".9rem",
        fontWeight: "400",
        lineHeight: "1.5",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        backgroundClip: "padding-box",
        border: "1px solid #dee2e6",
        borderRadius: ".25rem",
        fontFamily: "Helvetica"
    }),
    placeholder: (provided: any) => ({
        ...provided,
        textAlign: 'left',
        color: "#5c5f63"
    }),
    option: (provided: any) => ({
        ...provided,
        textAlign: 'left',
        color: "#5c5f63"
    }),
};

const SelectCountry: React.FC<SelectCountryProps> = ({ propName, listIndex }) => {
    const { t } = useTranslation()
    const { consignmentList, updateCargosonDataConsignment, updatePickups } = useDataContext()
    const collection_country = consignmentList[listIndex].cargosonData.pickups[0].collection_country;
    const delivery_country = consignmentList[listIndex].cargosonData.delivery_country

    const options: CountryOption[] = COUNTRIES.map((country) => ({
        value: country.value,
        label: t(country.title),
    }));

    const getInitialValue = () => {
        if (propName === "delivery_country") {
            return options.find((option) => option.value === consignmentList[0].cargosonData.delivery_country) || null;
        }
        if (propName === "collection_country") {
            return options.find((option) => option.value === consignmentList[0].cargosonData.pickups[0].collection_country) || null;
        }
        return null
    }

    const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(getInitialValue());

    const handleCountryChange = (selectedOption: CountryOption | null) => {
        if (selectedOption) {
            // Handle the selected country option
            if (propName === "delivery_country") {
                updateCargosonDataConsignment(listIndex, propName, selectedOption.value)
            }
            if (propName === "collection_country") {
                updatePickups(0, listIndex, propName, selectedOption.value)
            }

        }
    };

    useEffect(() => {
        setSelectedCountry(getInitialValue()); // Update the selected value when consignmentList changes
    }, [collection_country, delivery_country]);



    return (
        <Select
            options={options}
            styles={customStyles}
            isSearchable
            value={selectedCountry}
            placeholder={t("Valige riik")}
            defaultValue={getInitialValue()}
            onChange={handleCountryChange}
        />
    );
};

export default SelectCountry;
