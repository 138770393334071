/* eslint-disable @typescript-eslint/no-unused-vars */
import '../../styles/buttons.css'
import '../../styles/Consignment.css'
import '../../styles/CargosonComponent.css';
import { useDataContext } from '../../context/DataContext';
import SelectSupplier from '../common/SelectSupplier';
import { useTranslation } from 'react-i18next';
import { useCustomer } from '../login/user';

type Props = {
    listIndex: number
    className?: string
    isUserPermitted: boolean
}
const ConsignmentHeaderFinland: React.FC<Props> = ({ listIndex, className = "consignment-header-container", isUserPermitted }) => {
    const { updateConsignmentData, consignmentList, supplier, setConsignmentList } = useDataContext();
    const consignmentData = consignmentList[listIndex]
    const { t } = useTranslation();

    const handleDynamicChange = (event: any) => {
        updateConsignmentData(listIndex, event.target.name, event.target.value)
    };

    const handleContactChange = (event: any) => {
        setConsignmentList(consignmentList.map((item, idx) => {
            return {
                ...item,
                "carrierContactId": event.target.value,
                "carrierRepresentative": supplier.contacts.find(x => x.id === +event.target.value)?.name ?? "",
                "carrierEmail": supplier.contacts.find(x => x.id === +event.target.value)?.email ?? "",
                "carrierPhone": supplier.contacts.find(x => x.id === +event.target.value)?.phone ?? "",
            }
        }))
    }

    return (
        <div className={className}>
            {(consignmentData !== undefined) ? (
                <div className="consignment-header-container--content" >
                    <h2 className="page__heading" style={{ textAlign: "left" }}>{t("Vedaja info")}</h2>
                    <table className="max">
                        <tbody className="max">
                            <tr>
                                <td style={{ width: "10%" }}>{t("Vedaja")}</td>
                                <td style={{ width: "30%" }}>
                                    <div>
                                        <SelectSupplier listIndex={listIndex} isUserPermitted={isUserPermitted} />
                                    </div>
                                </td>

                                <td colSpan={2} style={{ width: "50px" }}></td>

                                <td style={{ textAlign: "center" }}>{t("Autojuht")}</td>
                                <td>
                                    <input style={{ width: "100%", boxSizing: "border-box" }} className="input-addressForm" name="carrierDriver" value={consignmentData.carrierDriver || ""} placeholder={t("Nimi")} onChange={handleDynamicChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("Esindaja")}</td>
                                <td>
                                    <select className="input-addressForm" style={{ width: "100%", boxSizing: "border-box" }} name="carrierContactId" value={consignmentData.carrierContactId || ""} onChange={handleContactChange} disabled={!isUserPermitted}>
                                        {(consignmentData.cargosonTransportId !== (0 || null) && supplier.contacts && supplier.contacts.length !== 0) ?
                                            (<>
                                                <option value="" disabled hidden >{t("Valige esindaja")}</option>
                                                {supplier.contacts.map((contact, index) => {
                                                    return (
                                                        <option key={index} value={contact.id}>{contact.name}</option>
                                                    )
                                                })}
                                            </>)
                                            :
                                            (<>
                                                <option value="" disabled hidden >{t("Valige vedaja")}</option>
                                            </>)}
                                    </select>
                                </td>
                                <td colSpan={2} style={{ width: "50px" }}></td>

                                <td style={{ textAlign: "center" }}>{t("Email")}</td>
                                <td>
                                    <input readOnly={true} style={{ width: "100%", boxSizing: "border-box" }} className="input-addressForm" name="carrierEmail" value={consignmentData.carrierEmail || ""} placeholder={t("Valige esindaja")}></input>
                                </td>
                                <td colSpan={2} style={{ width: "50px" }}></td>
                            </tr>

                            <tr>
                                <td colSpan={1} >{t("Sisekommentaar")}</td>
                                <td colSpan={5} rowSpan={2} style={{ textAlign: 'center' }}>
                                    <input className="input-addressForm" name="internalComment" readOnly={!isUserPermitted} onChange={handleDynamicChange} value={consignmentData.internalComment || ""} style={{ height: "60px", width: '100%', boxSizing: "border-box" }}></input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : ""
            }
        </div>
    );
}

export default ConsignmentHeaderFinland;