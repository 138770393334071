/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import '../../../styles/Consignment.css'
import { GetPurchaseOrders, SearchDeliveriesWithDeliveryNumber } from '../../../queries/directo-queries';
import { Deliveries } from '../../../Types/directo/Deliveries';
import { PurchaseOrder } from '../../../Types/PurchaseOrder';
import { PurchaseOrderResponse } from '../../../Types/directo/PurchaseOrderResponse';

type Props = {
    purchaseOrderNumber: string;
}

const PurchaseOrderModal = ({ purchaseOrderNumber }: Props) => {
    const [purchaseOrderData, setPurchaseOrderData] = useState<PurchaseOrderResponse[]>([]);

    useEffect(() => {
        const handleDeliverySearch = (purchaseOrderNumber: string) => {
            GetPurchaseOrders(purchaseOrderNumber).then(response => {
                setPurchaseOrderData(response.data)
                console.log(response.data)
            });
        }
        handleDeliverySearch(purchaseOrderNumber)
    }, [])

    return (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div className="search-table-content__table-thead--td left" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                <div>
                    Ostutellimus {purchaseOrderNumber}
                </div>
                {/* <div>
                    Staatus: {purchaseOrderData[0]?.status}
                </div> */}
            </div>
            {Object.values(purchaseOrderData).map((value, idx) => (
                <table key={idx} style={{ borderCollapse: "collapse", width: "100%", color: "#6c757d", borderTop: "1px solid #dee2e6" }} >
                    <thead>
                        <tr className="deliveriesTable-head-tr">
                            <td className="search-table-content__table-thead--td left padding-xs">Artikkel</td>
                            <td className="search-table-content__table-thead--td left padding-xs">Kirjeldus</td>
                            <td className="search-table-content__table-thead--td left padding-xs">Kogus</td>
                        </tr>
                    </thead>
                    <tbody >
                        {Object.values(value.items).map((x, idx) => (
                            <tr key={idx} className="search-table-content__table--tr" >
                                <td className="search-table-content__table--outer-td left padding-xs">
                                    {x.itemCode}
                                </td>
                                <td className='left padding-xs'>
                                    {x.description}
                                </td>
                                <td className="search-table-content__table--outer-td left padding-xs">
                                    {x.quantity}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ))}
        </div>
    );
}

export default PurchaseOrderModal;
