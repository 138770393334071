import ShowAllConsignments from '../../components/consignment/ShowAllConsignments'
import '../../styles/Consignment.css';
import '../../styles/CargosonComponent.css';
import '../../styles/transport-route.css'
import '../../styles/directo-component.css'
import '../../styles/buttons.css'
import { useCustomer } from '../../components/login/user';
import { Navigate } from 'react-router-dom';
import CreateOrLoadConsignmentRedirect from '../../components/consignment/CreateOrLoadConsignmentRedirect';
import FilterConsignments from '../../components/consignment/FilterConsignments';
import { useState, useEffect } from 'react';
import { useDataContext } from '../../context/DataContext';
import { SearchAllConsignments } from '../../queries/queries';
import Paginator from '../../components/common/Paginator';
import Loading from '../../components/common/Loading';

const ConsignmentHomePage = () => {
    const { logged } = useCustomer();
    const { setConsignmentListResponseNew } = useDataContext();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsCount, setItemsCount] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [archiveView, setArchiveView] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState(""); // Default sorting field
    const [isDescending, setIsDescending] = useState(true); // Default sorting order
    const itemsPerPage = 30;
    const [isConsignmentDeleted, setIsConsignmentDeleted] = useState(false);
    const [consignmentType, setConsignmentType] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadConsignmentsByPage = (searchTermTemp: string) => {
        setIsLoading(true)
        SearchAllConsignments(searchTermTemp, currentPage, itemsPerPage, archiveView, sortBy, isDescending, consignmentType).then((response: any) => {
            setConsignmentListResponseNew(response.data.item1)
            setItemsCount(response.data.item2)
        }).finally(() => { setIsLoading(false) })
    }

    const handleSortingChange = (field: string, setSortingState: React.Dispatch<React.SetStateAction<boolean>>, sortingState: boolean) => {
        setSortingState(!sortingState)
        setSortBy(field);
        setIsDescending(!isDescending);
        SearchAllConsignments(searchTerm, currentPage, itemsPerPage, archiveView, field, !sortingState, consignmentType).then((response: any) => {
            setConsignmentListResponseNew(response.data.item1)
            setItemsCount(response.data.item2)
        })
    };

    const handleConsignmentDeleted = () => {
        setIsConsignmentDeleted(true);
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    }

    useEffect(() => {
        loadConsignmentsByPage(searchTerm);
        setIsConsignmentDeleted(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, isConsignmentDeleted, archiveView, consignmentType]);

    if (!logged) {
        return <Navigate to="/login" />
    }

    return (
        <div className="page__container-outer-wrapper">
            {isLoading && <div style={{ zIndex: "1000", position: "absolute", top: "7rem" }}><Loading /></div>}
            <div className="page__container-wrapper--flexbox">
                <div className="page__center-column-flexbox all-consignments--container">
                    <div className='page__heading' style={{ fontSize: "40px", marginBottom: "1%" }}>Veotellimused</div>
                    <div className='page__header' >
                        <div style={{ alignItems: "center", display: "flex", flexDirection: "row", gap: "50px", paddingLeft: "3%" }}>
                            <CreateOrLoadConsignmentRedirect />
                            <FilterConsignments searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleSearch={loadConsignmentsByPage} setCurrentPage={setCurrentPage} />
                        </div>
                    </div>
                    <ShowAllConsignments onDeleteConsignment={handleConsignmentDeleted} archiveView={archiveView} setArchiveView={setArchiveView} handleSortingChange={handleSortingChange} consignmentType={consignmentType} setConsignmentType={setConsignmentType} />
                    <div style={{ marginTop: "1%" }}>
                        <Paginator currentPage={currentPage} totalItems={itemsCount} itemsPerPage={itemsPerPage} onChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ConsignmentHomePage