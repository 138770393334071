import { IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ConvertDate } from '../../../components/common/ConvertDate';
import Loading from '../../../components/common/Loading';
import { GetInHouseConsignment } from '../../../queries/deliveryDriverQueries';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import '../../../styles/tracking-app/delivery-details.css';
import { HandleGetInHouseConsignmentStatus } from '../../../components/tracking-app/driver/DeliveryStatusBadge';
import Modal from '../../../components/common/Modal';
import ItemsModal from '../../../components/consignment/Directo/ItemsModal';
import { ReactComponent as SearchIcon } from '../../../assets/chat_search_fill.svg';
import PurchaseOrderModal from '../../../components/consignment/Directo/PurchaseOrderModal';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../../components/login/user';
import PhoneIcon from '@mui/icons-material/Phone';

const DeliveryDetailsLoader = () => {
  const { t } = useTranslation();
  const { permissions } = useCustomer();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deliveryData, setDeliveryData] = useState<InHouseConsignment>({
    deliveryLatitude: 0,
    deliveryLongitude: 0
  } as InHouseConsignment);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const calendardate = queryParams.get('calendardate');
  const screenWidth = window.screen.width;
  const mapWidth = screenWidth > 800 ? 780 : screenWidth;
  const [calendarDate, setCalendarDate] = useState<Date>(calendardate ? ConvertDate(calendardate) : new Date());
  const [showItemsModal, setShowItemsModal] = useState<boolean>(false);
  const [deliveryToShow, setDeliveryToShow] = useState<string>('');
  const formattedDate = calendarDate.toLocaleString('et-EE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  const [deliveryDriverLocation, setDeliveryDriverLocation] = useState<GeolocationPosition | null>(null);
  const handleLoadData = () => {
    if (id !== undefined) {
      setIsLoading(true);
      GetInHouseConsignment(+id)
        .then((response: AxiosResponse<InHouseConsignment>) => {
          setDeliveryData(response.data);
          const driverGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.deliveryDriver.latitude,
              longitude: response.data.deliveryDriver.longitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: response.data.deliveryDriver.locationUpdateTime
          };

          setDeliveryDriverLocation(driverGeolocationPosition);
        })
        .catch((error) => {
          console.error(error);
        });
      setIsLoading(false);
    }
  };

  const openItemsModal = (deliveryNumber: string) => {
    setShowItemsModal(true);
    setDeliveryToShow(deliveryNumber);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        handleLoadData();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  if (!permissions.includes("PEstonianWareHouseWorker")) {
    return <Navigate to="/unauthorized" />
  }
  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div className="delivery-page--wrapper" style={{ marginTop: "0" }}>
      <div className="driver-delivery-details--info-block" style={{ marginTop: "0" }}>
        <div className="flex justify-between items-start">
          <div className="flex flex-col items-start justify-start">
            {deliveryData.type === "Export" &&
              <>
                <div style={{ color: '#fff' }}>Lähetused:</div>
                <div className="delivery-details--deliveries-container">
                  {deliveryData.deliveries?.map((x, idx) => (
                    <div key={idx} className="delivery-details--delivery-numbers" style={{ textDecoration: ` ${x.confirmed === "1" ? "line-through" : ""}` }}>
                      {x.deliveryNumber} ({x.deliveryStatus})
                      <IconButton onClick={() => openItemsModal(x.deliveryNumber)}>
                        <SearchIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </>
            }
            {deliveryData.type === "Import" &&
              <>
                <div style={{ color: '#fff' }}>Ostutellimused:</div>
                <div className="delivery-details--deliveries-container">
                  {deliveryData.directoPurchaseOrderNumbers?.map((x, idx) => (
                    <div key={idx} className="delivery-details--delivery-numbers">
                      {x}
                      <IconButton onClick={() => openItemsModal(x)}>
                        <SearchIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </>
            }
          </div>
          <div className="flex items-center justify-end gap-xs">
            Staatus:
            <HandleGetInHouseConsignmentStatus statusNumber={deliveryData.consignmentStatus} />
          </div>
        </div>

        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Eeldatav saabumise aeg:</div>
          <div className="delivery-details--info-row-content">{`${deliveryData.deliveryTimeFrom}-${deliveryData.deliveryTimeTo}`}</div>
          <div className="delivery-details--info-row-content">{deliveryData.deliveryDate}</div>
        </div>
        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Klient: </div>
          <div className="delivery-details--info-row-content">{deliveryData.deliveryCompanyName || ''}</div>
        </div>
        {deliveryData.deliveryObject && (
          <div className="flex justify-start gap-xs delivery-details--info-row">
            <div>Projekt: </div>
            <div className="delivery-details--info-row-content">{deliveryData.deliveryObject || ''}</div>
          </div>
        )}

        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Aadress: </div>
          <div className="delivery-details--info-row-content">{deliveryData.deliveryAddressRow1 || ''}</div>
        </div>
        <div className="flex flex-col justify-start gap-xs delivery-details--info-row">
          <div>Veotellimuse looja:</div>
          <div className="flex justify-center items-center gap-xs w-100">
            <a href={`tel:${deliveryData.consignmentCreator || ''} `} style={{ cursor: 'pointer' }}>
              <IconButton style={{ background: '#fff' }}>
                <PhoneIcon fontSize="large" htmlColor="green" />
              </IconButton>
            </a>
            <div className="delivery-details--info-row-content">{deliveryData.consignmentCreator || ''} </div>
            <div className="delivery-details--info-row-content">{deliveryData.consignmentCreatorPhone || ''}</div>
          </div>
        </div>

        <div className="flex flex-col justify-start gap-xs delivery-details--info-row">
          <div>Kontakt:</div>
          <div className="flex justify-center items-center gap-xs w-100">
            <a href={`tel:${deliveryData.deliveryContactPhone || ''}`} style={{ cursor: 'pointer' }}>
              <IconButton style={{ background: '#fff' }}>
                <PhoneIcon fontSize="large" htmlColor="green" />
              </IconButton>
            </a>
            <div className="delivery-details--info-row-content">{deliveryData.deliveryContactName || ''}</div>
            <div className="delivery-details--info-row-content">{deliveryData.deliveryContactPhone || ''}</div>
          </div>
        </div>

        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Pealelaadimise aadress</div>
          <div className="delivery-details--info-row-content">{deliveryData.collectionAddressRow1 || ''}</div>
        </div>
        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Mahalaadimise aadress</div>
          <div className="delivery-details--info-row-content">{deliveryData.deliveryAddressRow1 || ''}</div>
        </div>
        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Kirjeldus:</div>
          <div className="delivery-details--info-row-content">{deliveryData.internalComment || ''}</div>
        </div>
        {deliveryData.consignmentStatus === ConsignmentStatus.Cancelled && (
          <>
            {' '}
            <div className="flex justify-start gap-xs delivery-details--info-row">
              <div>Tühistatas:</div>
              <div className="delivery-details--info-row-content">{deliveryData.cancelledBy}</div>
            </div>
            <div className="flex justify-start gap-xs delivery-details--info-row">
              <div>Tühistamise põhjus:</div>
              <div className="delivery-details--info-row-content">{deliveryData.cancellationReason}</div>
            </div>
          </>
        )}
      </div>
      {deliveryData.type === "Export" &&
        <Modal show={showItemsModal} onClose={() => setShowItemsModal(!showItemsModal)}>
          <ItemsModal deliveryNumber={deliveryToShow} />
        </Modal>
      }
      {deliveryData.type === "Import" &&
        <Modal show={showItemsModal} onClose={() => setShowItemsModal(!showItemsModal)}>
          <PurchaseOrderModal purchaseOrderNumber={deliveryToShow} />
        </Modal>
      }
    </div>
  );
};

export default DeliveryDetailsLoader;
