import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetAllDeliveryDrivers, ToggleDriverVisibility } from "../../../queries/deliveryDriverQueries";
import { DeliveryDriver } from "../../../Types/delivery-app/DeliveryDriver";
import { IconButton } from "@mui/material";
import { ReactComponent as UncheckedBox } from '../../../assets/tracking-app/buttons/checkbox.svg';
import { ReactComponent as CheckedBox } from '../../../assets/tracking-app/buttons/checkbox-checked.svg';
import { AxiosResponse } from "axios";
import Loading from "../../common/Loading";
/* import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete"; */

interface DriversTableProps {
  baseUrl: string;
  refresh: boolean;
}

const DriversTable = ({ baseUrl, refresh }: DriversTableProps) => {
  const [deliveryDrivers, setDeliveryDrivers] = useState<DeliveryDriver[]>([]);
  const navigate = useNavigate();

  const handleGetAllDeliveryDrivers = () => {
    GetAllDeliveryDrivers().then((response) => {
      setDeliveryDrivers(response.data);
    });
  };

  const handleNavigate = (id: number) => {
    navigate(`/${baseUrl}/` + id);
  };

  const handleHideCar = (e: any, id: number) => {
    e.stopPropagation();
    ToggleDriverVisibility(id).then((response: AxiosResponse<DeliveryDriver>) => {
      const updatedDriver = response.data;
      setDeliveryDrivers((x) =>
        x.map((driver) =>
          driver.id === id ? updatedDriver : driver
        )
      );
    }).catch(error => {
      console.error('Error toggling driver visibility:', error);
    });
    return;
  };
  useEffect(() => {
    handleGetAllDeliveryDrivers();
  }, [refresh]);

  if (!deliveryDrivers || deliveryDrivers.length === 0) {
    return <Loading />;
  }
  return (
    <div>
      <table className="consignment-table--table">
        <thead>
          <tr className="consignment-table--tr">
            <td className="consignment-table--thead">Autojuht</td>
            <td className="consignment-table--thead">Autoliik</td>
            <td className="consignment-table--thead">Reg Nr</td>
            <td className="consignment-table--thead">Asukoht</td>
            <td className="consignment-table--thead">Peida</td>
          </tr>
        </thead>
        <tbody>
          {deliveryDrivers.map((x, idx) => (
            <tr
              className="consignment-table--tr"
              key={idx}
              onClick={() => handleNavigate(x.id)}
              style={{ cursor: "pointer" }}
            >
              <td className="consignment-table--td">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  {x.drivers.map((x, idx) => (
                    <div key={idx}>{x}</div>
                  ))}
                </div>
              </td>
              <td className="consignment-table--td">{x.carName}</td>
              <td className="consignment-table--td">{x.carLicenseNumber}</td>
              <td className="consignment-table--td">
                {x.latitude} {x.longitude}
              </td>
              <td className="consignment-table--td">
                <IconButton onClick={(e: any) => handleHideCar(e, x.id)}>{x.hidden === true ? <CheckedBox /> : <UncheckedBox />}</IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DriversTable;
