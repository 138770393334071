import { Button } from '@mui/material'
import { useCustomer } from '../../../components/login/user';
import DriversTable from '../../../components/tracking-app/driver/DriversTable'
import '../../../styles/main.css'
import { Navigate } from 'react-router-dom';
import AddCarDialog from '../../admin/AddCarDialog';
import { useState } from 'react';
import { AddDeliveryDriver } from '../../../Types/admin/AddDeliveryDriver';

const AllDriversPage = () => {
    const { logged, permissions } = useCustomer();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [deliveryDriver, setDeliveryDriver] = useState<AddDeliveryDriver>({ id: 0, carName: "", carLicenseNumber: "", countryCode: "", selectedDrivers: [] } as unknown as AddDeliveryDriver);
    const [refreshTable, setRefreshTable] = useState<boolean>(false);

    const handleTableRefresh = () => {
        setRefreshTable(!refreshTable);
    };

    if (!logged) {
        return <Navigate to="/login" />
    }
    if (!permissions.includes("PInHouseDriver")) {
        return <Navigate to="/unauthorized" />
    }
    return (
        <div style={{ padding: "1%" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h3 className='cargoson-container__address-form--title'>Autode table</h3>
                {permissions.includes("PManageUsers") &&
                    <Button style={{ background: "#c00", color: "#fff", fontWeight: "bold", height: "55px" }} onClick={() => setIsDialogOpen(true)}>Lisa uus</Button>
                }
            </div>
            <div>
                <DriversTable baseUrl={"carrier-driver"} refresh={refreshTable} />
            </div>
            <AddCarDialog open={isDialogOpen} onClose={function (): void {
                setIsDialogOpen(false);
            }} deliveryDriver={deliveryDriver} setDeliveryDriver={setDeliveryDriver}
                onAddCar={handleTableRefresh} />
        </div>
    )
}
export default AllDriversPage