import { IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConvertDate } from '../../../components/common/ConvertDate';
import Loading from '../../../components/common/Loading';
import GoogleMapComponent from '../../../components/tracking-app/GoogleMap';
import { CompleteDelivery, GetInHouseConsignment, StartDelivery, UpdateInHouseConsignmentStatus, SendInHouseConsignmentToDirecto } from '../../../queries/deliveryDriverQueries';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import '../../../styles/tracking-app/delivery-details.css';
import { HandleGetInHouseConsignmentStatus } from '../../../components/tracking-app/driver/DeliveryStatusBadge';
import PhoneIcon from '@mui/icons-material/Phone';
import Modal from '../../../components/common/Modal';
import WazeLink from '../../../components/tracking-app/driver/WazeLink';
import ItemsModal from '../../../components/consignment/Directo/ItemsModal';
import { ReactComponent as SearchIcon } from '../../../assets/chat_search_fill.svg';
import { ReactComponent as StartIcon } from '../../../assets/tracking-app/start-icon.svg';
import { ReactComponent as CompletedIcon } from '../../../assets/tracking-app/round-completed-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/tracking-app/location-icon.svg';
import { ReactComponent as PencilIcon } from '../../../assets/tracking-app/pencil-icon.svg';
import { ReactComponent as PauseIcon } from '../../../assets/tracking-app/pause-icon.svg';
import { ReactComponent as CancelIcon } from '../../../assets/tracking-app/cancel-icon.svg';
import { ReactComponent as NavigateBackIcon } from '../../../assets/tracking-app/navigate-back-icon.svg';
import { GetPdfFromDirecto } from '../../../queries/queries';
import { saveAs } from 'file-saver';
import PurchaseOrderModal from '../../../components/consignment/Directo/PurchaseOrderModal';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../../components/login/user';
import useGeolocation from '../../../components/tracking-app/useGeolocation';

const DriverDeliveryDetails = () => {
  const { t } = useTranslation();
  const { permissions } = useCustomer();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deliveryData, setDeliveryData] = useState<InHouseConsignment>({
    deliveryLatitude: 0,
    deliveryLongitude: 0
  } as InHouseConsignment);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const calendardate = queryParams.get('calendardate');
  const screenWidth = window.screen.width;
  const mapWidth = screenWidth > 800 ? 780 : screenWidth;
  const navigate = useNavigate();
  const [calendarDate, setCalendarDate] = useState<Date>(calendardate ? ConvertDate(calendardate) : new Date());
  const [showItemsModal, setShowItemsModal] = useState<boolean>(false);
  const [deliveryToShow, setDeliveryToShow] = useState<string>('');
  const formattedDate = calendarDate.toLocaleString('et-EE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  const [deliveryDriverLocation, setDeliveryDriverLocation] = useState<GeolocationPosition | null>(null);
  const { position, error } = useGeolocation();

  const handleLoadData = () => {
    if (id !== undefined) {
      setIsLoading(true);
      GetInHouseConsignment(+id)
        .then((response: AxiosResponse<InHouseConsignment>) => {
          setDeliveryData(response.data);
          const driverGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.deliveryDriver.latitude,
              longitude: response.data.deliveryDriver.longitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: response.data.deliveryDriver.locationUpdateTime
          };

          setDeliveryDriverLocation(driverGeolocationPosition);
        })
        .catch((error) => {
          console.error(error);
        });
      setIsLoading(false);
    }
  };

  const openItemsModal = (deliveryNumber: string) => {
    setShowItemsModal(true);
    setDeliveryToShow(deliveryNumber);
  };

  const handleStatusChange = (consignmentId: number, status: ConsignmentStatus) => {
    if (status === ConsignmentStatus.InTransit && id !== undefined) {
      StartDelivery(consignmentId, +id).then((response: any) => { });
    }
    if (status === ConsignmentStatus.Finished && id !== undefined) {
      CompleteDelivery(consignmentId);
      navigate(`/carrier-driver/${deliveryData.deliveryDriverId}/?calendardate=${formattedDate}`)
    }
    UpdateInHouseConsignmentStatus(consignmentId, status)
      .then((response: any) => {
        setDeliveryData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSendToDirecto = async () => {
    try {
      setIsLoading(true);
      if (!position) {
        alert('Geolocation is not available. Please enable location services and try again.');
        setIsLoading(false);
        return;
      }

      const updatedDeliveryData = {
        ...deliveryData,
        consignmentStatus: ConsignmentStatus.Finished,
        dropOffLatitude: position!.coords.latitude,
        dropOffLongitude: position!.coords.longitude
      };

      await SendInHouseConsignmentToDirecto(updatedDeliveryData);
      await CompleteDelivery(deliveryData.id);
      setIsLoading(false);
      navigate(`/carrier-driver/${deliveryData.deliveryDriverId}`);
      alert('Vedu lõpetatud');
    } catch (error) {
      console.error('Error sending to Directo:', error);
      alert('Failed to send data. Please try again.');
      setIsLoading(false);
    }

  };

  const handleGetPrintout = (directoCode: string) => {
    if (directoCode === (null || undefined)) return;
    setIsLoading(true);
    GetPdfFromDirecto(directoCode, 1619)
      .then((response) => {
        saveAs(response.data, `Saateleht-${directoCode}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handelCancelDelivery = () => {
    navigate(`/carrier-driver/cancel/${id}`);
  };

  const handleEndImportDelivery = () => {
    if (deliveryData.type === "Export") {
      navigate(`/carrier-driver/end-delivery/${id}`)
      return;
    }
    if (deliveryData.type === "Import") {
      handleStatusChange(+id!, ConsignmentStatus.Delivered)
      handleSendToDirecto()
    }
  }

  const handleGoToDirecto = (directoCode: string) => {
    if (directoCode) {
      window.open(`https://login.directo.ee/ocra_ets_nord/event.asp?KOOD=${directoCode}`, '_blank');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        handleLoadData();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  if (!permissions.includes("PInHouseDriver")) {
    return <Navigate to="/unauthorized" />
  }
  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div className="delivery-page--wrapper">
      <header className="delivery-driver--header" >
        <nav className="flex justify-between">
          <div
            className="flex flex-row justify-between items-center"
            style={{
              width: '100%',
              justifyContent: 'space-between',
              padding: '2rem',
              paddingLeft: "3rem",
              paddingRight: "3rem"
            }}
          >
            <div className="flex flex-col justify-start">
              <IconButton onClick={() => navigate(`/carrier-driver/${deliveryData.deliveryDriverId}/?calendardate=${formattedDate}`)}>
                <NavigateBackIcon />
              </IconButton>
              <label className="header--button-label">Tänased veod</label>
            </div>

            <>
              {deliveryData.consignmentStatus === ConsignmentStatus.InTransit && (
                <div className="flex flex-col items-center">
                  <IconButton onClick={() => handleStatusChange(+id!, ConsignmentStatus.WaitingClient)}>
                    <LocationIcon />
                  </IconButton>
                  <label className="header--button-label" style={{ marginTop: '-5px' }}>
                    Olen kohal
                  </label>
                </div>
              )}
            </>
            <>
              {deliveryData.consignmentStatus === ConsignmentStatus.WaitingClient && (
                <div className="flex flex-col justify-start">
                  <IconButton onClick={handleEndImportDelivery}>
                    <PencilIcon />
                  </IconButton>
                  <label className="header--button-label">Anna üle</label>
                </div>
              )}
            </>
            <div className="flex flex-col justify-start">
              {(deliveryData.consignmentStatus === ConsignmentStatus.Pending || deliveryData.consignmentStatus === ConsignmentStatus.Loaded) && (
                <>
                  <IconButton
                    onClick={() => {
                      handleStatusChange(+id!, ConsignmentStatus.InTransit);
                    }}
                  >
                    <StartIcon />
                  </IconButton>
                  <label className="header--button-label">Alusta</label>
                </>
              )}
              {(deliveryData.consignmentStatus === ConsignmentStatus.Delivered || deliveryData.consignmentStatus === ConsignmentStatus.UnSigned) && (
                <>
                  <IconButton
                    onClick={() => {
                      handleStatusChange(+id!, ConsignmentStatus.Finished);
                    }}
                  >
                    <CompletedIcon />
                  </IconButton>
                  <label className="header--button-label">Lõpeta vedu</label>
                </>
              )}

              {deliveryData.consignmentStatus === ConsignmentStatus.InTransit && (
                <>
                  <IconButton
                    onClick={() => {
                      handleStatusChange(+id!, ConsignmentStatus.Pending);
                    }}
                  >
                    <PauseIcon />
                  </IconButton>
                  <label className="header--button-label">Peata vedu</label>
                </>
              )}

              {deliveryData.consignmentStatus === ConsignmentStatus.WaitingClient && (
                <>
                  <IconButton
                    onClick={() => {
                      handleStatusChange(+id!, ConsignmentStatus.InTransit);
                    }}
                  >
                    <PauseIcon />
                  </IconButton>
                  <label className="header--button-label">Peata üleandmine</label>
                </>
              )}
            </div>
            <>
              {(deliveryData.consignmentStatus === ConsignmentStatus.UnSigned || deliveryData.consignmentStatus === ConsignmentStatus.Delivered) && (
                <div className="flex flex-col items-center">
                  <IconButton onClick={() => navigate(`/carrier-driver/end-delivery/${id}`)}>
                    <PencilIcon />
                  </IconButton>
                  <label className="header--button-label">Muuda</label>
                </div>
              )}
            </>
            {(deliveryData.consignmentStatus === ConsignmentStatus.Pending || deliveryData.consignmentStatus === ConsignmentStatus.Loaded) && (
              <div className="flex flex-col items-center">
                <IconButton onClick={handelCancelDelivery}>
                  <CancelIcon />
                </IconButton>
                <label className="header--button-label">Tühista</label>
              </div>
            )}
            <div className="flex flex-col items-center">
              <WazeLink address={deliveryData.deliveryAddressRow1} />
              <label className="header--button-label">Ava Waze</label>
            </div>
          </div>
        </nav>
      </header>

      <div className="driver-delivery-details--info-block">
        <div className="flex justify-between items-start">
          <div className="flex flex-col items-start justify-start">
            {deliveryData.type === "Export" &&
              <>
                <div style={{ color: '#fff' }}>Lähetused:</div>
                <div className="delivery-details--deliveries-container">
                  {deliveryData.deliveries?.map((x, idx) => (
                    <div key={idx} className="delivery-details--delivery-numbers" style={{ textDecoration: ` ${x.confirmed === "1" ? "line-through" : ""}` }}>
                      {x.deliveryNumber} ({x.deliveryStatus})
                      <IconButton onClick={() => openItemsModal(x.deliveryNumber)}>
                        <SearchIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </>
            }
            {deliveryData.type === "Import" &&
              <>
                <div style={{ color: '#fff' }}>Ostutellimused:</div>
                <div className="delivery-details--deliveries-container">
                  {deliveryData.directoPurchaseOrderNumbers?.map((x, idx) => (
                    <div key={idx} className="delivery-details--delivery-numbers">
                      {x}
                      <IconButton onClick={() => openItemsModal(x)}>
                        <SearchIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </>
            }
          </div>
          <div className="flex items-center justify-end gap-xs">
            Staatus:
            <HandleGetInHouseConsignmentStatus statusNumber={deliveryData.consignmentStatus} />
          </div>
        </div>



        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Eeldatav saabumise aeg:</div>

          <div className="delivery-details--info-row-content">{`${deliveryData.deliveryTimeFrom}-${deliveryData.deliveryTimeTo}`}</div>
          <div className="delivery-details--info-row-content">{deliveryData.deliveryDate}</div>
        </div>
        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Klient: </div>
          <div className="delivery-details--info-row-content">{deliveryData.deliveryCompanyName || ''}</div>
        </div>
        {deliveryData.deliveryObject && (
          <div className="flex justify-start gap-xs delivery-details--info-row">
            <div>Projekt: </div>
            <div className="delivery-details--info-row-content">{deliveryData.deliveryObject || ''}</div>
          </div>
        )}
        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Kirjeldus:</div>
          <div className="delivery-details--info-row-content">{deliveryData.internalComment || ''}</div>
        </div>
        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Aadress: </div>
          <div className="delivery-details--info-row-content">{deliveryData.deliveryAddressRow1 || ''}</div>
        </div>

        {/* <div className="flex flex-col justify-start gap-xs delivery-details--info-row">
          <div>Müügiagent:</div>
          <div className="flex justify-center items-center gap-xs w-100">
            <a href={`tel:${deliveryData.salesPersonPhone || ''} `} style={{ cursor: 'pointer' }}>
              <IconButton style={{ background: '#fff' }}>
                <PhoneIcon fontSize="large" htmlColor="green" />
              </IconButton>
            </a>
            <div className="delivery-details--info-row-content">{deliveryData.salesPerson || ''} </div>
            <div className="delivery-details--info-row-content">{deliveryData.salesPersonPhone || ''}</div>
          </div>
        </div> */}

        <div className="flex flex-col justify-start gap-xs delivery-details--info-row">
          <div>Kontakt:</div>
          <div className="flex justify-center items-center gap-xs w-100">
            <a href={`tel:${deliveryData.deliveryContactPhone || ''}`} style={{ cursor: 'pointer' }}>
              <IconButton style={{ background: '#fff' }}>
                <PhoneIcon fontSize="large" htmlColor="green" />
              </IconButton>
            </a>
            <div className="delivery-details--info-row-content">{deliveryData.deliveryContactName || ''}</div>
            <div className="delivery-details--info-row-content">{deliveryData.deliveryContactPhone || ''}</div>
          </div>
        </div>

        <div className="flex flex-col justify-start gap-xs delivery-details--info-row">
          <div>Veotellimuse looja:</div>
          <div className="flex justify-center items-center gap-xs w-100">
            {deliveryData.consignmentCreatorPhone && <a href={`tel:${deliveryData.consignmentCreatorPhone || ''}`} style={{ cursor: 'pointer' }}>
              <IconButton style={{ background: '#fff' }}>
                <PhoneIcon fontSize="large" htmlColor="green" />
              </IconButton>
            </a>}
            <div className="delivery-details--info-row-content">{deliveryData.consignmentCreator || ''}</div>
            <div className="delivery-details--info-row-content">{deliveryData.consignmentCreatorPhone || ''}</div>
          </div>
        </div>

        <div className="flex justify-start gap-xs delivery-details--info-row">
          <div>Pealelaadimise aadress</div>
          <div className="delivery-details--info-row-content">{deliveryData.collectionAddressRow1 || ''}</div>
        </div>


        {deliveryData.directoCode !== null && deliveryData.consignmentStatus === ConsignmentStatus.Delivered && (
          <div className="delivery-details--pictures-container ">
            <div>Saateleht:</div>
            <div className="delivery-details--delivery-numbers">
              {deliveryData.directoCode || ''}
              <IconButton onClick={() => handleGetPrintout(deliveryData.directoCode)}>
                <SearchIcon />
              </IconButton>
            </div>
          </div>
        )}
        {deliveryData.directoCode !== null && deliveryData.consignmentStatus === ConsignmentStatus.Delivered && (
          <div className="delivery-details--pictures-container ">
            <div>Pildid:</div>
            <div className="delivery-details--delivery-numbers">
              {/* deliveryData.attachmentImage1Base64 && */ 'Pilt 1'}
              <IconButton onClick={() => handleGoToDirecto(deliveryData.directoCode)}>
                <SearchIcon />
              </IconButton>
            </div>
            <div className="delivery-details--delivery-numbers">
              {/* deliveryData.attachmentImage2Base64 && */ 'Pilt 2'}
              <IconButton>
                <SearchIcon />
              </IconButton>
            </div>
          </div>
        )}
        {
          <div className="flex justify-start gap-xs delivery-details--info-row">
            <div>Üleandmise kommentaar:</div>
            <div className="delivery-details--info-row-content">{deliveryData.endDeliveryComment}</div>
          </div>
        }
        {deliveryData.directoCode !== null && deliveryData.consignmentStatus === ConsignmentStatus.Delivered && (
          <div className="flex justify-start gap-xs delivery-details--info-row">
            <div>Allkiri:</div>
            <div className="delivery-details--info-row-content">{deliveryData.signatureBase64 !== null ? 'Jah' : 'Ei'}</div>
          </div>
        )}

        {deliveryData.consignmentStatus === ConsignmentStatus.Cancelled && (
          <>
            {' '}
            <div className="flex justify-start gap-xs delivery-details--info-row">
              <div>Tühistatas:</div>
              <div className="delivery-details--info-row-content">{deliveryData.cancelledBy}</div>
            </div>
            <div className="flex justify-start gap-xs delivery-details--info-row">
              <div>Tühistamise põhjus:</div>
              <div className="delivery-details--info-row-content">{deliveryData.cancellationReason}</div>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          zIndex: '5',
          marginBottom: '100px',
          position: 'absolute',
          top: '91%'
        }}
      >
        {deliveryData.type === 'Export' && deliveryData.deliveryLatitude !== 0 && deliveryData.deliveryLongitude !== 0 && <GoogleMapComponent latitude={deliveryData.deliveryLatitude} longitude={deliveryData.deliveryLongitude} width={mapWidth} />}
        {deliveryData.type === 'Import' && deliveryData.collectionLatitude !== 0 && deliveryData.collectionLongitude !== 0 && <GoogleMapComponent latitude={deliveryData.collectionLatitude} longitude={deliveryData.collectionLongitude} width={mapWidth} />}
        {/* <TrackingUser id={+userDriverId! === deliveryData.deliveryDriverId ? +userDriverId! : 0} currentLocation={deliveryDriverLocation} setCurrentLocation={setDeliveryDriverLocation} deliveryLocation={deliveryLocation} setDeliveryLocation={setDeliveryLocation} /> */}
      </div>
      {deliveryData.type === "Export" &&
        <Modal show={showItemsModal} onClose={() => setShowItemsModal(!showItemsModal)}>
          <ItemsModal deliveryNumber={deliveryToShow} />
        </Modal>
      }
      {deliveryData.type === "Import" &&
        <Modal show={showItemsModal} onClose={() => setShowItemsModal(!showItemsModal)}>
          <PurchaseOrderModal purchaseOrderNumber={deliveryToShow} />
        </Modal>
      }
    </div>
  );
};

export default DriverDeliveryDetails;
