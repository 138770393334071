import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ConvertDate } from '../../../components/common/ConvertDate';
import DeliveryOrderNew from '../../../components/tracking-app/driver/DeliveryOrderNew';
import { GetInHouseConsignmentsCalendar, StartDelivery, UpdateInHouseConsignmentStatus } from '../../../queries/deliveryDriverQueries';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrow';
import { HandleGetInHouseConsignmentStatus } from '../../../components/tracking-app/driver/DeliveryStatusBadge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ReactComponent as NavigateBackIcon } from '../../../assets/tracking-app/navigate-back-icon.svg';
import CreateIcon from "@mui/icons-material/Create";
import { useCustomer } from '../../../components/login/user';
import { Navigate } from 'react-router-dom';

const AllDeliveryDriversCalendar = () => {
    const [plannedConsignments, setPlannedConsignments] = useState<Record<number, Record<number, Record<number, InHouseConsignment[]>>>>();
    const navigate = useNavigate();
    const location = useLocation();
    const { permissions } = useCustomer();
    const { id } = useParams<{ id: string }>();
    const queryParams = new URLSearchParams(location.search);
    const calendardate = queryParams.get("calendardate");
    var userDeliveryDriverId = localStorage.getItem("deliveryDriverId")
    const [calendarDate, setCalendarDate] = useState<Date>(
        calendardate ? ConvertDate(calendardate) : new Date()
    );
    const handleNavigate = (id: number) => {
        navigate("/carrier-driver/delivery-details/" + id)
    }
    const handleGetCalendarConsignments = () => {
        var date = calendarDate.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' })
        GetInHouseConsignmentsCalendar(date).then((res: any) => {
            setPlannedConsignments(res.data)
        }).catch((err: any) => {
            setPlannedConsignments([])
        })
    }

    const handleBackButton = () => {
        navigate(-1);
    }
    const handleStatusChange = (
        consignmentId: number,
        status: ConsignmentStatus,
        deliveryDriverId: number
    ) => {
        if (deliveryDriverId !== +userDeliveryDriverId!) {
            alert("Ei klapi")
            return;
        }

        if (status === ConsignmentStatus.InTransit && id !== undefined) {
            StartDelivery(consignmentId, +id).then((response: any) => {
                navigate("/carrier-driver/delivery-details/" + consignmentId);
            });
        }
        UpdateInHouseConsignmentStatus(consignmentId, status)
            .then((response: any) => {
                handleGetCalendarConsignments()
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        handleGetCalendarConsignments()
    }, [calendarDate])

    if (!permissions.includes("PInHouseDriver")) {
        return <Navigate to="/unauthorized" />
    }
    return (
        <div >
            <header className="delivery-driver--header" style={{ height: "130px" }}>
                <nav className="flex justify-between">
                    <div
                        className="flex flex-row justify-between items-center"
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                            padding: "1rem",
                            paddingLeft: "2rem",
                            paddingRight: "2rem",
                        }}>
                        <div className="flex flex-col items-start justify-start" style={{ width: "25%" }}>

                            <div className="flex flex-col justify-center items-center" style={{ marginLeft: "2%" }}>
                                <IconButton onClick={handleBackButton}>
                                    <NavigateBackIcon />
                                </IconButton>
                                <label className="header--button-label">
                                    Tagasi
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center justify-center header--title" style={{ width: "50%" }}>Tänased veod</div>
                        <div style={{ width: "25%" }}></div>
                    </div>
                </nav>
            </header>
            <div style={{ overflowX: "auto" }}>
                {(plannedConsignments !== null && plannedConsignments !== undefined) && Object.entries(plannedConsignments!).map(([weekNumber, weekConsignments]) => (
                    <React.Fragment key={weekNumber}>

                        {Object.entries(weekConsignments).map(([weekDay, consignmentGroups]) => (
                            <React.Fragment key={weekDay}>
                                {Object.values(consignmentGroups).map((car, idx) =>
                                (
                                    <React.Fragment key={idx}>
                                        <div className='flex items-start' style={{ background: "#e10008", color: "#fff", marginBottom: "-45px", padding: "10px", paddingLeft: "5px", height: "120px" }}>
                                            <div style={{ marginLeft: "25px", marginTop: "5px", fontSize: "32px", fontFamily: "Helvetica-Bd" }}>
                                                {car[0].deliveryDriver.carName} - {car[0].deliveryDriver.carLicenseNumber}
                                            </div>
                                        </div>
                                        <React.Fragment key={idx}>
                                            {Object.values(car).map((calenderObj, idx) =>
                                            (

                                                <div key={idx} className='flex items-center justify-center'>
                                                    <DeliveryOrderNew
                                                        deliveryData={calenderObj}
                                                        handleNavigate={handleNavigate}
                                                    />
                                                    <div className="flex flex-col items-start gap-xs" style={{ padding: "1rem", paddingLeft: "0", paddingRight: "0", background: "#cbcbcb", width: "150px", marginTop: '-5rem', height: "150px" }}>
                                                        <HandleGetInHouseConsignmentStatus statusNumber={calenderObj.consignmentStatus} />
                                                        {calenderObj.consignmentStatus !== ConsignmentStatus.WaitingClient && calenderObj.consignmentStatus !== ConsignmentStatus.InTransit && calenderObj.consignmentStatus !== ConsignmentStatus.Delivered && (
                                                            <div className="flex flex-col items-center m-auto" >
                                                                <div className="flex flex-col gap-xs justify-center" >

                                                                    <IconButton disabled={calenderObj.deliveryDriverId !== +userDeliveryDriverId!} onClick={() => { handleStatusChange(calenderObj.id, ConsignmentStatus.InTransit, calenderObj.deliveryDriverId) }} style={{ background: "#fff", borderRadius: "10px" }}>
                                                                        <PlayArrowOutlinedIcon
                                                                            fontSize="large"
                                                                            htmlColor="gray"
                                                                        />
                                                                    </IconButton>
                                                                    <label className="delivery-calendar-view--menu-button-text">Alusta</label>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {(calenderObj.consignmentStatus === ConsignmentStatus.InTransit) && (
                                                            <div className="flex flex-col items-center m-auto">
                                                                <div className="flex flex-col gap-xs justify-center">
                                                                    <IconButton disabled={calenderObj.deliveryDriverId !== +userDeliveryDriverId!} style={{ background: "#fff", borderRadius: "10px" }} onClick={() => {
                                                                        handleStatusChange(calenderObj.id, ConsignmentStatus.WaitingClient, calenderObj.deliveryDriverId)
                                                                        navigate(`/carrier-driver/delivery-details/${calenderObj.id}`)
                                                                    }}>
                                                                        <LocationOnIcon fontSize="large" htmlColor="gray" />
                                                                    </IconButton>
                                                                    <label className="delivery-calendar-view--menu-button-text">Olen kohal</label>
                                                                </div>

                                                            </div>
                                                        )}

                                                        {(calenderObj.consignmentStatus === ConsignmentStatus.WaitingClient) && (
                                                            <div className="flex flex-col items-center m-auto">
                                                                <div className="flex flex-col gap-xs justify-center">
                                                                    <IconButton disabled={calenderObj.deliveryDriverId !== +userDeliveryDriverId!} style={{ background: "#fff", borderRadius: "10px" }} onClick={() => {
                                                                        navigate(`/carrier-driver/end-delivery/${calenderObj.id}`)
                                                                    }}>
                                                                        <CreateIcon fontSize="large" htmlColor="gray" />
                                                                    </IconButton>
                                                                    <label className="delivery-calendar-view--menu-button-text">Anna üle</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                            )}
                                        </React.Fragment>
                                    </React.Fragment>
                                )
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}

            </div>
        </div>
    )
}

export default AllDeliveryDriversCalendar