import { AxiosResponse } from 'axios';
import { useState } from 'react'
import { Button, IconButton } from '@mui/material'
import { useDataContext } from '../../context/DataContext';
import { Consignment } from '../../Types/Consignment';
import EastIcon from '@mui/icons-material/East';
import { CreateConsignmentRequest, GetConsignmentByDirectoCode } from '../../queries/queries';
import { AddConsignmentToTransportRoute } from '../../queries/transport-route-queries';

const CreateOrLoadConsignmentTransportRoute = (props: { listIndex: number }) => {
    const [createButtonState, setCreateButtonState] = useState<boolean>(false);
    const [loadButtonState, setLoadButtonState] = useState<boolean>(false);
    const [id, setId] = useState<string>("");
    const { setIsLoading, setIsSearchOpen, consignmentList, setConsignmentList, setConsignmentId, transportRouteId } = useDataContext();

    const handleCreateConsignmentClick = () => {
        setLoadButtonState(false)
        setCreateButtonState(true)
    }

    const handleLoadConsignmentClick = () => {
        setLoadButtonState(true)
        setCreateButtonState(false)
    }

    const handleCreateConsignment = (event: any, consignmentType: string) => {
        event.preventDefault();
        var userName = localStorage.getItem("user")
        var userEmail = localStorage.getItem("email")
        if (userName === null || userEmail === null) return
        var consignmentOriginCountry = "ET"
        CreateConsignmentRequest(consignmentType, userName, userEmail, consignmentOriginCountry).then((response: AxiosResponse<Consignment>) => {
            setConsignmentId(response.data.id.toString())
            if (consignmentList.length > 0) {
                var arr = consignmentList.map((item, idx) => idx === props.listIndex ? response.data : item);
                setConsignmentList(arr)
            }
            // if consignment list is empty array, add new consignment as a first member of the list

            else {
                var tempArr = consignmentList;
                tempArr.push(response.data)
                setConsignmentList(tempArr)
            }

            setIsSearchOpen(true)
            setCreateButtonState(false)
            if (transportRouteId) {
                AddConsignmentToTransportRoute(+response.data.id, +transportRouteId).then((response2) => { })
            }
        }).finally(() => { });
    }


    const handleLoadConsignment = (event: any) => {
        event.preventDefault();
        setCreateButtonState(false)
        setLoadButtonState(true)
        if (id === undefined) return
        setIsLoading(true)
        GetConsignmentByDirectoCode(id).then((response: AxiosResponse<Consignment>) => {
            setId(response.data.id)
            setConsignmentId(response.data.id.toString())
            var arr = consignmentList.map((item, idx) => idx === props.listIndex ? response.data : item);
            setConsignmentList(arr)
            setIsSearchOpen(true)

            if (transportRouteId) {
                AddConsignmentToTransportRoute(+response.data.id, +transportRouteId).then(() => { })
            }

        }).catch(() => { alert("No such consignment exists") }).finally(() => { setIsLoading(false) });
    }


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', gap: "50px", marginTop: "1%" }}>
                <Button variant="contained" style={{ height: "50px", backgroundColor: "gray", width: "150px" }} onClick={handleCreateConsignmentClick}>Koosta uus veotellimus</Button>
                <Button variant="contained" style={{ height: "50px", backgroundColor: "gray", width: "150px" }} onClick={handleLoadConsignmentClick}>Otsi veotellimust</Button>
            </div>
            {loadButtonState &&
                <form onSubmit={handleLoadConsignment} style={{ flexDirection: "column", justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
                    <h2 className="page__heading">Sisesta Directo sündmuse kood</h2>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <input className="input-addressForm" style={{ marginTop: "1%" }} type="number" onChange={e => setId(e.target.value)}></input>
                        <IconButton>
                            <EastIcon style={{ color: "gray" }} onClick={handleLoadConsignment} />
                        </IconButton>
                    </div>

                </form>
            }
            {createButtonState &&
                <div className="header-container" style={{ marginTop: "1%" }}>
                    <h2 className="page__heading">Vali tüüp</h2>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: "50px" }}>
                        <Button variant="contained" style={{ height: "50px", backgroundColor: "gray" }} onClick={(e: any) => handleCreateConsignment(e, "Export")}>Export</Button>
                        <Button variant="contained" style={{ height: "50px", backgroundColor: "gray" }} onClick={(e: any) => handleCreateConsignment(e, "Import")}>Import</Button>
                    </div>
                </div>
            }
        </>
    )
}

export default CreateOrLoadConsignmentTransportRoute