import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Paginator from '../../components/common/Paginator';
import { useCustomer } from '../../components/login/user';
import CreateOrLoadTransportRoute from '../../components/transport-route/CreateOrLoadTransportRoute'
import ShowAllTransportRoutes from '../../components/transport-route/ShowAllTransportRoutes';
import { useDataContext } from '../../context/DataContext';
import { SearchAndSortTransportRoutes } from '../../queries/transport-route-queries';
import '../../styles/transport-route.css'

const TransportRoutePage = () => {
    const { transportRouteId, setTransportRouteList } = useDataContext();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsCount, setItemsCount] = useState<number>(1);
    const originCountry = "ET"
    const itemsPerPage = 30;
    const [archiveView, setArchiveView] = useState<boolean>(false);
    const [isTransportRouteDeleted, setIsTransportRouteDeleted] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState("collection_date"); // Default sorting field
    const [isDescending, setIsDescending] = useState(true); // Default sorting order
    const { logged, permissions } = useCustomer();

    const fetchTransportRoutes = () => {
        SearchAndSortTransportRoutes(searchTerm, currentPage, itemsPerPage, archiveView, sortBy, isDescending, originCountry).then((response: any) => {
            setTransportRouteList(response.data.item1)
            setItemsCount(response.data.item2)
        })
    }
    const handleSortingChange = (field: string, setSortingState: React.Dispatch<React.SetStateAction<boolean>>, sortingState: boolean) => {
        setSortingState(!sortingState)
        setSortBy(field);
        setIsDescending(!isDescending);
        SearchAndSortTransportRoutes(searchTerm, currentPage, itemsPerPage, archiveView, field, !sortingState, originCountry).then((response: any) => {
            setTransportRouteList(response.data.item1)
            setItemsCount(response.data.item2)
        })
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    }

    const handleTransportRouteDeleted = () => {
        setIsTransportRouteDeleted(true);
    }

    useEffect(() => {
        fetchTransportRoutes();
        setIsTransportRouteDeleted(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, archiveView, isTransportRouteDeleted, searchTerm]);


    if (!logged) {
        return <Navigate to="/login" />
    }
    if (!permissions.includes("PEstonianUser")) {
        return <Navigate to="/unauthorized" />
    }

    return (
        <div className="page__container-outer-wrapper">
            <div className="page__container-wrapper--flexbox">
                <div className="page__center-column-flexbox all-consignments--container" >
                    <div className='page__heading' style={{ fontSize: "40px", marginBottom: "1%" }}>Veoringid</div>
                    <CreateOrLoadTransportRoute setSearchTerm={setSearchTerm} handleSearchTransportRoutes={fetchTransportRoutes} />

                    {!transportRouteId &&
                        <ShowAllTransportRoutes archiveView={archiveView} setArchiveView={setArchiveView} onDelete={handleTransportRouteDeleted} handleSortingChange={handleSortingChange} />}
                    <div style={{ marginTop: "2%" }}>
                        <Paginator currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={itemsCount} onChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TransportRoutePage