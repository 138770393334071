/* eslint-disable import/no-anonymous-default-export */
import Layout from './layout/Layout';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { DataContextWrapper } from './context/DataContext';
import TransportRoutePage from './pages/transport-route/TransportRoutePage';
import { CustomerWrapper, useCustomer } from './components/login/user';
import Login from './components/login/Login';
import LoginRedirect from './components/login/LoginRedirect';
import CustomerServicePage from './pages/customer-service/CustomerServicePage';
import ConsignmentHomePage from './pages/consignment/ConsignmentHomePage';
import TuusulaTransportDetailsView from './pages/finland/TuusulaTransportDetailsView';
import TuusulaTransportOrders from './pages/finland/TuusulaTransportOrders';
import ConsignmentDetails from './pages/consignment/ConsignmentDetails';
import TransportRouteDetails from './pages/transport-route/TransportRouteDetails';
import Admin from './pages/admin/Admin';
import SwedenTransportOrders from './pages/sweden/SwedenTransportOrders';
import SwedenTvView from './pages/sweden/SwedenTvView';
import SwedenTransportOrderDetails from './pages/sweden/SwedenTransportOrderDetails';
import TuusulaTvView from './pages/finland/TuusulaTvView';
import '../src/styles/layout.css';
import DeliveryDetails from './pages/tracking-app/logistician/DeliveryDetails';
import { useEffect } from 'react';
import Unauthorized from './pages/admin/Unauthorized';
import DeliveryPageCalendar from './pages/tracking-app/logistician/DeliveryPageCalendar';
import ClientPage from './pages/tracking-app/client/ClientPage';
import LoaderCalendarView from './pages/tracking-app/loader/LoaderCalendarView';
import DriverDeliveryDetailsView from './pages/tracking-app/driver/DriverDeliveryDetailsView';
import HistoryView from './pages/tracking-app/driver/HistoryView';
import AllDeliveryDriversCalendar from './pages/tracking-app/driver/AllDeliveryDriversCalendar';
import CancelDelivery from './pages/tracking-app/driver/CancelDeliveryPage';
import DriverTransportRoute from './pages/tracking-app/driver/DriverTransportRoute';
import DriverCalendarView from './pages/tracking-app/driver/DriverCalendarView';
import EndDelivery from './pages/tracking-app/driver/EndDelivery';
import AllDriversPage from './pages/tracking-app/driver/AllDriversPage';
import DeliveryDetailsLoader from './pages/tracking-app/loader/DeliveryDetailsLoader';

export default function () {
  return (
    <CustomerWrapper>
      <DataContextWrapper>
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/login/:guid" element={<LoginRedirect />} />
          </Routes>
          <CustomerRoutes />
        </Layout>
      </DataContextWrapper>
    </CustomerWrapper>
  );
}

const CustomerRoutes = () => {
  const { logged, isAdmin } = useCustomer();
  const navigate = useNavigate();
  const { permissions } = useCustomer();
  const driverId = localStorage.getItem("deliveryDriverId");

  const navigateUser = () => {
    const isTransportCompanyUser = permissions.find((permissions) => permissions === 'PTransportCompanyUser') !== undefined;
    const isAdmin = permissions.find((permissions) => permissions === 'PManageUsers') !== undefined;
    const isEstonianUser = permissions.find((permissions) => permissions === 'PEstonianUser') !== undefined;
    const isFinnishUser = permissions.find((permissions) => permissions === 'PFinnishUser') !== undefined;
    const isFinnishTv = permissions.find((permissions) => permissions === 'PFinnishTvViewer') !== undefined;
    const isSwedishUser = permissions.find((permissions) => permissions === 'PSwedishUser') !== undefined;
    const isInHouserDriver = permissions.find((permissions) => permissions === 'PInHouseDriver') !== undefined;
    const isInHouserDriverManager = permissions.find((permissions) => permissions === 'PInHouseDriverManager') !== undefined;
    const isEstWarehouseWorker = permissions.find((permissions) => permissions === 'PEstonianWareHouseWorker') !== undefined;
    if (window.location.pathname === '/') {
      if (isTransportCompanyUser && !isAdmin) {
        navigate('/tv');
        return;
      }
      if (isFinnishUser && !isAdmin) {
        navigate('/overview');
        return;
      }

      if (isSwedishUser && !isAdmin) {
        navigate('/sweden/transport-orders');
        return;
      }

      if (isEstonianUser) {
        navigate('/');
        return;
      }
      if (isFinnishTv) {
        navigate('/Tv');
        return;
      }
      if (isInHouserDriverManager) {
        navigate(`/delivery`)
        return;
      }
      if (isInHouserDriver) {
        navigate(`/carrier-driver/${driverId}`)
        return;
      }
      if (isEstWarehouseWorker) {
        navigate(`/loader`)
        return;
      }
      navigate('/');
      return;
    }

  };
  useEffect(() => {
    navigateUser()
  }, []);
  return (
    <Routes>
      <Route path={'/'} element={logged ? <ConsignmentHomePage /> : <Navigate to="/login" />} />
      <Route path={'/consignmentDetails/:consignmentId'} element={logged ? <ConsignmentDetails /> : <Navigate to="/login" />} />
      <Route path={'/consignmentDetails/directo/:directoCode'} element={logged ? <ConsignmentDetails /> : <Navigate to="/login" />} />
      <Route path="/transport-route" element={logged ? <TransportRoutePage /> : <Navigate to="/login" />} />
      <Route path={'/transport-route/details/:id'} element={logged ? <TransportRouteDetails /> : <Navigate to="/login" />} />
      <Route path={'/transport-route/details/directo/:directoCode'} element={logged ? <TransportRouteDetails /> : <Navigate to="/login" />} />
      <Route path="/customer-service" element={logged ? <CustomerServicePage /> : <Navigate to="/login" />} />
      <Route path="/overview" element={logged ? <TuusulaTransportOrders /> : <Navigate to="/login" />} />
      <Route path="/tuusula" element={logged ? <TuusulaTransportDetailsView /> : <Navigate to="/login" />} />
      <Route path="/tuusula/:id" element={logged ? <TuusulaTransportDetailsView /> : <Navigate to="/login" />} />
      <Route
        path="/tv"
        element={
          logged ? (
            <div className="tv-overview--container">
              <TuusulaTvView />
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/admin" element={logged && isAdmin() === true ? <Admin /> : <Navigate to="/login" />} />
      <Route path="/sweden/transport-orders" element={logged ? <SwedenTransportOrders /> : <Navigate to="/login" />} />
      <Route
        path="/sweden/tv"
        element={
          logged ? (
            <div
              style={{
                background: 'gray',
                height: '100vh',
                overflow: 'hidden'
              }}
            >
              <SwedenTvView />
            </div>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/sweden/transport-orders/:id" element={logged ? <SwedenTransportOrderDetails /> : <Navigate to="/login" />} />
      <Route path="/carrier-driver/delivery-details/:id" element={logged ? <DriverDeliveryDetailsView /> : <Navigate to="/login" />} />
      <Route path="/carrier-driver/:id/history" element={logged ? <HistoryView /> : <Navigate to="/login" />} />
      {/*  <Route path="/carrier-driver" element={logged ? <AllDriversPage /> : <Navigate to="/login" />} /> */}
      <Route path="/drivers/all" element={logged ? <AllDriversPage /> : <Navigate to="/login" />} />
      <Route path="/carrier-driver/:id" element={logged ? <DriverCalendarView /> : <Navigate to="/login" />} />
      <Route path="/carrier-driver/:id/transport-route" element={logged ? <DriverTransportRoute /> : <Navigate to="/login" />} />
      <Route path="/carrier-driver/end-delivery/:deliveryId" element={logged ? <EndDelivery /> : <Navigate to="/login" />} />
      <Route path="/carrier-driver/cancel/:deliveryId" element={logged ? <CancelDelivery /> : <Navigate to="/login" />} />
      <Route path="/carrier-driver/all-drivers" element={logged ? <AllDeliveryDriversCalendar /> : <Navigate to="/login" />} />

      <Route path="/delivery-details/directo/:directoCode" element={logged ? <DeliveryDetails /> : <Navigate to="/login" />} />
      <Route path="/delivery-details/:id" element={logged ? <DeliveryDetails /> : <Navigate to="/login" />} />
      <Route path="/delivery" element={logged ? <DeliveryPageCalendar /> : <Navigate to="/login" />} />
      <Route path="/loader" element={logged ? <LoaderCalendarView /> : <Navigate to="/login" />} />
      <Route path="/loader/delivery-details/:id" element={logged ? <DeliveryDetailsLoader /> : <Navigate to="/login" />} />
      <Route path="/client/:id" element={<ClientPage />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
    </Routes>
  );
};
