import { CargosonPriceList } from '../../Types/cargoson/CargosonPriceList'
import '../../styles/Consignment.css'
import '../../styles/pricelist.css'
import { useDataContext } from '../../context/DataContext'
import { AxiosResponse } from 'axios'
import { GetSupplierByRegNr } from '../../queries/queries'
import { Supplier } from '../../Types/Supplier'

type Props = {
  priceListData: CargosonPriceList[]
  listIndex: number
}

const CargosonPriceListComponent = ({ priceListData, listIndex }: Props) => {
  const { consignmentList, updateSupplierInfo, setConsignmentList } = useDataContext();
  const cargosonData = consignmentList[listIndex].cargosonData;


  const handleChooseCarrier = (carrierRegNo: string, carrierName: string, carrierServiceId: string, totalCost: string, currency: string) => {
    if (window.confirm(`Are you sure you want to choose ${carrierName}?`)) {
      GetSupplierByRegNr(carrierRegNo).then((response: AxiosResponse<Supplier>) => {
        updateSupplierInfo(response.data)

        setConsignmentList(consignmentList.map((item, idx) => idx === listIndex ? {
          ...item,
          "deliveryPrice": totalCost,
          "currency": currency,
          cargosonData: { ...item.cargosonData, "serviceId": carrierServiceId }
        } : item))

      })

      alert("Carrier is chosen")
    }
  }

  return (
    <>
      {/*       {priceListData.errors !== undefined ?
        <CargosonPriceListError priceListData={priceListData} /> : ""
      }
 */}

      {priceListData.map((priceData, index) =>
        <div key={index}>
          {priceData.object &&
            <>
              <div style={{ marginTop: "1%" }}>
                <>
                  {cargosonData.delivery_address_row_1 !== null && cargosonData.pickups[index].collection_address_row_1 !== null ?
                    `${cargosonData.pickups[index].collection_address_row_1} -> ${cargosonData.delivery_address_row_1}`
                    : ""}
                </>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: "25px" }}>
                {Object.values(priceData.object.prices).map((prices, index) =>
                  <table key={index} className="carrier-pricelist-table" style={{ border: "1px solid gray", width: "700px", borderRadius: "5px" }} onClick={() => handleChooseCarrier(prices.reg_no, prices.carrier, prices.service_id, prices.price, prices.currency)}>
                    <thead className="deliveriesTable-header">
                      <tr className="deliveriesTable-head-tr" style={{ width: "100%" }}>
                        <td colSpan={2} className="deliveriesTable-head-td" style={{ width: "100%", padding: "10px" }}>{prices.carrier}</td>
                      </tr>
                    </thead>
                    <tbody className="deliveriesTable-delivery-row">
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Reg Nr</td>
                        <td style={{ padding: "10px" }}>{prices.reg_no}</td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Service</td>
                        <td style={{ padding: "10px" }}>{prices.service}</td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Service Id</td>
                        <td style={{ padding: "10px" }}>{prices.service_id}</td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Total price</td>
                        <td style={{ padding: "10px" }}>{prices.price} {prices.currency}</td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Surcharges</td>
                        <td style={{ padding: "10px" }}>
                          <div>
                            <table>
                              <tbody>
                                {(prices.surcharges).map((value, idx) =>
                                  <tr key={idx}>
                                    <td style={{ textAlign: "left" }}>{value.identifier}</td>
                                    <td style={{ textAlign: "right", width: "100px" }} >{value.amount} {prices.currency}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Unit</td>
                        <td style={{ padding: "10px" }}>{prices.unit}</td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Type</td>
                        <td style={{ padding: "10px" }}>{prices.type}</td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Service type</td>
                        <td style={{ padding: "10px" }}>{prices.service_type}</td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Estimated collection date</td>
                        <td style={{ padding: "10px" }}>{prices.estimated_collection_date}</td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Estimated delivery date</td>
                        <td style={{ padding: "10px" }}>{prices.estimated_delivery_date}</td>
                      </tr>
                      <tr className="deliveriesTable-header-content-tr" style={{ borderBottom: "1px solid gray", color: "gray" }}>
                        <td style={{ padding: "10px" }}>Transit time</td>
                        <td style={{ padding: "10px" }}>{prices.transit_time}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </>
          }
        </div>
      )}
    </>
  )
}

export default CargosonPriceListComponent


