import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import TruckIcon from '../../assets/tracking-app/map-icon-truck-red.svg';
import PinIcon from '../../assets/tracking-app/map-icon-pin-red.svg';
type Props = {
  deliveryDriverLocation: GeolocationPosition | null;
  setDeliveryDriverLocation: React.Dispatch<React.SetStateAction<GeolocationPosition | null>>;
  driverId: number;
  deliveryLocation: GeolocationPosition | null;
  setDeliveryLocation: React.Dispatch<React.SetStateAction<GeolocationPosition | null>>;
  fetchDeliveryDriverLocation: (driverId: number) => Promise<void>;
  width?: number;
  height?: number;
};
const TrackingDeliveryDriver = ({ deliveryDriverLocation, deliveryLocation, setDeliveryLocation, fetchDeliveryDriverLocation, driverId, width = 500, height = 400 }: Props) => {
  console.log(deliveryLocation)
  useEffect(() => {
    const fetchDeliveryLocation = async () => {
      if (deliveryLocation?.coords.latitude === undefined || deliveryLocation?.coords.longitude === undefined) return;
      setDeliveryLocation({
        coords: {
          latitude: deliveryLocation?.coords.latitude,
          longitude: deliveryLocation?.coords.longitude,
          altitude: null,
          accuracy: 0,
          altitudeAccuracy: null,
          heading: null,
          speed: null
        },
        timestamp: Date.now()
      });
    };

    fetchDeliveryDriverLocation(driverId);
    fetchDeliveryLocation();
    getCenterAndZoom();
    const interval = setInterval(fetchDeliveryDriverLocation, 1 * 60 * 1000); //5 minutes
    return () => {
      clearInterval(interval);
    };
  }, []);

  const mapContainerStyle = {
    height: height + 'px',
    width: width + 'px',
    marginTop: '-220px'
  };

  const calculateDistance = (loc1: GeolocationCoordinates, loc2: GeolocationCoordinates) => {
    const rad = (x: number) => (x * Math.PI) / 180;
    const R = 6378137; // Earth’s mean radius in meter
    const dLat = rad(loc2.latitude - loc1.latitude);
    const dLong = rad(loc2.longitude - loc1.longitude);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(loc1.latitude)) * Math.cos(rad(loc2.latitude)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance; // returns the distance in meter
  };

  const getZoomLevel = (distance: number) => {
    if (distance < 500) return 15;
    if (distance < 1700) return 14;
    if (distance < 3600) return 13;
    if (distance < 7000) return 12;
    if (distance < 14000) return 11;
    if (distance < 16000) return 10;
    if (distance < 50000) return 9;
    if (distance < 100000) return 10;
    return 9; // default zoom for larger distances
  };

  const getCenterAndZoom = () => {
    if (deliveryDriverLocation && deliveryLocation) {
      const distance = calculateDistance(deliveryDriverLocation.coords, deliveryLocation.coords);
      const center = {
        lat: (deliveryDriverLocation.coords.latitude + deliveryLocation.coords.latitude) / 2,
        lng: (deliveryDriverLocation.coords.longitude + deliveryLocation.coords.longitude) / 2
      };
      const zoom = getZoomLevel(distance);
      return { center, zoom };
    } else if (deliveryDriverLocation) {
      return {
        center: {
          lat: deliveryDriverLocation.coords.latitude,
          lng: deliveryDriverLocation.coords.longitude
        },
        zoom: 15 // Closer view if only one marker is available
      };
    } else if (deliveryLocation) {
      return {
        center: {
          lat: deliveryLocation.coords.latitude,
          lng: deliveryLocation.coords.longitude
        },
        zoom: 15 // Closer view if only one marker is available
      };
    }
    // Default values if no locations
    return { center: { lat: 0, lng: 0 }, zoom: 2 }; // Show a wide view of the Earth
  };
  const { center, zoom } = getCenterAndZoom();
  return (
    <div
      style={
        {
          /*  padding: '1%', width: '97%' */
        }
      }
    >
      <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={zoom}>
        {deliveryDriverLocation && (
          <Marker
            options={{
              icon: TruckIcon
            }}
            position={{ lat: deliveryDriverLocation.coords.latitude, lng: deliveryDriverLocation.coords.longitude }}
          />
        )}
        {deliveryLocation && (
          <Marker
            options={{
              icon: PinIcon
              /* icon: {
                url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
              } */
            }}
            position={{ lat: deliveryLocation.coords.latitude, lng: deliveryLocation.coords.longitude }}
          />
        )}
      </GoogleMap>
      {/* {deliveryDriverLocation && (
        <div>
          <p>
            Viimane asukoht: {address} kell {new Date(deliveryDriverLocation.timestamp).toLocaleTimeString()}
          </p>
          <p>
            Test timestamp: {address} kell {deliveryDriverLocation.timestamp}
          </p>
          <p>
            Auto koordinaadid: {deliveryDriverLocation?.coords.latitude} {deliveryDriverLocation?.coords.longitude}
          </p>
        </div>
      )} */}
    </div>
  );
};

export default TrackingDeliveryDriver;
