/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { Container } from "reactstrap";
import '../styles/transport-route.css'
import Navbar from "./Navbar";
import { Grid, Paper } from "@mui/material";

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment >
        <Container >
            <Navbar />
            <Container >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Paper >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="layout-children--container" style={{ paddingTop: "75px" }}>{props.children}</div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    </React.Fragment>
);