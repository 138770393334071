export const handleGetInHouseConsignmentStatus = (statusNumber: any) => {
  if (statusNumber === 0) {
    return 'Tühistatud';
  }
  if (statusNumber === 1) {
    return 'Planeeritud';
  }
  if (statusNumber === 2) {
    return 'Sõidus';
  }
  if (statusNumber === 3) {
    return 'Ootab klienti';
  }
  if (statusNumber === 4) {
    return 'Kohale toimetatud';
  }
  if (statusNumber === 5) {
    return 'Peale laetud';
  }
  if (statusNumber === 6) {
    return 'Allkirjastamata';
  }
  if (statusNumber === 7) {
    return 'Lõpetatud';
  }
  if (statusNumber === 8) {
    return 'Komplekteeritud';
  }
  if (statusNumber === 9) {
    return 'Komplekteerimata';
  }

  return;
};

export const ReturnDayName = (dayNumber: string) => {
  if (dayNumber === '0') {
    return 'Pühapäev';
  }
  if (dayNumber === '1') {
    return 'Esmaspäev';
  }
  if (dayNumber === '2') {
    return 'Teisipäev';
  }
  if (dayNumber === '3') {
    return 'Kolmapäev';
  }
  if (dayNumber === '4') {
    return 'Neljapäev';
  }
  if (dayNumber === '5') {
    return 'Reede';
  }
  if (dayNumber === '6') {
    return 'Laupäev';
  }
};
