import { IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConvertDate } from '../../../components/common/ConvertDate';
import { CreateInHouseConsignment, GetAllDriverLocations, GetDeliveryCoordinates, GetInHouseConsignmentsCalendar, GetUnPlannedConsignmentsCalendar, SearchInHouseConsignments } from '../../../queries/deliveryDriverQueries';
import { DeliveryCoordinate } from '../../../Types/delivery-app/DeliveryCoordinate';
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import { handleGetInHouseConsignmentStatus } from '../../../components/tracking-app/Common';
import { clientUrl } from '../../../config/apiconfig';
import EastIcon from '@mui/icons-material/East';
import debounce from 'lodash/debounce';
import Paginator from '../../../components/common/Paginator';
import SearchIcon from '@mui/icons-material/Search';
import CarAssignedDeliveries from '../../../components/tracking-app/logistician/CarAssignedDeliveries';
import UnAssignedDeliveries from '../../../components/tracking-app/logistician/UnAssignedDeliveries';
import useWindowDimensions from '../../../components/common/useWindowsDimensions';
import WeekCalendar from '../../../components/tracking-app/logistician/WeekCalendar';
import { ReactComponent as AddIcon } from '../../../assets/tracking-app/buttons/add_icon_white.svg';
import DriverLocationsMap from '../../../components/tracking-app/DriverLocationsMap';
import { DriverLocations } from '../../../Types/delivery-app/DriverLocations';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../../components/login/user';
import '../../../styles/tracking-app/delivery-page-calendar.css'
import '../../../styles/tracking-app/delivery-page.css';

const DeliveryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { permissions } = useCustomer();
  const [plannedConsignments, setPlannedConsignments] = useState<Record<number, Record<number, Record<number, InHouseConsignment[]>>>>();
  const [unPlannedConsignments, setUnPlannedConsignments] = useState<Record<number, Record<number, InHouseConsignment[]>>>();
  const queryParams = new URLSearchParams(location.search);
  const calendardate = queryParams.get('calendardate');
  const [calendarDate, setCalendarDate] = useState<Date>(calendardate ? ConvertDate(calendardate) : new Date());
  const [consignments, setConsignments] = useState<InHouseConsignment[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsCount, setItemsCount] = useState<number>(1);
  const itemsPerPage = 30;
  const [sortBy, setSortBy] = useState('');
  const [isDescending, setIsDescending] = useState(true);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)
  const { width } = useWindowDimensions();
  const mapWidth = width / 2 - 25;
  const screenHeight = window.screen.height - 65;
  const [deliveryDriverLocations, setDeliveryDriverLocations] = useState<DriverLocations[]>([]);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [destionations, setDestinations] = useState<DeliveryCoordinate[]>([]);

  const handleCreateInHouseConsignment = () => {
    const formattedDate = calendarDate.toLocaleString('et-EE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    CreateInHouseConsignment(formattedDate).then((res: any) => {
      if (width <= 400) {
        navigate('/delivery-details/' + res.data.id);
        return;
      }
      handleNavigate(res.data.id);
    });
  };

  const handleNavigateToClientView = (e: any, id: string) => {
    e.stopPropagation();
    if (id !== '' && id !== null) {
      navigate('/client/' + id);
    }
  };

  const handleNavigate = (id: number) => {
    // Set the width and height for the new window
    const width = 1280;
    const height = 500;

    // Calculate the position to center the window on the screen
    const left = window.screenLeft + (window.outerWidth - width) / 2;
    const top = window.screenTop + (window.outerHeight - height) / 2;

    // Open a new window with the specified dimensions and position
    /*  const newWindow = window.open(`${clientUrl}/delivery-details/${id}`, '_blank', `width=${width},height=${800},left=${left},top=${top},noopener,noreferrer`); */
    const newWindow = window.open(`${clientUrl}/delivery-details/${id}`, '_blank', `width=${width},height=${800},left=${left},top=${top},noopener,noreferrer`);
    // Optional: Focus the new window if it's successfully opened
    if (newWindow) newWindow.focus();
  };

  const handleGetCalendarConsignments = () => {
    var date = calendarDate.toLocaleString('et-EE', { year: 'numeric', month: '2-digit', day: '2-digit' });
    GetInHouseConsignmentsCalendar(date)
      .then((res: any) => {
        setPlannedConsignments(res.data);
      })
      .catch((err: any) => {
        setPlannedConsignments([]);
      });
  };

  const handleGetUnPlannedConsignments = () => {
    var date = calendarDate.toLocaleString('et-EE', { year: 'numeric', month: '2-digit', day: '2-digit' });
    GetUnPlannedConsignmentsCalendar(date)
      .then((res: any) => {
        setUnPlannedConsignments(res.data);
      })
      .catch((err: any) => {
        setUnPlannedConsignments([]);
      });
  };

  const debouncedSetSearchTerm = debounce((e: any) => {
    setCurrentPage(1);
    setSearchTerm(e.target.value);
    handleSearchConsignment(e);
  }, 300);

  const handleSearchConsignment = (e: any) => {
    e.preventDefault();
    SearchInHouseConsignments(e.target.value, currentPage, itemsPerPage, sortBy, isDescending).then((response: any) => {
      setConsignments(response.data.item1);
      setItemsCount(response.data.item2);
      setPlannedConsignments([]);
      setUnPlannedConsignments([]);
    });
  };

  const handleSearchIconClick = () => {
    setIsSearchOpen(true);
    // Focus the input after the state update and element rendering
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleDateChange = (date: any) => {
    const formattedDate = date.toLocaleString('et-EE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    setConsignments([]);
    setSearchTerm('');
    navigate(`/delivery?calendardate=${formattedDate}`);
  };

  const loadConsignmentsByPage = () => {
    SearchInHouseConsignments(searchTerm, currentPage, itemsPerPage, sortBy, isDescending).then((response: any) => {
      setConsignments(response.data.item1);
      setItemsCount(response.data.item2);
    });
  };

  const fetchDeliveryDriverLocation = async () => {
    try {
      const formattedDate = calendarDate.toLocaleString('et-EE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      const driverLocations = await GetAllDriverLocations();
      const deliveryLocations = await GetDeliveryCoordinates(formattedDate);
      setDeliveryDriverLocations(driverLocations.data);
      setDestinations(deliveryLocations.data)
    } catch (error) {
      console.error('Failed to fetch driver locations:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsSearchOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (itemsCount === 1) return;
    loadConsignmentsByPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);


  useEffect(() => {
    const fetchData = () => {
      handleGetCalendarConsignments();
      handleGetUnPlannedConsignments();
      fetchDeliveryDriverLocation();
    };

    fetchData(); // Initial fetch when calendar date changes
    const interval = setInterval(fetchData, 1 * 60 * 1000); // Fetch every minute
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [calendarDate]);


  if (!permissions.includes("PInHouseDriverManager")) {
    return <Navigate to="/unauthorized" />
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="delivery-page-calendar--header-container">
        <div className='delivery-page-calendar--header' style={{ width: `${(consignments?.length > 0 || width <= 800) ? 100 : 50}% `, marginTop: "25px" }}>
          <div className='flex' style={{ gap: "20px", }}>
            <IconButton title="Loo uus vedu" onClick={handleCreateInHouseConsignment} style={{ marginBottom: "8px" }}>
              <AddIcon />
            </IconButton>

          </div>
          <WeekCalendar setCalendarDate={setCalendarDate} calendarDate={calendarDate} handleDateChange={handleDateChange} />
          {isSearchOpen ?
            <div ref={searchRef}>
              <input ref={inputRef} className="input-addressForm" style={{ height: '22px', width: 'unset', boxSizing: 'unset' }} placeholder="Otsi veotellimust" onChange={(e) => debouncedSetSearchTerm(e)}></input>
              <IconButton onClick={handleSearchConsignment}>
                <EastIcon style={{ color: '#fff' }} />
              </IconButton>
            </div>
            :
            <IconButton title="Otsi vedu" onClick={handleSearchIconClick} style={{ padding: "4px", marginBottom: "8px" }} >
              <SearchIcon htmlColor='#fff' fontSize='large' />
            </IconButton>
          }
        </div>
      </div>
      <div className='flex' >
        <div style={{ padding: '2%', paddingRight: "10px", paddingTop: '0', width: `${consignments?.length > 0 || width <= 800 ? 100 : 50}% ` }}>
          {consignments?.length > 0 ? (
            <div style={{ overflowX: "auto" }}>
              <div style={{ marginTop: '1%' }}>
                <Paginator currentPage={currentPage} totalItems={itemsCount} itemsPerPage={itemsPerPage} onChange={handlePageChange} />
              </div>
              <table className="consignment-table--table">
                <thead>
                  <tr className="consignment-table--tr">
                    <td className="consignment-table--thead">Ajavahemik</td>
                    <td className="consignment-table--thead">Tüüp</td>
                    <td className="consignment-table--thead">Sihtkoht</td>
                    <td className="consignment-table--thead">Klient</td>
                    <td className="consignment-table--thead">Mahalaadimine</td>
                    <td className="consignment-table--thead">Objekt</td>
                    <td className="consignment-table--thead">Müügitellimus</td>
                    <td className="consignment-table--thead">Staatus</td>
                    <td className="consignment-table--thead">Kliendi vaade</td>
                  </tr>
                </thead>

                <tbody>
                  {consignments !== null &&
                    consignments !== undefined &&
                    consignments.map((calenderObj, idx) => (
                      <React.Fragment key={idx}>
                        <tr className="consignment-table--tr" key={idx} onClick={() => handleNavigate(calenderObj.id)} style={{ cursor: 'pointer' }}>
                          <td className="consignment-table--td">
                            {calenderObj.deliveryTimeFrom} {calenderObj.deliveryTimeTo && `- ${calenderObj.deliveryTimeTo}`}
                          </td>
                          <td className="consignment-table--td">{calenderObj.type}</td>
                          <td className="consignment-table--td">{calenderObj.deliveryAddressRow1}</td>
                          <td className="consignment-table--td">
                            {calenderObj.deliveryCompanyName} {calenderObj.deliveryCompanyCustomerCode}
                          </td>
                          <td className="consignment-table--td">{calenderObj.deliveryDate}</td>
                          <td className="consignment-table--td">{calenderObj.deliveryObject}</td>
                          <td className="consignment-table--td">{calenderObj.directoOrderNumbers && calenderObj.directoOrderNumbers + ''}</td>
                          <td className="consignment-table--td">{handleGetInHouseConsignmentStatus(calenderObj.consignmentStatus)}</td>
                          <td className="consignment-table--td" style={{ cursor: 'pointer' }} onClick={(e: any) => handleNavigateToClientView(e, calenderObj.deliveryUrl)}>
                            {calenderObj.deliveryUrl ? 'Jah' : 'Ei'}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='delivery-page-calendar--deliveries-container' >
              <div className='delivery-page-calendar--deliveries-container-week' >
                {(plannedConsignments !== null && plannedConsignments !== undefined) &&
                  Object.entries(plannedConsignments!).map(([weekNumber, weekConsignments]) => (
                    <div key={weekNumber} style={{ marginTop: "-75px" }}>
                      {Object.entries(weekConsignments).map(([weekDay, consignmentGroups]) => (
                        <React.Fragment key={`${weekNumber}-${weekDay}`}>

                          {Object.values(consignmentGroups).map((car, idx) => (
                            <CarAssignedDeliveries key={idx} car={car} index={idx} handleRowClick={handleNavigate} />
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
              </div>
              <div className='delivery-page-calendar--deliveries-container-week'>
                {unPlannedConsignments !== null &&
                  unPlannedConsignments !== undefined &&
                  Object.entries(unPlannedConsignments!).map(([weekNumber, weekConsignments]) => (
                    <React.Fragment key={weekNumber}>
                      {Object.entries(weekConsignments).map(([weekDay, consignmentGroups]) => (
                        <React.Fragment key={weekDay}>
                          <UnAssignedDeliveries calenderObj={consignmentGroups} handleRowClick={handleNavigate} />
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          )}
          <div>
            {(plannedConsignments === undefined || plannedConsignments === null) && "Veod puudu"}
          </div>
        </div>

        {(consignments?.length === 0 && width > 800) &&
          <div className='w-50'>
            <DriverLocationsMap
              deliveryDriverLocations={deliveryDriverLocations}
              destinations={destionations}
              height={screenHeight} width={mapWidth} date={calendarDate} />
          </div>
        }
      </div>
    </div >
  );
};

export default DeliveryPage;
