import { SetStateAction, useState } from "react";
import { Package } from "../../../Types/cargoson/Package";
import { useDataContext } from "../../../context/DataContext";
import { AddPackageRowToConsignment } from "../../../queries/queries";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../common/CustomSelect";
import { useCustomer } from "../../login/user";

const AddPackage = (props: { pickupIdx: number, listIndex: number }) => {
    const { consignmentList, updatePackages } = useDataContext();
    const { t } = useTranslation();
    const { permissions } = useCustomer();
    const consignment = consignmentList[props.listIndex];
    const [description, setDescription] = useState<string>("");
    const [quantity, setQuantity] = useState<number>(0);
    const [type, setType] = useState<string>("");
    const [weight, setWeight] = useState<number>(0);
    const [length, setLength] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [volume, setVolume] = useState<number>(0);
    const [ldm, setLdm] = useState<number>(0);
    const [error, setError] = useState<boolean>(false);
    const packagesList = consignmentList[0].cargosonData.pickups[props.pickupIdx].rows_attributes;
    const [selectedDescriptionOption, setSelectedDescriptionOption] = useState<string>("custom");
    let totalLdm = 0;

    packagesList.forEach((value) => {
        totalLdm += value.ldm;
    });

    type predefinedLDM = {
        type: string;
        ldmValue: number;
    }

    const handleAddPackage = () => {
        /*  console.log(permissions)
         console.log(!permissions.includes("PEstonianUser") || permissions.includes("PFinnishUser"))
         if (!permissions.includes("PEstonianUser") || permissions.includes("PFinnishUser")) {
             alert("You don't have enough permissions")
             return;
         } */
        if (handleAddPackageValidation() === true) return;
        else {
            const packageToAdd = {
                description: description,
                quantity: quantity,
                package_type: type,
                weight: weight,
                length: length,
                width: width,
                height: height,
                volume: calculateVolume(length, width, height),
                ldm: ((isLdmCalculatable(type, ldm) === true) ? +((quantity) * (length / 100 * width / 100 / 2.4)).toFixed(3) : ldm)
            } as Package

            AddPackageRowToConsignment(consignment.cargosonData.pickups[props.pickupIdx].id, packageToAdd).then((response: AxiosResponse<Package>) => {
                updatePackages(props.pickupIdx, props.listIndex, response.data)
            })
            setQuantity(0);
            setType("");
            setWeight(0);
            setLength(0);
            setWidth(0);
            setHeight(0);
            setVolume(0);
            setLdm(0);
            setSelectedDescriptionOption("custom")
            setDescription("");
        }
    }

    const isLdmCalculatable = (type: string, ldmValue: number) => {
        const predefinedLDMPackages: predefinedLDM[] = [{ type: "EUR", ldmValue: 0.4 }, { type: "FIN", ldmValue: 0.5 }, { type: "LOAD", ldmValue: 13.6 }, { type: "HPL", ldmValue: 0.2 }, { type: "QPL", ldmValue: 0.1 }]
        var selectedPackage = predefinedLDMPackages.find((ldmType) => ldmType.type === type);
        //if user wants different ldm than is predefined returns false
        if (selectedPackage === undefined) return false;
        if (selectedPackage?.ldmValue === ldmValue) return true;
        return false;
    }

    const calculateVolume = (length: number, width: number, height: number): number => {
        if (quantity === 0 || length === 0 || width === 0 || height === 0) return 0;
        else return +((quantity) * (length) * (width) * (height) / 1000000).toFixed(3);
    }

    const handleTypeChange = (event: any) => {
        setType(event.target.value);
        if (event.target.value === "EUR") {
            setLength(120);
            setWidth(80);
            setLdm(0.4);
        }
        if (event.target.value === "FIN") {
            setLength(120);
            setWidth(100);
            setLdm(0.5);
        }
        if (event.target.value === "LOAD") {
            setLength(1360);
            setWidth(240);
            setLdm(13.6);
        }
        if (event.target.value === "HPL") {
            setLength(80);
            setWidth(60);
            setLdm(0.2);
        }
        if (event.target.value === "QPL") {
            setLength(60);
            setWidth(60);
            setLdm(0.1);
        }
    }

    const handleAddPackageValidation = (): boolean => {

        if (description === "") {
            setError(true);
            return true;
        }
        if (quantity === 0 || quantity === null || isNaN(quantity)) {
            setError(true);
            return true;
        }
        if (type === "") {
            setError(true);
            return true;
        }
        if (weight === 0 || weight === null) {
            setError(true);
            return true;
        }
        if (((isLdmCalculatable(type, ldm) === true) ? +((quantity) * (length / 100 * width / 100 / 2.4)).toFixed(3) : ldm) + totalLdm > 13.61) {
            setError(true);
            return true;
        }
        if (packagesList.length === 8) {
            setError(true);
            return true;
        }
        else {
            setError(false);
            return false;
        }
    }

    const handleAddPackageError = () => {

        if (description === "") {
            return t("Kirjeldus on kohustuslik");
        }
        if (quantity === 0 || quantity === null || isNaN(quantity)) {
            return t("Kogus on kohustuslik");
        }
        if (type === "") {
            return t("Tüüp on kohustuslik");
        }
        if (weight === 0 || weight === null || isNaN(weight)) {
            return t("Kaal on kohustuslik");
        }
        if (((isLdmCalculatable(type, ldm) === true) ? +((quantity) * (length / 100 * width / 100 / 2.4)).toFixed(3) : ldm) + totalLdm > 13.61) {
            return t("Laadimismeetreid ei saa olla üle 13.6");
        }
        if (packagesList.length === 8) {
            return t("8 rida on maksimum");
        }
    }

    const ShowAddPackageErrorPopup = () => {
        if (error) {
            setTimeout(() => {
                setError(false);
            }, 3000);
        }
        return (
            <div className="add-package__error-popup">
                {handleAddPackageError()}
                <button className="add-package__error-popup-close-button" onClick={() => setError(false)}>X</button>
            </div>
        )
    }

    const handleCustomInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    }

    const handleWholeNumberInput = (event: React.ChangeEvent<HTMLInputElement>, setState: (value: SetStateAction<number>) => void) => {
        const inputValue = event.target.value;
        // Remove commas or dots from input
        if (/^[0-9]*$/.test(inputValue)) {
            setState(event.target.valueAsNumber)
        }
    };

    return (
        <div style={{ width: "100%", overflowX: "auto" }}>
            <table className='cargoson-dropdown-table' style={{ borderSpacing: "10px" }}>
                <tbody>
                    <tr>
                        <td>{t("Kogus")}<span style={{ color: "red" }}>*</span></td>
                        <td>{t("Tüüp")}<span style={{ color: "red" }}>*</span></td>
                        <td>{t("Kaal(kg)")}<span style={{ color: "red" }}>*</span></td>
                        <td>{t("P(cm)")}</td>
                        <td>{t("L(cm)")}</td>
                        <td>{t("K(cm)")}</td>
                        {/* <td>{t("Maht(m3)")}</td> */}
                        <td>{t("LDM")}</td>
                        <td>{t("Kirjeldus")}<span style={{ color: "red" }}>*</span></td>
                    </tr>
                    <tr >
                        <td style={{ width: "9%" }}>
                            <input className="input-addressForm"
                                onChange={(e) => setQuantity(e.target.valueAsNumber)}
                                name="quantity"
                                value={quantity !== 0 ? quantity : ""}
                                type="number"
                                min="0"
                            ></input>
                        </td>
                        <td>
                            <select className="input-addressForm"
                                title="Package Type"
                                name="package type"
                                id="type"
                                onChange={(event) => handleTypeChange(event)}
                                value={type}
                            >
                                <option value="" disabled hidden></option>
                                <option value="EUR">EUR (120x80cm)</option>
                                <option value="CTN">Carton</option>
                                <option value="FIN">FIN (120x100cm)</option>
                                <option value="PLL">Pallet</option>
                                <option value="LOAD">Full load</option>
                                <option value="TBE">Tube</option>
                                <option value="CLL">Package unit</option>
                                <option value="RLL">ROLL</option>
                                <option value="HPL">HPL (80x60cm)</option>
                                <option value="QPL">QPL (60x60cm)</option>
                                <option value="LDM">LDM-Soome</option>
                            </select>
                        </td>
                        <td style={{ width: "9%" }}>
                            <input className="input-addressForm"
                                name="weight"
                                type="number"
                                min="0"
                                /* onChange={(e) => handleWholeNumberInput(e, setWeight)} */
                                onChange={(e) => setWeight(e.target.valueAsNumber)}
                                value={weight !== 0 ? weight : ""}
                            ></input>
                        </td>
                        <td style={{ width: "9%" }}>
                            <input className="input-addressForm"
                                type="number"
                                name="length"
                                min="0"
                                onChange={(e) => setLength(e.target.valueAsNumber)}
                                value={length !== 0 ? length : ""}
                            ></input>
                        </td>
                        <td style={{ width: "9%" }}>
                            <input className="input-addressForm"
                                type="number"
                                name="width"
                                min="0"
                                onChange={(e) => setWidth(e.target.valueAsNumber)}
                                value={width !== 0 ? width : ""}
                            ></input>
                        </td>
                        <td style={{ width: "9%" }}>
                            <input className="input-addressForm"
                                type="number"
                                name="height"
                                min="0"
                                onChange={(e) => setHeight(e.target.valueAsNumber)}
                                value={height !== 0 ? height : ""}
                            ></input>
                        </td>
                        {/* <td style={{ width: "9%" }}>
                            <input className="input-addressForm"
                                id="volume"
                                name="volume"
                                type="number"
                                min="0"
                                onChange={(e) => setVolume(e.target.valueAsNumber)}
                                value={volume}
                            ></input>
                        </td> */}
                        <td style={{ width: "9%" }}>
                            <input className="input-addressForm"
                                id="ldm"
                                name="ldm"
                                type="number"
                                min="0"
                                onChange={(e) => setLdm(e.target.valueAsNumber)}
                                value={ldm}
                            ></input>
                        </td>
                        <td style={{ width: "20%" }}>
                            <CustomSelect selectedOption={selectedDescriptionOption} setSelectedOption={setSelectedDescriptionOption}
                                handleCustomInputChange={handleCustomInputChange} customInputValue={description} setCustomInput={setDescription} />
                        </td>
                        <td>
                            <button className="package-table-button" onClick={handleAddPackage}/*  disabled={!isFinnishUserPermitted()} */>
                                {t("Lisa")}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            {error && <ShowAddPackageErrorPopup />}
        </div>
    );

}

export default AddPackage

