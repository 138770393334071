import { IconButton } from "@mui/material";
import { ReactComponent as WazeIcon } from '../../../assets/tracking-app/waze-icon.svg';
type Props = {
  address: string;
};
const WazeLink = ({ address }: Props) => {
  const encodedAddress = encodeURIComponent(address);
  const wazeUrl = `https://waze.com/ul?q=${encodedAddress}&navigate=yes`;
  return (
    <a
      href={wazeUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <IconButton>
        <WazeIcon />
      </IconButton>
    </a>
  );
};

export default WazeLink;
