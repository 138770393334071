import React from 'react'
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment'
import { handleGetInHouseConsignmentStatus } from '../Common'
type Props = {
    calenderObj: InHouseConsignment[]
    handleRowClick: (id: number) => void
}
const UnAssignedDeliveriesLoader = ({ calenderObj, handleRowClick }: Props) => {
    return (
        <div style={{ background: "#efefef", borderRadius: "30px 0 0 30px", padding: "1%", marginBottom: "15px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}>
            <div style={{ color: '#737372', fontSize: '18px', fontFamily: 'Helvetica-Bd', marginBottom: "10px" }}>
                Planeerimata veod
            </div>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead style={{ color: "#737372", fontFamily: "Helvetica-Roman" }}>
                    <tr>
                        <td>Mahalaadimine</td>
                        <td>Klient</td>
                        <td>Objekt</td>
                        <td>Lähetused</td>
                        <td>Staatus</td>
                    </tr>
                </thead>

                <React.Fragment>
                    {calenderObj.map((calenderObj, idx) => (
                        <React.Fragment key={idx}>
                            <tr style={{ background: "#fff", color: "#737372", fontFamily: "Helvetica-Roman", borderRadius: "10px", cursor: "pointer" }} onClick={() => handleRowClick(calenderObj.id)}>
                                <td style={{ borderRadius: "10px 0px 0px 0px", padding: "10px", paddingTop: "15px", paddingBottom: "0px", width: "20%" }}>{calenderObj.deliveryTimeFrom} {calenderObj.deliveryTimeTo && `- ${calenderObj.deliveryTimeTo}`}</td>
                                <td style={{ paddingTop: "15px", paddingBottom: "0px", width: "28%" }}>{calenderObj.deliveryCompanyName} {calenderObj.deliveryCompanyCustomerCode}</td>
                                <td style={{ paddingTop: "15px", paddingBottom: "0px", width: "13%" }}>{calenderObj.deliveryObject}</td>
                                <td style={{ paddingTop: "15px", paddingBottom: "0px", width: "25%" }}>
                                    {calenderObj.deliveries && calenderObj.deliveries.map((x, idx) => (
                                        <div key={idx} style={{ textDecoration: ` ${x.confirmed === "1" ? "line-through" : ""}` }}>{x.deliveryNumber} ({x.deliveryStatus}) </div>
                                    ))}
                                </td>
                                <td style={{ borderRadius: "0px 10px 0px 0px", paddingTop: "15px", paddingBottom: "0px", width: "14%" }}>{handleGetInHouseConsignmentStatus(calenderObj.consignmentStatus)}</td>
                            </tr>
                            {calenderObj.deliveryAddressRow1 && <tr style={{ background: "#fff", color: "#9c9c9c", fontFamily: "Helvetica-Roman", cursor: "pointer" }} onClick={() => handleRowClick(calenderObj.id)}>
                                <td colSpan={5} style={{ padding: "10px", paddingTop: "0px", paddingBottom: "5px", borderRadius: "0 0 10px 10px" }}>{calenderObj.deliveryAddressRow1}</td>
                            </tr>}
                            <tr style={{ background: "#efefef" }}>
                                <td colSpan={5} style={{ padding: "3px", borderRadius: "0 0 10px 10px" }}></td>
                            </tr>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            </table>
        </div>
    )
}

export default UnAssignedDeliveriesLoader