import axios from "axios";
import { apiUrl } from "../config/apiconfig";

export let instance = axios.create({

    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': '*',  
    }, withCredentials: true
})

const authqueries = {
    login: (guid: string) => {
        return instance.get(`${apiUrl}/api/auth/${guid}`);
    },
    refresh: (accessToken:string|null, refreshToken:string|null) => {
        return instance.post(`${apiUrl}/api/auth/refresh?accessToken=${accessToken}&refreshToken=${refreshToken}`);
    },
    logout: () => {
        return instance.get(`${apiUrl}/api/auth/logout`)
    }
};

export default authqueries;