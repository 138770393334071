import { Button, Card, CardContent, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useDataContext } from '../../context/DataContext';
import { ConvertDateToLocaleString } from '../common/ConvertDate';
import ReturnToDirectoTransportRoute from '../transport-route/ReturnToDirectoTransportRoute';
import { styled } from '@mui/system';

export const StyledCard = styled(Card, {
    name: "StyledCard",
    slot: "Wrapper"
})({
    margin: "1rem",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    "&:hover": {
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
    },
    "@media (max-width: 1920px)": {
        width: "300px",
    },
    "@media (max-width: 1024px)": {
        width: "225px",
    },
    ".MuiButton-root": {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "3px",
        marginBottom: "3px",
        color: "#fff",
        backgroundColor: "#c00",
        "&:hover": {
            backgroundColor: "#af0202",
        },
    }
})

const ConsignmentsCards = () => {
    const navigate = useNavigate();
    const { consignmentListResponseNew } = useDataContext();
    const handleLoadConsignment = (consignmentId: number) => {
        navigate(("/consignmentDetails/" + consignmentId))
    }

    return (
        <div className='consignment-cards--root'>
            {consignmentListResponseNew.map((consignment, idx) => {
                return (
                    <StyledCard key={idx}>
                        <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingBottom: "16px" }}>
                            <Typography variant="h6" sx={{ fontSize: "1.2rem", fontWeight: "bold", marginBottom: "0.5rem" }}>
                                Veotellimus: {consignment.directoCode ? consignment.directoCode : consignment.id}
                            </Typography>
                            <Typography variant="body1">
                                Loomise kuupäev: {ConvertDateToLocaleString(consignment.consignmentDate.toString())}
                            </Typography>
                            {/* {consignment.consignmentDate &&
                                <Typography variant="body1">
                                    Müügitellimuse kuupäev: {ConvertDateToLocaleString(consignment.creationDate)}
                                </Typography>
                            } */}
                            <Typography variant="body1">
                                Looja: {consignment.consignmentCreator}
                            </Typography>

                            {consignment.directoCode && (
                                <div className='consignment-cards--link'>
                                    <ReturnToDirectoTransportRoute
                                        returnId={consignment.directoCode}
                                    />
                                </div>
                            )}

                            <Button
                                variant="contained"
                                onClick={() => handleLoadConsignment(+consignment.id)}
                            >
                                Laadi
                            </Button>
                        </CardContent>

                        <CardContent>

                            <React.Fragment>

                                <Card sx={{ marginTop: "1rem", borderRadius: "10px", backgroundColor: "#eee" }}>
                                    <CardContent>
                                        <Typography variant="body1">
                                            Vedaja: {consignment.carrierName} - {consignment.carrierRepresentative}
                                        </Typography>
                                        <Typography variant="body1">
                                            Pealelaadimise kuupäev: {consignment.collectionDate}
                                        </Typography>
                                        <Typography variant="body1">
                                            Pealelaadimiskoht: {consignment.collectionCity}
                                        </Typography>

                                        <Typography variant="body1">
                                            Mahalaadimise kuupäev: {consignment.deliveryDate}
                                        </Typography>
                                        <Typography variant="body1">
                                            Sihtkoht: {consignment.deliveryCity}
                                        </Typography>
                                        <Typography variant="body1">
                                            Cargosonis: {consignment.cargosonReturnId ? "On" : "Ei ole"}
                                        </Typography>
                                    </CardContent>
                                </Card>

                            </React.Fragment>

                        </CardContent>
                    </StyledCard>
                );
            })}
        </div>
    )
}

export default ConsignmentsCards