import { CargosonQuery } from '../Types/cargoson/CargosonQuery';
import { Package } from '../Types/cargoson/Package';
import { Consignment } from '../Types/Consignment';
import { apiUrl } from "../config/apiconfig";
import { instance } from './auth'

export const CreateConsignmentRequest = async (consignmentType: string, userName: string, userEmail: string, consignmentOriginCountry: string) => {
    return instance.post(`${apiUrl}/api/consignment/create?consignmentType=${consignmentType}&userName=${userName}&userEmail=${userEmail}&consignmentOriginCountry=${consignmentOriginCountry}`);
}

export const CreateConsignmentCopy = async (consignmentId: number, userName: string, userEmail: string) => {
    return instance.post(`${apiUrl}/api/consignment/create/copy?consignmentId=${consignmentId}&userName=${userName}&userEmail=${userEmail}`);
}

export const CreateFinlandConsignment = async (consignmentType: string, userName: string, userEmail: string) => {
    return instance.post(`${apiUrl}/api/consignment/finland/create?consignmentType=${consignmentType}&userName=${userName}&userEmail=${userEmail}`);
}

export const CreateSwedenConsignment = async (consignmentType: string,) => {
    return instance.post(`${apiUrl}/api/consignment/sweden/create?consignmentType=${consignmentType}`, { headers: { "Content-Type": "application/json" } });
}

export const LoadConsignmentRequest = async (id: number) => {
    return instance.get(`${apiUrl}/api/consignment/get?id=${id}`);
}

export const GetConsignmentByDirectoCode = async (directoCode: string) => {
    return instance.get(`${apiUrl}/api/consignment/get/directoCode?directoCode=${directoCode}`);
}

export const DeleteIncompleteConsignment = async (consignmentId: number) => {
    return instance.delete(`${apiUrl}/api/consignment/delete?consignmentId=${consignmentId}`, { headers: { "Content-Type": "application/json" } });
}

export const UpdateConsignmentProperty = async (consignmentId: number, propertyName: string, propertyValue: string) => {
    return instance.get(`${apiUrl}/api/consignment/update/property?id=${consignmentId}&propertyName=${propertyName}&propertyValue=${propertyValue}`, { headers: { "Content-Type": "application/json" } });
}

export const SearchAllConsignments = async (searchTerm: string, page: number, pageSize: number, isArchived: boolean, sortBy: string, isDescending: boolean, consignmentType: string) => {
    return instance.get(`${apiUrl}/api/consignment/get/all/search?searchTerm=${searchTerm}&page=${page}&pageSize=${pageSize}&isArchived=${isArchived}&sortBy=${sortBy}&isDescending=${isDescending}&consignmentType=${consignmentType}`, { headers: { "Content-Type": "application/json" } });
}

export const SaveConsignmentList = async (consignmentList: Consignment[]) => {
    return instance.post(`${apiUrl}/api/consignment/save`, JSON.stringify(consignmentList), { headers: { "Content-Type": "application/json" } });
}

export const SaveConsignmentToDirecto = async (consignmentList: Consignment[]) => {
    return instance.post(`${apiUrl}/api/directo/post/consignment`, JSON.stringify(consignmentList), { headers: { "Content-Type": "application/json" } });
}

export const AddPackageRowToConsignment = async (pickupId: number, packageRowDto: Package) => {
    return instance.post(`${apiUrl}/api/consignment/package/add?pickupId=${pickupId}`, JSON.stringify(packageRowDto), { headers: { "Content-Type": "application/json" } });
}

export const RemovePackageRowFromConsignment = async (packageId: number) => {
    return instance.delete(`${apiUrl}/api/consignment/package/remove?packageId=${packageId}`, { headers: { "Content-Type": "application/json" } });
}

export const UpdatePackageConsignment = async (pickupId: number, packageRowDto: Package) => {
    return instance.post(`${apiUrl}/api/consignment/package/update?pickupId=${pickupId}`, JSON.stringify(packageRowDto), { headers: { "Content-Type": "application/json" } });
}

export const GetSuppliers = async () => {
    return instance.get(`${apiUrl}/api/consignment/suppliers/get`, { headers: { "Content-Type": "application/json" } });
}

export const GetSuppliersByName = async (searchText: string) => {
    return instance.get(`${apiUrl}/api/consignment/suppliers/get/name?searchText=${searchText}`, { headers: { "Content-Type": "application/json" } });
}

export const GetSupplierByRegNr = async (regNr: string) => {
    return instance.get(`${apiUrl}/api/consignment/suppliers/get/regNr?regNr=${regNr}`, { headers: { "Content-Type": "application/json" } });
}

export const GetCarrierByCode = async (carrierCode: string) => {
    return instance.get(`${apiUrl}/api/consignment/suppliers/get/code?carrierCode=${carrierCode}`, { headers: { "Content-Type": "application/json" } });
}

export const GetPrintoutFromDirecto = async (directoCode: string, row: number) => {
    return instance.get(`${apiUrl}/api/directo/get/printout?directoCode=${directoCode}&row=${row}`, { headers: { "Content-Type": "text/html" } });
}

export const GetPdfFromDirecto = async (directoCode: string, row: number) => {
    return instance.get<Blob>(`${apiUrl}/api/directo/get/printout?directoCode=${directoCode}&row=${row}`, { headers: { "Content-Type": "application/pdf" }, responseType: 'blob' })
}

export const SendQueryToCargoson = async (cargosonData: CargosonQuery) => {
    return instance.post(`${apiUrl}/api/cargoson/post/query`, JSON.stringify(cargosonData), { headers: { "Content-Type": "application/json" } });
}

export const SendMultipleQueriesToCargoson = async (consignmentList: Consignment[]) => {
    return instance.post(`${apiUrl}/api/cargoson/post/query/multiple`, JSON.stringify(consignmentList), { headers: { "Content-Type": "application/json" } });
}

export const GetFreightListPriceCargoson = async (cargosonData: CargosonQuery, regNr: string) => {
    return instance.post(`${apiUrl}/api/cargoson/post/freightList?regNr=${regNr}`, JSON.stringify(cargosonData), { headers: { "Content-Type": "application/json" } });
}