import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import { ReactComponent as StartIcon } from '../../../assets/tracking-app/start-icon.svg';
import { ReactComponent as NavigateBackIcon } from '../../../assets/tracking-app/navigate-back-icon.svg';
import { ReactComponent as WazeIcon } from '../../../assets/tracking-app/waze-icon.svg';
import { ReactComponent as CancelIcon } from '../../../assets/tracking-app/cancel-icon.svg';
import { IconButton } from '@mui/material';
import { CancelDelivery, GetInHouseConsignment } from '../../../queries/deliveryDriverQueries';
import Loading from '../../../components/common/Loading';
import { AxiosResponse } from 'axios';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../../components/login/user';

const CancelDeliveryPage = () => {
    const { permissions } = useCustomer();
    const [deliveryData, setDeliveryData] = useState<InHouseConsignment>({
        deliveryLatitude: 0,
        deliveryLongitude: 0,
    } as InHouseConsignment);
    const navigate = useNavigate();
    const { deliveryId } = useParams<{ deliveryId: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cancellationReason, setCancellationReason] = useState<string>("");

    const handelCancelDelivery = () => {
        if (cancellationReason === "") return alert("Tühistamise põhjus on kohustuslik")
        setIsLoading(true)
        CancelDelivery(+deliveryId!, cancellationReason).then((response: any) => {
            setDeliveryData(response.data)
        })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false)
                navigate(`/carrier-driver/${deliveryData.deliveryDriverId}`)
            });
    };

    useEffect(() => {
        const handleLoadData = () => {
            if (deliveryId !== undefined) {
                setIsLoading(true);
                GetInHouseConsignment(+deliveryId)
                    .then((response: AxiosResponse<InHouseConsignment>) => {
                        setDeliveryData(response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                setIsLoading(false);
            }
        };
        handleLoadData();
    }, []);

    if (isLoading) {
        return (
            <div>
                <Loading />
            </div>
        );
    }

    if (!permissions.includes("PInHouseDriver")) {
        return <Navigate to="/unauthorized" />
    }

    return (
        <div
            className="delivery-details-wrapper">
            <header className="delivery-driver--header" style={{ marginTop: "0" }}>
                <nav className="flex justify-between">
                    <div
                        className="flex flex-row justify-between items-center"
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                            padding: "1rem",
                        }}
                    >
                        <div
                            className="flex flex-col justify-start items-center"
                        >
                            <IconButton
                                onClick={() => navigate(`/carrier-driver/${deliveryData.deliveryDriverId}`)}
                            >
                                <NavigateBackIcon />
                            </IconButton>
                            <label className="header--button-label">
                                Tänased veod
                            </label>
                        </div>

                        <div
                            className="flex flex-col justify-center items-center"
                            style={{ opacity: "0.35" }}
                        >
                            <IconButton disabled={true}>
                                <StartIcon />
                            </IconButton>
                            <label className="header--button-label">
                                Alusta
                            </label>
                        </div>

                        <div className="flex flex-col justify-center items-center">
                            <IconButton
                                onClick={handelCancelDelivery}
                            >
                                <CancelIcon />
                            </IconButton>
                            <label className="header--button-label">
                                Tühista
                            </label>
                        </div>

                        <div
                            className="flex flex-col justify-center items-center"
                            style={{ opacity: "0.35" }}
                        >
                            <IconButton disabled={true}>
                                <WazeIcon />
                            </IconButton>
                            <label className="header--button-label">
                                Ava Waze
                            </label>
                        </div>
                    </div>
                </nav>
            </header>
            <div className='cancel-delivery--comment-container'>
                <textarea className="cancel-delivery--comment-area" placeholder='Veo tühistamise põhjendus:' name="cancellationReason" value={cancellationReason ?? ""} onChange={(e: any) => setCancellationReason(e.target.value)}></textarea>
            </div>
        </div>
    )
}

export default CancelDeliveryPage