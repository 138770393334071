import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Button,
    DialogActions,
    Select,
    MenuItem,
    Checkbox,
    InputLabel,
    TextField,
    Chip,
    SelectChangeEvent
} from '@mui/material';
import { addDeliveryDriver, getDeliveryDrivers } from '../../queries/adminQueries';
import { useDataContext } from '../../context/DataContext';
import { styled } from '@mui/system';
import { AddDeliveryDriver } from '../../Types/admin/AddDeliveryDriver';
import { User } from '../../Types/user/User';

interface Props {
    open: boolean;
    deliveryDriver: AddDeliveryDriver;
    setDeliveryDriver: Dispatch<SetStateAction<AddDeliveryDriver>>
    onClose: () => void;
    onAddCar: () => void;
}

export const StyledSelect = styled(Select, {
    name: "StyledSelect",
    slot: "Select"
})({
    "& fieldset": {
        "& legend": {
            opacity: "1",
            "& span": {
                color: "blue",
                opacity: "1",
            }
        }
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: "1px solid #0000003b"
    },

    "&.MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#0000003b"
        },
        "&:hover fieldset": {
            borderColor: "#0000003b"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#0000003b"
        }
    }

})

export const StyledTextField = styled(TextField, {
    name: "StyledTextField",
    slot: "TextField"
})({
    '.MuiOutlinedInput-notchedOutline': {
        border: "1px solid #0000003b"
    },
    '& label': {
        "&.Mui-focused": {
            position: "relative",
        }
    },
})

const AddCarDialog = ({ open, deliveryDriver, setDeliveryDriver, onClose, onAddCar }: Props) => {
    const { setIsLoading } = useDataContext()
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const handleFetchUsers = () => {
        getDeliveryDrivers().then((response) => {
            setUsers(response.data)
        });
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setDeliveryDriver((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        addDeliveryDriver(deliveryDriver).then((res) => {
            onClose();
            onAddCar();
        }).catch((err) => { console.log(err) }).finally(() => { setIsLoading(false) });
    };

    /* const handleUpdateUser = (e: any) => {
        e.preventDefault();
        updateDeliveryDriver(deliveryDriver).then((res) => {
            onClose()
        }).catch((err) => { console.log(err) }).finally(() => { setIsLoading(false) });
    } */

    const handleMultiSelectChange = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;
        const selected = typeof value === 'string' ? value.split(',') : value as string[];
        setSelectedUsers(selected);
        setDeliveryDriver((prevData) => ({
            ...prevData,
            selectedUsers: selected
        }));
    };

    const handleChecboxChange = (event: any) => {
        const { name, checked } = event.target;
        if (checked === true) {
            setDeliveryDriver((prevData) => ({
                ...prevData,
                [name]: true,
            }));
        } else {
            setDeliveryDriver((prevData) => ({
                ...prevData,
                [name]: false,
            }));
        }
    }
    useEffect(() => {
        handleFetchUsers()
    }, [])
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="add-user-dialog">
            <DialogTitle id="add-user-dialog">Lisa uus auto</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit} >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <StyledTextField
                                fullWidth
                                label="Auto nimi"
                                name="carName"
                                variant="outlined"
                                type="search"
                                required={true}
                                value={deliveryDriver.carName}
                                onChange={handleInputChange}
                                sx={{ marginTop: "15px" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                fullWidth
                                label="Numbrimärk"
                                name="carLicenseNumber"
                                type="search"
                                variant="outlined"
                                required={true}
                                value={deliveryDriver.carLicenseNumber}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="user-country">Riik</InputLabel>
                            <StyledSelect
                                fullWidth
                                labelId="country"
                                id="country"
                                name="countryCode"
                                value={deliveryDriver.countryCode}
                                placeholder='Country'
                                required={true}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="EST">Estonia</MenuItem>
                                <MenuItem value="FIN">Finland</MenuItem>
                                <MenuItem value="SWE">Sweden</MenuItem>

                            </StyledSelect>

                        </Grid>
                        <Grid item xs={6} md={6} >
                            <InputLabel htmlFor="car-hidden">Peidetud</InputLabel>
                            <Checkbox id="car-hidden" name="hidden" checked={deliveryDriver.hidden} onChange={handleChecboxChange}></Checkbox>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel htmlFor="user">Kasutaja</InputLabel>
                            <StyledSelect
                                fullWidth
                                labelId="user-role"
                                id="user-role"
                                name="role"
                                multiple={true}
                                value={selectedUsers}
                                onChange={handleMultiSelectChange}
                                renderValue={(selected) => (
                                    <div>
                                        {(selected as string[]).map((value) => (
                                            <Chip key={value} label={users.find(user => user.userId === value)?.fullName} />
                                        ))}
                                    </div>
                                )}
                            >
                                {users.map((x) => (
                                    <MenuItem key={x.userId} value={x.userId}>
                                        {x.fullName}
                                    </MenuItem>
                                ))}
                            </StyledSelect>

                        </Grid>

                    </Grid>

                    <DialogActions>
                        <Button onClick={onClose} color="secondary" sx={{ color: "#000" }}>
                            Tühista
                        </Button>

                        {/* {deliveryDriver.id !== 0 ?
                            <Button onClick={(e: any) => handleUpdateUser(e)} variant="contained" color="primary" sx={{
                                backgroundColor: "#c00", "&:hover": {
                                    backgroundColor: "#c00"
                                }
                            }}>
                                Muuda
                            </Button> : */}

                        <Button type="submit" variant="contained" color="primary" sx={{
                            backgroundColor: "#c00", "&:hover": {
                                backgroundColor: "#c00"
                            }
                        }}>
                            Lisa uus
                        </Button>

                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default AddCarDialog;
