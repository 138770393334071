import React from "react";
import {
    ConsignmentStatus,
} from "../../../Types/delivery-app/InHouseConsignment";
import "../../../styles/tracking-app/delivery-order.css";
import { HandleGetInHouseConsignmentStatus } from "./DeliveryStatusBadge";
import { DeliveryDriver } from "../../../Types/delivery-app/DeliveryDriver";
type Props = {
    deliveryDriver: DeliveryDriver;
    handleNavigate: (id: number) => void;
};

const TransportRouteMenu = ({ handleNavigate, deliveryDriver }: Props) => {
    return (
        <div className="flex items-center justify-end">
            <div style={{ width: "50px" }}></div>
            <div className="order" onClick={() => handleNavigate(deliveryDriver.id)} style={{ height: "fit-content", minHeight: "150px" }}>
                <div className="order-details">
                    <h3 className="deliveryOrder-title" >
                        Veoring 1
                    </h3>
                    <div className="deliveryOrder-row" >
                        <div className="deliveryOrder-heading-xs">Veod:</div>
                        <div className="deliveryOrder-row-content">
                            Valitud {deliveryDriver.consignmentsLoaded?.length} vedu

                        </div>
                    </div>

                    {(deliveryDriver.consignmentsLoaded !== null && deliveryDriver.consignmentsLoaded.length > 0) && (
                        <div className="deliveryOrder-row" >
                            <div className="deliveryOrder-heading-xs">Saabumise aeg:</div>
                            <div className="deliveryOrder-row-content">
                                {deliveryDriver.consignmentsLoaded[0]?.deliveryTimeFrom}-
                                {deliveryDriver.consignmentsLoaded[deliveryDriver.consignmentsLoaded?.length - 1]?.deliveryTimeTo}
                            </div>

                        </div>
                    )}
                    {(deliveryDriver.consignmentsLoaded !== null && deliveryDriver.consignmentsLoaded.length > 0) && (
                        <div className="flex-col" >
                            {deliveryDriver.consignmentsLoaded.map((x, idx) => (
                                <div key={x.id} className="flex justify-start deliveryOrder-row-content gap-xs">
                                    <div className="deliveryOrder-heading-xs" style={{ fontFamily: "Helvetica-Roman", fontWeight: "normal" }}>Sihtkoht {idx + 1}: </div>
                                    <div>{x.deliveryAddressRow1}</div>
                                </div>
                            )
                            )}
                        </div>
                    )}
                </div>

            </div>
            <div className="flex flex-col items-start justify-center" style={{ padding: "1rem", paddingLeft: "0", paddingRight: "0", background: "#cbcbcb", width: "150px", marginTop: '-5rem', height: "89px" }}>
                <HandleGetInHouseConsignmentStatus statusNumber={ConsignmentStatus.InTransit} />
            </div>
        </div>
    );
};

export default TransportRouteMenu;
