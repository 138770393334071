import { AxiosResponse } from 'axios';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import { UpdatePackageConsignment } from '../../queries/queries';
import { Package } from '../../Types/cargoson/Package';
import { useCustomer } from '../login/user';

type Props = {
    isUserPermitted: boolean
}
const AddPackageLdm = ({ isUserPermitted }: Props) => {
    const { consignmentList, setConsignmentList, updateCargosonDataConsignment, updateConsignmentData } = useDataContext();
    const { t } = useTranslation()

    const handleDynamicChange = (event: any) => {
        updateConsignmentData(0, event.target.name, event.target.value)
    };

    const currentPackage = consignmentList[0].cargosonData.pickups[0].rows_attributes[0];

    const handleLDMChange = (ldmValue: number) => {
        const packageToAdd = {
            id: currentPackage.id,
            description: currentPackage.description,
            quantity: currentPackage.quantity,
            package_type: currentPackage.package_type,
            weight: currentPackage.weight,
            length: currentPackage.length,
            width: currentPackage.width,
            height: currentPackage.height,
            volume: currentPackage.volume,
            ldm: ldmValue
        } as Package

        UpdatePackageConsignment(consignmentList[0].cargosonData.pickups[0].id, packageToAdd).then((response: AxiosResponse<Package>) => {
            const tempArr = consignmentList.map((item, idx) =>
                idx === 0
                    ? {
                        ...item,
                        cargosonData: {
                            ...item.cargosonData,
                            pickups: item.cargosonData.pickups.map((pickup, i) =>
                                i === 0
                                    ? {
                                        ...pickup,
                                        rows_attributes: [
                                            packageToAdd, // Update the first element with packageToAdd
                                            ...pickup.rows_attributes.slice(1), // Copy the rest of the elements
                                        ],
                                    }
                                    : pickup
                            ),
                        },
                    }
                    : item
            );
            setConsignmentList(tempArr);
        })
    }
    const handleCheckbox = (event: any) => {
        if (isUserPermitted === false) return;
        if (event.target.checked) {
            updateCargosonDataConsignment(0, event.target.name, true)
        } else {
            updateCargosonDataConsignment(0, event.target.name, false)
        }
    }
    return (
        <div className='quick-menu-form--wrapper'>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "30px", flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>{t("LDM")}:</div>
                    <form >
                        <input readOnly={!isUserPermitted} style={{ height: "20px", border: "none", borderBottom: "1px solid #e2e2e2", width: "40px", textAlign: "center" }} type="number" placeholder='(m)' value={currentPackage.ldm} onChange={(e) => handleLDMChange(+e.target.value)} />
                    </form>
                </div>

                <div>
                    <label>{t("Kraanaga auto")}</label>
                    <input disabled={!isUserPermitted} className="cargoson-container__address-form--checkbox" name="delivery_with_crane" type="checkbox" checked={consignmentList[0].cargosonData.delivery_with_crane === true} onChange={handleCheckbox}></input>
                </div>

                <div style={{ width: "99%" }}>
                    <label className='flex justify-center' style={{ marginBottom: "5px" }}>{t("Kommentaar")}</label>
                    <input readOnly={!isUserPermitted} className="input-addressForm" name="internalComment" onChange={handleDynamicChange} value={consignmentList[0].internalComment || ""} style={{ height: "42px", width: '100%', boxSizing: "border-box" }}></input>
                </div>
            </div>
        </div >
    )
}

export default AddPackageLdm