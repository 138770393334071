import React, { useCallback } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from 'date-fns/locale/en-GB';
import useResize from "../common/useRezise";
import { Button } from "@mui/material";
import '../../styles/tuusula-view.css'
import { useTranslation } from "react-i18next";

registerLocale('en-GB', enGB);

interface DateRangePickerProps {
    fromDate: Date | null;
    toDate: Date | null;
    setFromDate: React.Dispatch<React.SetStateAction<Date | null>>;
    setToDate: React.Dispatch<React.SetStateAction<Date | null>>;
    position?: {
        top: number,
        left: number
    },
    onClose: () => void
    setShowCalendar: React.Dispatch<React.SetStateAction<boolean>>
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ onClose, fromDate, toDate, setFromDate, setToDate, position = { top: "10%", left: "80%" }, setShowCalendar }) => {
    const { t } = useTranslation();
    const handleDateChange = (dates: [Date, Date] | null) => {
        if (dates) {
            const [start, end] = dates;
            setFromDate(start);
            setToDate(end);

        } else {
            setFromDate(null);
            setToDate(null);
        }
    };

    const customOnChange = (dates: [Date | null, Date | null], event: React.SyntheticEvent<any, Event> | undefined) => {
        handleDateChange(dates as [Date, Date]);
    };

    const handleResize = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleClearDates = (e: any) => {
        e.stopPropagation()
        setFromDate(null);
        setToDate(null);
        setShowCalendar(false)
    };

    useResize(handleResize);

    return (
        <div style={{ minWidth: "200px", position: "absolute", top: position.top, left: position.left, zIndex: "200", }}>

            <DatePicker
                selected={fromDate}
                onChange={customOnChange}
                startDate={fromDate}
                endDate={toDate}
                selectsRange
                inline
                locale="en-GB"
                showWeekNumbers={true}
                weekLabel="Week"
                onClickOutside={onClose}
            >
                <div>
                    <Button variant="contained" style={{ background: "#c00", color: "#fff", fontWeight: "bold" }} onClick={(e: any) => handleClearDates(e)}>
                        {t("Tühjenda")}
                    </Button>
                </div>
            </DatePicker>
        </div>
    );
};

export default DateRangePicker;
