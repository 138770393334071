import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateTimeCalendar from '../common/DateTimeCalendar';
import { Button } from '@mui/material';
import { useDataContext } from '../../context/DataContext';
import { ConvertDate, ConvertTime } from '../common/ConvertDate';
import { ChangeEvent, useState } from 'react';
import SelectCountry from '../common/SelectCountry';

const PickupAddressForm = (props: { idx: number; listIndex: number; }) => {
    const { updatePickups, consignmentList, setConsignmentList } = useDataContext();
    const pickup = consignmentList[props.listIndex].cargosonData.pickups[props.idx]
    const [showCollectionTime, setShowCollectionTime] = useState<boolean>(true);
    const [timeFrom, setTimeFrom] = useState<Date | null>(pickup.collection_time_from ? ConvertTime(pickup.collection_time_from) : null);
    const [timeTo, setTimeTo] = useState<Date | null>(pickup.collection_time_to ? ConvertTime(pickup.collection_time_to) : null);
    const isImport = consignmentList[props.listIndex].type === "Import";
    const [companyPresetValue, setCompanyPresetValue] = useState<string>("");

    const handleDynamicChange = (event: any) => {
        updatePickups(props.idx, props.listIndex, event.target.name, event.target.value)
    };

    const handleCollectionDate = (date: Date) => {
        updatePickups(props.idx, props.listIndex, "collection_date", date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' }))
    }

    const handleTimeFrom = (date: Date) => {
        setTimeFrom(date)
        updatePickups(props.idx, props.listIndex, "collection_time_from", date?.toLocaleTimeString("et-EE"))
    }
    const handleTimeTo = (date: Date) => {
        setTimeTo(date)
        updatePickups(props.idx, props.listIndex, "collection_time_to", date?.toLocaleTimeString("et-EE"))
    }

    const handleSelectAddressOptions = (event: any) => {
        if (event.target.value === "Tallinn") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    "incoterm_city": isImport ? "Tallinn" : item.cargosonData.incoterm_city,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Peterburi tee 53",
                            "collection_address_row_2": "",
                            "collection_postcode": "11415",
                            "collection_city": "Tallinn",
                            "collection_country": "EE",
                            "collection_company_name": "ETS NORD / Tallinn",
                            "collection_loading_area": "TALLINN1",
                            "collection_contact_name": "Kuido Jaaksaar",
                            "collection_contact_phone": "+372 5294670",
                            "collection_contact_email": "kuido.jaaksaar@etsnord.com",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Tallinn")
        }
        if (event.target.value === "Tuusula") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    "incoterm_city": isImport ? "Tuusula" : item.cargosonData.incoterm_city,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Pakkasraitti 4",
                            "collection_address_row_2": "",
                            "collection_postcode": "04360",
                            "collection_city": "Tuusula",
                            "collection_country": "FI",
                            "collection_company_name": "ETS NORD Suomi / Tuusula",
                            "collection_loading_area": "TUUSULA1",
                            "collection_contact_name": "Erki Adamson",
                            "collection_contact_phone": "+358 504 338 400",
                            "collection_contact_email": "erki.adamson@etsnord.fi",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Tuusula")
        }
        if (event.target.value === "Vantaa") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    "incoterm_city": isImport ? "Vantaa" : item.cargosonData.incoterm_city,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Pavintie 12",
                            "collection_address_row_2": "",
                            "collection_postcode": "01260",
                            "collection_city": "Vantaa",
                            "collection_country": "FI",
                            "collection_company_name": "ETS NORD Suomi / Vantaa",
                            "collection_loading_area": "VANTAA1",
                            "collection_contact_name": "Antti Airas",
                            "collection_contact_phone": "+358 40 737 5314",
                            "collection_contact_email": "antti.airas@recair.fi",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Vantaa")
        }
        if (event.target.value === "Kumla") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    "incoterm_city": isImport ? "Kumla" : item.cargosonData.incoterm_city,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Järsjögatan 7",
                            "collection_address_row_2": "",
                            "collection_postcode": "69235",
                            "collection_city": "Kumla",
                            "collection_country": "SE",
                            "collection_company_name": "ETS NORD Sverige / Kumla",
                            /* "collection_loading_area": "KUMLA1", */
                            "collection_contact_name": "Hans Blöhse",
                            "collection_contact_phone": "+46707805016",
                            "collection_contact_email": "hans.blohse@etsnord.se",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Kumla")
        }
        if (event.target.value === "Malmö") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                "incoterm_city": isImport ? "Malmö" : item.cargosonData.incoterm_city,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Pinjegatan 5",
                            "collection_address_row_2": "",
                            "collection_postcode": "21363",
                            "collection_city": "Malmö",
                            "collection_country": "SE",
                            "collection_company_name": "ETS NORD Sverige / Malmö",
                            "collection_contact_name": "Zebasthian Tullberg",
                            "collection_contact_phone": "+46700924984",
                            "collection_contact_email": "zebasthian.tullberg@etsnord.se",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Malmö")
        }
        if (event.target.value === "Södertälje") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                "incoterm_city": isImport ? "Södertälje" : item.cargosonData.incoterm_city,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Ängsgatan 33",
                            "collection_address_row_2": "",
                            "collection_postcode": "15134",
                            "collection_city": "Södertälje",
                            "collection_country": "SE",
                            "collection_company_name": "ETS NORD Sverige / Postoffice",
                            "collection_contact_name": "Richard Saukko",
                            "collection_contact_phone": "+4679-1421877",
                            "collection_contact_email": "richard.saukko@etsnord.se",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Södertälje")
        }
        if (event.target.value === "") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                "incoterm_city": isImport ? "" : item.cargosonData.incoterm_city,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "",
                            "collection_address_row_2": "",
                            "collection_postcode": "",
                            "collection_city": "",
                            "collection_country": "",
                            "collection_company_name": "",
                            "collection_contact_name": "",
                            "collection_contact_phone": "",
                            "collection_contact_email": "",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("")
        }
    }

    const handleSelectLoadingArea = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value.startsWith("TALLINN")) {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    "incoterm_city": isImport ? "Tallinn" : item.cargosonData.incoterm_city,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Peterburi tee 53",
                            "collection_address_row_2": "",
                            "collection_postcode": "11415",
                            "collection_city": "Tallinn",
                            "collection_country": "EE",
                            "collection_company_name": "ETS NORD / Tallinn",
                            "collection_loading_area": event.target.value,
                            "collection_contact_name": "Kuido Jaaksaar",
                            "collection_contact_phone": "+372 5294670",
                            "collection_contact_email": "kuido.jaaksaar@etsnord.com",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Tallinn")
        }
        if (event.target.value.startsWith("TUUSULA")) {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    "incoterm_city": isImport ? "Tuusula" : item.cargosonData.incoterm_city,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Pakkasraitti 4",
                            "collection_address_row_2": "",
                            "collection_postcode": "04360",
                            "collection_city": "Tuusula",
                            "collection_country": "FI",
                            "collection_company_name": "ETS NORD Suomi / Tuusula",
                            "collection_loading_area": event.target.value,
                            "collection_contact_name": "Erki Adamson",
                            "collection_contact_phone": "+358 504 338 400",
                            "collection_contact_email": "erki.adamson@etsnord.fi",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Tuusula")
        }
        if (event.target.value.startsWith("VANTAA")) {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    "incoterm_city": isImport ? "Vantaa" : item.cargosonData.incoterm_city,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Pavintie 12",
                            "collection_address_row_2": "",
                            "collection_postcode": "01260",
                            "collection_city": "Vantaa",
                            "collection_country": "FI",
                            "collection_company_name": "ETS NORD Suomi / Vantaa",
                            "collection_loading_area": event.target.value,
                            "collection_contact_name": "Antti Airas",
                            "collection_contact_phone": "+358 40 737 5314",
                            "collection_contact_email": "antti.airas@recair.fi",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Vantaa")
        }
        if (event.target.value.startsWith("KUMLA")) {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    "incoterm_city": isImport ? "Kumla" : item.cargosonData.incoterm_city,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Järsjögatan 7",
                            "collection_address_row_2": "",
                            "collection_postcode": "69235",
                            "collection_city": "Kumla",
                            "collection_country": "SE",
                            "collection_company_name": "ETS NORD Sverige / Kumla",
                            "collection_loading_area": event.target.value,
                            "collection_contact_name": "Hans Blöhse",
                            "collection_contact_phone": "+46707805016",
                            "collection_contact_email": "hans.blohse@etsnord.se",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Kumla")
        }
        if (event.target.value.startsWith("MALMÖ")) {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                "incoterm_city": isImport ? "Malmö" : item.cargosonData.incoterm_city,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Pinjegatan 5",
                            "collection_address_row_2": "",
                            "collection_postcode": "21363",
                            "collection_city": "Malmö",
                            "collection_country": "SE",
                            "collection_company_name": "ETS NORD Sverige / Malmö",
                            "collection_loading_area": event.target.value,
                            "collection_contact_name": "Zebasthian Tullberg",
                            "collection_contact_phone": "+46700924984",
                            "collection_contact_email": "zebasthian.tullberg@etsnord.se",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Malmö")
        }
        if (event.target.value.startsWith("SÖDERTÄLJE")) {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                "incoterm_city": isImport ? "Södertälje" : item.cargosonData.incoterm_city,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "Ängsgatan 33",
                            "collection_address_row_2": "",
                            "collection_postcode": "15134",
                            "collection_city": "Södertälje",
                            "collection_country": "SE",
                            "collection_company_name": "ETS NORD Sverige / Postoffice ",
                            "collection_loading_area": event.target.value,
                            "collection_contact_name": "Richard Saukko",
                            "collection_contact_phone": "+4679-1421877",
                            "collection_contact_email": "richard.saukko@etsnord.se",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("Södertälje")
        }
        if (event.target.value === "") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                "incoterm_city": isImport ? "" : item.cargosonData.incoterm_city,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_address_row_1": "",
                            "collection_address_row_2": "",
                            "collection_postcode": "",
                            "collection_city": "",
                            "collection_country": "",
                            "collection_company_name": "",
                            "collection_loading_area": "",
                            "collection_contact_name": "",
                            "collection_contact_phone": "",
                            "collection_contact_email": "",
                        } : pickup)
                    ]
                }
            } : item))
            setCompanyPresetValue("")
        }
    }

    const handleSelectContactOptions = (event: any) => {

        if (event.target.value === "KuidoJaaksaar") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_contact_name": "Kuido Jaaksaar",
                            "collection_contact_phone": "+372 5294670",
                            "collection_contact_email": "kuido.jaaksaar@etsnord.com",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "MerikeHelistvee") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_contact_name": "Merike Helistvee",
                            "collection_contact_phone": "+372 58556362",
                            "collection_contact_email": "merike.helistvee@etsnord.com",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "JanarVoltein") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_contact_name": "Janar Voltein",
                            "collection_contact_phone": "+372 51963835",
                            "collection_contact_email": "janar.voltein@etsnord.com",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "ErkiAdamson") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_contact_name": "Erki Adamson",
                            "collection_contact_phone": "+358 504 338 400",
                            "collection_contact_email": "erki.adamson@etsnord.fi",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "HansBlöhse") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_contact_name": "Hans Blöhse",
                            "collection_contact_phone": "+46707805016",
                            "collection_contact_email": "hans.blohse@etsnord.se",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "ZebasthianTullberg") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_contact_name": "Zebasthian Tullberg",
                            "collection_contact_phone": "+46700924984",
                            "collection_contact_email": "zebasthian.tullberg@etsnord.se",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "AnttiAiras") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_contact_name": "Antti Airas",
                            "collection_contact_phone": "+358 40 737 5314",
                            "collection_contact_email": "antti.airas@recair.fi",
                        } : pickup)
                    ]
                }
            } : item))
        }
        if (event.target.value === "Myself") {
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                            ...pickup,
                            "collection_contact_name": `${localStorage.getItem("user")}`,
                            "collection_contact_phone": "",
                            "collection_contact_email": `${localStorage.getItem("email")}`
                        } : pickup)
                    ]
                }
            } : item))
        }
    }

    const handleCheckbox = (event: any) => {
        if (event.target.checked) {
            updatePickups(props.idx, props.listIndex, event.target.name, true)

        } else {
            updatePickups(props.idx, props.listIndex, event.target.name, false)
        }
    }

    const handlePickupCityChange = (event: any) => {
        //change incoterm city value to pickup city value
        var tempArr = consignmentList.map((item, idx) => idx === props.listIndex ? {
            ...item,
            cargosonData: {
                ...item.cargosonData,
                "incoterm_city": isImport ? event.target.value : item.cargosonData.incoterm_city,
                pickups: [
                    ...item.cargosonData.pickups.map((pickup, i) => i === props.idx ? {
                        ...pickup,
                        "collection_city": event.target.value
                    } : pickup)
                ]
            }
        } : item);
        setConsignmentList(tempArr)
    }

    return (
        <div className="cargoson-container__address-form-wrapper">
            <h2 className="cargoson-container__address-form--title">
                PEALELAADIMISKOHT
            </h2>
            <div className="cargoson-container__address-form">
                <table className='cargoson-dropdown-table'>
                    <tbody>
                        <tr>
                            <td className="cargoson-container__address-form--first-td">Pealelaadimise aeg</td>
                            <td colSpan={2}>
                                <DateTimeCalendar handleChange={handleCollectionDate} currentDate={pickup.collection_date ? ConvertDate(pickup.collection_date) : new Date()} />  {/* date ei tohi tühi olla */}
                            </td>
                            <td style={{ textAlign: "left" }}>
                                <Button style={{ color: "gray" }} onClick={() => setShowCollectionTime(!showCollectionTime)}>
                                    <AccessTimeIcon />
                                </Button>
                            </td>
                        </tr>
                        {showCollectionTime &&
                            <>
                                <tr>
                                    <td></td>
                                    <td>
                                        <DateTimeCalendar handleChange={handleTimeFrom} currentDate={timeFrom} showTimeSelectOnly={true} placeHolder={"Alates"} />
                                    </td>
                                    <td>
                                        <DateTimeCalendar handleChange={handleTimeTo} currentDate={timeTo} showTimeSelectOnly={true} placeHolder={"Kuni"} />
                                    </td>
                                    <td colSpan={2}>
                                        <select className="cargoson-container__address-form--input" onChange={handleSelectLoadingArea} name="collection_loading_area" value={pickup.collection_loading_area || ""}>
                                            <option value="" disabled hidden>Vali laadimisala</option>
                                            <option value=""></option>
                                            <option value="TALLINN1">Tallinn 1</option>
                                            <option value="TALLINN2">Tallinn 2</option>
                                            <option value="TALLINN3">Tallinn 3</option>
                                            <option value="TALLINN4">Tallinn 4</option>
                                            <option value="TUUSULA1">Tuusula 1</option>
                                            <option value="VANTAA1">Vantaa 1</option>
                                            <option value="KUMLA1">Kumla 1</option>
                                            <option value="SÖDERTÄLJE1">Södertälje 1</option>
                                        </select>
                                    </td>
                                </tr>
                            </>
                        }
                        <tr>
                            <td className="cargoson-container__address-form--first-td">Ettevõte</td>
                            <td colSpan={2}>
                                <select className="cargoson-container__address-form--input" onChange={handleSelectAddressOptions} value={companyPresetValue || ""} >
                                    <option value="" disabled hidden>Vali eeltäidetud</option>
                                    <option value=""></option>
                                    <option value="Tallinn">ETS NORD Tallinn</option>
                                    <option value="Tuusula">ETS NORD Tuusula</option>
                                    <option value="Vantaa">ETS NORD Vantaa</option>
                                    <option value="Kumla">ETS NORD Kumla</option>
                                    <option value="Malmö">ETS NORD Malmö</option>
                                    <option value="Södertälje">ETS NORD Södertälje</option>
                                </select>
                            </td>
                            <td colSpan={2}>
                                <input className="cargoson-container__address-form--input" placeholder="Ettevõte" name="collection_company_name" value={pickup.collection_company_name || ""} onChange={handleDynamicChange}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="cargoson-container__address-form--first-td">Aadress</td>

                            <td>
                                <input className="cargoson-container__address-form--input" placeholder="Tänav" name="collection_address_row_1" value={pickup.collection_address_row_1 || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" placeholder="Aadressi lisa" name="collection_address_row_2" value={pickup.collection_address_row_2 || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" placeholder="Postiindeks" name="collection_postcode" value={pickup.collection_postcode || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" placeholder="Linn" name="collection_city" value={pickup.collection_city || ""} onChange={handlePickupCityChange}></input>
                            </td>
                            <td>
                                <SelectCountry propName="collection_country" listIndex={props.listIndex} />
                            </td>
                        </tr>
                        <tr>
                            <td className="cargoson-container__address-form--first-td">Kontakt</td>
                            <td>
                                <select className="cargoson-container__address-form--input" placeholder="Kontakt" defaultValue="" onChange={handleSelectContactOptions}>
                                    <option value="" disabled hidden>Vali eeltäidetud</option>
                                    <option value="KuidoJaaksaar">Kuido Jaaksaar</option>
                                    <option value="MerikeHelistvee">Merike Helistvee</option>
                                    <option value="JanarVoltein">Janar Voltein</option>
                                    <option value="ErkiErkiAdamson">Erki Adamson</option>
                                    <option value="HansBlöhse">Hans Blöhse</option>
                                    <option value="ZebasthianTullberg">Zebasthian Tullberg</option>
                                    <option value="AnttiAiras">Antti Airas</option>
                                    <option value="Myself">Vali ennast</option>
                                </select>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" placeholder="Ees- ja perekonnanimi" name="collection_contact_name" value={pickup.collection_contact_name || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td>
                                <input className="cargoson-container__address-form--input" placeholder="Telefoni nr" name="collection_contact_phone" value={pickup.collection_contact_phone || ""} onChange={handleDynamicChange}></input>
                            </td>
                            <td colSpan={2} style={{ textAlign: 'left' }}>
                                <input className="cargoson-container__address-form--input"
                                    placeholder="E-mail"
                                    name="collection_contact_email"
                                    value={pickup.collection_contact_email || ""}
                                    onChange={handleDynamicChange}
                                ></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="cargoson-container__address-form--first-td">Kommentaar</td>
                            <td colSpan={2} style={{ textAlign: "left" }}>
                                <input className="cargoson-container__address-form--input" placeholder="Kommentaar" name="collection_comment" onChange={handleDynamicChange} value={pickup.collection_comment || ""} ></input>
                            </td>
                            <td colSpan={3} style={{ textAlign: "center" }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", gap: "15%" }}>
                                    <div>
                                        <label>Tagaluuk</label>
                                        <input className="cargoson-container__address-form--checkbox" name="collection_with_tail_lift" type="checkbox" checked={pickup.collection_with_tail_lift || false} onChange={handleCheckbox}></input>
                                    </div>
                                    <div>
                                        <label>Ettehelistamine</label>
                                        <input className="cargoson-container__address-form--checkbox" name="collection_prenotification" type="checkbox" checked={pickup.collection_prenotification || false} onChange={handleCheckbox}></input>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PickupAddressForm