import { IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { AddPurchaseOrderToInHouseConsignment, DeletePurchaseOrderInHouseConsignment, PurchaseOrderInfoToInHouseConsignment, UpdateInHouseConsignment } from '../../../queries/deliveryDriverQueries';
import { useParams } from 'react-router-dom';
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import { geocodeByAddress, Suggestion } from 'react-places-autocomplete';
import Modal from '../../common/Modal';
import { ReactComponent as AddIcon } from '../../../assets/tracking-app/buttons/add_square.svg';
import { ReactComponent as SearchIcon } from '../../../assets/tracking-app/buttons/Search_alt.svg';
import { ReactComponent as PlusIcon } from '../../../assets/tracking-app/buttons/Chat_plus.svg';
import { ReactComponent as CloseIcon } from '../../../assets/tracking-app/buttons/Close_round.svg';
import PurchaseOrderModal from '../../consignment/Directo/PurchaseOrderModal';

type Props = {
    inHouseConsignment: InHouseConsignment;
    setInHouseConsignment: React.Dispatch<React.SetStateAction<InHouseConsignment>>;
    updateAddressSuggestions: (suggestions: Suggestion[]) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    setShowMapModal: React.Dispatch<React.SetStateAction<boolean>>;
    isUserPermitted?: boolean;
};
const AddPurchaseOrder = ({ inHouseConsignment, setInHouseConsignment, updateAddressSuggestions, inputRef, setShowMapModal, isUserPermitted = true }: Props) => {
    const [orderNumber, setOrderNumber] = useState<string>();
    const { id } = useParams<{ id: string }>();
    const [showItemsModal, setShowItemsModal] = useState<boolean>(false);
    const [deliveryToShow, setDeliveryToShow] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!orderNumber) return;
        if (id === undefined) return;
        if (inHouseConsignment?.directoPurchaseOrderNumbers?.includes(orderNumber)) return;
        AddPurchaseOrderToInHouseConsignment(+id, orderNumber)
            .then((response) => {
                setInHouseConsignment({ ...inHouseConsignment, directoPurchaseOrderNumbers: response.data.directoPurchaseOrderNumbers });
                setOrderNumber(() => '');
            })
            .catch((err) => {
                console.log(err);
                alert(err.response.data);
            });
    };

    const fetchAddressSuggestions = async (address: string) => {
        // Use Google Maps API to fetch address suggestions based on deliveryNumber
        try {
            const results = await geocodeByAddress(address);
            const suggestions: Suggestion[] = results.map((result, index) => ({
                description: result.formatted_address,
                placeId: result.place_id,
                matchedSubstrings: [],
                terms: [],
                type: 'address',
                types: result.types || [],
                id: index.toString(),
                active: false,
                index: index,
                formattedSuggestion: {
                    mainText: result.formatted_address, // Adjust this part based on your requirement
                    secondaryText: '' // Adjust this part based on your requirement
                }
            }));
            inputRef.current?.focus();
            updateAddressSuggestions(suggestions); // Call the function to update suggestions
            setShowMapModal(true);
        } catch (error) {
            setShowMapModal(true);
            console.error('Error fetching address suggestions:', error);
        }
    };

    const handleAddInfoToHeader = async (orderNumber: string) => {
        if (!isUserPermitted) return;
        await UpdateInHouseConsignment(inHouseConsignment)
            .then((response: any) => { })
            .catch((error) => {
                console.error(error);
            });

        if (id === undefined) return;
        PurchaseOrderInfoToInHouseConsignment(+id, orderNumber)
            .then((response: AxiosResponse<InHouseConsignment>) => {
                setInHouseConsignment(response.data);
                fetchAddressSuggestions(response.data.collectionAddressRow1);
                alert(`Ostutellimuse ${orderNumber} info võetud aluseks. Palun kinnitage sihtkoha aadress.`);
            })
            .catch((err) => {
                console.log(err);
                alert(`Ei saanud ostutellimust ${orderNumber} veotellimuse aluseks võtta`);
            });
    };

    const handleRemove = (orderNumber: string) => {
        if (!isUserPermitted) return;
        if (id === undefined) return;
        DeletePurchaseOrderInHouseConsignment(+id, orderNumber)
            .then((response) => {
                setInHouseConsignment({ ...inHouseConsignment, directoPurchaseOrderNumbers: response.data.directoPurchaseOrderNumbers });
            })
            .catch((err) => {
                console.log(err.response);
                alert('Ei saanud lähetust eemaldada');
            });
    };

    const openItemsModal = (deliveryNumber: string) => {
        setShowItemsModal(true);
        setDeliveryToShow(deliveryNumber);
    };

    return (
        <div className="text-reg" style={{ borderRadius: '5px', padding: '20px', minHeight: '95px', backgroundColor: '#fff' }}>
            <div className="flex-col justify-start items-start">
                {inHouseConsignment.directoPurchaseOrderNumbers?.map((x, idx) => (
                    <div key={idx} className="flex justiy-between items-center gap-xs">
                        <div
                            key={idx}
                            onClick={() => {
                                setShowItemsModal(!showItemsModal);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <a className="addedDeliveires__anchor-tag" style={{ color: '#737372' }} target="_blank" rel="noreferrer" href={`https://login.directo.ee/ocra_ets_nord/otell_tellimus.asp?NUMBER=${x}`}>
                                {x}
                            </a>
                        </div>
                        <div className="flex gap-xs">
                            <IconButton style={{ padding: '0' }} onClick={() => openItemsModal(x)}>
                                <SearchIcon />
                            </IconButton>
                            <IconButton style={{ padding: '0' }} title="Võta põhjaks" onClick={() => handleAddInfoToHeader(x)}>
                                <PlusIcon />
                            </IconButton>
                            <IconButton style={{ padding: '0' }} title="Eemalda" onClick={() => handleRemove(x)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit}>
                <div className="w-50 flex justify-start items-center" style={{ minWidth: '190px' }}>
                    <input readOnly={!isUserPermitted} className="input-addressForm input--tracking-app" type="number" placeholder="Ostutellimuse nr" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />
                    <IconButton type="submit">
                        <AddIcon />
                    </IconButton>
                </div>
            </form>
            <Modal show={showItemsModal} onClose={() => setShowItemsModal(!showItemsModal)}>
                <PurchaseOrderModal purchaseOrderNumber={deliveryToShow} />
            </Modal>
        </div>
    );
};

export default AddPurchaseOrder;
