import AddPackageLdm from '../../components/finland-transport/AddPackageLdm'
import AddSalesOrder from '../../components/finland-transport/AddSalesOrder'
import CarrierDriver from '../../components/finland-transport/CarrierDriver'
import CreateNewTransportOrder from '../../components/finland-transport/CreateNewTransportOrder'
import DropdownTime from '../../components/finland-transport/DropdownTime'
import PickupTime from '../../components/finland-transport/PickupTime'
import '../../styles/buttons.css'
import '../../styles/Consignment.css'
import '../../styles/CargosonComponent.css';
import '../../styles/main.css';
import PickupAddressFinland from '../../components/finland-transport/PickupAddressFinland'
import GeneralTransportInfo from '../../components/finland-transport/GeneralTransportInfo'
import { useEffect, useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, IconButton } from '@mui/material'
import { useDataContext } from '../../context/DataContext'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { DeleteIncompleteConsignment, LoadConsignmentRequest } from '../../queries/queries'
import { AxiosResponse } from 'axios'
import { Consignment } from '../../Types/Consignment'
import { ConvertDate, ConvertTime } from '../../components/common/ConvertDate'
import DropdownAddressFinland from '../../components/finland-transport/DropdownAddressFinland'
import ConsignmentHeaderFinland from '../../components/finland-transport/ConsignmentHeaderFinland'
import FinlandConsignmentOptions from '../../components/finland-transport/FinlandConsignmentOptions'
import AddToTransportRoute from '../../components/finland-transport/AddToTransportRoute'
import { useTranslation } from 'react-i18next'
import { useCustomer } from '../../components/login/user'

const TuusulaTransportDetailsView = () => {
    const { consignmentList, setConsignmentId, setConsignmentList, setIsSearchOpen } = useDataContext();
    const cargosonData = consignmentList[0].cargosonData;
    const pickup = consignmentList[0].cargosonData.pickups[0]
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(true);
    const [isPickupVisible, setIsPickupVisible] = useState<boolean>(true);
    const [isCargosonHeaderVisible, setIsCargosonHeaderVisible] = useState<boolean>(false);
    const [isConsignmentHeaderVisible, setIsConsignmentHeaderVisible] = useState<boolean>(false);

    const [deliveryDate, setDeliveryDate] = useState(cargosonData?.delivery_date ? ConvertDate(cargosonData.delivery_date) : new Date());
    const [deliveryTimeFrom, setDeliveryTimeFrom] = useState<Date | null>(cargosonData.delivery_time_from != null ? ConvertTime(cargosonData.delivery_time_from) : ConvertTime("8:00"));
    const [deliveryTimeTo, setDeliveryTimeTo] = useState<Date | null>((cargosonData.delivery_time_to != null) ? ConvertTime(cargosonData.delivery_time_to) : ConvertTime("15:00"));
    const [collectionDate, setCollectionDate] = useState(pickup.collection_date ? ConvertDate(pickup.collection_date) : new Date());
    const [collectionTimeFrom, setCollectionTimeFrom] = useState<Date | null>(pickup.collection_time_from != null ? ConvertTime(pickup.collection_time_from) : ConvertTime("8:00"));
    const [collectionTimeTo, setCollectionTimeTo] = useState<Date | null>(pickup.collection_time_to != null ? ConvertTime(pickup.collection_time_to) : ConvertTime("15:00"));
    const [isConsignmentCreated, setIsConsignmentCreated] = useState<boolean>(false);

    const [initialConsignment, setInitialConsignment] = useState<Consignment | null>(null);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation()
    const { isFinnishUserPermitted, permissions } = useCustomer()
    const navigate = useNavigate()

    const getDeliveryTimePresetValue = () => {
        if (cargosonData.delivery_time_from === "07:30:00") return "7:30";
        if (cargosonData.delivery_time_from === "07:00:00") return "7:00";
        if (cargosonData.delivery_time_from === "08:00:00") return "8:00";
        if (cargosonData.delivery_time_from === "08:00:00" && cargosonData.delivery_time_to === "15:00:00") return "Day";
        else return ""
    }

    const [deliveryTimePresetValue, setDeliveryTimePresetValue] = useState<string>(getDeliveryTimePresetValue())

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const togglePickup = () => {
        setIsPickupVisible(!isPickupVisible);
    };

    const toggleCargosonHeader = () => {
        setIsCargosonHeaderVisible(!isCargosonHeaderVisible);
    };

    const toggleConsignmentHeader = () => {
        setIsConsignmentHeaderVisible(!isConsignmentHeaderVisible);
    };

    const handleConsignmentRequest = () => {
        if (id !== undefined) {
            LoadConsignmentRequest(+id).then((response: AxiosResponse<Consignment>) => {
                var arr = consignmentList.map((item, idx) => idx === 0 ? response.data : item);
                setConsignmentId(response.data.id.toString())
                setConsignmentList(arr)
                setInitialConsignment(response.data);
                setIsSearchOpen(true)
                setIsConsignmentCreated(true)
                setDeliveryDate(() => ConvertDate(response.data.cargosonData?.delivery_date))
                setCollectionDate(() => ConvertDate(response.data.cargosonData?.pickups[0]?.collection_date))
                setDeliveryTimeFrom(() => ConvertTime(response.data.cargosonData?.delivery_time_from))
                setDeliveryTimeTo(() => ConvertTime(response.data.cargosonData?.delivery_time_to))
                setCollectionTimeFrom(() => ConvertTime(response.data.cargosonData?.pickups[0].collection_time_from))
                setCollectionTimeTo(() => ConvertTime(response.data.cargosonData?.pickups[0].collection_time_to))
            }).catch((error) => { console.error(error) })
        }
    }

    const handleRemoveConsignment = async () => {
        if (window.confirm(t`Oled kindel?`)) {
            await DeleteIncompleteConsignment(+consignmentList[0].id)
            navigate("/overview")
        }
    }

    useEffect(() => {
        handleConsignmentRequest()
        setDeliveryTimePresetValue("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!permissions.includes("PViewFinnishTransportOrders")) {
        return <Navigate to="/unauthorized" />
    }

    return (
        <div className='flex justify-center items-center' style={{ width: "100%", paddingTop: "15px" }} >
            <div className='flex flex-col justify-between items-center tuusula-view--width' style={{ gap: "25px", paddingBottom: "100px" }}>

                <div className='flex flex-wrap justify-between items-center transport-order--block' >
                    <div className='page__heading'>{t("Uue veotellimuse täitmine")}</div>
                    {isFinnishUserPermitted() === true &&
                        <div className='flex justify-end items-center new-order-buttons--box'>

                            <div style={{ width: "50%" }}>
                                <CreateNewTransportOrder alignItems='center' isUserPermitted={isFinnishUserPermitted()} />
                            </div>
                            {!consignmentList[0].directoCode &&
                                <Button style={{ background: "#C00", color: "white", width: "175px", height: "61px", fontWeight: "bold" }} onClick={handleRemoveConsignment}>{t("Kustuta")}</Button>
                            }
                        </div>}

                </div>
                {isConsignmentCreated === true &&
                    <>
                        <div className='transport-quick-menu--container'>
                            <AddSalesOrder setDeliveryDate={setDeliveryDate} />
                            <AddPackageLdm isUserPermitted={isFinnishUserPermitted()} />
                            <PickupTime collectionDate={collectionDate} setCollectionDate={setCollectionDate} timeFrom={collectionTimeFrom} setTimeFrom={setCollectionTimeFrom} timeTo={collectionTimeTo} setTimeTo={setCollectionTimeTo} />
                            <DropdownTime deliveryDate={deliveryDate} setDeliveryDate={setDeliveryDate} setTimeFrom={setDeliveryTimeFrom} timeFrom={deliveryTimeFrom}
                                setTimeTo={setDeliveryTimeTo} timeTo={deliveryTimeTo} deliveryTimePresetValue={deliveryTimePresetValue} setDeliveryTimePresetValue={setDeliveryTimePresetValue} />
                            <CarrierDriver />
                        </div>

                        <AddToTransportRoute isUserPermitted={isFinnishUserPermitted()} originCountry="FI" />
                        {isDropdownVisible ? (
                            <div className='transport-order--block'>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "10px" }}>
                                    <IconButton onClick={toggleDropdown}>
                                        {isDropdownVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </div>
                                <DropdownAddressFinland listIndex={0} deliveryDate={deliveryDate} setDeliveryDate={setDeliveryDate} timeFrom={deliveryTimeFrom} setTimeFrom={setDeliveryTimeFrom}
                                    timeTo={deliveryTimeTo} setTimeTo={setDeliveryTimeTo} deliveryTimePresetValue={deliveryTimePresetValue} setDeliveryTimePresetValue={setDeliveryTimePresetValue}
                                    isUserPermitted={isFinnishUserPermitted()} />
                            </div>
                        ) :
                            <div className='transport-order--summary-block' onClick={toggleDropdown}>
                                <h2 className="cargoson-container__address-form--title block-title--width" >
                                    {t("Mahalaadimine")}
                                </h2>
                                <div className='flex justify-center items-center transport-order-block__heading'>
                                    {consignmentList[0].cargosonData.delivery_address_row_1} {consignmentList[0].cargosonData.delivery_city} - {ConvertDate(consignmentList[0].cargosonData.delivery_date).toLocaleDateString('et-EE')}
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", }}>
                                    <IconButton onClick={toggleDropdown}>
                                        {isDropdownVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </div>
                            </div>
                        }

                        {isPickupVisible ? (
                            <div className='transport-order--block'>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "10px" }}>
                                    <IconButton onClick={togglePickup}>
                                        {isPickupVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </div>
                                <PickupAddressFinland key={1} idx={0} listIndex={0} collectionDate={collectionDate} setCollectionDate={setCollectionDate}
                                    timeFrom={collectionTimeFrom} setTimeFrom={setCollectionTimeFrom} timeTo={collectionTimeTo} setTimeTo={setCollectionTimeTo}
                                    isUserPermitted={isFinnishUserPermitted()} />
                            </div>
                        ) :
                            <div className='transport-order--summary-block' onClick={togglePickup}>
                                <h2 className="cargoson-container__address-form--title block-title--width" >
                                    {t("Pealelaadimine")}
                                </h2>
                                <div className='flex justify-center items-center transport-order-block__heading'>
                                    {consignmentList[0].cargosonData.pickups[0].collection_address_row_1} {consignmentList[0].cargosonData.pickups[0].collection_city} - {consignmentList[0].cargosonData.pickups[0].collection_date}
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", }}>
                                    <IconButton onClick={togglePickup}>
                                        {isPickupVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </div>
                            </div>
                        }

                        {isCargosonHeaderVisible ? (
                            <div className='transport-order--block'>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "10px" }}>
                                    <IconButton onClick={toggleCargosonHeader}>
                                        {isCargosonHeaderVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </div>
                                <div className='transport-order-block--content' style={{ backgroundColor: "#f8f8f8" }}>
                                    <GeneralTransportInfo listIndex={0} containerPadding="1% 1% 0 4%" isUserPermitted={isFinnishUserPermitted()} />
                                </div>

                            </div>
                        ) :
                            <div className='transport-order--summary-block' onClick={toggleCargosonHeader}>
                                <h2 className="cargoson-container__address-form--title block-title--width" >
                                    {t("Transpordi info")}
                                </h2>
                                <div style={{ display: "flex", justifyContent: "flex-end", }}>
                                    <IconButton onClick={toggleCargosonHeader}>
                                        {isCargosonHeaderVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </div>
                            </div>
                        }

                        {isConsignmentHeaderVisible ? (
                            <div className='transport-order--block'>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "10px" }}>
                                    <IconButton onClick={toggleConsignmentHeader}>
                                        {isConsignmentHeaderVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </div>
                                <div className='transport-order-block--content'>
                                    <ConsignmentHeaderFinland listIndex={0} className="consignment-header-container--tuusula" isUserPermitted={isFinnishUserPermitted()} />
                                </div>
                            </div>
                        ) :
                            <div className='transport-order--summary-block' onClick={toggleConsignmentHeader}>
                                <h2 className="cargoson-container__address-form--title block-title--width" >
                                    {t("Vedaja info")}
                                </h2>
                                <div style={{ display: "flex", justifyContent: "flex-end", }}>
                                    <IconButton onClick={toggleConsignmentHeader}>
                                        {isConsignmentHeaderVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </div>
                            </div>
                        }
                        <FinlandConsignmentOptions showOptions={true} listIndex={0} initialConsignment={initialConsignment} />
                    </>
                }
            </div>
        </div>
    )
}

export default TuusulaTransportDetailsView