export const ConvertDate = (dateString: string) => {
    if (dateString === null) return dateString;
    if (dateString.includes('T')) {
        return new Date(dateString)
    }
    var newDate = `${dateString.split(".")[2]}-${dateString.split(".")[1]}-${dateString.split(".")[0]}`
    return new Date(newDate)
}

export const ConvertTime = (timeString: any) => {
    if (timeString === null) return timeString;
    if (timeString === undefined) return null;
    var hours = +timeString.split(":")[0]
    var minutes = +timeString.split(":")[1]
    let today = new Date();
    today.setHours(hours, minutes, 0);
    return today;
}

export const ConvertDateToLocaleString = (dateString: string) => {
    if (dateString === null) return;
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('et-EE');
    return formattedDate;
}
