import { Button, IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react'
import { getUserDetails, getUsers, getUsersByName, removeUser } from '../../queries/adminQueries';
import '../../styles/Consignment.css';
import '../../styles/transport-route.css';
import '../../styles/main.css';
import AddUserDialog from './AddUserDialog';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { User } from '../../Types/user/User';
import debounce from 'lodash/debounce';
import DeliveryDriverDialog from './DeliveryDriverDialog';

const Admin = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [userData, setUserData] = useState<User>({ userId: "", fullName: "", role: "", email: "", countryCode: "" } as User);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [isDeliveryDriverDialogOpen, setIsDeliveryDriverDialogOpen] = useState<boolean>(false)

    const handleFetchUsers = () => {
        getUsers().then((response) => {
            setUsers(response.data)
        });
    }
    const handleGetUserData = (userId: string) => {
        getUserDetails(userId).then((res: AxiosResponse<User>) => {
            setUserData(res.data)
            setIsDialogOpen(true)
        }).catch((err) => {
            console.log(err)
        })
    }
    const handleCreateUserButton = () => {
        setUserData({} as User)
        setIsDialogOpen(true)
    }

    const handleRemoveUser = (userId: string) => {
        if (window.confirm("Are you sure?")) {
            removeUser(userId).then((res) => {
                handleFetchUsers()
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const handleSearchUsers = (fullName: string) => {
        getUsersByName(fullName).then((response) => {
            setUsers(response.data)
        });
    }

    const handleDeliveryDriverData = (userId: string) => {
        getUserDetails(userId).then((res: AxiosResponse<User>) => {
            setUserData(res.data)
            setIsDeliveryDriverDialogOpen(true)
        }).catch((err) => {
            console.log(err)
        })
    }

    const debouncedSetSearchTerm = debounce((value: string) => {
        handleSearchUsers(value)
    }, 300);

    useEffect(() => {
        handleFetchUsers()
    }, [])

    return (
        <div style={{ padding: "0 2% 0 2%" }}>
            <div className='consignment-table--container'>
                <div className='title-wrapper'>
                    <div className='container'>
                        <div className='item' style={{ marginBottom: "1%" }}>
                            <h3>Users management</h3>
                        </div>
                    </div>
                    <div className='item' style={{ marginBottom: "1%" }}>
                        <Button sx={{
                            background: "#c00", color: "#fff", "&:hover": {
                                backgroundColor: "#c00"
                            }
                        }} onClick={handleCreateUserButton}>Create user</Button>
                    </div>
                </div>
                <div style={{ width: "15%" }}>
                    <input className='cargoson-container__address-form--input' placeholder='Search by name' type="search" onChange={(e) => debouncedSetSearchTerm(e.target.value)}></input>
                </div>
                <table className="consignment-table--table" >
                    <thead>
                        <tr>
                            <td className='consignment-table--thead'>Name</td>
                            <td className='consignment-table--thead'>Email</td>
                            <td className='consignment-table--thead'>Country</td>
                            <td className='consignment-table--thead'>Role</td>
                            <td className='consignment-table--thead'>Enabled</td>
                            <td className='consignment-table--thead'>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((x, idx) => (
                            <tr key={idx}>
                                <td style={{ borderBottom: "1px solid #00000014", paddingRight: "1rem", paddingLeft: "1rem" }}>{x.fullName}</td>
                                <td style={{ borderBottom: "1px solid #00000014", paddingRight: "1rem", paddingLeft: "1rem" }}>{x.email}</td>
                                <td style={{ borderBottom: "1px solid #00000014", paddingRight: "1rem", paddingLeft: "1rem" }}>{x.countryCode}</td>
                                <td style={{ borderBottom: "1px solid #00000014", paddingRight: "1rem", paddingLeft: "1rem" }}>{x.role}</td>
                                <td style={{ borderBottom: "1px solid #00000014", paddingRight: "1rem", paddingLeft: "1rem" }}>{x.isEnabled ? "Yes" : "No"}</td>
                                <td style={{ borderBottom: "1px solid #00000014", paddingRight: "1rem", paddingLeft: "1rem" }}>
                                    <IconButton onClick={() => handleGetUserData(x.userId)} title="Edit user"><EditIcon /></IconButton>
                                    <IconButton onClick={() => handleDeliveryDriverData(x.userId)} title="Make user delivery driver"><LocalShippingIcon /></IconButton>
                                    <IconButton onClick={() => handleRemoveUser(x.userId)} title="Remove user"><ClearIcon /></IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                    <AddUserDialog open={isDialogOpen} onClose={function (): void {
                        setIsDialogOpen(false);
                        handleFetchUsers();
                    }} user={userData} setUser={setUserData} />

                    <DeliveryDriverDialog open={isDeliveryDriverDialogOpen} onClose={function (): void {
                        setIsDeliveryDriverDialogOpen(false);
                        /*  handleFetchUsers(); */
                    }} user={userData} setUser={setUserData} />
                </div>
            </div>
        </div>
    )
}

export default Admin