import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';

const ReturnToDirecto = (props: { returnId?: string, listIndex: number }) => {
    const { consignmentList } = useDataContext();
    const { t } = useTranslation()
    const directoCode = consignmentList[props.listIndex].directoCode
    const goToDirecto = (directoCode: string) => {
        if (directoCode) {
            window.open(`https://login.directo.ee/ocra_ets_nord/event.asp?KOOD=${directoCode}`, '_blank');
        }
    }

    return (
        <>
            {(directoCode || props.returnId) &&
                <button
                    type="button"
                    className='consignment-options--button'
                    style={{ backgroundColor: "#1522a0", marginTop: "1%" }}
                    onClick={() => goToDirecto(directoCode ?? props.returnId)}
                >
                    {t('Directosse')}
                </button>}
        </>
    );
};

export default ReturnToDirecto