import '../../styles/CargosonComponent.css';
import { useDataContext } from "../../context/DataContext";
import SelectSupplier from '../common/SelectSupplier';

const CargosonHeaderFormNew = (props: { listIndex: number }) => {
  const { updateCargosonDataConsignment, consignmentList, setConsignmentList, supplier, updateConsignmentData, transportRouteData, setTransportRouteData } = useDataContext();
  const cargosonData = consignmentList[props.listIndex].cargosonData;
  const consignmentData = consignmentList[props.listIndex]

  const handleDynamicChangeConsignment = (event: any) => {
    updateConsignmentData(props.listIndex, event.target.name, event.target.value)
  };

  const handleContactChange = (event: any) => {
    setConsignmentList(consignmentList.map((item, idx) => {
      return {
        ...item,
        "carrierContactId": event.target.value,
        "carrierRepresentative": supplier.contacts.find(x => x.id === +event.target.value)?.name ?? "",
        "carrierEmail": supplier.contacts.find(x => x.id === +event.target.value)?.email ?? "",
        "carrierPhone": supplier.contacts.find(x => x.id === +event.target.value)?.phone ?? "",
      }
    }))
  }

  const handleDynamicChange = (event: any) => {
    updateCargosonDataConsignment(props.listIndex, event.target.name, event.target.value)
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputPrice = event.target.value;
    // Use regular expression to check for non-numeric characters (excluding dots and commas)
    if (/^[0-9,.]*$/.test(inputPrice)) {
      var tempArr = consignmentList.map((item, idx) => idx === props.listIndex ? {
        ...item,
        cargosonData: { ...item.cargosonData, [event.target.name]: event.target.value }
      } : item);
      setConsignmentList(tempArr);
    }
  };

  const handleTransportRoutePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputPrice = event.target.value;
    // Use regular expression to check for non-numeric characters (excluding dots and commas)
    if (/^[0-9,.]*$/.test(inputPrice)) {
      setTransportRouteData({ ...transportRouteData, [event.target.name]: event.target.value });
    }
  };

  return (
    <div className='cargoson-header-form-wrapper' >

      <div className='cargoson-header-form-container1'>
        <h2 className="page__heading" style={{ textAlign: "left", marginBottom: "2%" }}>TRANSPORDI INFO</h2>
        <table className="max">
          <tbody className="max">
            {consignmentData.transportRouteId !== 0 ?
              <tr className='cargoson-header--tr'>
                <td className="cargoson-header--td">Veoringi number (viide):</td>
                <td className="cargoson-header--td">
                  <input className="input-addressForm"
                    readOnly={true}
                    name="customer_reference"
                    value={cargosonData.customer_reference || ""}
                    onChange={handleDynamicChange}
                    style={{ background: "inherit", border: "none", fontWeight: "bold", fontSize: "18px" }}
                  ></input>
                </td>
                <td className="cargoson-header--td">Veotellimus:</td>
                <td className="cargoson-header--td">
                  <input className="input-addressForm"
                    readOnly={true}
                    name="customer_reference"
                    value={consignmentData.directoCode || ""}
                    onChange={handleDynamicChange}
                    style={{ background: "inherit", border: "none", fontWeight: "bold", fontSize: "18px" }}
                  ></input>
                </td>
              </tr>
              :
              <tr className='cargoson-header--tr'>
                <td className="cargoson-header--td">Veotellimuse viide:</td>
                <td className="cargoson-header--td">
                  <input className="input-addressForm"
                    readOnly={true}
                    name="customer_reference"
                    value={cargosonData.customer_reference || ""}
                    onChange={handleDynamicChange}
                    style={{ background: "inherit", border: "none", fontWeight: "bold", fontSize: "18px" }}
                  ></input>
                </td>
                <td className="cargoson-header--td"></td>
              </tr>
            }

            {consignmentData.transportRouteId !== 0 ?
              <>
                <tr className='cargoson-header--tr'>
                  <td className="cargoson-header--td">Veoringi maksumus kokku:</td>
                  <td className="cargoson-header--td">
                    <input className="input-addressForm" name="totalPrice" type="number" min="0" onChange={handleTransportRoutePriceChange} value={transportRouteData.totalPrice || ""} onWheel={event => event.currentTarget.blur()} style={{ width: '100%', boxSizing: "border-box" }} placeholder="Hind"></input>
                  </td>
                  <td className="cargoson-header--td" colSpan={2}>
                    <input className="input-addressForm"
                      readOnly={true}
                      name="currency"
                      value={cargosonData.currency || ""}
                      placeholder="EUR"
                      onChange={handleDynamicChange}
                      style={{ background: "inherit", border: "none", fontWeight: "bold", fontSize: "18px" }}
                    ></input>
                  </td>
                </tr>
                <tr className='cargoson-header--tr'>
                  <td className="cargoson-header--td">Veotellimuse maksumus:</td>
                  <td className="cargoson-header--td">
                    <input className="input-addressForm" name="total_price" type="number" min="0" onChange={handlePriceChange} value={cargosonData.total_price || ""} onWheel={event => event.currentTarget.blur()} style={{ width: '100%', boxSizing: "border-box" }} placeholder="Hind"></input>
                  </td>
                  <td className="cargoson-header--td" colSpan={2}>
                    <input className="input-addressForm"
                      readOnly={true}
                      name="currency"
                      value={cargosonData.currency || ""}
                      placeholder="EUR"
                      onChange={handleDynamicChange}
                      style={{ background: "inherit", border: "none", fontWeight: "bold", fontSize: "18px" }}
                    ></input>
                  </td>
                </tr>
              </>
              :
              <tr className='cargoson-header--tr'>
                <td className="cargoson-header--td">Veomaksumus:</td>
                <td className="cargoson-header--td">
                  <input className="input-addressForm" name="total_price" type="number" min="0" onChange={handlePriceChange} value={cargosonData.total_price || ""} onWheel={event => event.currentTarget.blur()} style={{ width: '100%', boxSizing: "border-box" }} placeholder="Hind"></input>
                </td>
                <td className="cargoson-header--td" colSpan={2}>
                  <input className="input-addressForm"
                    readOnly={true}
                    name="currency"
                    value={cargosonData.currency || ""}
                    placeholder="EUR"
                    onChange={handleDynamicChange}
                    style={{ background: "inherit", border: "none", fontWeight: "bold", fontSize: "18px" }}
                  ></input>
                </td>
              </tr>
            }
            <tr className='cargoson-header--tr'>
              <td className="cargoson-header--td">Tarneklausel:</td>
              <td className="cargoson-header--td" style={{ width: "40%" }}>
                <select className="input-addressForm" style={{ width: '100%', boxSizing: "border-box" }} name="incoterm_code" onChange={handleDynamicChange} value={cargosonData.incoterm_code || ""}>
                  <option value="EXW">EXW - Ex Works</option>
                  <option value="FCA">FCA - Free Carrier</option>
                  <option value="CPT">CPT - Carriage Paid to</option>
                  <option value="CIP">CIP - Carriage and Insurance Paid to</option>
                  <option value="DAT">DAT - Delivered at Terminal</option>
                  <option value="DPU">DPU - Delivered at Place Unloaded</option>
                  <option value="DAP">DAP - Delivered at Place</option>
                  <option value="DDP">DDP - Delivery Duty Paid</option>
                  <option value="FAS">FAS - Free Alongside Ship</option>
                  <option value="FOB">FOB - Free on Board</option>
                  <option value="CFR">CFR - Cost and Freight</option>
                  <option value="CIF">CIF - Cost Insurance and Freight</option>
                </select>
              </td>
              <td className="cargoson-header--td" colSpan={2}>
                <input className="input-addressForm" placeholder="Linn" style={{ width: '100%', boxSizing: "border-box" }} name="incoterm_city" value={cargosonData.incoterm_city || ""} onChange={handleDynamicChange} ></input>
              </td>

            </tr>

            <tr className='cargoson-header--tr'>
              <td className="cargoson-header--td">Täiendavad märkused vedajale:</td>
              <td className="cargoson-header--td" colSpan={3} rowSpan={2}>
                <input className="input-addressForm" name="customer_remark" onChange={handleDynamicChange} value={cargosonData.customer_remark || ""} style={{ height: "60px", width: '100%', boxSizing: "border-box" }}></input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='cargoson-header-form-container2'>
        <h2 className="page__heading" style={{ textAlign: "left", marginBottom: "2%" }}>Vedaja info</h2>
        <table className="max">
          <tbody className="max">
            <tr className='consignment-header--tr'>
              <td style={{ width: "10%" }}>Vedaja</td>
              <td style={{ width: "30%" }}>
                <div>
                  <SelectSupplier listIndex={props.listIndex} />
                </div>
              </td>

              <td colSpan={2} style={{ width: "50px" }}></td>

              <td style={{ textAlign: "center" }}>Tüüp</td>
              <td>
                <input className="input-addressForm" style={{ width: "100%", boxSizing: "border-box" }} name="Type" readOnly={true} value={consignmentData.type || ""}></input>
              </td>
            </tr>
            <tr className='consignment-header--tr'>
              <td>Esindaja</td>
              <td>
                <select className="input-addressForm" style={{ width: "100%", boxSizing: "border-box" }} name="carrierContactId" value={consignmentData.carrierContactId || ""} placeholder="Valige vedaja" onChange={handleContactChange}>
                  {(consignmentData.cargosonTransportId !== (0 || null) && supplier.contacts && supplier.contacts.length !== 0) ?
                    (<>
                      <option value=""></option>
                      {supplier.contacts.map((contact, index) => {
                        return (
                          <option key={index} value={contact.id}>{contact.name}</option>
                        )
                      })
                      }
                    </>)
                    :
                    (<>
                      <option value="" disabled hidden >Valige vedaja</option>
                    </>)}
                </select>
              </td>
              <td colSpan={2} style={{ width: "50px" }}></td>

              <td style={{ textAlign: "center" }}>Objekt</td>
              <td>
                <select className="input-addressForm" style={{ width: "100%", }} name="consignmentObject" value={consignmentData.consignmentObject || ""} onChange={handleDynamicChangeConsignment} >
                  <option value="" disabled hidden>Valige tingimus</option>
                  <option value="MEST">Eesti - MEST</option>
                  <option value="MFIN">Tuusula - MFIN</option>
                  <option value="MVAN">Vantaa - MVAN</option>
                  <option value="MSWE">Kumla - MSWE</option>
                  <option value="MMAL">Malmö - MMAL</option>
                  <option value="MUAE">Dubai - MUAE</option>
                  <option value="MSOD">Södertälje - MSOD</option>
                  <option value="MPOS">Post office - MPOS</option>
                </select>
              </td>
              <td colSpan={2} style={{ width: "50px" }}></td>
            </tr>
            <tr className='consignment-header--tr' >
              <td >Email</td>
              <td>
                <input style={{ width: "100%", boxSizing: "border-box" }} className="input-addressForm" name="carrierEmail" value={consignmentData.carrierEmail || ""} placeholder="Valige esindaja" onChange={handleDynamicChangeConsignment}></input>
              </td>
              <td colSpan={2}></td>

              <td style={{ textAlign: "center" }}>Konto</td>
              <td>
                <select className="input-addressForm" style={{ width: "100%", boxSizing: "border-box" }} name="bankAccountCode" value={consignmentData.bankAccountCode || ""} onChange={handleDynamicChangeConsignment} >
                  <option value="" disabled hidden>Valige tingimus</option>
                  <option value="50201">50201</option>
                  <option value="50211">50211</option>
                </select>
              </td>

            </tr>
            <tr className='consignment-header--tr'>
              <td colSpan={1} >Sisekommentaar</td>
              <td colSpan={5} rowSpan={2} style={{ textAlign: 'center' }}>
                <input className="input-addressForm" name="internalComment" onChange={handleDynamicChangeConsignment} value={consignmentData.internalComment || ""} style={{ height: "60px", width: '100%', boxSizing: "border-box" }}></input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default CargosonHeaderFormNew;
