import React from 'react';
import '../../styles/loading.css'
const Loading = () => {
    return (
        <div>
            <div className="loading-spinner-container" >
                <div className='spinner' />
            </div>
        </div>
    )
};
export default Loading;
