import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConvertDate } from '../../../components/common/ConvertDate';
import { handleGetInHouseConsignmentStatus } from '../../../components/tracking-app/Common';
import { GetInHouseConsignmentsCalendarRange } from '../../../queries/deliveryDriverQueries';
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/tracking-app/arrow-left.svg';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../../components/login/user';

const HistoryView = () => {
    const [plannedConsignments, setPlannedConsignments] = useState<Record<number, Record<number, Record<number, InHouseConsignment[]>>>>();
    const navigate = useNavigate();
    const { permissions } = useCustomer();
    const location = useLocation();
    const { id } = useParams<{ id: string }>();
    const queryParams = new URLSearchParams(location.search);
    const calendardate = queryParams.get("calendardate");
    const [calendarDate, setCalendarDate] = useState<Date>(
        calendardate ? ConvertDate(calendardate) : new Date()
    );
    const handleNavigate = (id: number) => {
        navigate("/carrier-driver/delivery-details/" + id)
    }
    const handleGetCalendarConsignments = () => {
        var endDate = calendarDate.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' })
        const startDate = new Date(calendarDate);
        startDate.setDate(calendarDate.getDate() - 14);
        const formattedStartDate = startDate.toLocaleString("et-EE", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        GetInHouseConsignmentsCalendarRange(formattedStartDate, endDate, +id!).then((res: any) => {
            setPlannedConsignments(res.data)
        }).catch((err: any) => {
            setPlannedConsignments([])
        })
    }

    const handleBackButton = () => {
        navigate(-1);
    }

    useEffect(() => {
        handleGetCalendarConsignments()
    }, [calendarDate])

    if (!permissions.includes("PInHouseDriver")) {
        return <Navigate to="/unauthorized" />
    }
    return (
        <div>
            <header className="delivery-driver--header">
                <nav className="flex justify-between">
                    <div
                        className="flex flex-row justify-between items-center"
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                            padding: "1rem",
                        }}>
                        <div className="flex flex-col items-start justify-start" style={{ width: "25%" }}>

                            <div className="flex flex-col justify-start">
                                <IconButton onClick={handleBackButton}>
                                    <ArrowLeftIcon />
                                </IconButton>
                                <label className="header--button-label">
                                    Tagasi
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center justify-center header--title" style={{ width: "50%" }}>Viimase 2 nädala ajalugu</div>
                        <div style={{ width: "25%" }}></div>
                    </div>
                </nav>
            </header>
            <div style={{ overflowX: "auto" }}>
                <table className='consignment-table--table'>
                    <thead>
                        <tr className="consignment-table--tr">
                            <td className="consignment-table--thead">Mahalaadimise kellaaeg</td>
                            <td className="consignment-table--thead">Mahalaadimine</td>
                            <td className="consignment-table--thead">Ettevõte</td>
                            <td className="consignment-table--thead">Projekt</td>
                            <td className="consignment-table--thead">Sihtkoht</td>
                            <td className="consignment-table--thead">Kontakt</td>
                            <td className="consignment-table--thead">Lähetused</td>
                            <td className="consignment-table--thead">Staatus</td>
                        </tr>
                    </thead>

                    <tbody>
                        {(plannedConsignments !== null && plannedConsignments !== undefined) && Object.entries(plannedConsignments!).map(([weekNumber, weekConsignments]) => (
                            <React.Fragment key={weekNumber}>

                                {Object.entries(weekConsignments).map(([weekDay, consignmentGroups]) => (
                                    <React.Fragment key={weekDay}>

                                        {Object.values(consignmentGroups).map((car, idx) =>
                                        (


                                            <React.Fragment key={idx}>
                                                {Object.values(car).map((calenderObj, idx) =>
                                                (

                                                    <React.Fragment key={idx}>
                                                        <tr className="consignment-table--tr" key={idx} onClick={() => handleNavigate(calenderObj.id)} style={{ cursor: "pointer" }}>
                                                            <td className="consignment-table--td">{calenderObj.deliveryTimeFrom} {calenderObj.deliveryTimeTo && `- ${calenderObj.deliveryTimeTo}`}</td>
                                                            <td className="consignment-table--td">{calenderObj.deliveryDate}</td>
                                                            <td className="consignment-table--td">{calenderObj.deliveryCompanyName}</td>
                                                            <td className="consignment-table--td">{calenderObj.deliveryObject}</td>
                                                            <td className="consignment-table--td">{calenderObj.deliveryAddressRow1}</td>
                                                            <td className="consignment-table--td">{calenderObj.deliveryContactName}</td>
                                                            <td className="consignment-table--td" style={{ maxWidth: "250px", wordWrap: "break-word" }}>{calenderObj.directoDeliveryNumbers && calenderObj.directoDeliveryNumbers + ""}</td>
                                                            <td className="consignment-table--td">{handleGetInHouseConsignmentStatus(calenderObj.consignmentStatus)}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                                )}
                                            </React.Fragment>
                                        )
                                        )}
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default HistoryView

