import React, { useEffect, useState } from 'react'
import '../../styles/Consignment.css';
import '../../styles/transport-route.css';
import { Button, IconButton, Tab, Tabs } from '@mui/material';
import { Consignment } from '../../Types/Consignment';
import { useDataContext } from '../../context/DataContext';
import { CargosonChild, CargosonQuery } from '../../Types/cargoson/CargosonQuery';
import { Package } from '../../Types/cargoson/Package';
import DirectoComponent from '../../components/consignment/DirectoComponent';
import ConsignmentOptions from '../../components/consignment/ConsignmentOptions';
import { Navigate, useParams } from 'react-router-dom';
import { useCustomer } from '../../components/login/user';
import { TransportRoute } from '../../Types/TransportRoute';
import { AxiosResponse } from 'axios';
import CreateOrLoadConsignmentTransportRoute from '../../components/transport-route/CreateOrLoadConsignmentTransportRoute';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/system';
import CargosonComponent from '../../components/cargoson/CargosonComponent';
import { RemoveConsignmentFromTransportRoute, GetTransportRouteByDirectoCode, GetTransportRoute, CreateTransportRouteFromPreset } from '../../queries/transport-route-queries';
import Loading from '../../components/common/Loading';

export const StyledTab = styled(Tab, {
    name: "StyledTab",
    slot: "Tab"
})({
    color: "#00000099 !important",
    fontWeight: "bold",
})

export const StyledTabs = styled(Tabs, {
    name: "StyledTabs",
    slot: "Tabs"
})({
    '.MuiTabs-indicator': {
        backgroundColor: '#f50057',
    }
})

const TransportRouteDetails = () => {
    const [value, setValue] = useState(0);
    const { consignmentList, setConsignmentList, setTransportRouteData, transportRouteData, transportRouteId, setTransportRouteId, setIsSearchOpen } = useDataContext();
    const { id, directoCode } = useParams<{ id: string, directoCode: string }>();
    const { logged, permissions } = useCustomer();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleAddConsignment = () => {
        setConsignmentList([...consignmentList, {} as Consignment]);
        setValue(consignmentList.length)
    }

    const removeConsignment = (consignmentId: string, index: number) => {
        if (consignmentId && transportRouteId) {
            if (consignmentList.length === 1) {
                RemoveConsignmentFromTransportRoute(+consignmentId, +transportRouteId)
                setConsignmentList([{ cargosonData: { pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery } as Consignment] as Consignment[])
            }
            else {
                RemoveConsignmentFromTransportRoute(+consignmentId, +transportRouteId)
                setConsignmentList([...consignmentList.filter((temp) => temp.id !== consignmentId)])
            }
        }
        if (consignmentList.length > 1) {
            setConsignmentList([...consignmentList.filter((_, idx) => idx !== index)])
        }
    }

    const handleTransportRouteRequest = () => {
        if (directoCode !== undefined) {
            setIsLoading(true)
            GetTransportRouteByDirectoCode(directoCode).then((response: AxiosResponse<TransportRoute>) => {
                setTransportRouteId(response.data.id.toString())
                setTransportRouteData(response.data)
                setConsignmentList(response.data.consignment)
                setIsSearchOpen(true)
            }).catch((error) => {
                console.error(error)

            }).finally(() => { setIsLoading(false) })
        }
        if (id !== undefined) {
            setIsLoading(true)
            GetTransportRoute(+id).then((response: AxiosResponse<TransportRoute>) => {
                setTransportRouteId(response.data.id.toString())
                setTransportRouteData(response.data)
                setConsignmentList(response.data.consignment)
                setIsSearchOpen(true)
            }).catch((error) => {
                console.error(error)

            }).finally(() => { setIsLoading(false) })
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleCreatePreset = (presetValue: number) => {
        CreateTransportRouteFromPreset(transportRouteData.id, presetValue).then((response: AxiosResponse<TransportRoute>) => {
            setTransportRouteId(response.data.id.toString())
            setTransportRouteData(response.data)
            setConsignmentList(response.data.consignment)
            setIsSearchOpen(true)
        })
    }

    const AddButtonSx = {
        marginLeft: "15px",
        backgroundColor: "#c00",
        color: "#fff",
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: "#c00"
        }
    };
    useEffect(() => {
        handleTransportRouteRequest()
    }, []);

    if (isLoading) {
        return <div><Loading /></div>;
    }
    if (!logged) {
        return <Navigate to="/login" />
    }
    if (!permissions.includes("PEstonianUser")) {
        return <Navigate to="/unauthorized" />
    }

    return (
        <div className="transportRoute__consignmentList--container" >
            <div className='page__heading-title' style={{ display: "flex", justifyContent: "center" }}>{transportRouteData.directoCode ? `Veoring ${transportRouteData.directoCode}` : "Veoringi loomine"}</div>
            {
                consignmentList.length > 0 ?
                    <div style={{ flexGrow: "1" }}>
                        <div className='flex justify-start'>
                            <StyledTabs value={value} onChange={handleChange} aria-label="page tabs" sx={{ paddingLeft: "1%" }} >
                                {consignmentList?.map((t, index) => {
                                    return (
                                        <StyledTab key={index} label={`Veotellimus ${t?.directoCode ? t?.directoCode : index + 1}`}></StyledTab>
                                    )
                                })}
                            </StyledTabs>
                            <Button variant="contained" sx={AddButtonSx} onClick={handleAddConsignment}>+ Lisa </Button>
                        </div>

                        {consignmentList?.map((t, index) => {
                            return (
                                <div key={index} className="page__center-row-flexbox" style={{ width: "100%", borderBottom: "1px solid rgb(0 0 0 / 8%)" }}>
                                    <div className="page__center-column-flexbox">
                                        {value === index && (
                                            <>
                                                <div className="page__container-outer-wrapper">
                                                    <div className="page__container-wrapper--flexbox">
                                                        <div className="page__center-column-flexbox">
                                                            <div className='page__heading'>
                                                                Veotellimus {t?.directoCode ? t?.directoCode : index + 1}
                                                                <IconButton onClick={() => removeConsignment(t.id, index)}>
                                                                    <DeleteOutlineIcon style={{ color: "#c00" }} />
                                                                </IconButton>
                                                            </div>
                                                            <div className='page-wrapper'>
                                                                {!t.id &&
                                                                    <div style={{ width: "100%" }}>
                                                                        <CreateOrLoadConsignmentTransportRoute listIndex={index} />
                                                                    </div>}
                                                                <DirectoComponent listIndex={index} />
                                                                <CargosonComponent listIndex={index} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                        <ConsignmentOptions listIndex={0} />
                    </div>
                    :
                    <>
                        <CreateOrLoadConsignmentTransportRoute listIndex={0} />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "50px", gap: "25px" }}>
                            <div className='page__heading'>
                                Vali eeltäidetud koondtellimus
                            </div>
                            <Button variant="contained" className='create-route-preset--button' style={{ background: "#c00" }} onClick={() => handleCreatePreset(1)}>
                                Raintar - Ets Nord Tallinn - Ets Nord Tuusula
                            </Button>

                            <Button variant="contained" className='create-route-preset--button' style={{ background: "#c00" }} onClick={() => handleCreatePreset(2)}>
                                Favor - Ets Nord Tallinn - Ets Nord Vantaa
                            </Button>
                            <Button variant="contained" className='create-route-preset--button' style={{ background: "#c00" }} onClick={() => handleCreatePreset(3)}>
                                Raintar - Ets Nord Tallinn - Ets Nord Kumla
                            </Button>
                            <Button variant="contained" className='create-route-preset--button' style={{ background: "#c00" }} onClick={() => handleCreatePreset(4)}>
                                Ets Nord Tallinn - Ets Nord Tuusula - Ets Nord Vantaa
                            </Button>
                        </div>
                    </>
            }
        </div >

    )
}

export default TransportRouteDetails