import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';


const CarrierDriver = () => {
    const { consignmentList, updateConsignmentData } = useDataContext();
    const { t } = useTranslation()
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const handleDynamicChange = (event: any) => {
        updateConsignmentData(0, event.target.name, event.target.value)
    };

    return (
        <div className='quick-menu-form--wrapper'>
            <div style={{ marginBottom: "10px" }
            } >
                {t("Vedaja")}: {consignmentList[0].carrierName || ""}
            </div >
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", alignItems: "center" }}>
                    <div style={{ width: "30%" }}>{t("Autojuht")}: </div>
                    <input className='input-addressForm' style={{ height: "42px", width: "70%" }} type="text" placeholder={t("Nimi")} name="carrierDriver" value={consignmentList[0].carrierDriver || ""} onChange={handleDynamicChange} />
                </div>
            </form>
        </div >
    )
}

export default CarrierDriver