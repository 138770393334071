import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useDataContext } from '../../context/DataContext';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';
import { GetTransportRouteEmailPreview, GetEmailMessagePreview, GetTuusulaEmailMessagePreview, SendTransportRouteEmail, SendEmailToCarrier, SendTuusulaTransportEmailToCarrier } from '../../queries/email-queries';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

type Props = { title: string };

const EmailPreviewModal = ({ title }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [bodyText, setBodyText] = useState<string>('');
    const [isAddingRecipient, setIsAddingRecipient] = useState(false);
    const [newRecipient, setNewRecipient] = useState<string>('');
    const { consignmentList, setIsLoading, transportRouteData } = useDataContext();
    const [additionalRecipients, setAdditionalRecipients] = useState<string[]>([]);
    const { t } = useTranslation();
    var transportRouteDirectoCode = transportRouteData.directoCode;

    const handleSplitEmail = () => {
        const carrierEmail = consignmentList[0].carrierEmail
        if (carrierEmail.split(',').length > 1) {
            const splitArr = carrierEmail.split(',')
            const email1 = splitArr[0];
            const email2 = splitArr[1];
            setAdditionalRecipients([email1, email2])
            return;
        }
        if (carrierEmail.split(';').length > 1) {
            const splitArr = carrierEmail.split(';')
            const email1 = splitArr[0];
            const email2 = splitArr[1];

            setAdditionalRecipients([email1, email2])
            return;
        }
        else {
            setAdditionalRecipients([carrierEmail])
            return;
        }
    }

    const handleOpen = () => {
        const carrierEmail = consignmentList[0].carrierEmail
        if (carrierEmail === undefined || carrierEmail === null || carrierEmail === "") return alert("Valige vedaja");

        handleSplitEmail()

        if (window.location.pathname.startsWith("/transport-route")) {
            if (transportRouteData.totalPrice === null || transportRouteData.totalPrice.toString() === "") {
                transportRouteData.totalPrice = 0;
            }
            GetTransportRouteEmailPreview(consignmentList, transportRouteDirectoCode, transportRouteData.emailTemplateValue, transportRouteData.totalPrice).then((res) => {
                setBodyText(res.data)
            }).catch(err => console.log(err))
        }
        if (window.location.pathname.startsWith("/consignment")) {
            GetEmailMessagePreview(consignmentList).then((res) => {
                setBodyText(res.data)
            }).catch(err => console.log(err))
            setIsOpen(true);
        }
        if (window.location.pathname.startsWith("/tuusula")) {
            GetTuusulaEmailMessagePreview(consignmentList).then((res) => {
                setBodyText(res.data)
            }).catch(err => console.log(err))
            setIsOpen(true);
        }
        setIsOpen(true);

    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSendEmail = () => {
        setIsLoading(true);
        if (window.location.pathname.startsWith("/transport-route")) {
            if (transportRouteData.totalPrice === null || transportRouteData.totalPrice.toString() === "") {
                transportRouteData.totalPrice = 0;
            }
            const requestData = {
                consignmentList: consignmentList,
                recipientList: additionalRecipients,
                directoCode: transportRouteDirectoCode,
                emailTemplateValue: transportRouteData.emailTemplateValue,
                totalPrice: transportRouteData.totalPrice
            }

            SendTransportRouteEmail(requestData).then(() => { console.log("success") }).catch(err => console.log(err)).finally(() => setIsLoading(false))
        }
        if (window.location.pathname.startsWith("/consignment")) {
            const requestData = {
                consignmentList: consignmentList,
                recipientList: additionalRecipients
            }
            SendEmailToCarrier(requestData).then(() => { console.log("success") }).catch(err => console.log(err)).finally(() => setIsLoading(false))
        }
        if (window.location.pathname.startsWith("/tuusula")) {
            SendTuusulaTransportEmailToCarrier(consignmentList).then(() => { console.log("success") }).catch(err => console.log(err)).finally(() => setIsLoading(false))
        }
        else {
            setIsLoading(false)
        }
        setIsOpen(false);
    }

    const isEmailSendingAvailable = (): boolean | undefined => {
        if (window.location.pathname.startsWith("/transport-route")) {
            return transportRouteDirectoCode !== null;
        }
        if (window.location.pathname.startsWith("/consignment")) {
            return consignmentList[0].directoCode !== null;
        }
        if (window.location.pathname.startsWith("/tuusula")) {
            //return consignmentList[0].directoCode !== null;
            return true;
        }
        return false;
    }

    const handleAddRecipient = () => {
        setIsAddingRecipient(true);
    };

    const handleRecipientChange = (event: any) => {
        setNewRecipient(event.target.value);
    };

    const handleConfirmRecipient = () => {
        if (newRecipient) {
            setAdditionalRecipients([...additionalRecipients, newRecipient]);
            setNewRecipient('');
            setIsAddingRecipient(false);
        }
    };

    const removeExtraRecipient = (emailToRemove: string) => {
        const updatedRecipients = additionalRecipients.filter(email => email !== emailToRemove);
        setAdditionalRecipients(updatedRecipients);
    }

    return (
        <>
            {isEmailSendingAvailable() &&
                <>
                    <div style={{ marginTop: "1%" }} onClick={handleOpen}>
                        <IconButton><EmailIcon /></IconButton>
                    </div>

                    <Modal
                        open={isOpen}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white',
                            width: "600px",
                            whiteSpace: "pre-line",
                            overflowY: "auto",
                            maxHeight: "90vh"
                        }}>
                            <Typography sx={{ marginTop: "10px", paddingLeft: "25px", fontSize: "24px" }} id="modal-modal-title" variant="h6" component="h2">
                                {title}
                            </Typography>
                            <Typography sx={{ display: "flex", justifyContent: "flex-start", alignItems: "baseline", gap: "10px", marginTop: "10px", paddingLeft: "25px", fontSize: "24px" }} id="modal-modal-title" variant="h6" component="h2">
                                {t("Kellele")}:
                                {!isAddingRecipient && (
                                    <div style={{ height: "100%" }}>
                                        <IconButton onClick={handleAddRecipient} >
                                            <PersonAddIcon />
                                        </IconButton>
                                    </div>
                                )}
                                <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
                                    {additionalRecipients.map((x, idx) => (
                                        <div style={{ fontSize: "18px", padding: "1px", background: "#f8f8f8", margin: "2px" }} key={idx}>{x}

                                            <IconButton onClick={() => removeExtraRecipient(x)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
                            </Typography>
                            <Typography sx={{ marginTop: "10px", paddingLeft: "25px", fontSize: "24px" }}>
                            </Typography>
                            {isAddingRecipient && (
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "50%", paddingLeft: "4%", paddingTop: "1%" }}>
                                    <input
                                        type="text"
                                        placeholder={t("Lisa veel emaili saajaid")}
                                        value={newRecipient}
                                        onChange={handleRecipientChange}
                                    />
                                    <IconButton onClick={handleConfirmRecipient}>
                                        <PersonAddIcon />
                                    </IconButton>
                                </div>
                            )}

                            <Typography sx={{
                                height: "80%",
                                padding: '30px',
                                color: 'gray',
                                textAlign: 'left',
                                fontSize: '18px'
                            }} id="modal-modal-description">
                                {bodyText}
                            </Typography>

                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "20px",
                                gap: "50px",
                                borderTop: "1px solid gray"
                            }}>
                                <Button variant="contained" sx={{ background: "#c00", color: "white", "&:hover": { background: "#c00" } }} onClick={handleSendEmail}>{t("Saada email")}</Button>
                                <Button variant="contained" sx={{ background: "#c00", color: "white", "&:hover": { background: "#c00" } }} onClick={handleClose}>{t("Tühista")}</Button>
                            </Box>
                        </Box>
                    </Modal>

                </>
            }
        </>
    );
}
export default EmailPreviewModal;
