import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import DateTimeCalendar from '../common/DateTimeCalendar';
import { useCustomer } from '../login/user';

type Props = {
    deliveryDate: Date,
    setDeliveryDate: React.Dispatch<React.SetStateAction<Date>>,
    setTimeFrom: React.Dispatch<React.SetStateAction<Date | null>>,
    setTimeTo: React.Dispatch<React.SetStateAction<Date | null>>,
    timeFrom: Date | null,
    timeTo: Date | null,
    deliveryTimePresetValue: string,
    setDeliveryTimePresetValue: React.Dispatch<React.SetStateAction<string>>
}
const DropdownTime = ({ deliveryDate, setDeliveryDate, setTimeFrom, timeFrom, setTimeTo, timeTo, deliveryTimePresetValue, setDeliveryTimePresetValue }: Props) => {
    const { consignmentList, updateCargosonDataConsignment, setConsignmentList } = useDataContext();
    const cargosonData = consignmentList[0].cargosonData;
    const listIndex = 0;
    const { t } = useTranslation()
    const { isFinnishUserPermitted } = useCustomer();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const handleDeliveryDate = (date: Date) => {
        setDeliveryDate(date)
        updateCargosonDataConsignment(0, "delivery_date", date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' }))
    };

    const handleDynamicChange = (event: any) => {
        updateCargosonDataConsignment(0, event.target.name, event.target.value)
    };

    const handleTimeChangePreset = (presetName: string) => {
        if (presetName === "Day") {
            const fromTime = new Date();
            fromTime.setHours(8, 0, 0, 0);
            const toTime = new Date();
            toTime.setHours(15, 0, 0, 0);
            setTimeFrom(fromTime);
            setTimeTo(toTime);
            setDeliveryTimePresetValue("Day")
            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: { ...item.cargosonData, delivery_time_from: fromTime?.toLocaleTimeString("et-EE"), delivery_time_to: toTime?.toLocaleTimeString("et-EE") }
            } : item);
            setConsignmentList(tempArr);
        }

        if (presetName === "7:00") {
            const fromTime = new Date();
            fromTime.setHours(7, 0, 0, 0);

            setTimeFrom(fromTime);
            setDeliveryTimePresetValue("7:00")
            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: { ...item.cargosonData, delivery_time_from: fromTime?.toLocaleTimeString("et-EE") }
            } : item);
            setConsignmentList(tempArr);
        }

        if (presetName === "7:30") {
            const fromTime = new Date();
            fromTime.setHours(7, 30, 0, 0);

            setTimeFrom(fromTime);
            setDeliveryTimePresetValue("7:30")
            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: { ...item.cargosonData, delivery_time_from: fromTime?.toLocaleTimeString("et-EE") }
            } : item);
            setConsignmentList(tempArr);
        }
        if (presetName === "8:00") {
            const fromTime = new Date();
            fromTime.setHours(8, 0, 0, 0);

            setTimeFrom(fromTime);
            setDeliveryTimePresetValue("8:00")
            const tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                ...item,
                cargosonData: { ...item.cargosonData, delivery_time_from: fromTime?.toLocaleTimeString("et-EE") }
            } : item);
            setConsignmentList(tempArr);
        }
    };

    const handleTimeFrom = (date: Date) => {
        setTimeFrom(date)
        updateCargosonDataConsignment(listIndex, "delivery_time_from", date?.toLocaleTimeString("et-EE"))
    }

    return (
        <div className='quick-menu-form--wrapper'>
            <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                <label>{t("Mahalaadimine")}</label>
            </div>
            <form onSubmit={handleSubmit} style={{ width: "100%", display: "flex", flexDirection: "column", rowGap: "5px" }}>
                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <div style={{ width: "30%" }}>{t("Aeg")}</div>
                    <div style={{ width: "70%" }}>
                        <select disabled={!isFinnishUserPermitted()} className="cargoson-container__address-form--input" placeholder={t("Eeltäidetud kellaajad")} value={deliveryTimePresetValue ?? ""} onChange={(e: any) => handleTimeChangePreset(e.target.value)}>
                            <option value="" disabled hidden>{t("Eeltäidetud kellaajad")}</option>
                            <option value="Day">{t("Terve päev")}</option>
                            <option value="7:00">7:00</option>
                            <option value="7:30">7:30</option>
                            <option value="8:00">8:00</option>
                        </select>
                    </div>
                </div>
                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <div style={{ width: "30%" }}> </div>
                    <div style={{ width: "70%" }}>
                        <DateTimeCalendar handleChange={handleTimeFrom} currentDate={timeFrom} showTimeSelectOnly={true} placeHolder={t("Alates")} isUserPermitted={isFinnishUserPermitted()} />
                    </div>

                </div>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", alignItems: "center", width: "100%" }}>
                    <div style={{ width: "30%" }}>{t("Kuupäev")}: </div>
                    <div style={{ width: "70%" }}>
                        <DateTimeCalendar handleChange={handleDeliveryDate} currentDate={deliveryDate} isUserPermitted={isFinnishUserPermitted()} />
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", alignItems: "center" }}>
                    <div style={{ width: "30%" }}>{t("Sihtkoht")}: </div>
                    <input className='input-addressForm' readOnly={!isFinnishUserPermitted()} style={{ height: "42px", width: "70%" }} type="text" placeholder={t("Linn")} name="delivery_city" value={cargosonData.delivery_city || ""} onChange={handleDynamicChange} />
                </div>
            </form >
        </div >
    )
}

export default DropdownTime