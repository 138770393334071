import React from 'react'
import EastIcon from '@mui/icons-material/East';
import { IconButton } from '@mui/material';
import debounce from 'lodash/debounce';

type Props = {
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
    searchTerm: string,
    handleSearch: (searchTermTemp: string) => void
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}
const FilterConsignments = ({ setSearchTerm, handleSearch, setCurrentPage, searchTerm }: Props) => {

    const handleSearchConsignment = (e: any, searchTerm: string) => {
        e.preventDefault()
        setCurrentPage(1);
        handleSearch(searchTerm)
    }

    const debouncedSetSearchTerm = debounce((e: any) => {
        setCurrentPage(1);
        setSearchTerm(e.target.value);
        handleSearch(e.target.value)
    }, 300);

    return (
        <form onSubmit={(e: any) => handleSearchConsignment(e, searchTerm)} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
            <input className="input-addressForm" style={{ marginTop: "1%", height: "40px", width: "unset", boxSizing: "unset" }} placeholder="Otsi veotellimust" onChange={e => debouncedSetSearchTerm(e)}></input>
            <IconButton onClick={(e: any) => handleSearchConsignment(e, searchTerm)}>
                <EastIcon style={{ color: "gray" }} />
            </IconButton>
        </form>
    )
}

export default FilterConsignments