import { useDataContext } from '../../context/DataContext';

const ReturnToDirectoTransportRoute = (props: { returnId: string }) => {
    const { transportRouteData } = useDataContext();
    const directoCode = transportRouteData.directoCode

    const goToDirecto = (directoCode: string) => {
        if (directoCode) {
            window.open(`https://login.directo.ee/ocra_ets_nord/event.asp?KOOD=${directoCode}`, '_blank');
        }
    }

    return (
        <>
            {(directoCode || props.returnId) &&
                <button
                    type="button"
                    className='consignment-options--button'
                    style={{ /* backgroundColor: "#1522a0",  */marginTop: "1%" }}
                    onClick={() => goToDirecto(directoCode ?? props.returnId)}
                >
                    Directosse
                </button>}
        </>
    );
};

export default ReturnToDirectoTransportRoute