import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { SingleValue, ActionMeta, StylesConfig } from 'react-select';
import { GetCarrierByCode, GetSuppliersByName, LoadConsignmentRequest } from '../../queries/queries';
import Select from 'react-select'
import { Supplier } from '../../Types/Supplier';
import { useDataContext } from '../../context/DataContext';
import { SupplierSelect } from '../../Types/SupplierSelect';
import { Consignment } from '../../Types/Consignment';

type Props = {
    listIndex: number;
    isUserPermitted?: boolean;
}
const SelectSupplier = ({ listIndex, isUserPermitted = true }: Props) => {
    const [inputValue, setInputValue] = useState("");
    const { setSupplier, updateSupplierInfo, removeSupplierInfo, supplier, consignmentList, selectedSupplier, setSelectedSupplier, consignmentId, setConsignmentList } = useDataContext()
    const [supplierOptions, setSupplierOptions] = useState<SupplierSelect[]>([])
    const [isLoading, setLoading] = useState(false);
    const [timer, setTimer] = useState<null | ReturnType<typeof setTimeout>>(null);
    const [isLoadingData, setIsLoadingData] = useState(true);

    const filterSuppliers = (inputValue: string) => {
        setLoading(true)
        GetSuppliersByName(inputValue).then((response: AxiosResponse<Supplier[]>) => {
            const data = response.data.map(x => {
                return { label: x.label, value: x } as SupplierSelect
            });
            setSupplierOptions(data)
            setLoading(false)
        })
    };

    const handleInputChange = (newValue: string) => {
        const parsedValue = newValue.replace(/\W/g, '');
        setInputValue(parsedValue);
        if (inputValue === parsedValue) return;

        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                filterSuppliers(parsedValue)
            }, 1000)
        );
    };


    const handleChange: (option: SingleValue<SupplierSelect>, action: ActionMeta<SupplierSelect>) => void = (option, { action }) => {
        if (option) {
            setSelectedSupplier({ label: option?.label!, value: option?.value! })
            setSupplier(option?.value!)
        } else {
            setSelectedSupplier({} as SupplierSelect);

        }
        if (action === "clear") {
            removeSupplierInfo()
            return;
        }
        updateSupplierInfo(option?.value!)
    }

    const handleGetCarrierByCode = () => {

        const carrierCode = consignmentList[listIndex].carrierCode

        if (carrierCode === null) return;
        if (carrierCode === undefined) {
            console.log(isLoading)
            return;
        }
        GetCarrierByCode(carrierCode).then((response: AxiosResponse<Supplier>) => {
            const supplier = response.data
            setSelectedSupplier({ label: `${supplier.name} - ${supplier.serviceName}`, value: supplier })
            setSupplier(supplier);
        })
    }

    useEffect(() => {

        if (isLoadingData) {
            // make the API call to fetch the selected supplier
            handleGetCarrierByCode()
            setIsLoadingData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supplier, isLoadingData])

    const customStyles: StylesConfig<SupplierSelect, false> = {
        option: (styles, { data }) => {
            return {
                ...styles,
                color: data.value.serviceId ? 'green' : 'red'
            };
        },
    };
    return (
        <Select
            onFocus={() => filterSuppliers("")}
            isDisabled={!isUserPermitted}
            onChange={handleChange}
            isLoading={isLoading}
            options={supplierOptions}
            value={selectedSupplier.label === undefined ? { label: "Valige vedaja", value: {} as Supplier } : selectedSupplier}
            onInputChange={handleInputChange}
            isClearable
            styles={customStyles}
            theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                    ...theme.colors,
                    text: '#6c757d',
                    neutral80: "#6c757d",
                    primary: '#d7d7d7',
                    neutral20: "#dee2e6",
                },
            })}
        />
    )
}
export default SelectSupplier


