import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CompleteDelivery, GetDeliveryDriverConsignmnetsWithDate, StartDelivery, StartMultipleDeliveries, UpdateInHouseConsignmentStatus } from '../../../queries/deliveryDriverQueries';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import '../../../styles/tracking-app/delivery-driver.css';
import '../../../styles/tracking-app/buttons.css';
import '../../../styles/main.css';
import '../../../styles/tracking-app/delivery-order.css';
import React from 'react';
import DateTimeCalendar from '../../../components/common/DateTimeCalendar';
import { DeliveryDriver } from '../../../Types/delivery-app/DeliveryDriver';
import { InHouseTransportRoute } from '../../../Types/delivery-app/InHouseTransportRoute';
import { ConvertDate } from '../../../components/common/ConvertDate';
import { HandleGetInHouseConsignmentStatus } from '../../../components/tracking-app/driver/DeliveryStatusBadge';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrow';
import CreateIcon from '@mui/icons-material/Create';
import DeliveryOrderNew from '../../../components/tracking-app/driver/DeliveryOrderNew';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { ReactComponent as MenuIcon } from '../../../assets/tracking-app/menu-icon.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/tracking-app/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/tracking-app/arrow-right.svg';
import { ReactComponent as PlusIcon } from '../../../assets/tracking-app/plus-icon.svg';
import { ReactComponent as StartIcon } from '../../../assets/tracking-app/start-icon.svg';
import CompletedIcon from '@mui/icons-material/Done';
import TransportRouteMenu from '../../../components/tracking-app/driver/TransportRouteMenu';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../../components/login/user';

const DriverCalendarView = () => {
  const location = useLocation();
  const { permissions } = useCustomer();
  const [deliveryDriver, setDeliveryDriver] = useState<DeliveryDriver>({} as DeliveryDriver);
  const [endDeliveryOpen, setEndDeliveryOpen] = useState<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  const calendardate = queryParams.get('calendardate');
  const [calendarDate, setCalendarDate] = useState<Date>(calendardate ? ConvertDate(calendardate) : new Date());
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [activeDelivery, setActiveDelivery] = useState<InHouseConsignment>({} as InHouseConsignment);
  const [inHouseTransportRoute, setInHouseTransportRoute] = useState<InHouseTransportRoute>({} as InHouseTransportRoute);
  const [addToTransportRoute, setAddToTransportRoute] = useState<boolean>(false);
  const [deliveriesToAdd, setDeliveriesToAdd] = useState<InHouseConsignment[]>([]);
  const formattedDate = calendarDate.toLocaleString('et-EE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });

  const handleGetDeliveryDriver = () => {
    if (id === undefined) return;
    var date = calendarDate.toLocaleString('et-EE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    GetDeliveryDriverConsignmnetsWithDate(+id, date)
      .then((res) => {
        setDeliveryDriver(() => res.data);
      })
      .catch((err) => {
        console.error();
      });
  };

  const handleNavigate = (id: number) => {
    navigate('/carrier-driver/delivery-details/' + id);
  };

  const handleNavigateToTransportRoute = (deliveryDriverId: number) => {
    navigate(`/carrier-driver/${deliveryDriverId}/transport-route?calendardate=${formattedDate}`);
  };

  const handleStatusChange = (consignmentId: number, status: ConsignmentStatus) => {
    if (status === ConsignmentStatus.InTransit && id !== undefined) {
      StartDelivery(consignmentId, +id).then((response: any) => {
        setEndDeliveryOpen(false);
        setActiveDelivery(response.data);
        navigate('/carrier-driver/delivery-details/' + consignmentId);
      });
    }
    if (status === ConsignmentStatus.Finished && id !== undefined) {
      CompleteDelivery(consignmentId);
    }
    UpdateInHouseConsignmentStatus(consignmentId, status)
      .then((response: any) => {
        handleGetDeliveryDriver();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeliveryDate = (date: Date) => {
    setCalendarDate(date);

    const formattedDate = date.toLocaleString('et-EE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });

    navigate(`/carrier-driver/${id}?calendardate=${formattedDate}`);
  };

  const handleCreateTransportRoute = () => {
    setAddToTransportRoute(true);
    setInHouseTransportRoute((prevRoute) => ({
      ...prevRoute,
      consignments: deliveriesToAdd
    }));
  };

  const handleUpdateArray = (e: any, deliveryToAdd: InHouseConsignment) => {
    e.stopPropagation();
    if (e.target.checked === true) {
      setDeliveriesToAdd([...deliveriesToAdd, deliveryToAdd]);
    }
    if (e.target.checked === false) {
      const newArr = deliveriesToAdd.filter((t) => t !== deliveryToAdd);
      setDeliveriesToAdd(newArr);
    }
  };

  const handleStartMultipleDeliveries = async () => {
    if (!id) return;
    setAddToTransportRoute(false);

    const multipleDeliveries = {
      deliveryDriverId: +id,
      consignmentIds: deliveriesToAdd.map((x) => x.id)
    };
    await StartMultipleDeliveries(multipleDeliveries);
    navigate(`/carrier-driver/${id}/transport-route?calendardate=${formattedDate}`);
  };

  const handleChangeCalendarDate = (direction: 'prev' | 'next') => {
    // Create a new Date object based on the current state
    const newDate = new Date(calendarDate);
    const tempDate = new Date(calendarDate);
    const todayDate = new Date();
    // Adjust the date based on the direction
    if (direction === 'prev') {
      newDate.setDate(newDate.getDate() - 1);
    } else if (direction === 'next') {
      newDate.setDate(newDate.getDate() + 1);
    }

    // Update the calendarDate state
    setCalendarDate(newDate);

    // Format the new date for the URL
    const formattedDate = newDate.toLocaleString('et-EE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    const formattedTodayDate = todayDate.toLocaleString('et-EE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });

    // Navigate to the updated URL only with the newDate is today
    if (direction === 'prev' && tempDate.getUTCDate() === todayDate.getUTCDate()) {
      navigate(`/carrier-driver/${id}/history?calendardate=${formattedTodayDate}`);
      return;
    } else {
      navigate(`/carrier-driver/${id}?calendardate=${formattedDate}`);
      return;
    }
  };

  const handleDropOffDelivery = (deliveryData: InHouseConsignment) => {
    if (deliveryData.type === "Export") {
      navigate(`/carrier-driver/end-delivery/${deliveryData.id}`)
      return;
    }
    if (deliveryData.type === "Import") {
      navigate(`/carrier-driver/delivery-details/${deliveryData.id}`);
    }
  }

  useEffect(() => {
    handleGetDeliveryDriver();
  }, [calendarDate]);

  if (!permissions.includes("PInHouseDriver")) {
    return <Navigate to="/unauthorized" />
  }

  return (
    <div className="flex flex-col items-center justify-center" >
      <header className="delivery-driver--header">
        <nav className="flex justify-between">
          <div
            className="flex flex-row justify-between items-center"
            style={{
              width: '100%',
              justifyContent: 'space-between',
              padding: '2rem',
              paddingLeft: "3rem",
              paddingRight: "3rem"
            }}
          >
            {!addToTransportRoute && deliveryDriver.consignmentsLoaded?.length === 0 && (
              <div className="flex flex-col justify-start items-center">
                <IconButton onClick={handleCreateTransportRoute}>
                  <PlusIcon />
                </IconButton>
                <label className="header--button-label">Loo veoring</label>
              </div>
            )}
            {addToTransportRoute && deliveriesToAdd?.length > 0 && (
              <div className="flex flex-col items-center">
                <div className="flex flex-col justify-start">
                  <IconButton onClick={handleStartMultipleDeliveries}>
                    <StartIcon />
                  </IconButton>
                  <label className="header--button-label">Alusta korraga</label>
                </div>
              </div>
            )}
            {addToTransportRoute && deliveriesToAdd?.length === 0 && (
              <div className="flex flex-col justify-start">
                <IconButton onClick={() => setAddToTransportRoute(false)}>
                  <NotInterestedIcon fontSize="large" htmlColor="white" />
                </IconButton>
                <label className="header--button-label" style={{ marginLeft: '5px' }}>
                  Tühista loomine
                </label>
              </div>
            )}

            {deliveryDriver.consignmentsLoaded?.length > 0 && (
              <div className="flex flex-col justify-start items-center">
                <IconButton onClick={() => navigate(`/carrier-driver/${id}/transport-route?calendardate=${formattedDate}`)}>
                  <PlusIcon />
                </IconButton>
                <label className="header--button-label">Veoring</label>
              </div>
            )}
            <div className="flex flex-row justify-center items-center gap-m">
              <IconButton onClick={() => handleChangeCalendarDate('prev')}>
                <ArrowLeftIcon />
              </IconButton>
              <DateTimeCalendar handleChange={handleDeliveryDate} currentDate={calendarDate} />
              {/* <div className="header--title">Tänased veod</div> */}

              <IconButton onClick={() => handleChangeCalendarDate('next')}>
                <ArrowRightIcon />
              </IconButton>
            </div>
            <div className="flex flex-col justify-start">
              <IconButton onClick={() => navigate(`/carrier-driver/all-drivers?calendardate=${formattedDate}`)}>
                <MenuIcon />
              </IconButton>
              <label className="header--button-label">Kõik veod</label>
            </div>
          </div>
        </nav>
      </header>
      <div style={{ maxWidth: '1000px', width: '100%' }}>
        {deliveryDriver?.consignmentsLoaded && deliveryDriver?.consignmentsLoaded?.length > 0 && deliveryDriver?.consignmentsLoaded[0].deliveryDate === formattedDate && (
          <div className="m-top-neg-75 m-bot-75">
            <TransportRouteMenu deliveryDriver={deliveryDriver} handleNavigate={handleNavigateToTransportRoute} />
          </div>
        )}

        <ul className="m-top-neg-75">
          {deliveryDriver?.consignments && deliveryDriver?.consignments.length > 0 ? (
            deliveryDriver?.consignments.map((x, _) => (
              <React.Fragment key={x.id}>
                {deliveryDriver.consignmentsLoaded.find((y) => y.id === x.id) === undefined && x.consignmentStatus !== ConsignmentStatus.Finished && (
                  <li className="flex items-center justify-end">
                    {addToTransportRoute && x.consignmentStatus !== ConsignmentStatus.InTransit && (
                      <div className="flex flex-col items-center justify-center" style={{ marginBottom: '4rem' }}>
                        <input
                          type="checkbox"
                          style={{
                            width: '30px',
                            height: '30px',
                            cursor: 'pointer'
                          }}
                          checked={deliveriesToAdd.includes(x)}
                          onChange={(e: any) => handleUpdateArray(e, x)}
                        />
                      </div>
                    )}

                    <DeliveryOrderNew deliveryData={x} handleNavigate={handleNavigate} />
                    <div className="flex flex-col items-start gap-xs" style={{ padding: '1rem', paddingLeft: '0', paddingRight: '0', background: '#cbcbcb', width: '150px', marginTop: '-5rem' }}>
                      <HandleGetInHouseConsignmentStatus statusNumber={x.consignmentStatus} />
                      {x.consignmentStatus === ConsignmentStatus.Pending && (
                        <div className="flex flex-col items-center m-auto">
                          <div className="flex flex-col gap-xs justify-center">
                            <IconButton
                              onClick={() => {
                                handleStatusChange(x.id, ConsignmentStatus.InTransit);
                              }}
                              style={{ background: '#fff', borderRadius: '10px' }}
                            >
                              <PlayArrowOutlinedIcon fontSize="large" htmlColor="gray" />
                            </IconButton>
                            <label className="delivery-calendar-view--menu-button-text">Alusta</label>
                          </div>
                        </div>
                      )}

                      {x.consignmentStatus === ConsignmentStatus.InTransit && (
                        <div className="flex flex-col items-center m-auto">
                          <div className="flex flex-col gap-xs justify-center">
                            <IconButton
                              style={{ background: '#fff', borderRadius: '10px' }}
                              onClick={() => {
                                handleStatusChange(x.id, ConsignmentStatus.WaitingClient);
                                navigate(`/carrier-driver/delivery-details/${x.id}`);
                              }}
                            >
                              <LocationOnIcon fontSize="large" htmlColor="gray" />
                            </IconButton>
                            <label className="delivery-calendar-view--menu-button-text">Olen kohal</label>
                          </div>
                        </div>
                      )}

                      {x.consignmentStatus === ConsignmentStatus.WaitingClient && (
                        <div className="flex flex-col items-center m-auto">
                          <div className="flex flex-col gap-xs justify-center">
                            <IconButton
                              style={{ background: '#fff', borderRadius: '10px' }}
                              onClick={() => {
                                /*    navigate(`/carrier-driver/end-delivery/${x.id}`); */
                                handleDropOffDelivery(x)
                              }}
                            >
                              <CreateIcon fontSize="large" htmlColor="gray" />
                            </IconButton>
                            <label className="delivery-calendar-view--menu-button-text">Anna üle</label>
                          </div>
                        </div>
                      )}

                      {(x.consignmentStatus === ConsignmentStatus.Delivered || x.consignmentStatus === ConsignmentStatus.UnSigned) && (
                        <div className="flex flex-col items-center m-auto">
                          <div className="flex flex-col gap-xs justify-center items-center">
                            <IconButton
                              style={{ background: '#fff', borderRadius: '10px' }}
                              onClick={() => {
                                handleStatusChange(x.id, ConsignmentStatus.Finished);
                              }}
                            >
                              <CompletedIcon htmlColor="gray" fontSize="large" />
                            </IconButton>
                            <label className="delivery-calendar-view--menu-button-text">Lõpeta</label>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                )}
              </React.Fragment>
            ))
          ) : (
            <>
              <div className="flex justify-center items-center w-100" style={{ marginTop: '10%' }}>
                <div style={{ fontWeight: '500', color: '#cbcbcb', textAlign: 'center', width: '250px' }}>Antud kuupäevaks ei ole sinule rohkem vedusid planeeritud</div>
              </div>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default DriverCalendarView;
