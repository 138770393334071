import { useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import TruckIconRed from '../../assets/tracking-app/map-icon-truck-red.svg';
import TruckIconGray from '../../assets/tracking-app/map-icon-truck-gray.svg';
import TruckIconBlue from '../../assets/tracking-app/map-icon-truck-blue.svg';
import TruckIconYellow from '../../assets/tracking-app/map-icon-truck-yellow.svg';
import PinIconRed from '../../assets/tracking-app/map-icon-pin-red.svg';
import PinIconYellow from '../../assets/tracking-app/map-icon-pin-yellow.svg';
import PinIconBlue from '../../assets/tracking-app/map-icon-pin-blue.svg';
import PinIconGray from '../../assets/tracking-app/map-icon-pin-gray.svg';
import { DriverLocations } from '../../Types/delivery-app/DriverLocations';
import { DeliveryCoordinate } from '../../Types/delivery-app/DeliveryCoordinate';
import { clientUrl } from '../../config/apiconfig';
import { handleGetInHouseConsignmentStatus } from './Common';
type Props = {
    deliveryDriverLocations: DriverLocations[],
    destinations: DeliveryCoordinate[],
    width?: number;
    height?: number;
    date: Date;
};
const DriverLocationsMap = ({ deliveryDriverLocations, destinations, width = 500, height = 400 }: Props) => {
    const [activeMarker, setActiveMarker] = useState<any>(null);
    const [destinationActiveMarker, setDestinationActiveMarker] = useState<any>(null);
    const [mapCenter, setMapCenter] = useState({ lat: 59.4284215, lng: 24.8180724 });
    const [mapZoom, setMapZoom] = useState(13);
    const mapRef = useRef<google.maps.Map | null>(null);
    const mapContainerStyle = {
        height: height + 'px',
        width: width + 'px',
        marginTop: '-220px'
    };
    const handleMapLoad = (map: google.maps.Map) => {
        mapRef.current = map;
    };

    const handleNavigate = (id: number) => {
        // Set the width and height for the new window
        const width = 1280;
        const height = 500;

        // Calculate the position to center the window on the screen
        const left = window.screenLeft + (window.outerWidth - width) / 2;
        const top = window.screenTop + (window.outerHeight - height) / 2;

        // Open a new window with the specified dimensions and position
        const newWindow = window.open(`${clientUrl}/delivery-details/${id}`, '_blank', `width=${width},height=${800},left=${left},top=${top},noopener,noreferrer`);
        if (newWindow) newWindow.focus();
    };

    const getIcon = (id: number) => {
        const icons: any = {
            1: PinIconRed,
            2: PinIconBlue,
            3: PinIconYellow,
        };
        return icons[id] || PinIconGray; // default icon
    };

    const getCarIcon = (id: number) => {
        const icons: any = {
            1: TruckIconRed,
            2: TruckIconBlue,
            3: TruckIconYellow,
        };
        return icons[id] || TruckIconGray; // default icon
    };

    const handleMapCenterChanged = (map: google.maps.Map) => {
        if (mapRef.current) {
            const newCenter = mapRef.current.getCenter();
            if (newCenter) {
                setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
            }
        }
    };

    const handleMapZoomChanged = (map: google.maps.Map) => {
        if (mapRef.current) {
            const newZoom = mapRef.current.getZoom();
            if (newZoom !== undefined) {
                setMapZoom(newZoom);
            }
        }
    };
    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={mapCenter}
                zoom={mapZoom}
                onLoad={handleMapLoad}
                onCenterChanged={() => handleMapCenterChanged}
                onZoomChanged={() => handleMapZoomChanged}>
                {deliveryDriverLocations.map((location, index) => (
                    <Marker
                        key={index + 1}
                        options={{
                            icon: getCarIcon(location.id)
                        }}
                        position={{ lat: location.latitude, lng: location.longitude }}
                        onClick={() => setActiveMarker(index + 1)}
                    >
                        {activeMarker === index + 1 && (
                            <InfoWindow onCloseClick={() => setActiveMarker(0)}>
                                <div>
                                    <h2>{location.carName}</h2>
                                    <p>Numbrimärk: {location.carLicenseNumber}</p>
                                    <p>Auto: {location.carName}</p>
                                    <p>Juhid: {location.drivers.join(', ')}</p>
                                    <p>Viimane uuendus: {new Date(location.locationUpdateTime).toLocaleString('et-EE')}</p>
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                ))}

                {destinations.map((location, index2) => (
                    <Marker
                        key={index2 + 1}
                        options={{
                            icon: getIcon(location.driverId),
                        }}
                        position={{ lat: location.deliveryLatitude, lng: location.deliveryLongitude }}
                        onClick={() => setDestinationActiveMarker(index2 + 1)}
                    >
                        {destinationActiveMarker === index2 + 1 && (
                            <InfoWindow onCloseClick={() => setDestinationActiveMarker(0)}>
                                <div>
                                    <h2 style={{ marginTop: "0" }}>{location.deliveryCompanyName}</h2>
                                    <h2>{location.carName} {location.licenseNr}</h2>
                                    <p>Aadress: {location.deliveryAddress}</p>
                                    <p>Saabumine: {location.deliveryTimeFrom}-{location.deliveryTimeTo}</p>
                                    <p>Staatus: {handleGetInHouseConsignmentStatus(location.consignmentStatus)}</p>
                                    <p style={{ color: "#e10008", cursor: "pointer" }} onClick={() => handleNavigate(location.id)}>Rohkem infot</p>
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                ))}
            </GoogleMap>
        </div>
    );
};
export default DriverLocationsMap;