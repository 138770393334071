import React from 'react';
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import '../../../styles/tracking-app/delivery-order.css';
type Props = {
  deliveryData: InHouseConsignment;
  handleNavigate: (id: number) => void;
};
const DeliveryOrderNew = ({ handleNavigate, deliveryData }: Props) => {
  return (
    <div className="order" onClick={() => handleNavigate(deliveryData.id)}>
      <div className="order-details">
        <h3 className="deliveryOrder-title">{deliveryData.deliveryObject}</h3>
        <div className="deliveryOrder-row">
          {deliveryData.type === "Export" &&
            <>
              <div className="deliveryOrder-heading-xs">Lähetused:</div>
              <div className="deliveryOrder-row-content">
                {deliveryData.deliveries?.filter(x => x.confirmed !== "1").map((x, idx) => (
                  <React.Fragment key={idx}>
                    {x.deliveryNumber}({x.deliveryStatus}){deliveryData.directoDeliveryNumbers.length > 1 && idx + 1 < deliveryData.directoDeliveryNumbers.length && ', '}
                  </React.Fragment>
                ))}
              </div>
            </>
          }
          {deliveryData.type === "Import" &&
            <>
              <div className="deliveryOrder-heading-xs">Ostutellimused:</div>
              <div className="deliveryOrder-row-content">
                {deliveryData.directoPurchaseOrderNumbers?.map((x, idx) => (
                  <React.Fragment key={idx}>
                    {x}{deliveryData.directoPurchaseOrderNumbers.length > 1 && idx + 1 < deliveryData.directoPurchaseOrderNumbers.length && ', '}
                  </React.Fragment>
                ))}
              </div>
            </>
          }
        </div>

        {deliveryData.deliveryTimeFrom && (
          <div className="deliveryOrder-row">
            <div className="deliveryOrder-heading-xs">Saabumise aeg:</div>
            <div className="deliveryOrder-row-content">
              {deliveryData.deliveryTimeFrom}-{deliveryData.deliveryTimeTo}
            </div>
          </div>
        )}

        <div className="deliveryOrder-row--last">
          <div>{deliveryData.deliveryCompanyName}</div>
          {deliveryData.type === "Import" &&
            <div>{deliveryData.collectionAddressRow1}</div>
          }
          {deliveryData.type === "Export" &&
            <div>{deliveryData.deliveryAddressRow1}</div>
          }
        </div>
      </div>
    </div>
  );
};

export default DeliveryOrderNew;
