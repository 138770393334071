import { Button } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDataContext } from "../../../context/DataContext";
import { RemovePackageRowFromConsignment } from "../../../queries/queries";
import { useTranslation } from "react-i18next";
import { useCustomer } from "../../login/user";

const ReturnPackages = (props: { pickupIdx: number, listIndex: number }) => {
    const { consignmentList, setConsignmentList } = useDataContext();
    const cargosonData = consignmentList[props.listIndex].cargosonData;
    const { t } = useTranslation();
    const { permissions } = useCustomer();

    const handleDelete = (packageId: number) => {
        /*  if (!permissions.includes("PEstonianUser") || permissions.includes("PFinnishUser")) {
             alert("You don't have enough permissions")
             return;
         } */
        RemovePackageRowFromConsignment(packageId).then(() => {
            const newPackages = [...consignmentList[props.listIndex].cargosonData.pickups[props.pickupIdx].rows_attributes]
            setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === props.pickupIdx ? {
                            ...pickup,
                            "rows_attributes": newPackages.filter((_, idx) => _.id !== packageId)
                        } : pickup)
                    ]
                }
            } : item))
        })
    }

    // Calculate total quantity, total LDM, and total weight
    let totalQuantity = 0;
    let totalLdm = 0;
    let totalWeight = 0;

    cargosonData.pickups[props.pickupIdx].rows_attributes.forEach((value) => {
        totalQuantity += value.quantity;
        totalLdm += value.ldm;
        totalWeight += value.weight;
    });

    if (cargosonData.pickups[props.pickupIdx].rows_attributes.length < 1) return <></>;
    return (
        <table
            style={{
                width: "600px",
                borderCollapse: "collapse",
                margin: "1% 0 3% 0",
                border: "1px solid gray",
            }}
        >
            <thead style={{ border: "1px solid black" }}>
                <tr style={{ border: "1px solid black" }}>
                    <td className="return-package-container__package-table--thead-td">{t("Kogus")}</td>
                    <td className="return-package-container__package-table--thead-td">{t("Tüüp")}</td>
                    <td className="return-package-container__package-table--thead-td">{t("Kaal")}</td>
                    <td className="return-package-container__package-table--thead-td">{t("Pikkus")}</td>
                    <td className="return-package-container__package-table--thead-td">{t("Laius")}</td>
                    <td className="return-package-container__package-table--thead-td">{t("Kõrgus")}</td>
                    <td className="return-package-container__package-table--thead-td">{t("Maht")}</td>
                    <td className="return-package-container__package-table--thead-td">{t("LDM")}</td>
                    <td className="return-package-container__package-table--thead-td">{t("Kirjeldus")}</td>
                    <td className="return-package-container__package-table--thead-td">
                        <DeleteOutlineIcon fontSize="small" style={{ verticalAlign: "bottom" }} />
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="return-package-container__package-table--tbody-td sum-row" style={{ borderLeft: "1px solid #dee2e6" }}>{totalQuantity}</td>
                    <td className="return-package-container__package-table--tbody-td sum-row"></td>
                    <td className="return-package-container__package-table--tbody-td sum-row">{totalWeight.toFixed(2)}</td>
                    <td className="return-package-container__package-table--tbody-td sum-row"></td>
                    <td className="return-package-container__package-table--tbody-td sum-row"></td>
                    <td className="return-package-container__package-table--tbody-td sum-row"></td>
                    <td className="return-package-container__package-table--tbody-td sum-row"></td>
                    <td className="return-package-container__package-table--tbody-td sum-row">{totalLdm.toFixed(2)}</td>
                    <td className="return-package-container__package-table--tbody-td sum-row"></td>
                    <td className="return-package-container__package-table--tbody-td sum-row" style={{ borderRight: "1px solid #dee2e6" }}></td>
                </tr>
                {cargosonData.pickups[props.pickupIdx].rows_attributes?.slice(0).reverse().map((value, idx) => {
                    return (
                        <tr key={idx}>
                            <td className="return-package-container__package-table--tbody-td" style={{ borderLeft: "1px solid #dee2e6" }} >{value.quantity}</td>
                            <td className="return-package-container__package-table--tbody-td" >{value.package_type}</td>
                            <td className="return-package-container__package-table--tbody-td" >{value.weight}</td>
                            <td className="return-package-container__package-table--tbody-td" >{value.length}</td>
                            <td className="return-package-container__package-table--tbody-td" >{value.width}</td>
                            <td className="return-package-container__package-table--tbody-td" >{value.height}</td>
                            <td className="return-package-container__package-table--tbody-td" >{value.volume}</td>
                            <td className="return-package-container__package-table--tbody-td" >{value.ldm}</td>
                            <td className="return-package-container__package-table--tbody-td" >{value.description}</td>
                            <td className="return-package-container__package-table--tbody-td" style={{ borderRight: "1px solid #dee2e6" }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: "red",
                                        width: "5px",
                                        height: "30px",
                                    }}
                                    onClick={() => handleDelete(value.id)}
                                >
                                    X
                                </Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default ReturnPackages