import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/CalendarInput.css';

type Props = {
  handleChange: (date: Date) => void;
  currentDate: Date | null;
  showTimeSelectOnly?: boolean | undefined;
  placeHolder?: string | undefined;
  isUserPermitted?: boolean | undefined
}
const DateTimeCalendar = ({ handleChange, currentDate, showTimeSelectOnly, placeHolder, isUserPermitted = true }: Props) => {
  // Define the minimum and maximum times
  const minTime = new Date();
  minTime.setHours(6, 0, 0); // 07:00 AM

  const maxTime = new Date();
  maxTime.setHours(19, 0, 0); // 06:00 PM

  return (
    <div >
      {showTimeSelectOnly === true ?
        <DatePicker
          selected={currentDate}
          onChange={handleChange}
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          timeIntervals={15}
          showTimeSelect
          showTimeSelectOnly={showTimeSelectOnly}
          placeholderText={placeHolder}
          showPopperArrow={false}
          disabled={!isUserPermitted}
          popperPlacement={"bottom-start"}
          portalId={"time-datepicker"}
          minTime={minTime}
          maxTime={maxTime} /> :
        <DatePicker
          selected={currentDate}
          onChange={handleChange}
          dateFormat="dd/MM/yyyy"
          placeholderText={placeHolder}
          showPopperArrow={false}
          showWeekNumbers={true}
          calendarStartDay={1}
          disabled={!isUserPermitted}
          popperPlacement={"bottom-start"}
          portalId={"datepicker"} />
      }
    </div>
  );
};

export default DateTimeCalendar