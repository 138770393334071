import { IconButton } from '@mui/material';
import React, { useState } from 'react'
import { useDataContext } from '../../../../context/DataContext';
import '../../../../styles/Consignment.css'
import ClearIcon from '@mui/icons-material/Clear';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EastIcon from '@mui/icons-material/East';
import { AxiosResponse } from 'axios';
import { Deliveries } from '../../../../Types/directo/Deliveries';
import { Consignment } from '../../../../Types/Consignment';
import { SearchDeliveriesWithDeliveryNumber, AddDeliveryToConsignment, RemoveDeliveryFromConsignment, GetOrderInfoFromDelivery } from '../../../../queries/directo-queries';
import { SaveConsignmentList } from '../../../../queries/queries';
import { clientUrl } from '../../../../config/apiconfig';

const AddDeliveryNew = (props: { listIndex: number }) => {
    const [deliveryNumber, setDeliveryNumber] = useState<string>();
    const { consignmentList, setConsignmentList } = useDataContext();
    const consignment = consignmentList[props.listIndex];

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!deliveryNumber) return;
        if (consignmentList[props.listIndex].deliveryNumbers.includes(deliveryNumber)) return;
        try {
            const response: AxiosResponse<Deliveries[]> = await SearchDeliveriesWithDeliveryNumber(deliveryNumber);

            AddDeliveryToConsignment(deliveryNumber, +consignment.id).then(
                (response: AxiosResponse<Consignment>) => {
                    setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                        ...item,
                        deliveryNumbers: response.data.deliveryNumbers,
                        deliveries: response.data.deliveries,
                    } : item));
                    setDeliveryNumber(() => "")
                }
            ).catch(err => {
                console.log(err);
                // Check if the error response exists and has the 'error' field
                if (err.response && err.response.data && err.response.data.error) {
                    const errorMessage = err.response.data.error;

                    // Split the error message by newline character to separate the URL and the message
                    const [url, message] = errorMessage.split('\n');

                    // Display the alert with the message
                    alert(`${message}, avan uues aknas?\nURL: ${clientUrl}${url}`);

                    // After the user clicks "OK" in the alert, open the URL in a new tab
                    var urlToOpen = clientUrl + url;
                    console.log(urlToOpen)
                    window.open(urlToOpen, '_blank');
                } else {
                    // If there is no specific error, display a generic message
                    alert("An unknown error occurred");
                }
            });

        } catch (error) {
            alert("Ei leitud lähetust");
        }
    };

    const handleAddDeliveryInfoToHeader = async (deliveryNumber: string) => {
        await SaveConsignmentList(consignmentList)
        GetOrderInfoFromDelivery(+consignment.id, deliveryNumber).then(
            (response: AxiosResponse<Consignment>) => {
                //TODO
                setConsignmentList(() => consignmentList.map((item, idx) => idx === props.listIndex ? response.data : item))
                alert(`Lähetuse ${deliveryNumber} info võetud aluseks`)
            }
        ).catch(err => { console.log(err); alert(`Ei saanud lähetust ${deliveryNumber} veotellimuse aluseks võtta`) });
    }

    const handleRemoveDelivery = (deliveryNumber: string) => {
        RemoveDeliveryFromConsignment(deliveryNumber, +consignment.id).then(
            (response: AxiosResponse<Consignment>) => {
                setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                    ...item,
                    deliveryNumbers: response.data.deliveryNumbers,
                    deliveries: response.data.deliveries,
                } : item));
            }
        ).catch(err => { console.log(err); alert("Could not remove order") });
    }

    return (
        <div style={{ border: "1px solid #e2e2e2", padding: "20px", minHeight: "130px", backgroundColor: "#fff" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <input type="number" style={{ border: "none", borderBottom: "1px solid #e2e2e2" }} placeholder='Lisa lähetus' value={deliveryNumber} onChange={(e) => setDeliveryNumber(e.target.value)} />
                    <IconButton type="submit">
                        <EastIcon style={{ color: "gray" }} />
                    </IconButton>
                </div>
            </form>
            <div >
                {consignmentList[props.listIndex].deliveryNumbers?.map((x, idx) => (
                    <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #e2e2e2" }}>
                        <a className="addedDeliveires__anchor-tag" target="_blank" rel="noreferrer" href={`https://login.directo.ee/ocra_ets_nord/ladu_l.asp?NUMBER=${x}`} >
                            {x}
                        </a>
                        <IconButton title="Võta põhjaks" onClick={() => handleAddDeliveryInfoToHeader(x)}>
                            <PlaylistAddIcon />
                        </IconButton>
                        <IconButton title="Eemalda" onClick={() => handleRemoveDelivery(x)}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AddDeliveryNew