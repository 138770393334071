import React, { useEffect, useState } from 'react'
import { UpdateConsignmentProperty } from '../../queries/queries';
import '../../styles/consignment-overview.css'
import { ConvertDate } from '../../components/common/ConvertDate';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import '../../styles/tuusula-view.css'
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { GetGroupedTransportRoutesAndConsignments, UpdateTransportRouteProperty } from '../../queries/transport-route-queries';
import { Navigate, useNavigate } from 'react-router-dom';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { useCustomer } from '../../components/login/user';
import { TuusulaConsignmentList } from '../../Types/TuusulaConsignmentList';
import { ConsignmentCalendar } from '../../Types/ConsignmentCalendar';
import { GetCollectionTime, GetDateFromAndTo } from '../../components/helpers/GroupConsignments';

const SwedenTvView = () => {
    const { permissions } = useCustomer();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsCount, setItemsCount] = useState<number>(1);
    const itemsPerPage = 350;
    const [sortBy, setSortBy] = useState("collection_date"); // Default sorting field
    const [isDescending, setIsDescending] = useState(false); // Default sorting order
    const [groupedConsignments, setGroupedConsignments] = useState<Record<number, Record<number, Record<number, ConsignmentCalendar[]>>>>();
    const navigate = useNavigate();
    const { t } = useTranslation()
    const isArchived = false;
    const originCountry = "SE"
    const searchTerm = "";

    const fetchAll = async (dateFrom: string, dateTo: string) => {
        if (dateFrom && dateTo) {
            GetGroupedTransportRoutesAndConsignments(currentPage, itemsPerPage, isArchived, sortBy, isDescending, dateFrom, dateTo, originCountry, searchTerm).then((response: any) => {
                setGroupedConsignments(response.data)
            }).catch((error) => { console.log(error) })
        }
    }

    const handleConsignmentBackground = (consignment: TuusulaConsignmentList) => {
        const today = new Date();
        const collectionDate = consignment.collectionDate;
        const [collectionDay, collectionMonth, collectionYear] = collectionDate.split(".");
        const collectionDateObject = new Date(+collectionYear, +collectionMonth - 1, +collectionDay + 1);
        if (consignment.consignmentStatus === "ULD_FINISH") {

            return "background-finished--tv";
        }
        if (collectionDateObject < today) {

            return "background-finished--tv"
        }

        if (consignment.deliveryWithCrane === true) {
            return "background-with-crane--tv";
        }

        if (consignment.consignmentStatus === "TR_ORDER") {

            return "background-incomplete--tv";
        }

        if (!consignment.directoCode) {
            return "background-incomplete--tv";
        }
        return;
    }

    const handleTransportRouteBackground = (calenderObject: ConsignmentCalendar) => {
        if (calenderObject.transportRouteStatus === "ULD_FINISH") {
            return "background-finished--tv"
        }
        if (calenderObject.consignmentGroup.find(x => x.deliveryWithCrane === true)) {
            return "background-with-crane--tv";
        }
        if (calenderObject.transportRouteStatus === "TR_ORDER") {
            return "background-incomplete--tv"
        }
        return;
    }

    const handleLoadConsignment = (consignmentId: number) => {
        navigate(("/sweden/transport-orders/" + consignmentId))
    }

    const handleConsignmentStatusChange = async (event: any, consignmentId: number) => {
        //make call to api to change consignment's status
        event.stopPropagation();
        await UpdateConsignmentProperty(consignmentId, "ConsignmentStatus", "ULD_FINISH")
        var dates = GetDateFromAndTo()
        fetchAll(dates.dateFrom, dates.dateTo);
    }

    const handleTransportRouteStatusChange = async (event: any, transportRouteId: number) => {
        event.stopPropagation();
        await UpdateTransportRouteProperty(transportRouteId, "Status", "ULD_FINISH")
        var dates = GetDateFromAndTo()
        fetchAll(dates.dateFrom, dates.dateTo);
    }

    const ReturnDayWithDate = (dateString: string): string => {
        dateString = ConvertDate(dateString).toLocaleDateString('et-EE')
        const dateStringWithoutYear = (dateString).split('.')[0] + '.' + (dateString).split('.')[1];
        const deliveryDate: Date = ConvertDate(dateString);
        const weekDayNumber = deliveryDate.getDay();
        if (weekDayNumber === 1) {
            return `Monday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 2) {
            return `Tuesday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 3) {
            return `Wednesday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 4) {
            return `Thursday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 5) {
            return `Friday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 6) {
            return `Saturday ${dateStringWithoutYear}`
        }
        if (weekDayNumber === 0) {
            return `Sunday ${dateStringWithoutYear}`
        }
        return dateStringWithoutYear
    }

    useEffect(() => {
        var dates = GetDateFromAndTo()
        fetchAll(dates.dateFrom, dates.dateTo);
    }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            var dates = GetDateFromAndTo()
            fetchAll(dates.dateFrom, dates.dateTo);
        }, 45000); // 45s in milliseconds

        // Clean up the interval on component unmount
        return () => {
            clearInterval(interval);
        };
    }, []);


    if (!permissions.includes("PViewSwedishTv")) {
        return <Navigate to="/unauthorized" />
    }

    return (
        <div style={{ width: "100%", background: "gray", position: "absolute", top: "0%", paddingTop: "70px", overflowX: "auto", boxSizing: "border-box" }}>
            <table className='tv-view--table'>
                <thead>
                    <tr>
                        <td className="tv-overview__thead--td">
                            {t("Loading")}
                        </td>
                        <td className="tv-overview__thead--td">
                            {t("Unloading")}
                        </td>
                        <td className="tv-overview__thead--td">
                            {t("Route")}
                        </td>
                        <td className="tv-overview__thead--td" >
                            {t("Driver")}
                        </td>
                        <td className="tv-overview__thead--td"></td>
                    </tr>
                </thead>
                <tbody>
                    {(groupedConsignments !== null && groupedConsignments !== undefined) && Object.entries(groupedConsignments!).map(([year, yearConsignments]) => (
                        <React.Fragment key={year}>
                            {Object.entries(yearConsignments).map(([weekNumber, weekConsignments]) => (
                                <React.Fragment key={weekNumber}>
                                    <tr>
                                        <td colSpan={5} style={{ color: "white", fontSize: "23px", marginLeft: "10px", fontFamily: "helvetica", padding: "10px", paddingLeft: "5px", fontWeight: "bold", background: "#71ac71" }}>{`Week ${weekNumber}`}</td>
                                    </tr>
                                    {Object.entries(weekConsignments).map(([weekDayNumber, consignmentGroups]) =>
                                    (
                                        <React.Fragment key={weekDayNumber}>

                                            {Object.values(consignmentGroups).map((calenderObj, idx) =>
                                            (
                                                <React.Fragment key={idx}>
                                                    {calenderObj.isTransportRoute === true ?
                                                        <React.Fragment key={calenderObj.id}>
                                                            <tr className={`consignment-overview-tv--tr ${handleTransportRouteBackground(calenderObj)}`} onClick={() => handleLoadConsignment(calenderObj.consignmentGroup[0].id)}>

                                                                <td className='white tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                    {calenderObj.consignmentGroup.find(x => x.deliveryWithCrane === true) &&
                                                                        <IconButton aria-readonly={true} title={t("Kraanaga auto")}>
                                                                            <PrecisionManufacturingIcon fontSize='medium' />
                                                                        </IconButton>
                                                                    }
                                                                    &#160;
                                                                    {ReturnDayWithDate(calenderObj.consignmentGroup[0].collectionDate) ?? "-"} {GetCollectionTime(calenderObj.consignmentGroup[0].collectionTimeFrom, calenderObj.consignmentGroup[0].collectionTimeTo)}
                                                                </td>

                                                                <td className='white tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                    {calenderObj.consignmentGroup.map((x, idx4) =>
                                                                    (
                                                                        <React.Fragment key={idx4}>
                                                                            {x.deliveryDate &&
                                                                                <>
                                                                                    {idx4 + 1}: {ReturnDayWithDate(x.deliveryDate)} {GetCollectionTime(x.deliveryTimeFrom, x.deliveryTimeTo)}
                                                                                    <br></br>
                                                                                </>
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                    )}
                                                                </td>

                                                                <td className='white tv-view--td' style={{ borderRight: "1px solid #c00" }}>{calenderObj.consignmentGroup.map((x, idx4) =>
                                                                (
                                                                    <React.Fragment key={idx4}>
                                                                        {idx4 + 1}: {x.collectionAddressRow1} - {x.deliveryCity} {x.orderNumbers && x.orderNumbers + ""}  {x.totalLdm > 0 && " " + (x.totalLdm + " LDM")}
                                                                        {x.internalComment !== null && (" " + x.internalComment)} {(x.deliveryWithCrane === true) &&
                                                                            <IconButton aria-readonly={true} title={t("Kraanaga auto")}>
                                                                                <PrecisionManufacturingIcon fontSize='medium' />
                                                                            </IconButton>
                                                                        }
                                                                        <br></br>
                                                                    </React.Fragment>
                                                                )
                                                                )}
                                                                </td>

                                                                <td className='white tv-view--td' >{calenderObj.consignmentGroup[0].carrierDriver ?? "-"}</td>
                                                                <td className='white tv-view--td' >
                                                                    <IconButton onClick={(e: any) => handleTransportRouteStatusChange(e, calenderObj.id)}>
                                                                        {calenderObj.transportRouteStatus === "ULD_FINISH" ?
                                                                            <ThumbUpAltIcon fontSize='large' />
                                                                            :
                                                                            <ThumbUpOffAltIcon fontSize='large' />
                                                                        }
                                                                    </IconButton>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                        :

                                                        <React.Fragment key={calenderObj.id}>
                                                            {calenderObj.consignmentGroup.map((x, idx3) => (
                                                                <React.Fragment key={idx3}>
                                                                    {calenderObj.isTransportRoute === false &&
                                                                        <tr className={`consignment-overview-tv--tr ${handleConsignmentBackground(x)}`} onClick={() => handleLoadConsignment(x.id)} >

                                                                            <td className='white tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                                {x.deliveryWithCrane === true &&
                                                                                    <IconButton aria-readonly={true} title={t("Kraanaga auto")}>
                                                                                        <PrecisionManufacturingIcon fontSize='medium' />
                                                                                    </IconButton>
                                                                                }
                                                                                {ReturnDayWithDate(x.collectionDate) ?? "-"} {GetCollectionTime(calenderObj.consignmentGroup[0].collectionTimeFrom, calenderObj.consignmentGroup[0].collectionTimeTo)}
                                                                            </td>
                                                                            <td className='white tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                                {ReturnDayWithDate(x.deliveryDate) ?? "-"} {GetCollectionTime(x.deliveryTimeFrom, x.deliveryTimeTo)}
                                                                            </td>
                                                                            <td className='white tv-view--td' style={{ borderRight: "1px solid #c00" }}>
                                                                                {x.collectionAddressRow1} - {x.deliveryCity} {x.orderNumbers && x.orderNumbers + ""}
                                                                                {x.totalLdm > 0 && " " + (x.totalLdm + " LDM")}
                                                                                {x.internalComment !== null && (" " + x.internalComment)}
                                                                                {(x.deliveryWithCrane === true) &&
                                                                                    <IconButton aria-readonly={true} title={t("Kraanaga auto")}>
                                                                                        <PrecisionManufacturingIcon fontSize='medium' />
                                                                                    </IconButton>
                                                                                }
                                                                            </td>
                                                                            <td className='white tv-view--td' >{x.carrierDriver ?? "-"}</td>
                                                                            <td className='white tv-view--td' >

                                                                                <IconButton onClick={(e: any) => handleConsignmentStatusChange(e, x.id)}>
                                                                                    {x.consignmentStatus === "ULD_FINISH" ?
                                                                                        <ThumbUpAltIcon fontSize='large' />
                                                                                        :
                                                                                        <ThumbUpOffAltIcon fontSize='large' />
                                                                                    }
                                                                                </IconButton>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </React.Fragment>
                                                            ))}
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            )
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default SwedenTvView