const CustomerServicePage = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", width: "100%", fontSize: "30px" }}>
            {/* {!transportRouteId && checkDirectoCode() &&
                    <button className="consignment-options--button" style={{ }}>
                        <a style={{ color: "white", backgroundColor: "inherit", fontSize: "14px", textDecoration: "none" }}
                            target="_blank"
                            rel="noreferrer"
                            href={`https://login.directo.ee/ocra_ets_nord/yld_print.asp?mida=xsl&moodul=event&number=${consignmentList[listIndex].directoCode}&ver=&print=yes&rns=&print=no&signeeri=2!1619&row=1619`}
                        >
                            Signeeri
                        </a>
                    </button>} */}
        </div>
    )
}

export default CustomerServicePage