import React from 'react'
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment'
import { handleGetInHouseConsignmentStatus } from '../Common'
type Props = {
    calenderObj: InHouseConsignment[]
    handleRowClick: (id: number) => void
}
const UnAssignedDeliveries = ({ calenderObj, handleRowClick }: Props) => {
    return (
        <div style={{ background: "#efefef", borderRadius: "30px 0 0 30px", padding: "1%", marginBottom: "15px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}>
            <div style={{ color: '#737372', fontSize: '20px', fontFamily: 'Helvetica-Bd', marginBottom: "10px" }}>
                Planeerimata veod
            </div>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead style={{ color: "#737372", fontFamily: "Helvetica-Roman" }}>
                    <tr>
                        <td>Mahalaadimine</td>
                        <td>Klient</td>
                        <td>Objekt</td>
                        <td>Müügitellimus</td>
                        <td>Staatus</td>
                    </tr>
                </thead>

                <React.Fragment>
                    {calenderObj.map((calenderObj, idx) => (
                        <React.Fragment key={idx}>
                            <tr style={{ background: "#fff", color: "black", fontFamily: "Helvetica-Roman", borderRadius: "10px", cursor: "pointer" }} onClick={() => handleRowClick(calenderObj.id)}>
                                <td style={{ borderRadius: "10px 0px 0px 0px", padding: "10px", paddingTop: "15px", paddingBottom: "0px", width: "20%" }}>{calenderObj.deliveryTimeFrom} {calenderObj.deliveryTimeTo && `- ${calenderObj.deliveryTimeTo}`}</td>
                                <td style={{ paddingTop: "15px", paddingBottom: "0px", width: "28%" }}>{calenderObj.deliveryCompanyName}{/*  {calenderObj.deliveryCompanyCustomerCode} */}</td>
                                <td style={{ paddingTop: "15px", paddingBottom: "0px", width: "13%" }}>{calenderObj.deliveryObject}</td>
                                <td style={{ paddingTop: "15px", paddingBottom: "0px", width: "25%" }}>{calenderObj.directoOrderNumbers && calenderObj.directoOrderNumbers + ''}</td>
                                <td style={{ borderRadius: "0px 10px 0px 0px", paddingTop: "15px", paddingBottom: "0px", width: "14%" }}>{handleGetInHouseConsignmentStatus(calenderObj.consignmentStatus)}</td>
                            </tr>
                            {calenderObj.internalComment && <tr style={{ background: "#fff", color: "black", fontFamily: "Helvetica-Roman", cursor: "pointer" }} onClick={() => handleRowClick(calenderObj.id)}>
                                <td colSpan={5} style={{ padding: "10px", paddingTop: "0px", paddingBottom: "5px", }}>{calenderObj.internalComment}</td>
                            </tr>}
                            {calenderObj.deliveryAddressRow1 && <tr style={{ background: "#fff", color: "black", fontFamily: "Helvetica-Roman", cursor: "pointer" }} onClick={() => handleRowClick(calenderObj.id)}>
                                <td
                                    colSpan={5}
                                    style={{ padding: "10px", paddingTop: "0px", paddingBottom: "5px", borderRadius: "0 0 10px 10px" }}
                                >
                                    {calenderObj.type === "Import" ? (
                                        <span><span style={{ fontFamily: "Helvetica-Bd", fontSize: "20px" }}>{calenderObj.type}</span> - {calenderObj.collectionAddressRow1}</span>
                                    ) : (
                                        calenderObj.deliveryAddressRow1
                                    )}
                                </td>
                            </tr>}
                            <tr style={{ background: "#efefef" }}>
                                <td colSpan={5} style={{ padding: "3px", borderRadius: "0 0 10px 10px" }}></td>
                            </tr>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            </table>
        </div>
    )
}

export default UnAssignedDeliveries