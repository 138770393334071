import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { GetActiveDeliveryDrivers, GetInHouseConsignmentsCalendar, GetUnPlannedConsignmentsCalendar } from '../../../queries/deliveryDriverQueries';
import { DeliveryDriver } from '../../../Types/delivery-app/DeliveryDriver';
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import '../../../styles/tracking-app/loader-page.css'
import { ConvertDate } from '../../common/ConvertDate';
import WeekCalendar from '../logistician/WeekCalendar';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../login/user';
import CarAssignedDeliveriesLoader from './CarAssignedDeliveriesLoader';
import UnAssignedDeliveriesLoader from './UnAssignedDeliveriesLoader';

const LoaderDeskdopCalendarView = () => {
    const location = useLocation();
    const { permissions } = useCustomer();
    const [deliveryDrivers, setDeliveryDrivers] = useState<DeliveryDriver[]>([]);
    const hours = Array.from({ length: 13 }, (_, index) => index + 6);
    const navigate = useNavigate();
    const [plannedConsignments, setPlannedConsignments] = useState<Record<number, Record<number, Record<number, InHouseConsignment[]>>>>();
    const [unPlannedConsignments, setUnPlannedConsignments] = useState<Record<number, Record<number, InHouseConsignment[]>>>();
    const [selectedCar, setSelectedCar] = useState<number>(0)
    const queryParams = new URLSearchParams(location.search);
    const calendardate = queryParams.get("calendardate");
    const [calendarDate, setCalendarDate] = useState<Date>(calendardate ? ConvertDate(calendardate) : new Date());

    const handleGetAllDeliveryDrivers = () => {
        GetActiveDeliveryDrivers().then((response) => {
            setDeliveryDrivers(response.data)
        })
    }

    const handleGetCalendarConsignments = () => {
        var date = calendarDate.toLocaleString('et-EE', { year: 'numeric', month: '2-digit', day: '2-digit' });
        GetInHouseConsignmentsCalendar(date)
            .then((res: any) => {
                setPlannedConsignments(res.data);
            })
            .catch((err: any) => {
                setPlannedConsignments([]);
            });
    };

    const handleGetUnPlannedConsignments = () => {
        var date = calendarDate.toLocaleString('et-EE', { year: 'numeric', month: '2-digit', day: '2-digit' });
        GetUnPlannedConsignmentsCalendar(date)
            .then((res: any) => {
                setUnPlannedConsignments(res.data);
            })
            .catch((err: any) => {
                setUnPlannedConsignments([]);
            });
    };
    const handleSelectCar = (carId: number) => {
        if (selectedCar === carId) {
            setSelectedCar(0)
            return;
        }
        else {
            setSelectedCar(carId)
        }
    }
    const handleNavigate = (id: number) => {
        navigate(`/loader/delivery-details/${id}`)
    }

    const handleDeliveryDate = (date: Date) => {
        setCalendarDate(date);

        const formattedDate = date.toLocaleString("et-EE", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        navigate("/loader?calendardate=" + formattedDate)
    };
    useEffect(() => {
        handleGetAllDeliveryDrivers()
        handleGetCalendarConsignments();
        handleGetUnPlannedConsignments();
    }, [calendarDate]);


    if (!permissions.includes("PEstonianWareHouseWorker")) {
        return <Navigate to="/unauthorized" />
    }

    return (

        <>
            {(plannedConsignments !== null && plannedConsignments !== undefined) &&
                <div className='flex-col justify-center items-center  w-100 text-reg'>
                    <div className='flex items-center justify-center w-100' style={{ background: "#e10008" }}>
                        <WeekCalendar setCalendarDate={setCalendarDate} calendarDate={calendarDate} handleDateChange={handleDeliveryDate} />
                    </div>
                    <div className='flex-col justify-center items-center gap-xs' style={{ gap: "2%", marginBottom: "50px" }}>
                        <div className='tex-reg' style={{ fontSize: "20px" }}>Vali auto</div>
                        <div className='flex justify-center items-center gap-xs'>
                            {deliveryDrivers.map((driver, idx) => (
                                <div key={idx} className='text-reg flex justify-center items-center' onClick={() => handleSelectCar(driver.id)}
                                    style={{ cursor: "pointer", textAlign: "center", fontSize: "22px", padding: "5px", marginTop: "2%", color: "#fff", background: `${selectedCar === driver.id ? "#e10008" : "#737372"}`, borderRadius: "10px", width: "fit-content", height: "60px" }}>{driver.carLicenseNumber}-{driver.carName}</div>
                            ))}
                        </div>
                    </div>
                    <div style={{ paddingTop: '0', marginTop: '1%', width: "100%" }}>
                        {(plannedConsignments !== null && plannedConsignments !== undefined) &&
                            Object.entries(plannedConsignments!).map(([weekNumber, weekConsignments]) => (
                                <div key={weekNumber} style={{ marginTop: "-75px" }}>
                                    {Object.entries(weekConsignments).map(([weekDay, consignmentGroups]) => (
                                        <React.Fragment key={weekDay}>
                                            {selectedCar === 0 ? (
                                                Object.entries(consignmentGroups).map(([carId, carConsignments]) => (
                                                    <CarAssignedDeliveriesLoader key={carId} car={carConsignments} index={parseInt(carId)} handleRowClick={handleNavigate} />
                                                ))
                                            ) : (
                                                consignmentGroups[selectedCar] && (
                                                    <CarAssignedDeliveriesLoader key={selectedCar} car={consignmentGroups[selectedCar]} index={selectedCar} handleRowClick={handleNavigate} />
                                                )
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            ))}
                    </div>
                    <div style={{ paddingTop: '0', marginTop: '1%', width: "100%" }}>
                        {unPlannedConsignments !== null &&
                            unPlannedConsignments !== undefined &&
                            Object.entries(unPlannedConsignments!).map(([weekNumber, weekConsignments]) => (
                                <React.Fragment key={weekNumber}>
                                    {Object.entries(weekConsignments).map(([weekDay, consignmentGroups]) => (
                                        <React.Fragment key={weekDay}>
                                            <UnAssignedDeliveriesLoader calenderObj={consignmentGroups} handleRowClick={handleNavigate} />
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                    </div>
                </div>
            }
        </>
    )
}

export default LoaderDeskdopCalendarView