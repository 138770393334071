import { useNavigate } from 'react-router-dom';
import { useDataContext } from '../../context/DataContext';
import '../../styles/consignment-table.css'
import { ConvertDate } from '../common/ConvertDate';
import CloseIcon from '@mui/icons-material/Close';
import { CreateConsignmentCopy, DeleteIncompleteConsignment, UpdateConsignmentProperty } from '../../queries/queries';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { AxiosResponse } from 'axios';
import { Consignment } from '../../Types/Consignment';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { SupplierSelect } from '../../Types/SupplierSelect';
import { IconButton } from '@mui/material';
import { clientUrl } from '../../config/apiconfig';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
    onDeleteConsignment: () => void;
    handleSortingChange: (field: string, setSortingState: React.Dispatch<React.SetStateAction<boolean>>, sortingState: boolean) => void
}
const ConsignmentsTable = ({ onDeleteConsignment, handleSortingChange }: Props) => {
    const { consignmentListResponseNew, setIsLoading, setConsignmentId, setSelectedSupplier } = useDataContext();
    const navigate = useNavigate();
    const [isDescendingCollectionDate, setIsDescendingCollectionDate] = useState(false);
    const [isDescendingDeliveryDate, setIsDescendingDeliveryDate] = useState(false);
    const [isDescendingDirectoCode, setIsDescendingDirectoCode] = useState(false);
    const [isDescendingCarrierName, setIsDescendingCarrierName] = useState(false);

    const handleRemoveConsignment = async (e: any, consignmentId: number) => {
        e.stopPropagation();
        if (window.confirm(`Kustuta veotellimus?`)) {
            await DeleteIncompleteConsignment(consignmentId)
            onDeleteConsignment();
        }
    }

    const handleCreateConsignmentCopy = async (e: any, consignmentId: number) => {
        e.stopPropagation();
        var userName = localStorage.getItem("user")
        var userEmail = localStorage.getItem("email");
        setIsLoading(true)

        if (userName === null || userEmail === null) return
        CreateConsignmentCopy(consignmentId, userName, userEmail).then((response: AxiosResponse<Consignment>) => {
            setConsignmentId(response.data.id.toString())
            navigate(("/consignmentDetails/" + response.data.id.toString()))
        }).finally(() => {
            setIsLoading(false)
            setSelectedSupplier({} as SupplierSelect);
        });
    }

    const handleArhciveConsignment = async (e: any, consignmentId: number) => {
        e.stopPropagation();
        if (window.confirm(`Arhiveeri veotellimus?`)) {
            UpdateConsignmentProperty(consignmentId, "IsArchived", "true")
            onDeleteConsignment();
        }
    }

    const handleUnArhciveConsignment = async (e: any, consignmentId: number) => {
        e.stopPropagation();
        UpdateConsignmentProperty(consignmentId, "IsArchived", "false")
        onDeleteConsignment();
    }

    const goToDirecto = (e: any, directoCode: string) => {
        e.stopPropagation();
        if (directoCode) {
            window.open(`https://login.directo.ee/ocra_ets_nord/event.asp?KOOD=${directoCode}`, '_blank');
        }
        return;
    }
    return (
        <div className='consignment-table--container'>
            <table className="consignment-table--table"  >
                <thead>
                    <tr>
                        <td className='consignment-table--thead thead-left'>
                            <div className='flex-r-start'>
                                <div>Veotellimuse number</div>
                                {isDescendingDirectoCode ?
                                    <IconButton onClick={() => handleSortingChange("directoCode", setIsDescendingDirectoCode, isDescendingDirectoCode)}><NorthIcon /></IconButton>
                                    :
                                    <IconButton onClick={() => handleSortingChange("directoCode", setIsDescendingDirectoCode, isDescendingDirectoCode)}><SouthIcon /></IconButton>
                                }
                            </div>
                        </td>
                        <td className='consignment-table--thead'>
                            <div className='flex-r-start'>
                                <div>Vedaja</div>

                                {isDescendingCarrierName ?
                                    <IconButton onClick={() => handleSortingChange("carrierName", setIsDescendingCarrierName, isDescendingCarrierName)}><NorthIcon /></IconButton>
                                    :
                                    <IconButton onClick={() => handleSortingChange("carrierName", setIsDescendingCarrierName, isDescendingCarrierName)}><SouthIcon /></IconButton>
                                }</div>
                        </td>
                        <td className='consignment-table--thead'>Tüüp</td>
                        <td className='consignment-table--thead'>
                            <div className='flex-r-start'>
                                <div>
                                    Pealelaadimine
                                </div>
                                {isDescendingCollectionDate ?
                                    <IconButton onClick={() => handleSortingChange("collection_date", setIsDescendingCollectionDate, isDescendingCollectionDate)}><NorthIcon /></IconButton>
                                    :
                                    <IconButton onClick={() => handleSortingChange("collection_date", setIsDescendingCollectionDate, isDescendingCollectionDate)}><SouthIcon /></IconButton>
                                }
                            </div>
                        </td>
                        <td className='consignment-table--thead'>
                            <div className='flex-r-start'>
                                <div>Mahalaadimine</div>
                                {isDescendingDeliveryDate ?
                                    <IconButton onClick={() => handleSortingChange("delivery_date", setIsDescendingDeliveryDate, isDescendingDeliveryDate)}><NorthIcon /></IconButton>
                                    :
                                    <IconButton onClick={() => handleSortingChange("delivery_date", setIsDescendingDeliveryDate, isDescendingDeliveryDate)}><SouthIcon /></IconButton>
                                }
                            </div>
                        </td>
                        <td className='consignment-table--thead'>Pealelaadimise linn</td>
                        <td className='consignment-table--thead'>Kauba saaja</td>
                        <td className='consignment-table--thead'>Sihtkoht</td>
                        <td className='consignment-table--thead'>Looja</td>
                        <td className='consignment-table--thead' style={{ textAlign: "center" }}>Staatus</td>
                        <td className='consignment-table--thead thead-right'>Tegevus</td>
                    </tr>
                </thead>
                <tbody >
                    {consignmentListResponseNew.map((x, idx) =>
                        <tr key={idx} className="consignment-table--tr">
                            <td className="consignment-table--td">
                                {x.directoCode !== null ? <IconButton title="Directosse" onClick={(e) => goToDirecto(e, x.directoCode)}><OpenInNewIcon /></IconButton> : ""}
                                <a className='consignment-table--a' rel="noreferrer" target="_blank" href={`${clientUrl}/${x.transportRouteId !== undefined && x.transportRouteId !== 0 ? `transport-route/details/${x.transportRouteId}` : `consignmentDetails/${x.id}`}`}>
                                    {x.directoCode ? x.directoCode : "Pooleli"}
                                </a>
                            </td>
                            <td className="consignment-table--td">
                                {x.carrierName}
                            </td>
                            <td className="consignment-table--td">{x.type}</td>
                            <td className="consignment-table--td">
                                {x.collectionDate !== null &&
                                    (ConvertDate(x.collectionDate).toLocaleDateString('et-EE', {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit"
                                    }))
                                }
                            </td>
                            <td className="consignment-table--td">{x.deliveryDate !== null &&
                                ConvertDate(x.deliveryDate).toLocaleDateString('et-EE', {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit"
                                })
                            }
                            </td>
                            <td className="consignment-table--td">{x.collectionCity}</td>
                            <td className="consignment-table--td">{x.deliveryCompanyName}</td>
                            <td className="consignment-table--td">{x.deliveryCity}</td>
                            <td className="consignment-table--td">{x.consignmentCreator}</td>
                            <td className="consignment-table--td">
                                <div style={{ fontSize: "15px", display: "flex", justifyContent: "center", gap: "5px", flexDirection: "column", alignContent: "center" }}>
                                    {x.consignmentStatus === "TR_ORDER" && x.isEmailSent === false && x.directoCode !== null &&
                                        <div style={{ background: "#e8f7f0", borderRadius: "20px", display: "flex", justifyContent: "center", alignItems: "center", color: "#3eb668", whiteSpace: "nowrap", minWidth: "78px" }}>Directos
                                        </div>
                                    }
                                    {x.directoCode === null &&
                                        <div style={{ background: "#fbfbde", borderRadius: "20px", display: "flex", justifyContent: "center", alignItems: "center", color: "#be5c23", whiteSpace: "nowrap" }}>Pooleli
                                        </div>
                                    }
                                    {x.consignmentStatus === "TR_ORDER" && x.isEmailSent === true &&
                                        <div style={{ background: "#3eb668", borderRadius: "20px", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", whiteSpace: "nowrap" }}>Email saadetud
                                        </div>
                                    }
                                    {x.consignmentStatus === "TR_ORDER" && x.cargosonReturnId !== null &&
                                        <div style={{ background: "#3eb668", borderRadius: "20px", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", whiteSpace: "nowrap" }}>Cargosonis
                                        </div>
                                    }
                                    {x.transportRouteId > 0 &&
                                        <div style={{ background: "#3e84b6", borderRadius: "20px", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", whiteSpace: "nowrap" }}>Veoringis
                                        </div>
                                    }
                                </div>
                            </td>
                            <td >
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <IconButton title="Võta koopiana aluseks" onClick={(e: any) => handleCreateConsignmentCopy(e, +x.id)}>
                                        <CopyAllIcon />
                                    </IconButton>
                                    {x.directoCode === null &&
                                        <IconButton title="Kustuta" onClick={(e: any) => handleRemoveConsignment(e, +x.id)}>
                                            <CloseIcon />
                                        </IconButton>
                                    }
                                    {(x.directoCode !== null && x.isArchived === false) && (
                                        <IconButton title="Arhiveeri" onClick={(e: any) => handleArhciveConsignment(e, +x.id)}>
                                            <CloseIcon />
                                        </IconButton>)
                                    }
                                    {(x.isArchived === true) && (
                                        <IconButton title="Võta tagasi" onClick={(e: any) => handleUnArhciveConsignment(e, +x.id)}>
                                            <AddIcon />
                                        </IconButton>)
                                    }
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div >
    )
}

export default ConsignmentsTable