import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react'
import { TransportRoute } from '../../Types/TransportRoute';
import { useDataContext } from '../../context/DataContext';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import "../../styles/tuusula-view.css"
import CloseIcon from '@mui/icons-material/Close';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { ConvertDate } from '../common/ConvertDate';
import { useTranslation } from 'react-i18next';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Button, IconButton } from '@mui/material';
import { GetRecommendedTransportRoutes, AddConsignmentToTransportRoute, AddConsignmentToNewTransportRoute, GetTransportRoute, RemoveConsignmentFromTransportRoute, AddNewConsignmentToTransportRoute, UpdateTransportRouteProperty } from '../../queries/transport-route-queries';
import { SaveConsignmentList, UpdateConsignmentProperty } from '../../queries/queries';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type Props = {
    isUserPermitted: boolean
    originCountry: string
}

const AddToTransportRoute = ({ isUserPermitted, originCountry }: Props) => {
    const { t } = useTranslation();
    const [addToTransportRoute, setAddToTransportRoute] = useState<boolean>(false);
    const [recommendedTransportRoutes, setRecommendedTransportRoute] = useState<TransportRoute[]>([]);
    const { consignmentList } = useDataContext();
    const [relatedTransportRoute, setRelatedTransportRoute] = useState<TransportRoute>();
    const [titleValue, setTitleValue] = useState<string>(relatedTransportRoute?.title!)
    const [changeTitleValue, setChangeTitleValue] = useState<boolean>(false)
    const [transportRouteTitle, setTransportRouteTitle] = useState<string>(`${consignmentList[0].cargosonData.pickups[0].collection_date} ${consignmentList[0].cargosonData.delivery_city !== null ? `- ${consignmentList[0].cargosonData.delivery_city}` : `- ${t("Veoring")}`}`);
    const [isInTransportRoute, setIsInTransportRoute] = useState<boolean>(consignmentList[0].transportRouteId !== 0 ? true : false)
    let tempPosition = 0;
    const navigate = useNavigate();

    const fetchRecommendedTransportRoutes = () => {
        const collectionDate = consignmentList[0].cargosonData.pickups[0].collection_date;
        GetRecommendedTransportRoutes(collectionDate, originCountry).then((res: AxiosResponse<TransportRoute[]>) => {
            setAddToTransportRoute(true)
            setRecommendedTransportRoute(() => res.data)
        });
    }

    const handleAddToTransportRoute = async (consignmentId: number, transportRouteId: number) => {
        //add's current consignment with selected trnasportRoute
        await SaveConsignmentList(consignmentList)
        await AddConsignmentToTransportRoute(consignmentId, transportRouteId).then(() => {
            fetchTransportRoute(transportRouteId)
        })
        setIsInTransportRoute(true)
    }

    const handleAddToNewTransportRoute = async (consignmentId: number, title: string) => {
        //creates new transportroute and binds it with this consignment
        const user = localStorage.getItem("user")
        const email = localStorage.getItem("email");
        await AddConsignmentToNewTransportRoute(consignmentId, user!, email!, title, originCountry).then((res: AxiosResponse<TransportRoute>) => {
            fetchTransportRoute(res.data.id)
        })
        //todo fetch transport route or set response data to transport route
        setIsInTransportRoute(true)
    }

    const handleNewConsignmentToTransportRoute = async (transportRouteId: number) => {

        await AddNewConsignmentToTransportRoute(transportRouteId, originCountry).then((res: AxiosResponse<TransportRoute>) => {
            fetchTransportRoute(res.data.id)
        })

        //todo fetch transport route or set response data to transport route
        setIsInTransportRoute(true)
    }

    const fetchTransportRoute = async (transportRouteId: number) => {
        if (transportRouteId === 0) return;
        await GetTransportRoute(transportRouteId).then((res: AxiosResponse<TransportRoute>) => {
            setRelatedTransportRoute(res.data);
            setTitleValue(res.data.title)
        })
    }

    const handleLoadConsignment = (consignmentId: string) => {
        if (originCountry === "FI") {
            navigate(("/tuusula/" + consignmentId))
        }
        if (originCountry === "SE") {
            navigate(("/sweden/transport-orders/" + consignmentId))
        }
    }

    const handleRemoveConsignment = (consignmentId: number, transportRouteId: number, idx: number) => {
        if (isUserPermitted === false) {
            alert("You don't have enough permissions")
            return;
        }
        const currentConsignmentId = consignmentList[0].id
        RemoveConsignmentFromTransportRoute(consignmentId, transportRouteId).then((res: AxiosResponse<TransportRoute>) => {

            if (currentConsignmentId.toString() === relatedTransportRoute?.consignment[idx].id.toString()) {
                setRelatedTransportRoute(() => res.data)
                setIsInTransportRoute(false)
                fetchRecommendedTransportRoutes()
                return;
            }
            setRelatedTransportRoute(() => res.data)

        })
    }

    const handleTableRowBackgroundColor = (consignmentId: string) => {
        if (consignmentId === consignmentList[0].id) {
            return "#dddddd"
        }
        return "inherit"
    }

    const handleEditTitle = (transportRouteId: number) => {
        if (titleValue === undefined) return;
        UpdateTransportRouteProperty(transportRouteId, "Title", titleValue).then(() => {
            setChangeTitleValue(true);
        });
    };

    const handleTitleInputBlur = (transportRouteId: number) => {
        if (titleValue === undefined) return;
        if (transportRouteId !== undefined) {
            UpdateTransportRouteProperty(transportRouteId, "Title", titleValue)
                .then(() => {
                    //setChangeTitleValue(false);
                })
                .catch(error => {
                    // Handle API call error if necessary
                    console.error(error);
                });
        }
    };

    const handleChangeOrder = async (e: any, index: number, consignmentId: string, type: string) => {
        e.stopPropagation();
        if (index >= 0 && relatedTransportRoute?.consignment) {
            const consignmentArr = [...relatedTransportRoute.consignment];
            const currentPostion = consignmentArr[index].consignmentPosition;

            if (type === "Decrease" && index < consignmentArr.length - 1) {
                // Decrease the order: Swap positions with the next consignment
                const nextConsignment = consignmentArr[index + 1];
                const newPosition = nextConsignment.consignmentPosition;
                nextConsignment.consignmentPosition = currentPostion;
                await UpdateConsignmentProperty(+nextConsignment.id, "ConsignmentPosition", newPosition.toString());
            } else if (type === "Raise" && index > 0) {
                // Raise the order: Swap positions with the previous consignment
                const previousConsignment = consignmentArr[index - 1];
                const newPosition = previousConsignment.consignmentPosition;
                previousConsignment.consignmentPosition = currentPostion;
                await UpdateConsignmentProperty(+previousConsignment.id, "ConsignmentPosition", newPosition.toString());
            } else if (type === "Raise" && index === consignmentArr.length - 1) {
                // Special case: Moving the last consignment to the second position
                const newPosition = 1; // Set the new position (assuming 0-based indexing)
                consignmentArr[index].consignmentPosition = newPosition;
                await UpdateConsignmentProperty(+consignmentId, "ConsignmentPosition", newPosition.toString());
            }



            // Update the current consignment's position
            await UpdateConsignmentProperty(+consignmentId, "ConsignmentPosition", consignmentArr[index].consignmentPosition.toString());

            // Sort the consignmentArr based on consignmentPosition
            consignmentArr.sort((a, b) => a.consignmentPosition - b.consignmentPosition);

            // Update all consignments' positions in the transport route
            await Promise.all(
                consignmentArr.map(async (consignment, idx) => {
                    await UpdateConsignmentProperty(+consignment.id, "ConsignmentPosition", idx.toString());
                })
            );

            // Refetch the transport route data to update the UI
            await fetchTransportRoute(relatedTransportRoute.id);

        }
    };



    useEffect(() => {
        fetchTransportRoute(consignmentList[0].transportRouteId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempPosition])

    useEffect(() => {
        fetchRecommendedTransportRoutes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consignmentList[0].cargosonData.pickups[0].collection_date])



    return (
        <div className='transport-order--block' style={{ gap: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start" }}>
            <div className='page__heading'>{t("Veoringi lisamine")}</div>
            {isInTransportRoute ?
                <div className='transport-route-container' >
                    <h3 className='heading-larger'>{t("Veotellimus lisatud veoringi")}</h3>
                    <div className='transport-route-container-block'>
                        <div className='heading-regular'>
                            {t("Veoringi nimetus")}: {changeTitleValue ? (
                                <input
                                    id="titleInput"
                                    className='transport-route-title--input'
                                    value={titleValue ?? ""}
                                    onChange={(e: any) => setTitleValue(e.target.value)}
                                    onBlur={() => handleTitleInputBlur(relatedTransportRoute?.id!)}
                                    autoFocus
                                />
                            ) : (
                                relatedTransportRoute?.title
                            )}
                            <IconButton size='small' onClick={() => handleEditTitle(relatedTransportRoute?.id!)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                        <h4 className='heading-regular'>
                            {t("Veoringis sisalduvad veotellimused")}:
                        </h4>

                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "10px" }}>
                            {relatedTransportRoute?.consignment
                                .sort((a, b) => b.consignmentPosition - a.consignmentPosition) // Sort consignment based on 'consignmentOrder' property
                                .map((x, idx) => (
                                    <div key={idx} style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div className="transport-route-container--row" onClick={() => handleLoadConsignment(x.id)} style={{ backgroundColor: `${handleTableRowBackgroundColor(x.id)}` }}>
                                            {t("Veotellimus")}: {x.cargosonData.pickups[0].collection_city} <EastIcon /> {x.cargosonData.delivery_city} {ConvertDate(x.cargosonData.delivery_date).toLocaleDateString('et-EE')}
                                            <div className="transport-route-container__row-button-container">
                                                <IconButton onClick={() => handleLoadConsignment(x.id)} title={t("Vaata veotellimust")}>
                                                    <ZoomInIcon />
                                                </IconButton>
                                                <IconButton onClick={(e) => handleChangeOrder(e, idx, x.id, "Decrease")} title="Lower Order">
                                                    <ArrowDownwardIcon />
                                                </IconButton>
                                                <IconButton onClick={(e) => handleChangeOrder(e, idx, x.id, "Raise")} title="Raise Order">
                                                    <ArrowUpwardIcon />
                                                </IconButton>

                                            </div>
                                        </div>
                                        <IconButton onClick={() => handleRemoveConsignment(+x.id, x.transportRouteId, idx)} title={"Remove from the transport route"}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                ))}
                        </div>

                        <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                            <Button variant="contained" style={{ background: "#c00", color: "#fff", fontWeight: "bold" }} onClick={() => handleNewConsignmentToTransportRoute(relatedTransportRoute?.id!)}>{t("Lisa juurde")}</Button>
                        </div>
                    </div>
                </div> :
                <>
                    {addToTransportRoute === false &&
                        <Button variant="contained" style={{ background: "gray", color: "white", height: "50px" }} onClick={fetchRecommendedTransportRoutes}>{t("Lisa veoringi")}</Button>
                    }
                </>
            }

            {
                (!isInTransportRoute && addToTransportRoute) &&
                <div className='transport-route-container--adding-block'>
                    {recommendedTransportRoutes.length > 0 &&
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "5px", alignItems: "center", width: "100%" }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                                <label className='text-regular' style={{ marginBottom: "4px", fontWeight: "bold" }}>{t("Lisa olemasolevasse veoringi")}</label>
                            </div>

                            {recommendedTransportRoutes.map((x, idx) =>
                                <div key={idx} style={{
                                    border: "1px solid #dee2e6", borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px"
                                    , fontSize: ".9rem", fontWeight: "400", lineHeight: "1.5", color: "#6c757d", paddingLeft: "5px", width: "100%"
                                }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
                                        {t("Veoring")}: {x.id} - {x.consignment[0].cargosonData.pickups[0].collection_date}
                                        &#160;
                                        {(x.consignment).map((y, idx) => (
                                            <div key={idx} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                {y.cargosonData.delivery_city} &#160;
                                            </div>
                                        ))}
                                    </div>

                                    {isUserPermitted &&
                                        <IconButton title={t("Lisa olemasolevasse veoringi")} onClick={() => handleAddToTransportRoute(+consignmentList[0].id, x.id)}>
                                            <LibraryAddIcon />
                                        </IconButton>
                                    }
                                </div>
                            )}
                        </div>
                    }

                    <div style={{ marginTop: "5px", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                            <label className='text-regular' style={{ marginBottom: "4px", fontWeight: "bold" }}>{t("Loo uus nimega veoring")}</label>

                        </div>
                        <div className='transport-route-container--new-route-block'>
                            <input className='input-addressForm' type="text" title={t("Veoringi nimi")} name="transportRouteTitle" style={{ height: "50px", padding: "5px", border: "none", cursor: "pointer" }}
                                value={transportRouteTitle} onChange={(e: any) => setTransportRouteTitle(e.target.value)} />
                            {isUserPermitted &&
                                <IconButton title={t("Lisa uude veoringi")} onClick={() => handleAddToNewTransportRoute(+consignmentList[0].id, transportRouteTitle)}>
                                    <LibraryAddIcon />
                                </IconButton>
                            }
                        </div>
                    </div>
                </div>
            }



        </div >
    )
}

export default AddToTransportRoute