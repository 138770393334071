import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { GetActiveDeliveryDrivers, GetLoaderCalendarConsignments } from '../../../queries/deliveryDriverQueries';
import { DeliveryDriver } from '../../../Types/delivery-app/DeliveryDriver';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import '../../../styles/tracking-app/loader-page.css'
import { ConvertDate } from '../../common/ConvertDate';
import WeekCalendar from '../logistician/WeekCalendar';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../login/user';
import DeliveryOrderLoader from './DeliveryOrderLoader';

const LoaderMobileCalendarView = () => {
    const location = useLocation();
    const { permissions } = useCustomer();
    const [deliveryDrivers, setDeliveryDrivers] = useState<DeliveryDriver[]>([]);
    /* const hours = Array.from({ length: 24 }, (_, index) => index); */
    const hours = Array.from({ length: 13 }, (_, index) => index + 6);
    const navigate = useNavigate();
    const [plannedConsignments, setPlannedConsignments] = useState<Record<number, Record<number, InHouseConsignment[]>>>();
    const queryParams = new URLSearchParams(location.search);
    const calendardate = queryParams.get("calendardate");
    const [calendarDate, setCalendarDate] = useState<Date>(calendardate ? ConvertDate(calendardate) : new Date());
    const [selectedCar, setSelectedCar] = useState<number>(0)
    const handleGetAllDeliveryDrivers = () => {
        GetActiveDeliveryDrivers().then((response) => {
            setDeliveryDrivers(response.data)
        })
    }

    const handleGetCalendarConsignments = () => {
        var date = calendarDate.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' })
        GetLoaderCalendarConsignments(date).then((res: any) => {
            setPlannedConsignments(res.data)
        }).catch((err: any) => {
            setPlannedConsignments([])
        })
    }

    const handleNavigate = (id: number) => {
        navigate(`/loader/delivery-details/${id}`)
    }

    const handleDeliveryDate = (date: Date) => {
        setCalendarDate(date);

        const formattedDate = date.toLocaleString("et-EE", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        navigate("/loader?calendardate=" + formattedDate)
    };

    useEffect(() => {
        handleGetAllDeliveryDrivers()
        handleGetCalendarConsignments()
    }, [calendarDate])

    if (!permissions.includes("PEstonianWareHouseWorker")) {
        return <Navigate to="/unauthorized" />
    }

    return (
        <>
            {(plannedConsignments !== null && plannedConsignments !== undefined) &&
                <div className='flex-col justify-center items-center  w-100 text-reg'>
                    <div className='flex items-center justify-center w-100' style={{ background: "#e10008" }}>
                        <WeekCalendar setCalendarDate={setCalendarDate} calendarDate={calendarDate} handleDateChange={handleDeliveryDate} />
                    </div>
                    <div className='flex-col justify-center items-center gap-xs' style={{ gap: "2%", marginBottom: "50px" }}>
                        <div className='tex-reg' style={{ fontSize: "20px" }}>Vali auto</div>
                        <div className='flex justify-center items-center gap-xs'>
                            {deliveryDrivers.map((driver, idx) => (
                                <div key={idx} className='text-reg flex justify-center items-center' onClick={() => setSelectedCar(driver.id)}
                                    style={{ cursor: "pointer", textAlign: "center", fontSize: "22px", padding: "5px", marginTop: "2%", color: "#fff", background: `${selectedCar === driver.id ? "#e10008" : "#737372"}`, borderRadius: "10px", width: "fit-content", height: "60px" }}>{driver.carLicenseNumber}-{driver.carName}</div>
                            ))}
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-start" }}>

                        {deliveryDrivers.filter(driver => driver.id === selectedCar).map((driver) => (
                            <div key={driver.id} className="flex-col justify-center" style={{ width: "100%" }}>
                                {hours.map(hour => (


                                    <div key={hour} className="flex-col justify-center items-center">
                                        {plannedConsignments[driver.id] && plannedConsignments[driver.id][hour]?.filter(consignment => consignment.consignmentStatus !== ConsignmentStatus.Delivered && consignment.consignmentStatus !== ConsignmentStatus.Finished).map((delivery, index) => (
                                            <DeliveryOrderLoader key={index} deliveryData={delivery} handleNavigate={() => handleNavigate(delivery.id)} />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                </div >
            }
        </>
    )
}

export default LoaderMobileCalendarView