/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import '../../../styles/Consignment.css'
import { SearchOrderWithOrderNumber } from '../../../queries/directo-queries';
import { Order } from '../../../Types/Order';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { IconButton, Button } from '@mui/material';
import Modal from '../../common/Modal';
import ItemsModal from './ItemsModal';
import React from 'react';
import { AddMultipleDeliveriesToInHouseConsignment } from '../../../queries/deliveryDriverQueries';
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';

type Props = {
    orderNumber: string;
    inHouseConsignment: InHouseConsignment;
    setInHouseConsignment: React.Dispatch<React.SetStateAction<InHouseConsignment>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const OrderDetailsModal = ({ orderNumber, inHouseConsignment, setInHouseConsignment, setShowModal }: Props) => {
    const [orderData, setOrderData] = useState<Order[]>([]);
    const [showItemsModal, setShowItemsModal] = useState<boolean>(false);
    const [deliveryToShow, setDeliveryToShow] = useState<string>("");
    const [deliveriesToAdd, setDeliveriesToAdd] = useState<string[]>(inHouseConsignment.directoDeliveryNumbers ?? []);

    const handleUpdateArray = (e: any, item: string) => {
        if (e.target.checked === true) {
            setDeliveriesToAdd([...deliveriesToAdd, item]);
        }
        if (e.target.checked === false) {
            const newArr = deliveriesToAdd.filter((t) => t !== item);
            setDeliveriesToAdd(newArr);
        }
    }

    const handleAddDeliveries = async () => {
        if (deliveriesToAdd.length === 0) return;
        const multipleDeliveries = {
            "id": inHouseConsignment.id,
            "deliveryNumbers": deliveriesToAdd
        };
        await AddMultipleDeliveriesToInHouseConsignment(multipleDeliveries).then(
            (response) => {
                setInHouseConsignment({ ...inHouseConsignment, directoDeliveryNumbers: response.data.directoDeliveryNumbers, deliveries: response.data.deliveries })
            }
        ).catch(err => { console.log(err); alert(err.response.data) }).finally(() => {
            setShowModal(false)
        });
    };

    const openItemsModal = (deliveryNumber: string) => {
        setShowItemsModal(true)
        setDeliveryToShow(deliveryNumber)
    }

    useEffect(() => {
        const handleGetDirectoSalesOrder = () => {
            SearchOrderWithOrderNumber(orderNumber).then(response => {
                setOrderData(() => response.data)
            });
        }
        handleGetDirectoSalesOrder()
    }, [])

    if (orderData === undefined || orderData === null) {
        return <></>
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div className="search-table-content__table-thead--td left">Lisa lähetused</div>
            {Object.values(orderData).map((value, idx) => (
                <React.Fragment key={idx}>
                    <table style={{ borderCollapse: "collapse", width: "100%", color: "#6c757d", borderTop: "1px solid #dee2e6" }}>
                        <thead>
                            <tr className="deliveriesTable-head-tr">
                                <td className="search-table-content__table-thead--td left padding-xs">Lähetuse number</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(value.orderDeliveries).map((x, idx) => (
                                <tr key={idx} className="search-table-content__table--tr">
                                    <td className="search-table-content__table--outer-td left padding-xs">
                                        <IconButton onClick={() => openItemsModal(x.number)}>
                                            <ZoomInIcon style={{ color: "gray" }} />
                                        </IconButton>
                                        {x.number}
                                    </td>
                                    <td className="search-table-content__table--outer-td left padding-xs" style={{ borderLeft: "none" }}>
                                        <input type="checkbox" style={{ width: "20px", height: "20px", cursor: "pointer" }} checked={deliveriesToAdd.includes(x.number)}
                                            onChange={(e: any) => handleUpdateArray(e, x.number)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Modal show={showItemsModal} onClose={() => setShowItemsModal(!showItemsModal)}>
                        <ItemsModal deliveryNumber={deliveryToShow} />
                    </Modal>
                    <Button style={{ background: "#c00", fontWeight: "bold", color: "#fff", marginTop: "1%", display: "flex", width: "100%" }} onClick={handleAddDeliveries}>Lisa</Button>
                </React.Fragment>
            ))
            }
        </div >
    );
}

export default OrderDetailsModal;