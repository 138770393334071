import { IconButton } from '@mui/material';
import React, { useState } from 'react'
import { useDataContext } from '../../../../context/DataContext';
import '../../../../styles/Consignment.css'
import ClearIcon from '@mui/icons-material/Clear';
import EastIcon from '@mui/icons-material/East';
import { AxiosResponse } from 'axios';
import { Consignment } from '../../../../Types/Consignment';
import { GetStockMovement, AddStockMovementToConsignment, RemoveStockMovementFromConsignment } from '../../../../queries/directo-queries';

const AddStockMovementNew = (props: { listIndex: number }) => {
    const [stockMovementNumber, setStockMovementNumber] = useState<string>();
    const { consignmentList, setConsignmentList } = useDataContext();
    const consignment = consignmentList[props.listIndex];

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!stockMovementNumber) return;
        if (consignmentList[props.listIndex].stockMovementNumbers.includes(stockMovementNumber)) return;
        try {
            GetStockMovement(stockMovementNumber).then((response: any) => {
                setStockMovementNumber("")

                AddStockMovementToConsignment(stockMovementNumber, +consignment.id).then(
                    (response: AxiosResponse<Consignment>) => {
                        setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                            ...item,
                            stockMovementNumbers: response.data.stockMovementNumbers,
                            stockMovements: response.data.stockMovements
                        } : item));
                        setStockMovementNumber(() => "")
                    }
                ).catch(err => { console.log(err); alert("Ei saanud laoliikumist lisada") });
            }).catch((error) => {
                alert("Ei leitud laoliikumist")
            });

        } catch (error) {
            alert("Ei leitud laoliikumist");
        }
    };

    const handleRemoveStockMovement = (stockMovementNumber: string) => {
        RemoveStockMovementFromConsignment(stockMovementNumber, +consignment.id).then(
            (response: AxiosResponse<Consignment>) => {
                setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                    ...item,
                    stockMovementNumbers: response.data.stockMovementNumbers,
                    stockMovements: response.data.stockMovements
                } : item));
            }
        ).catch(err => { console.log(err); alert("Eemaldamine ebaõnnestus") });
    }

    return (
        <div style={{ border: "1px solid #e2e2e2", padding: "20px", minHeight: "130px", backgroundColor: "#fff" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <input type="number" style={{ border: "none", borderBottom: "1px solid #e2e2e2" }} placeholder='Lisa laoliikumine' onChange={(e) => setStockMovementNumber(e.target.value)} />
                    <IconButton type="submit">
                        <EastIcon style={{ color: "gray" }} />
                    </IconButton>
                </div>
            </form>
            <div >
                {consignmentList[props.listIndex].stockMovementNumbers?.map((x, idx) => (
                    <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #e2e2e2" }}>
                        <div >{x}</div>
                        <IconButton title="Eemalda" onClick={() => handleRemoveStockMovement(x)}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AddStockMovementNew