import { Button } from '@mui/material'
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDataContext } from '../../context/DataContext';
import { CreateSwedenConsignment } from '../../queries/queries';
import { Consignment } from '../../Types/Consignment';
import { Supplier } from '../../Types/Supplier';
import { SupplierSelect } from '../../Types/SupplierSelect';
import { useCustomer } from '../login/user';

const CreateSwedenTransportOrder = (props: { alignItems: string }) => {
    const { setIsLoading, setConsignmentId, setSupplier, setSelectedSupplier } = useDataContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isSwedishUserPermitted } = useCustomer()

    const handleCreateConsignment = () => {
        setIsLoading(true)
        var consignmentType = "Export"
        CreateSwedenConsignment(consignmentType).then((response: AxiosResponse<Consignment>) => {
            setConsignmentId(response.data.id.toString())
            setSupplier({} as Supplier)
            setSelectedSupplier({} as SupplierSelect)
            navigate(("/sweden/transport-orders/" + response.data.id.toString()))

        }).finally(() => { setIsLoading(false) });
    }

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: `${props.alignItems}` }}>
            <Button style={{ background: "#C00", color: "white", width: "175px", fontWeight: "bold" }} onClick={handleCreateConsignment} disabled={!isSwedishUserPermitted()}>{t("Uus transporditellimus")}</Button>
        </div>
    )
}

export default CreateSwedenTransportOrder