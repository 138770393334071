import { Dialog, DialogTitle, DialogContent, Grid, InputLabel, MenuItem, Checkbox, DialogActions, Button } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDataContext } from '../../context/DataContext';
import { addUser, updateUser } from '../../queries/adminQueries';
import { AssignUserToDeliveryDriver, GetAllDeliveryDrivers } from '../../queries/deliveryDriverQueries';
import { DeliveryDriver } from '../../Types/delivery-app/DeliveryDriver';
import { User } from '../../Types/user/User';
import { StyledTextField, StyledSelect } from './AddUserDialog';

interface Props {
    open: boolean;
    onClose: () => void;
    user: User;
    setUser: Dispatch<SetStateAction<User>>
}
const DeliveryDriverDialog = ({ open, onClose, user, setUser }: Props) => {
    const { setIsLoading } = useDataContext()
    const [cars, setCars] = useState<DeliveryDriver[]>([]);
    const [selectedCar, setSelectedCar] = useState<number>(0);

    const handleInputChange = (e: any) => {
        setSelectedCar(+e.target.value)
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        addUser(user).then((res) => {
            onClose()
        }).catch((err) => { console.log(err) }).finally(() => { setIsLoading(false) });
    };

    const handleAssignUserToDeliveryDriver = (e: any, userId: string) => {
        e.preventDefault();
        if (selectedCar === 0) return;
        AssignUserToDeliveryDriver(userId, selectedCar).then((res) => {
            onClose()
        }).catch((err) => { console.log(err) }).finally(() => { setIsLoading(false) });
    }

    useEffect(() => {
        const handleGetAllDeliveryDrivers = () => {
            GetAllDeliveryDrivers().then((res) => {
                onClose()
                setCars(res.data)
            }).catch((err) => { console.log(err) }).finally(() => { setIsLoading(false) });
        }
        handleGetAllDeliveryDrivers()
    }, [])

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="add-user-dialog">
            <DialogTitle id="add-user-dialog">Make user a delivery driver</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit} >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <StyledTextField
                                fullWidth
                                label="Full name"
                                name="fullName"
                                variant="outlined"
                                type="search"
                                aria-readonly={true}
                                value={user.fullName}
                                sx={{ marginTop: "15px" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputLabel htmlFor="delivery-car">Delivery car</InputLabel>
                            <StyledSelect
                                fullWidth
                                labelId="delivery-car"
                                id="delivery-car"
                                value={selectedCar}
                                placeholder='Delivery car'
                                required={true}
                                onChange={(e: any) => handleInputChange(e)}
                            >
                                {cars.map((x, idx) => (
                                    <MenuItem key={idx} value={x.id}> {x.carName} {x.carLicenseNumber}</MenuItem>
                                ))}
                            </StyledSelect>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary" sx={{ color: "#000" }}>
                            Cancel
                        </Button>

                        {user.userId !== undefined ?
                            <Button onClick={(e: any) => handleAssignUserToDeliveryDriver(e, user.userId)} variant="contained" color="primary" sx={{
                                backgroundColor: "#c00", "&:hover": {
                                    backgroundColor: "#c00"
                                }
                            }}>
                                Modify user
                            </Button> :

                            <Button type="submit" variant="contained" color="primary" sx={{
                                backgroundColor: "#c00", "&:hover": {
                                    backgroundColor: "#c00"
                                }
                            }}>
                                Add new user
                            </Button>
                        }
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default DeliveryDriverDialog