import React, { createContext, useContext, useState } from 'react'
import Loading from '../components/common/Loading';
import Layout from '../layout/Layout';
import { CargosonError } from '../Types/cargoson/CargosonError';
import { CargosonPriceList } from '../Types/cargoson/CargosonPriceList';
import { CargosonQuery, CargosonChild } from '../Types/cargoson/CargosonQuery';
import { CargosonQueryResponse2 } from '../Types/cargoson/CargosonQueryResponse2';
import { Package } from '../Types/cargoson/Package';
import { Consignment } from '../Types/Consignment';
import { ConsignmentList } from '../Types/ConsignmentList';
import { Delivery } from '../Types/Delivery';
import { Order } from '../Types/Order';
import { Supplier } from '../Types/Supplier';
import { SupplierSelect } from '../Types/SupplierSelect';
import { TransportRoute } from '../Types/TransportRoute';

interface props {
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isSearchOpen: boolean,
    setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>,
    consignmentData: Consignment,
    setConsignmentData: React.Dispatch<React.SetStateAction<Consignment>>
    cargosonData: CargosonQuery,
    setCargosonData: React.Dispatch<React.SetStateAction<CargosonQuery>>,
    transportState: string,
    setTransportState: React.Dispatch<React.SetStateAction<string>>,
    consignmentList: Consignment[],
    setConsignmentList: React.Dispatch<React.SetStateAction<Consignment[]>>,
    updateConsignment: (listIndex: number, newData: any) => void,
    updateConsignmentData: (listIndex: number, targetName: any, targetValue: any) => void
    cargosonDataList: CargosonQuery[],
    setCargosonDataList: React.Dispatch<React.SetStateAction<CargosonQuery[]>>,
    updateCargosonDataConsignment: (listIndex: number, targetName: any, targetValue: any) => void,
    updatePickups: (pickupIdx: number, listIndex: number, targetName: any, targetValue: any) => void
    updatePackages: (pickupIdx: number, listIndex: number, packageToAdd: Package) => void
    transportRouteId: string,
    setTransportRouteId: React.Dispatch<React.SetStateAction<string>>,
    consignmentId: string,
    setConsignmentId: React.Dispatch<React.SetStateAction<string>>,
    cargosonPricelist: CargosonPriceList[],
    setCargosonPricelist: React.Dispatch<React.SetStateAction<CargosonPriceList[]>>,
    cargosonQueryResponse: CargosonQueryResponse2[],
    setCargosonQueryResponse: React.Dispatch<React.SetStateAction<CargosonQueryResponse2[]>>,
    supplier: Supplier,
    setSupplier: React.Dispatch<React.SetStateAction<Supplier>>,
    selectedSupplier: SupplierSelect,
    setSelectedSupplier: React.Dispatch<React.SetStateAction<SupplierSelect>>,
    updateSupplierInfo: (supplier: Supplier) => void,
    removeSupplierInfo: () => void,
    errors: CargosonError[],
    setErrors: React.Dispatch<React.SetStateAction<CargosonError[]>>,
    returnDirectoCode: string,
    setReturnDirectoCode: React.Dispatch<React.SetStateAction<string>>,
    transportRouteData: TransportRoute,
    setTransportRouteData: React.Dispatch<TransportRoute>
    setTransportRouteList: React.Dispatch<React.SetStateAction<TransportRoute[]>>,
    transportRouteList: TransportRoute[],
    setConsignmentListResponse: React.Dispatch<React.SetStateAction<Consignment[]>>,
    consignmentListResponse: Consignment[],
    consignmentListResponseNew: ConsignmentList[],
    setConsignmentListResponseNew: React.Dispatch<React.SetStateAction<ConsignmentList[]>>,
}
export const DataContext = createContext({} as props);
export const useDataContext = () => useContext(DataContext);

export const DataContextWrapper = ({ children }: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [consignmentData, setConsignmentData] = useState<Consignment>({ orders: [] as Order[], deliveries: [] as Delivery[] } as Consignment); // üle vaadata mis kohad kasutavad seda veel
    const [cargosonData, setCargosonData] = useState<CargosonQuery>({ pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery);
    const [transportState, setTransportState] = useState<string>("");
    const [transportRouteId, setTransportRouteId] = useState<string>("");
    const [consignmentId, setConsignmentId] = useState<string>("");
    const [consignmentListResponse, setConsignmentListResponse] = useState<Consignment[]>([]);
    const [consignmentListResponseNew, setConsignmentListResponseNew] = useState<ConsignmentList[]>([]);
    const [consignmentList, setConsignmentList] = useState<Consignment[]>([{ cargosonData: { pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery } as Consignment] as Consignment[]);
    const [cargosonDataList, setCargosonDataList] = useState<CargosonQuery[]>([{ pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery]);
    const [cargosonPricelist, setCargosonPricelist] = useState<CargosonPriceList[]>([{} as CargosonPriceList] as CargosonPriceList[]);
    const [cargosonQueryResponse, setCargosonQueryResponse] = useState<CargosonQueryResponse2[]>([]);
    const [supplier, setSupplier] = useState<Supplier>({} as Supplier);
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierSelect>(supplier ? { label: supplier.name, value: supplier } as SupplierSelect : {} as SupplierSelect);
    const [errors, setErrors] = useState<CargosonError[]>([]);
    const [returnDirectoCode, setReturnDirectoCode] = useState<string>("");
    const [transportRouteData, setTransportRouteData] = useState<TransportRoute>({} as TransportRoute);
    const [transportRouteList, setTransportRouteList] = useState<TransportRoute[]>([]);

    const updateConsignment = (listIndex: number, newData: Consignment) => {
        /* ---find consignment in list by index then overwrite old data with newData--- */
        setConsignmentList(consignmentList.map((item, idx) => idx === listIndex ? newData : item));
    }

    const updateConsignmentData = (listIndex: number, targetName: any, targetValue: any) => {
        /* ---find consignment in list by index then overwrite old target data with new data--- */
        setConsignmentList(consignmentList.map((item, idx) => idx === listIndex ? {
            ...item,
            [targetName]: targetValue
        } : item));
    }

    const updateCargosonDataConsignment = (listIndex: number, targetName: any, targetValue: any) => {
        /* ---find consignment in list by index then overwrite old cargoson data with new cargoson data--- */
        var tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
            ...item,
            cargosonData: { ...item.cargosonData, [targetName]: targetValue }
        } : item);
        setConsignmentList(tempArr);
    }

    const updatePickups = (pickupIdx: number, listIndex: number, targetName: any, targetValue: any) => {
        var tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
            ...item,
            cargosonData: {
                ...item.cargosonData,
                pickups: [
                    ...item.cargosonData.pickups.map((pickup, i) => i === pickupIdx ? {
                        ...pickup,
                        [targetName]: targetValue
                    } : pickup)
                ]
            }
        } : item);
        setConsignmentList(tempArr)
    }

    const updatePackages = (pickupIdx: number, listIndex: number, packageToAdd: Package) => {
        const newPackages = [...consignmentList[listIndex].cargosonData.pickups[pickupIdx].rows_attributes]
        newPackages.push(packageToAdd)
        var tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
            ...item,
            cargosonData: {
                ...item.cargosonData,
                pickups: [
                    ...item.cargosonData.pickups.map((pickup, i) => i === pickupIdx ? {
                        ...pickup,
                        "rows_attributes": newPackages
                    } : pickup)
                ]
            }
        } : item);
        setConsignmentList(tempArr)
    }

    const updateSupplierInfo = (supplier: Supplier) => {
        setSupplier(supplier)
        setConsignmentList(consignmentList.map((item, idx) => {

            return {
                ...item,
                "cargosonTransportId": supplier.id,
                "carrierContactId": null,
                "carrierCode": supplier.code,
                "carrierName": supplier.name,
                "carrierRegNr": supplier.regNr,
                "carrierPhone": "",
                "carrierEmail": "",
                "carrierRepresentative": "",
                cargosonData: { ...item.cargosonData, "serviceId": supplier.serviceId }
            }
        }))
    }

    const removeSupplierInfo = () => {
        setSupplier({} as Supplier)
        setConsignmentList(consignmentList.map((item, idx) => {
            return {
                ...item,
                "cargosonTransportId": null,
                "carrierContactId": null,
                "carrierCode": "",
                "carrierName": "",
                "carrierEmail": "",
                "carrierRepresentative": "",
                "carrierRegNr": "",
                "carrierPhone": "",
                cargosonData: { ...item.cargosonData, "serviceId": "" }
            }
        }))
    }

    return (
        <DataContext.Provider value={{
            setIsLoading, isLoading, isSearchOpen, setIsSearchOpen, consignmentData, setConsignmentData, cargosonData, setCargosonData,
            transportState, setTransportState, consignmentList, setConsignmentList, updateConsignment, updateConsignmentData, cargosonDataList, setCargosonDataList, updateCargosonDataConsignment,
            updatePickups, updatePackages, transportRouteId, setTransportRouteId, consignmentId, setConsignmentId, cargosonQueryResponse, setCargosonQueryResponse, cargosonPricelist, setCargosonPricelist,
            supplier, setSupplier, updateSupplierInfo, removeSupplierInfo, errors, setErrors, returnDirectoCode, setReturnDirectoCode, setTransportRouteData, transportRouteData, transportRouteList, setTransportRouteList,
            consignmentListResponse, setConsignmentListResponse, selectedSupplier, setSelectedSupplier, consignmentListResponseNew, setConsignmentListResponseNew
        }}>
            {isLoading
                ?
                <Layout>
                    <Loading />
                </Layout>
                : children}
        </DataContext.Provider>
    );
}

