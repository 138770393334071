import { useEffect, useState } from 'react';
import { useDataContext } from '../../context/DataContext';
import AddDeliveryNew from './Directo/export/AddDeliveryNew';
import AddOrderNew from './Directo/export/AddOrderNew';
import AddStockMovementNew from './Directo/export/AddStockMovementNew';
import AddStockOrderNew from './Directo/export/AddStockOrderNew';
import AddPurchaseOrderNew from './Directo/import/AddPurchaseOrderNew';

const DirectoComponent = (props: { listIndex: number }) => {
    const { isSearchOpen, consignmentList } = useDataContext();
    const consignment = consignmentList[props.listIndex];
    const [isStockOrder, setIsStockOrder] = useState<boolean>(false);

    useEffect(() => {
        const calculatedStockOrder = (consignment.stockOrderNumbers?.length > 0 || consignment.stockMovementNumbers?.length > 0);
        setIsStockOrder(calculatedStockOrder);
    }, [consignment]);

    return (

        <div className="directo-component-content-container" >
            {(isSearchOpen && consignment.type === "Export") &&
                <div style={{ display: "flex", justifyContent: "flex-start", gap: "20px", flexDirection: "column", alignItems: "flex-start" }}>


                    <div style={{ display: "flex", justifyContent: "center", gap: "30px", flexWrap: "wrap", width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                            <h2 className="page__heading" style={{ fontSize: "20px", fontWeight: "bold", borderBottom: `${!isStockOrder ? "2px solid #f50057" : "none"}`, cursor: "pointer" }} onClick={() => setIsStockOrder(false)}>Müügitellimus</h2>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                            <h2 className="page__heading" style={{ fontSize: "20px", fontWeight: "bold", borderBottom: `${isStockOrder ? "2px solid #f50057" : "none"}`, cursor: "pointer" }} onClick={() => setIsStockOrder(true)}>Laotellimus</h2>
                        </div>
                    </div>

                    {!isStockOrder &&
                        <div style={{ width: "300px", display: "flex", flexDirection: "column", gap: "20px", paddingTop: "20px" }}>
                            <AddOrderNew listIndex={props.listIndex} />
                            <AddDeliveryNew listIndex={props.listIndex} />
                        </div>
                    }
                    {isStockOrder &&
                        <div style={{ width: "300px", display: "flex", flexDirection: "column", gap: "20px", paddingTop: "20px" }}>
                            <AddStockMovementNew listIndex={props.listIndex} />
                            <AddStockOrderNew listIndex={props.listIndex} />
                        </div>
                    }
                </div>
            }
            {(isSearchOpen && consignment.type === "Import") &&
                <div style={{ display: "flex", justifyContent: "flex-start", gap: "20px" }}>
                    <div style={{ width: "300px", display: "flex", flexDirection: "column", gap: "20px", paddingLeft: "20px", paddingTop: "20px" }}>
                        <AddPurchaseOrderNew listIndex={props.listIndex} />
                    </div>
                </div>
            }
        </div>
    )
}

export default DirectoComponent