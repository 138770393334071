
import { Dispatch, SetStateAction, useState } from 'react'
import '../../styles/transport-route-list.css'
import { IconButton } from '@mui/material'
import TocIcon from '@mui/icons-material/Toc';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TransportRoutesTable from './TransportRoutesTable'
import TransportRoutesCards from './TransportRoutesCards'
import InventoryIcon from '@mui/icons-material/Inventory';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

type Props = {
    onDelete: () => void,
    archiveView: boolean,
    setArchiveView: Dispatch<SetStateAction<boolean>>,
    handleSortingChange: (field: string, setSortingState: React.Dispatch<React.SetStateAction<boolean>>, sortingState: boolean) => void
}
const ShowAllTransportRoutes = ({ handleSortingChange, onDelete, archiveView, setArchiveView }: Props) => {
    const [tableView, setTableView] = useState<boolean>(true);
    const togglArchiveView = () => {
        setArchiveView(!archiveView)
    }
    return (
        <div className='all-consignments--wrapper'>
            <div className='all-consignments--view-button-container'>
                {archiveView === false ?
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", fontWeight: "bold", color: "#757575" }}>Tavavaade</div>
                    : <div style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", fontWeight: "bold", color: "#757575" }}>Arhiiv</div>}

                {tableView === false ?
                    <IconButton onClick={() => setTableView(true)} title="Tabelivaade">
                        <TocIcon fontSize='large'></TocIcon>
                    </IconButton> :
                    <IconButton onClick={() => setTableView(false)} title="Kaardivaade">
                        <DashboardIcon fontSize='medium'></DashboardIcon>
                    </IconButton>
                }
                {archiveView === false ?
                    <IconButton onClick={togglArchiveView} title="Arhiiv">
                        <InventoryIcon />
                    </IconButton> :
                    <IconButton onClick={togglArchiveView} title="Tavavaade">
                        <CollectionsBookmarkIcon />
                    </IconButton>
                }
            </div>

            <>
                {tableView === true ?
                    <TransportRoutesTable onDelete={onDelete} handleSortingChange={handleSortingChange} />
                    :
                    <TransportRoutesCards />
                }
            </>
        </div>
    );
}

export default ShowAllTransportRoutes    
