import { Button } from '@mui/material'
import { AxiosResponse } from 'axios';
import { useDataContext } from '../../context/DataContext';
import { CreateConsignmentRequest } from '../../queries/queries';
import { Consignment } from '../../Types/Consignment';
import { useNavigate } from 'react-router-dom';
import { SupplierSelect } from '../../Types/SupplierSelect';
import { Supplier } from '../../Types/Supplier';

const CreateOrLoadConsignment = () => {
    const { setIsLoading, setConsignmentId, setSupplier, setSelectedSupplier } = useDataContext();
    const navigate = useNavigate();

    const handleCreateConsignment = (consignmentType: string) => {
        setIsLoading(true)
        var userName = localStorage.getItem("user")
        var userEmail = localStorage.getItem("email")
        var consignmentOriginCountry = "ET"
        if (userName === null || userEmail === null) return
        CreateConsignmentRequest(consignmentType, userName, userEmail, consignmentOriginCountry).then((response: AxiosResponse<Consignment>) => {
            setConsignmentId(response.data.id.toString())
            setSupplier({} as Supplier)
            setSelectedSupplier({} as SupplierSelect)
            navigate(("/consignmentDetails/" + response.data.id.toString()))
        }).finally(() => { setIsLoading(false) });
    }

    return (
        <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", alignItems: "center", gap: "50px" }}>
            <Button color="primary" variant="contained" style={{ height: "50px", backgroundColor: "#c00", width: "150px", fontWeight: "bold" }} onClick={() => handleCreateConsignment("Export")}>Eksport veotellimus</Button>
            <Button color="primary" variant="contained" style={{ height: "50px", backgroundColor: "#c00", width: "150px", fontWeight: "bold" }} onClick={() => handleCreateConsignment("Import")}>Import veotellimus</Button>
        </div>
    )
}

export default CreateOrLoadConsignment