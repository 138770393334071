import { Delivery } from "../Delivery";
import { DeliveryDriver } from "./DeliveryDriver";

export type InHouseConsignment = {
    id: number;
    transportRouteId: number;
    directoCode: string;
    consignmentPosition: number;
    isArchived: boolean;
    isEmailSent: boolean;
    consignmentDate: Date;
    consignmentCreator: string;
    consignmentCreatorPhone: string;
    consignmentCreatorEmail: string;
    consignmentOriginCountry: string;
    type: string;
    consignmentStatus: ConsignmentStatus;
    internalComment: string;
    carrierDriver: string;
    collectionDate: string;
    collectionTimeFrom: string;
    collectionTimeTo: string;
    collectionCountry: string;
    collectionPostcode: string;
    collectionAddressRow1: string;
    collectionAddressRow2: string;
    collectionCity: string;
    collectionCompanyName: string;
    totalLdm: number;
    deliveryDate: string;
    deliveryTimeFrom: string;
    deliveryTimeTo: string;
    deliveryCountry: string;
    deliveryContactName: string;
    deliveryContactPhone: string;
    deliveryPostcode: string;
    deliveryAddressRow1: string;
    deliveryAddressRow2: string;
    deliveryCity: string;
    deliveryCompanyName: string;
    deliveryCompanyCustomerCode: string;
    deliveryWithCrane?: boolean | null;
    directoOrderNumbers: string[];
    directoPurchaseOrderNumbers: string[];
    directoDeliveryNumbers: string[];
    deliveryObject: string;
    deliveryDriverId: number;
    deliveryDriver: DeliveryDriver;
    deliveryUrl: string;

    deliveryLatitude: number;
    deliveryLongitude: number;
    collectionLatitude: number;
    collectionLongitude: number;
    dropOffLatitude: number;
    dropOffLongitude: number;
    attachmentBase64: string;
    attachmentImage1Base64: string;
    attachmentImage2Base64: string;
    saved: boolean;
    signatureBase64: string;
    packageReceiverName: string;
    deliveryDriverName: string;
    deliveries: Delivery[];
    salesPerson: string;
    salesPersonPhone: string;
    endDeliveryComment: string;
    cancelledBy: string;
    cancellationReason: string;
}

export enum ConsignmentStatus {
    Cancelled = 0,
    Pending = 1,
    InTransit = 2,
    WaitingClient = 3,
    Delivered = 4,
    Loaded = 5, /* remove this? */
    UnSigned = 6,
    Finished = 7,
    Packed = 8,
    NotPacked = 9
}