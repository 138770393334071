import { Button } from '@mui/material'
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDataContext } from '../../context/DataContext';
import { CreateFinlandConsignment } from '../../queries/queries';
import { Consignment } from '../../Types/Consignment';
import { Supplier } from '../../Types/Supplier';
import { SupplierSelect } from '../../Types/SupplierSelect';

type Props = {
    isUserPermitted: boolean;
    alignItems: string
}
const CreateNewTransportOrder = ({ alignItems, isUserPermitted }: Props) => {
    const { setIsLoading, setConsignmentId, setSupplier, setSelectedSupplier } = useDataContext();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleCreateConsignment = () => {
        setIsLoading(true)
        var userName = localStorage.getItem("user")
        var userEmail = localStorage.getItem("email")
        var consignmentType = "Export"
        if (userName === null || userEmail === null) return
        CreateFinlandConsignment(consignmentType, userName, userEmail).then((response: AxiosResponse<Consignment>) => {
            setConsignmentId(response.data.id.toString())
            setSupplier({} as Supplier)
            setSelectedSupplier({} as SupplierSelect)
            navigate(("/tuusula/" + response.data.id.toString()))

        }).finally(() => { setIsLoading(false) });
    }

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: `${alignItems}` }}>
            <Button style={{ background: "#C00", color: "white", width: "175px", fontWeight: "bold" }} onClick={handleCreateConsignment} disabled={!isUserPermitted}>{t("Uus transporditellimus")}</Button>
        </div>
    )
}

export default CreateNewTransportOrder