import { useState, useEffect, useCallback } from 'react';

interface GeolocationData {
  position: GeolocationPosition | null;
  error: GeolocationPositionError | null;
  requestPermission: () => void;
}

const useGeolocation = (): GeolocationData => {
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [error, setError] = useState<GeolocationPositionError | null>(null);

  const requestPermission = useCallback(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            setPosition(pos);
            setError(null);
          },
          (err) => {
            setError(err);
            setPosition(null);
          }
        );
      } else {
        setError({
          code: 1,
          message: 'Geolocation permission denied',
          PERMISSION_DENIED: 1,
          POSITION_UNAVAILABLE: 2,
          TIMEOUT: 3
        } as GeolocationPositionError);
      }
    }).catch((err) => {
      setError(err as GeolocationPositionError);
    });
  }, []);

  useEffect(() => {
    if (!position && !error) {
      requestPermission();
    }
  }, [position, error, requestPermission]);

  return { position, error, requestPermission };
};

export default useGeolocation;
