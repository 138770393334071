import { Button, IconButton } from '@mui/material'
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react'
import { useDataContext } from '../../context/DataContext';
import { TransportRoute } from '../../Types/TransportRoute';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { CargosonChild, CargosonQuery } from '../../Types/cargoson/CargosonQuery';
import { Package } from '../../Types/cargoson/Package';
import { Consignment } from '../../Types/Consignment';
import { Supplier } from '../../Types/Supplier';
import { SupplierSelect } from '../../Types/SupplierSelect';
import { CreateTransportRoute } from '../../queries/transport-route-queries';

type Props = {
    setSearchTerm: Dispatch<SetStateAction<string>>,
    handleSearchTransportRoutes: (e: any) => void
};

const CreateOrLoadTransportRoute = ({ setSearchTerm, handleSearchTransportRoutes }: Props) => {
    const { setTransportRouteId, setConsignmentList, setSelectedSupplier, setSupplier } = useDataContext();
    const { setIsLoading } = useDataContext();
    const navigate = useNavigate();

    const handleCreateTransportRoute = () => {
        setIsLoading(true)
        var userName = localStorage.getItem("user")
        var userEmail = localStorage.getItem("email")
        if (userName === null || userEmail === null) return
        CreateTransportRoute(userName, userEmail, "ET").then((response: AxiosResponse<TransportRoute>) => {
            setConsignmentList([{ cargosonData: { pickups: [{ rows_attributes: [] as Package[] } as CargosonChild] as CargosonChild[] } as CargosonQuery } as Consignment] as Consignment[])
            setTransportRouteId(response.data.id.toString())
            setSupplier({} as Supplier)
            setSelectedSupplier({} as SupplierSelect)
            navigate(("/transport-route/details/" + response.data.id))
        }).finally(() => { setIsLoading(false) });
    }

    return (
        <div className='page__header'>
            <div style={{ width: "100%", alignItems: "center", display: "flex", flexDirection: "row", gap: "50px", paddingLeft: "3%" }}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: "50px", alignItems: "center" }}>
                    <Button variant="contained" style={{ backgroundColor: "#c00", width: "200px", height: "50px", fontWeight: "bold" }} onClick={handleCreateTransportRoute}>Koosta koondveotellimus</Button>
                </div>

                <form onSubmit={handleSearchTransportRoutes} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                    <input className="input-addressForm" style={{ marginTop: "1%", height: "40px", width: "unset", boxSizing: "unset" }} placeholder="Otsi koondveotellimust" onChange={e => setSearchTerm(e.target.value)}></input>
                    <IconButton onClick={handleSearchTransportRoutes}>
                        <EastIcon style={{ color: "gray" }} />
                    </IconButton>
                </form>

            </div>
        </div>
    )
}

export default CreateOrLoadTransportRoute