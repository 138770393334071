import { IconButton } from '@mui/material';
import React, { useState } from 'react'
import '../../../../styles/Consignment.css'
import ClearIcon from '@mui/icons-material/Clear';
import EastIcon from '@mui/icons-material/East';
import { AxiosResponse } from 'axios';
import { useDataContext } from '../../../../context/DataContext';
import { PurchaseOrderResponse } from '../../../../Types/directo/PurchaseOrderResponse';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Consignment } from '../../../../Types/Consignment';
import { GetPurchaseOrders, AddPurchaseOrderToConsignment, AddPurchaseOrderInfoToConsignment, RemovePurchaseOrderFromConsignment } from '../../../../queries/directo-queries';
import { SaveConsignmentList } from '../../../../queries/queries';

const AddPurchaseOrderNew = (props: { listIndex: number }) => {
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>();
    const { consignmentList, setConsignmentList } = useDataContext();
    const consignment = consignmentList[props.listIndex];

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!purchaseOrderNumber) return;
        if (consignmentList[props.listIndex].purchaseOrderNumbers.includes(purchaseOrderNumber)) return;
        try {
            const response: AxiosResponse<PurchaseOrderResponse[]> = await GetPurchaseOrders(purchaseOrderNumber);
            AddPurchaseOrderToConsignment(purchaseOrderNumber, +consignment.id, response.data[0]).then(
                (response: AxiosResponse<Consignment>) => {
                    setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                        ...item,
                        purchaseOrderNumbers: response.data.purchaseOrderNumbers,
                        purchaseOrders: response.data.purchaseOrders
                    } : item));
                    setPurchaseOrderNumber(() => "")
                }
            ).catch(err => { console.log(err); alert(err.response.data) });

        } catch (error) {
            alert("Ei leidnud ostutellimust");
        }
    };

    const handleAddPurchaseOrderInfoToConsignment = async (orderNumber: string) => {
        await SaveConsignmentList(consignmentList)
        AddPurchaseOrderInfoToConsignment(orderNumber, +consignment.id).then(
            (response: AxiosResponse<Consignment>) => {
                //TODO
                setConsignmentList(() => consignmentList.map((item, idx) => idx === props.listIndex ? response.data : item))
                alert(`Ostutellimuse ${orderNumber} hankija info võetud aluseks`)
            }
        ).catch(err => { console.log(err); alert(`Ei saanud ostutellimust ${orderNumber} veotellimuse aluseks võtta`) });
    }

    const handleRemovePurchaseOrder = (orderNumber: string) => {
        RemovePurchaseOrderFromConsignment(orderNumber, +consignment.id).then(
            (response: AxiosResponse<Consignment>) => {
                setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                    ...item,
                    purchaseOrderNumbers: response.data.purchaseOrderNumbers,
                    purchaseOrders: response.data.purchaseOrders
                } : item));
            }
        ).catch(err => { console.log(err); alert("Ostutellimuse eemaldamine ebaõnnestus") });
    }

    return (

        <div style={{ border: "1px solid #e2e2e2", padding: "20px", minHeight: "130px", backgroundColor: "#fff" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <input type="number" style={{ border: "none", borderBottom: "1px solid #e2e2e2" }} placeholder='Lisa ostutellimus' onChange={(e) => setPurchaseOrderNumber(e.target.value)} />
                    <IconButton type="submit">
                        <EastIcon style={{ color: "gray" }} />
                    </IconButton>
                </div>
            </form>
            <div >
                {consignmentList[props.listIndex].purchaseOrderNumbers?.map((x, idx) => (
                    <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #e2e2e2" }}>
                        <a className="addedDeliveires__anchor-tag" target="_blank" rel="noreferrer" href={`https://login.directo.ee/ocra_ets_nord/otell_tellimus.asp?NUMBER=${x}`} >
                            {x}
                        </a>
                        <IconButton title="Võta põhjaks" onClick={() => handleAddPurchaseOrderInfoToConsignment(x)}>
                            <PlaylistAddIcon />
                        </IconButton>
                        <IconButton title="Eemalda" onClick={() => handleRemovePurchaseOrder(x)}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AddPurchaseOrderNew