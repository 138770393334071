import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import { UpdateCollectionDateAllConsignments } from '../../queries/transport-route-queries';
import DateTimeCalendar from '../common/DateTimeCalendar';
import { useCustomer } from '../login/user';

type Props = {
    collectionDate: Date,
    setCollectionDate: React.Dispatch<React.SetStateAction<Date>>,
    timeFrom: Date | null,
    setTimeFrom: React.Dispatch<React.SetStateAction<Date | null>>,
    timeTo: Date | null,
    setTimeTo: React.Dispatch<React.SetStateAction<Date | null>>
}

const PickupTime = ({ collectionDate, setCollectionDate, timeFrom, setTimeFrom, timeTo, setTimeTo, }: Props) => {
    const { updatePickups, consignmentList } = useDataContext();
    const { t } = useTranslation()
    const { isFinnishUserPermitted } = useCustomer();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };
    const handleCollectionDate = (date: Date) => {

        if (consignmentList[0].transportRouteId !== 0) {
            setCollectionDate(date)
            updatePickups(0, 0, "collection_date", date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' }))

            const pickupDate = date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' })
            UpdateCollectionDateAllConsignments(consignmentList[0].transportRouteId, pickupDate)
        }
        else {
            setCollectionDate(date)
            updatePickups(0, 0, "collection_date", date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' }))
        }
    }

    const handleTimeFrom = (date: Date) => {
        setTimeFrom(date)
        updatePickups(0, 0, "collection_time_from", date?.toLocaleTimeString("et-EE"))
    }
    const handleTimeTo = (date: Date) => {
        setTimeTo(date)
        updatePickups(0, 0, "collection_time_to", date?.toLocaleTimeString("et-EE"))
    }
    return (
        <div className='quick-menu-form--wrapper'>
            <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <label>{t("Pealelaadimine")}</label>
            </div>
            <form onSubmit={handleSubmit} style={{ display: "flex", rowGap: "15px", flexDirection: "column", width: "100%" }}>
                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <div style={{ width: "30%" }}>{t("Aeg")}: </div>
                    <div style={{ width: "32%" }}>
                        <DateTimeCalendar handleChange={handleTimeFrom} currentDate={timeFrom} showTimeSelectOnly={true} placeHolder={t("Alates")} />
                    </div>
                    -
                    <div style={{ width: "32%" }}>
                        <DateTimeCalendar handleChange={handleTimeTo} currentDate={timeTo} showTimeSelectOnly={true} placeHolder={t("Kuni")} />
                    </div>
                </div >
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", alignItems: "center" }}>
                    <div style={{ width: "30%" }}>{t("Kuupäev")}: </div>
                    <div style={{ width: "70%" }}>
                        <DateTimeCalendar handleChange={handleCollectionDate} currentDate={collectionDate} isUserPermitted={isFinnishUserPermitted()} />
                    </div>
                </div>
            </form >
        </div >
    )
}

export default PickupTime

