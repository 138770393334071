import React, { useEffect, useState } from 'react'
import '../../../styles/tracking-app/week-calendar.css'
import { ReactComponent as ArrowLeftIcon } from '../../../assets/tracking-app/buttons/arrow-left-small.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/tracking-app/buttons/arrow-right-small.svg';
import { IconButton } from '@mui/material';

type Props = {
    calendarDate: Date;
    setCalendarDate: React.Dispatch<React.SetStateAction<Date>>;
    handleDateChange: (date: any) => void
}
const WeekCalendar = ({ calendarDate, setCalendarDate, handleDateChange }: Props) => {
    const [currentDate, setCurrentDate] = useState(calendarDate);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Initially today's date in YYYY-MM-DD
    const weekDates = getWeekDates(currentDate);

    useEffect(() => {
        setCurrentDate(calendarDate);
        setSelectedDate(calendarDate.toISOString().split('T')[0]);
    }, [calendarDate]);

    const goToPreviousWeek = () => {
        let newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - 7);
        setCurrentDate(newDate);
    };

    const goToNextWeek = () => {
        let newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + 7);
        setCurrentDate(newDate);
    };

    const selectDate = (date: any) => {
        setCalendarDate(date);
        setSelectedDate(date.toISOString().split('T')[0]);
        handleDateChange(date)
    };

    function getWeekDates(date: any) {
        // Get the day of the week (0 for Sunday, 6 for Saturday)
        let dayOfWeek = date.getDay();

        // If it's Sunday (0), set dayOfWeek to 7 to start the week on Monday
        if (dayOfWeek === 0) {
            dayOfWeek = 7;
        }

        // Calculate startOfWeek as the Monday of the current week
        const startOfWeek = date.getDate() - dayOfWeek + 1;

        // Generate an array of dates for the week, starting from Monday
        let week = new Array(7).fill(null).map((_, index) => {
            let day = new Date(date);
            day.setDate(startOfWeek + index);
            return day;
        });

        return week;
    }

    const monthYear = `${currentDate.toLocaleString('et-EE', { month: 'long' })} ${currentDate.getFullYear()}`;
    const capitalizedMonthYear = monthYear.charAt(0).toUpperCase() + monthYear.slice(1);

    return (
        <div className='flex-col items-center'>
            <div className="week-calendar" >
                <div className='month-year-container'>
                    <div className="month-year">{capitalizedMonthYear}</div>
                    <IconButton onClick={goToPreviousWeek} >
                        <ArrowLeftIcon />
                    </IconButton>
                </div>

                <div className='flex' >
                    {weekDates.map((date, index) => (
                        <div key={index} className="date-container">
                            <div className="day-name">{date.toLocaleString('et-EE', { weekday: 'short' })[0]}</div>
                            <button key={index} onClick={() => selectDate(date)} className={`date-button ${date.toISOString().split('T')[0] === selectedDate ? 'highlighted' : ''}`}>
                                {date.getDate()}
                            </button>
                        </div>
                    ))}
                </div>
                <IconButton onClick={goToNextWeek} >
                    <ArrowRightIcon />
                </IconButton>
            </div>
        </div>

    );
}

export default WeekCalendar