import { Marker, GoogleMap } from '@react-google-maps/api';

type Props = {
    latitude: number;
    longitude: number;
    width?: number;
}
const GoogleMapComponent = ({ latitude, longitude, width = 500 }: Props) => {
    return (
        <div className="map-modal">
            <GoogleMap
                mapContainerStyle={{ height: '550px', width: width + 'px', position: "relative", top: "0px" }}
                center={{ lat: latitude, lng: longitude }}
                zoom={15}
            >
                <Marker
                    position={{ lat: latitude, lng: longitude }}
                    draggable={true}
                />
            </GoogleMap>
        </div>
    )
}

export default GoogleMapComponent