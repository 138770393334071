import { Dispatch, SetStateAction, useState } from 'react'
import ConsignmentsTable from './ConsignmentsTable';
import '../../styles/consignment-table.css'
import TocIcon from '@mui/icons-material/Toc';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ConsignmentsCards from './ConsignmentsCards';
import InventoryIcon from '@mui/icons-material/Inventory';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { IconButton } from '@mui/material';

type Props = {
    onDeleteConsignment: () => void,
    archiveView: boolean,
    setArchiveView: Dispatch<SetStateAction<boolean>>,
    handleSortingChange: (field: string, setSortingState: React.Dispatch<React.SetStateAction<boolean>>, sortingState: boolean) => void,
    consignmentType: string,
    setConsignmentType: React.Dispatch<React.SetStateAction<string>>,
}

const ShowAllConsignments = ({ onDeleteConsignment, archiveView, setArchiveView, handleSortingChange, setConsignmentType, consignmentType }: Props) => {
    const [tableView, setTableView] = useState<boolean>(true);

    const togglArchiveView = () => {
        setArchiveView(!archiveView)
    }

    const handleSearchConsignment = (newConsignmentType: any) => {
        if (newConsignmentType === consignmentType) {
            setConsignmentType('')
            return;
        }
        setConsignmentType(newConsignmentType)
    }
    return (
        <div className='all-consignments--wrapper'>
            <div className='all-consignments--view-button-container'>
                {archiveView === false ?
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", fontWeight: "bold", color: "#757575" }}>Tavavaade</div>
                    : <div style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", fontWeight: "bold", color: "#757575" }}>Arhiiv</div>}
                {tableView === false ?
                    <IconButton onClick={() => setTableView(true)} title="Tabelivaade">
                        <TocIcon fontSize='large'></TocIcon>
                    </IconButton> :
                    <IconButton onClick={() => setTableView(false)} title="Kaardivaade">
                        <DashboardIcon fontSize='medium'></DashboardIcon>
                    </IconButton>
                }
                {archiveView === false ?
                    <IconButton onClick={togglArchiveView} title="Arhiiv">
                        <InventoryIcon />
                    </IconButton> :
                    <IconButton onClick={togglArchiveView} title="Tavavaade">
                        <CollectionsBookmarkIcon />
                    </IconButton>
                }
                <div style={{ display: "flex", width: "20%", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <div className={`table-overhead--button ${consignmentType === "Import" && "-active"}`} onClick={() => handleSearchConsignment("Import")}>Import</div>
                    <div className={`table-overhead--button ${consignmentType === "Export" && "-active"}`} onClick={() => handleSearchConsignment("Export")}>Eksport</div>
                </div>
            </div>

            <>
                {tableView === true ?
                    <ConsignmentsTable onDeleteConsignment={onDeleteConsignment} handleSortingChange={handleSortingChange} />
                    :
                    <ConsignmentsCards />
                }
            </>
        </div>
    );
}

export default ShowAllConsignments