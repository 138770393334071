import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import { UpdateCollectionDateAllConsignments } from '../../queries/transport-route-queries';
import DateTimeCalendar from '../common/DateTimeCalendar';
import { useCustomer } from '../login/user';

type Props = {
    collectionDate: Date,
    setCollectionDate: React.Dispatch<React.SetStateAction<Date>>,
    timeFrom: Date | null,
    setTimeFrom: React.Dispatch<React.SetStateAction<Date | null>>,
    timeTo: Date | null,
    setTimeTo: React.Dispatch<React.SetStateAction<Date | null>>
}

const PickupTimeSweden = ({ collectionDate, setCollectionDate, timeFrom, setTimeFrom, timeTo, setTimeTo, }: Props) => {
    const { updatePickups, consignmentList, setConsignmentList } = useDataContext();
    const { t } = useTranslation()
    const { isSwedishUserPermitted } = useCustomer();

    const handleCollectionDate = (date: Date) => {
        if (consignmentList[0].transportRouteId !== 0) {
            setCollectionDate(date)
            updatePickups(0, 0, "collection_date", date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' }))

            const pickupDate = date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' })
            UpdateCollectionDateAllConsignments(consignmentList[0].transportRouteId, pickupDate)
        }
        else {
            setCollectionDate(date)
            updatePickups(0, 0, "collection_date", date?.toLocaleString("et-EE", { year: 'numeric', month: '2-digit', day: '2-digit' }))
        }
    }

    const handleAddressPreset = (presetValue: string) => {
        if (presetValue === "1") {
            const tempArr = consignmentList.map((item, idx) => idx === 0 ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === 0 ? {
                            ...pickup,
                            "collection_address_row_1": "Ängsgatan 33",
                            "collection_city": "Södertälje",
                            "collection_country": "SE",
                            "collection_postcode": "15134",
                            "collection_company_name": "ETS NORD"
                        } : pickup)
                    ]
                }
            } : item);
            setConsignmentList(tempArr)

        }
        else if (presetValue === "2") {
            const tempArr = consignmentList.map((item, idx) => idx === 0 ? {
                ...item,
                cargosonData: {
                    ...item.cargosonData,
                    pickups: [
                        ...item.cargosonData.pickups.map((pickup, i) => i === 0 ? {
                            ...pickup,
                            "collection_address_row_1": "Förrådsvägen 5",
                            "collection_city": "Södertälje",
                            "collection_country": "SE",
                            "collection_postcode": "15158",
                            "collection_company_name": "ETS NORD"
                        } : pickup)
                    ]
                }
            } : item);
            setConsignmentList(tempArr)
        }
    }

    const handleTimeFrom = (date: Date) => {
        setTimeFrom(date)
        updatePickups(0, 0, "collection_time_from", date?.toLocaleTimeString("et-EE"))
    }
    const handleTimeTo = (date: Date) => {
        setTimeTo(date)
        updatePickups(0, 0, "collection_time_to", date?.toLocaleTimeString("et-EE"))
    }

    const getPresetValue = () => {
        if (consignmentList[0].cargosonData.pickups[0].collection_address_row_1 === "Ängsgatan 33") {
            return "1"
        }
        if (consignmentList[0].cargosonData.pickups[0].collection_address_row_1 === "Förrådsvägen 5") {
            return "2"
        }
        else { return "0" }

    }

    return (
        <div className='quick-menu-form--wrapper'>
            <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <label>{t("Pealelaadimine")}</label>
            </div>
            <form style={{ display: "flex", rowGap: "15px", flexDirection: "column", width: "100%" }}>
                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    <div style={{ width: "30%" }}>{t("Aeg")}: </div>
                    <div style={{ width: "32%" }}>
                        <DateTimeCalendar handleChange={handleTimeFrom} currentDate={timeFrom} showTimeSelectOnly={true} placeHolder={t("Alates")} />
                    </div>
                    -
                    <div style={{ width: "32%" }}>
                        <DateTimeCalendar handleChange={handleTimeTo} currentDate={timeTo} showTimeSelectOnly={true} placeHolder={t("Kuni")} />
                    </div>
                </div >
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", alignItems: "center" }}>
                    <div style={{ width: "30%" }}>{t("Kuupäev")}: </div>
                    <div style={{ width: "70%" }}>
                        <DateTimeCalendar handleChange={handleCollectionDate} currentDate={collectionDate} isUserPermitted={isSwedishUserPermitted()} />
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", alignItems: "center" }}>
                    <div style={{ width: "100%" }}>
                        <select className="input-addressForm" onChange={(e: any) => handleAddressPreset(e.target.value)} value={getPresetValue()}>
                            <option value="0">Select pickup address</option>
                            <option value="1">Post office</option>
                            <option value="2">VT Vent</option>
                        </select>
                    </div>
                </div>
            </form >
        </div >
    )
}

export default PickupTimeSweden

