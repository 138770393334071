
import { useCustomer } from './user';
import { Navigate } from "react-router-dom";
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Login = () => {
    var Buffer = require('buffer/').Buffer
    const encodedString = Buffer.from(window.location.origin + "/").toString('base64');
    const { logged } = useCustomer();
    const { i18n } = useTranslation();
    const lang = i18n.language;

    if (logged) {
        return <Navigate to="/" />
    }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "10%" }}>
                <Button variant="contained" style={{ width: "200px", height: "50px", backgroundColor: "#c00", borderRadius: "25px", zIndex: "99", fontWeight: "bold" }}
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `https://login.etsnord.com/?fast=True&language=${lang}&returnurl=${encodedString}`
                    }}
                >
                    Login
                </Button>
                <div className="container-background" style={{ position: "fixed", width: "100vw", height: "100vh", bottom: "0", left: "0" }}></div>
            </div>
        </div>
    )
}
export default Login
