import React, { Dispatch, SetStateAction } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Button,
    DialogActions,
    Select,
    MenuItem,
    Checkbox,
    InputLabel,
    TextField
} from '@mui/material';
import { addUser, updateUser } from '../../queries/adminQueries';
import { useDataContext } from '../../context/DataContext';
import { styled } from '@mui/system';
import { User } from '../../Types/user/User';

interface Props {
    open: boolean;
    onClose: () => void;
    user: User;
    setUser: Dispatch<SetStateAction<User>>
}

export const StyledSelect = styled(Select, {
    name: "StyledSelect",
    slot: "Select"
})({
    "& fieldset": {
        "& legend": {
            opacity: "1",
            "& span": {
                color: "blue",
                opacity: "1",
            }
        }
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: "1px solid #0000003b"
    },

    "&.MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#0000003b"
        },
        "&:hover fieldset": {
            borderColor: "#0000003b"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#0000003b"
        }
    }

})

export const StyledTextField = styled(TextField, {
    name: "StyledTextField",
    slot: "TextField"
})({
    '.MuiOutlinedInput-notchedOutline': {
        border: "1px solid #0000003b"
    },
    '& label': {
        "&.Mui-focused": {
            position: "relative",
        }
    },
})

const AddUserDialog = ({ open, onClose, user, setUser }: Props) => {
    const { setIsLoading } = useDataContext()

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setUser((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        addUser(user).then((res) => {
            onClose()
        }).catch((err) => { console.log(err) }).finally(() => { setIsLoading(false) });
    };

    const handleUpdateUser = (e: any) => {
        e.preventDefault();
        updateUser(user).then((res) => {
            onClose()
        }).catch((err) => { console.log(err) }).finally(() => { setIsLoading(false) });
    }

    const handleChecboxChange = (event: any) => {
        event.preventDefault();
        const { name, checked } = event.target;

        if (checked) {
            setUser((prevData) => ({
                ...prevData,
                [name]: true,
            }));
        } else {
            setUser((prevData) => ({
                ...prevData,
                [name]: false,
            }));
        }
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="add-user-dialog">
            <DialogTitle id="add-user-dialog">Add new user</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit} >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <StyledTextField
                                fullWidth
                                label="Full name"
                                name="fullName"
                                variant="outlined"
                                type="search"
                                required={true}
                                value={user.fullName}
                                onChange={handleInputChange}
                                sx={{ marginTop: "15px" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                fullWidth
                                label="Email address"
                                name="email"
                                type="email"
                                variant="outlined"
                                required={true}
                                value={user.email}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="user-country">Country</InputLabel>
                            <StyledSelect
                                fullWidth
                                labelId="country"
                                id="country"
                                name="countryCode"
                                value={user.countryCode}
                                placeholder='Country'
                                required={true}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="EST">Estonia</MenuItem>
                                <MenuItem value="FIN">Finland</MenuItem>
                                <MenuItem value="SWE">Sweden</MenuItem>

                            </StyledSelect>

                        </Grid>
                        <Grid item xs={6} md={6} >
                            <InputLabel htmlFor="user-enabled">Enabled</InputLabel>
                            <Checkbox id="user-enabled" name="isEnabled" checked={user.isEnabled || false} onChange={handleChecboxChange}></Checkbox>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel htmlFor="user-role">Role</InputLabel>
                            <StyledSelect
                                fullWidth
                                labelId="user-role"
                                id="user-role"
                                name="role"
                                value={user.role}
                                required={true}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Admin">Admin</MenuItem>
                                <MenuItem value="EstonianUser">Estonian user</MenuItem>
                                <MenuItem value="FinnishUser">Finnish user</MenuItem>
                                <MenuItem value="SwedishUser">Swedish user</MenuItem>
                                <MenuItem value="TransportCompanyUser">Finnish transport company</MenuItem>
                                <MenuItem value="FinnishTvViewer">Finnish TV</MenuItem>
                                <MenuItem value="InHouseDriver">In-house driver</MenuItem>
                                <MenuItem value="InHouseDriverManager">In-house driver manager</MenuItem>
                                <MenuItem value="EstonianWarehouseWorker">EST warehouse worker</MenuItem>
                                <MenuItem value="SwedishEstonianUser">SWE-EST user</MenuItem>
                            </StyledSelect>
                        </Grid>

                    </Grid>

                    <DialogActions>
                        <Button onClick={onClose} color="secondary" sx={{ color: "#000" }}>
                            Cancel
                        </Button>

                        {user.userId !== undefined ?
                            <Button onClick={(e: any) => handleUpdateUser(e)} variant="contained" color="primary" sx={{
                                backgroundColor: "#c00", "&:hover": {
                                    backgroundColor: "#c00"
                                }
                            }}>
                                Modify user
                            </Button> :

                            <Button type="submit" variant="contained" color="primary" sx={{
                                backgroundColor: "#c00", "&:hover": {
                                    backgroundColor: "#c00"
                                }
                            }}>
                                Add new user
                            </Button>
                        }
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default AddUserDialog;
