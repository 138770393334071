import '../../styles/Consignment.css'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { CargosonQueryResponse2 } from '../../Types/cargoson/CargosonQueryResponse2';
import { useDataContext } from '../../context/DataContext';

type Props = {
  response: CargosonQueryResponse2[]
  listIndex: number
}

const CargosonQueryError = ({ response, listIndex }: Props) => {
  const { consignmentList, transportRouteId } = useDataContext();
  const consignment = consignmentList[listIndex];

  return (
    <>
      {response.map((v, idx) =>
        <div key={idx}>
          {(listIndex === idx && transportRouteId && v.errors) ?
            <>
              <div style={{ display: "flex", justifyContent: "flex-start", fontWeight: "bold", padding: "12px" }}>
                {`${consignment.cargosonData.pickups[0].collection_address_row_1} -> ${consignment.cargosonData.delivery_address_row_1}`}
              </div>
              {(v.errors).map((errors, index) =>
                <table key={index}>
                  <tbody>
                    <tr>
                      <td><PriorityHighIcon style={{ color: "red", padding: "5px" }} /></td>
                      <td style={{ color: "red", fontWeight: "700", padding: "5px" }}>{errors}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </>
            :
            <>
              {!transportRouteId &&
                <>
                  <div style={{ display: "flex", justifyContent: "flex-start", fontWeight: "bold", padding: "12px" }}>
                    <>
                      {consignment.cargosonData.pickups[idx] !== undefined &&
                        <>
                          {`${consignment.cargosonData.pickups[idx].collection_address_row_1} -> ${consignment.cargosonData.delivery_address_row_1}`}
                        </>
                      }
                    </>
                  </div>
                  {(v.errors).map((errors, index) =>
                    <table key={index}>
                      <tbody>
                        <tr>
                          <td><PriorityHighIcon style={{ color: "red", padding: "5px" }} /></td>
                          <td style={{ color: "red", fontWeight: "700", padding: "5px" }}>{errors}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </>}
            </>
          }
        </div>
      )}
    </>
  )
}

export default CargosonQueryError