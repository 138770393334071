import { OrdersResponse } from '../Types/directo/OrdersResponse';
import { PurchaseOrderResponse } from '../Types/directo/PurchaseOrderResponse';
import { apiUrl } from "../config/apiconfig";
import { instance } from './auth'


export const SearchOrderWithOrderNumber = async (orderNumber: string) => {
    return instance.get(`${apiUrl}/api/directo/order?orderNumber=${orderNumber}`);
}
export const GetSwedenSalesOrder = async (orderNumber: string) => {
    return instance.get(`${apiUrl}/api/directo/sweden/salesorder?orderNumber=${orderNumber}`);
}
export const SearchDeliveriesWithDeliveryNumber = async (deliveryNumber: string) => {
    return instance.get(`${apiUrl}/api/directo/delivery?deliveryNumber=${deliveryNumber}`);
}

export const GetStockOrder = async (stockOrderNumber: string) => {
    return instance.get(`${apiUrl}/api/directo/stockorder?stockOrderNumber=${stockOrderNumber}`);
}

export const GetStockMovement = async (stockMovementNumber: string) => {
    return instance.get(`${apiUrl}/api/directo/stockmovement?stockMovementNumber=${stockMovementNumber}`);
}


export const AddStockOrderToConsignment = async (stockOrderNumber: string, consignmentId: number) => {
    return instance.post(`${apiUrl}/api/consignment/stockorder/add?stockOrderNumber=${stockOrderNumber}&consignmentId=${consignmentId}`);
}

export const AddStockMovementToConsignment = async (stockMovementNumber: string, consignmentId: number) => {
    return instance.post(`${apiUrl}/api/consignment/stockmovement/add?stockMovementNumber=${stockMovementNumber}&consignmentId=${consignmentId}`);
}

export const AddDeliveryToConsignment = async (deliveryNumber: string, consignmentId: number) => {
    return instance.post(`${apiUrl}/api/consignment/delivery/add?deliveryNumber=${deliveryNumber}&id=${consignmentId}`);
}

export const GetOrderInfoFromDelivery = async (consignmentId: number, deliveryNumber: string) => {
    return instance.get(`${apiUrl}/api/consignment/delivery/base/add?consignmentId=${consignmentId}&deliveryNumber=${deliveryNumber}`);
}

export const AddOrderToConsignment = async (orderNumber: string, consignmentId: number, directoOrderResponse: OrdersResponse, directoCountry: string) => {
    return instance.post(`${apiUrl}/api/consignment/order/add?orderNumber=${orderNumber}&id=${consignmentId}&directoCountry=${directoCountry}`, JSON.stringify(directoOrderResponse), { headers: { "Content-Type": "application/json" } });
}

export const AddOrderInfoToHeader = async (orderNumber: string, consignmentId: number) => {
    return instance.post(`${apiUrl}/api/consignment/header/add?orderNumber=${orderNumber}&id=${consignmentId}`);
}

export const RemoveOrderFromConsignment = async (orderNumber: string, consignmentId: number) => {
    return instance.delete(`${apiUrl}/api/consignment/order/delete?orderNumber=${orderNumber}&id=${consignmentId}`);
}

export const RemoveDeliveryFromConsignment = async (deliveryNumber: string, consignmentId: number) => {
    return instance.delete(`${apiUrl}/api/consignment/delivery/delete?deliveryNumber=${deliveryNumber}&id=${consignmentId}`);
}

export const RemoveStockOrderFromConsignment = async (stockOrderNumber: string, consignmentId: number) => {
    return instance.delete(`${apiUrl}/api/consignment/stockorder/delete?stockOrderNumber=${stockOrderNumber}&id=${consignmentId}`);
}

export const RemoveStockMovementFromConsignment = async (stockMovementNumber: string, consignmentId: number) => {
    return instance.delete(`${apiUrl}/api/consignment/stockmovement/delete?stockMovementNumber=${stockMovementNumber}&id=${consignmentId}`);
}

export const GetPurchaseOrders = async (purchaseOrderNumber: string) => {
    return instance.get(`${apiUrl}/api/directo/purchaseorder?purchaseOrderNumber=${purchaseOrderNumber}`);
}

export const AddPurchaseOrderToConsignment = async (purchaseOrderNumber: string, consignmentId: number, directoOrderResponse: PurchaseOrderResponse) => {
    return instance.post(`${apiUrl}/api/consignment/purchaseorder/add?purchaseOrderNumber=${purchaseOrderNumber}&id=${consignmentId}`, JSON.stringify(directoOrderResponse), { headers: { "Content-Type": "application/json" } });
}

export const RemovePurchaseOrderFromConsignment = async (purchaseOrderNumber: string, consignmentId: number) => {
    return instance.delete(`${apiUrl}/api/consignment/purchaseorder/delete?purchaseOrderNumber=${purchaseOrderNumber}&id=${consignmentId}`);
}

export const AddPurchaseOrderInfoToConsignment = async (purchaseOrderNumber: string, consignmentId: number) => {
    return instance.post(`${apiUrl}/api/consignment/purchaseorder/info?purchaseOrderNumber=${purchaseOrderNumber}&id=${consignmentId}`);
}
