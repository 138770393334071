import { IconButton } from '@mui/material';
import React, { useState } from 'react'
import { useDataContext } from '../../../../context/DataContext';
import '../../../../styles/Consignment.css'
import ClearIcon from '@mui/icons-material/Clear';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EastIcon from '@mui/icons-material/East';
import { AxiosResponse } from 'axios';
import { Consignment } from '../../../../Types/Consignment';
import { OrdersResponse } from '../../../../Types/directo/OrdersResponse';
import { SearchOrderWithOrderNumber, AddOrderToConsignment, AddOrderInfoToHeader, RemoveOrderFromConsignment } from '../../../../queries/directo-queries';
import { SaveConsignmentList } from '../../../../queries/queries';

const AddOrderNew = (props: { listIndex: number }) => {
    const [salesOrder, setSalesOrder] = useState<string>();
    const { consignmentList, setConsignmentList } = useDataContext();
    const consignment = consignmentList[props.listIndex];
    const directoCountry = "EST"
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!salesOrder) return;
        if (consignmentList[props.listIndex].orderNumbers.includes(salesOrder)) return;
        try {
            const response: AxiosResponse<OrdersResponse[]> = await SearchOrderWithOrderNumber(salesOrder);
            AddOrderToConsignment(salesOrder, +consignment.id, response.data[0], directoCountry).then(
                (res: AxiosResponse<Consignment>) => {
                    setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                        ...item,
                        orderNumbers: res.data.orderNumbers,
                        orders: res.data.orders
                    } : item));
                    setSalesOrder(() => "")
                }
            ).catch(err => { console.log(err); alert(err.response.data) });

        } catch (error) {
            alert("Ei leidnud müügitellimust");
        }
    };

    const handleAddOrderInfoToHeader = async (orderNumber: string) => {
        await SaveConsignmentList(consignmentList)
        AddOrderInfoToHeader(orderNumber, +consignment.id).then(
            (response: AxiosResponse<Consignment>) => {
                setConsignmentList(() => consignmentList.map((item, idx) => idx === props.listIndex ? response.data : item))
                alert(`Müügitellimuse ${orderNumber} info võetud aluseks`)
            }
        ).catch(err => { console.log(err); alert(`Ei saanud tellimust ${orderNumber} veotellimuse aluseks võtta`) });
    }

    const handleRemoveSalesOrder = (orderNumber: string) => {
        RemoveOrderFromConsignment(orderNumber, +consignment.id).then(
            (response: AxiosResponse<Consignment>) => {
                setConsignmentList(consignmentList.map((item, idx) => idx === props.listIndex ? {
                    ...item,
                    orderNumbers: response.data.orderNumbers,
                    orders: response.data.orders
                } : item));
            }
        ).catch(err => { console.log(err); alert("Could not remove order") });
    }

    return (
        <div style={{ border: "1px solid #e2e2e2", padding: "20px", minHeight: "130px", backgroundColor: "#fff" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <input type="number" style={{ border: "none", borderBottom: "1px solid #e2e2e2" }} placeholder='Lisa müügitellimus' value={salesOrder} onChange={(e) => setSalesOrder(e.target.value)} />
                    <IconButton type="submit">
                        <EastIcon style={{ color: "gray" }} />
                    </IconButton>
                </div>
            </form>
            <div >
                {consignmentList[props.listIndex].orderNumbers?.map((x, idx) => (
                    <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #e2e2e2" }}>
                        <a className="addedDeliveires__anchor-tag" target="_blank" rel="noreferrer" href={`https://login.directo.ee/ocra_ets_nord/tell_tellimus.asp?NUMBER=${x}`} >
                            {x}
                        </a>
                        <IconButton title="Võta põhjaks" onClick={() => handleAddOrderInfoToHeader(x)}>
                            <PlaylistAddIcon />
                        </IconButton>
                        <IconButton title="Eemalda" onClick={() => handleRemoveSalesOrder(x)}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AddOrderNew