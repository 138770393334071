import { instance } from './auth'
import { apiUrl } from "../config/apiconfig";
import { ConsignmentStatus, InHouseConsignment } from '../Types/delivery-app/InHouseConsignment';
import { DeliveryDriver } from '../Types/delivery-app/DeliveryDriver';
import { DriverLocations } from '../Types/delivery-app/DriverLocations';
import { ClientInHouseConsignment } from '../Types/delivery-app/ClientInHouseConsignment';
import { DeliveryCoordinate } from '../Types/delivery-app/DeliveryCoordinate';

export const GetDeliveryDriver = async (id: string) => {
    return instance.get<DeliveryDriver>(`${apiUrl}/api/deliveryDriver/get?id=${id}`, { headers: { "Content-Type": "application/json" } });
}

export const GetDeliveryDriverConsignmnetsWithDate = async (id: number, dateTime: string) => {
    return instance.get<DeliveryDriver>(`${apiUrl}/api/deliveryDriver/get/date?id=${id}&dateTime=${dateTime}`, { headers: { "Content-Type": "application/json" } });
}

export const GetActiveDeliveryDrivers = async () => {
    return instance.get<DeliveryDriver[]>(`${apiUrl}/api/deliveryDriver/get/all/active`, { headers: { "Content-Type": "application/json" } });
}

export const GetAllDeliveryDrivers = async () => {
    return instance.get<DeliveryDriver[]>(`${apiUrl}/api/deliveryDriver/get/all`, { headers: { "Content-Type": "application/json" } });
}

export const CreateInHouseConsignment = async (date: string) => {
    return instance.get<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/create/new?date=${date}`, { headers: { "Content-Type": "application/json" } });
}

export const GetInHouseConsignment = async (id: number) => {
    return instance.get<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/get?id=${id}`, { headers: { "Content-Type": "application/json" } });
}

export const GetInHouseConsignmentDirectoCode = async (directoCode: string) => {
    return instance.get<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/get/directo?directoCode=${directoCode}`, { headers: { "Content-Type": "application/json" } });
}

export const GetInHouseConsignmentWithDeliveryUrl = async (deliveryUrl: string) => {
    return instance.get<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/client/delivery/get?deliveryUrl=${deliveryUrl}`, { headers: { "Content-Type": "application/json" } });
}

export const GetAllInHouseConsignment = async () => {
    return instance.get<InHouseConsignment[]>(`${apiUrl}/api/InHouseConsignment/get/all`, { headers: { "Content-Type": "application/json" } });
}

export const GetInHouseConsignmentsCalendar = async (dateTime: string) => {
    return instance.get(`${apiUrl}/api/InHouseConsignment/get/calendar?dateTime=${dateTime}`, { headers: { "Content-Type": "application/json" } });
}
export const GetInHouseConsignmentsCalendarRange = async (startDate: string, endDate: string, deliveryDriverId: number) => {
    return instance.get(`${apiUrl}/api/InHouseConsignment/get/calendar/range?startDate=${startDate}&endDate=${endDate}&deliveryDriverId=${deliveryDriverId}`, { headers: { "Content-Type": "application/json" } });
}

export const GetUnPlannedConsignmentsCalendar = async (dateTime: string) => {
    return instance.get(`${apiUrl}/api/InHouseConsignment/get/calendar/unplanned?dateTime=${dateTime}`, { headers: { "Content-Type": "application/json" } });
}

export const DeleteInHouseConsignment = async (id: number) => {
    return instance.delete(`${apiUrl}/api/InHouseConsignment/delete?id=${id}`);
}

export const UpdateInHouseConsignment = async (inHouseConsignmentData: InHouseConsignment) => {
    return instance.post(`${apiUrl}/api/InHouseConsignment/update`, JSON.stringify(inHouseConsignmentData), { headers: { "Content-Type": "application/json" } });
}

export const SendInHouseConsignmentToDirecto = async (inHouseConsignmentData: InHouseConsignment) => {
    return instance.post(`${apiUrl}/api/InHouseConsignment/directo/send`, JSON.stringify(inHouseConsignmentData), { headers: { "Content-Type": "application/json" } });
}

export const SendSignedPrintoutToDirecto = async (inHouseConsignmentData: ClientInHouseConsignment) => {
    return instance.post(`${apiUrl}/api/InHouseConsignment/client/directo/send`, JSON.stringify(inHouseConsignmentData), { headers: { "Content-Type": "application/json" } });
}

export const UpdateInHouseConsignmentProperty = async (id: number, propertyName: string, propertyValue: string) => {
    return instance.put<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/update/property?id=${id}&propertyName=${propertyName}&propertyValue=${propertyValue}`, { headers: { "Content-Type": "application/json" } });
}

export const UpdateInHouseConsignmentStatus = async (id: number, status: ConsignmentStatus) => {
    return instance.post(`${apiUrl}/api/InHouseConsignment/update/status?id=${id}`, JSON.stringify(status), { headers: { "Content-Type": "application/json" } });
}

export const AddDeliveryToInHouseConsignment = async (id: number, deliveryNumber: string) => {
    return instance.get<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/delivery/add?id=${id}&deliveryNumber=${deliveryNumber}`, { headers: { "Content-Type": "application/json" } });
}

export const AddMultipleDeliveriesToInHouseConsignment = async (multipleDeliveries: { id: number, deliveryNumbers: string[] }) => {
    return instance.post<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/delivery/multiple/add`, JSON.stringify(multipleDeliveries), { headers: { "Content-Type": "application/json" } });
}

export const AddOrderToInHouseConsignment = async (id: number, orderNumber: string) => {
    return instance.get<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/order/add?id=${id}&orderNumber=${orderNumber}`, { headers: { "Content-Type": "application/json" } });
}
export const AddPurchaseOrderToInHouseConsignment = async (id: number, orderNumber: string) => {
    return instance.post<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/purchaseorder/add?id=${id}&orderNumber=${orderNumber}`, { headers: { "Content-Type": "application/json" } });
}

export const DeletePurchaseOrderInHouseConsignment = async (id: number, orderNumber: string) => {
    return instance.delete<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/purchaseorder/delete?id=${id}&orderNumber=${orderNumber}`, { headers: { "Content-Type": "application/json" } });
}
export const PurchaseOrderInfoToInHouseConsignment = async (id: number, purchaseOrderNumber: string) => {
    return instance.post(`${apiUrl}/api/InHouseConsignment/purchaseorder/update-consignment?id=${id}&purchaseOrderNumber=${purchaseOrderNumber}`, { headers: { "Content-Type": "application/json" } });
}

export const DeleteDeliveryInHouseConsignment = async (id: number, deliveryNumber: string) => {
    return instance.delete<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/delivery/delete?id=${id}&deliveryNumber=${deliveryNumber}`, { headers: { "Content-Type": "application/json" } });
}

export const DeleteOrderInHouseConsignment = async (id: number, orderNumber: string) => {
    return instance.delete<InHouseConsignment>(`${apiUrl}/api/InHouseConsignment/order/delete?id=${id}&orderNumber=${orderNumber}`, { headers: { "Content-Type": "application/json" } });
}

export const AddOrderInfoFromDeliveryToInhouseConsignment = async (id: number, deliveryNumber: string) => {
    return instance.get(`${apiUrl}/api/InHouseConsignment/delivery/update-consignment?id=${id}&deliveryNumber=${deliveryNumber}`, { headers: { "Content-Type": "application/json" } });
}

export const StartDelivery = async (id: number, deliveryDriverId: number) => {
    return instance.get(`${apiUrl}/api/InHouseConsignment/delivery/start?id=${id}&deliveryDriverId=${deliveryDriverId}`, { headers: { "Content-Type": "application/json" } });
}

export const AssignUserToDeliveryDriver = async (userId: string, deliveryDriverId: number) => {
    return instance.post(`${apiUrl}/api/deliverydriver/user/assign?userId=${userId}&deliveryDriverId=${deliveryDriverId}`, { headers: { "Content-Type": "application/json" } });
}

export const UpdateDeliveryDriverLocation = async (deliveryDriverId: number, latitude: number, longitude: number) => {
    return instance.post(`${apiUrl}/api/deliverydriver/update/location?deliveryDriverId=${deliveryDriverId}&latitude=${latitude}&longitude=${longitude}`, { headers: { "Content-Type": "application/json" } });
}

export const GetActiveDelivery = async (deliveryDriverId: number) => {
    return instance.get<InHouseConsignment>(`${apiUrl}/api/deliverydriver/get/active?deliveryDriverId=${deliveryDriverId}`, { headers: { "Content-Type": "application/json" } });
}

export const GetDeliveryDriverLocation = async (deliveryDriverId: number) => {
    return instance.get<DeliveryDriver>(`${apiUrl}/api/deliverydriver/get/location?driverId=${deliveryDriverId}`, { headers: { "Content-Type": "application/json" } });
}

export const CancelDelivery = async (consignmentId: number, cancellationReason: string) => {
    return instance.get<boolean>(`${apiUrl}/api/InHouseConsignment/delivery/cancel?consignmentId=${consignmentId}&cancellationReason=${cancellationReason}`, { headers: { "Content-Type": "application/json" } });
}

export const FetchDeliveryDriverLocation = async (deliveryUrl: string) => {
    return instance.get(`${apiUrl}/api/InHouseConsignment/client/delivery-driver/location?deliveryUrl=${deliveryUrl}`, { headers: { "Content-Type": "application/json" } });
}

export const GetAllDriverLocations = async () => {
    return instance.get<DriverLocations[]>(`${apiUrl}/api/deliverydriver/get/all/location`, { headers: { "Content-Type": "application/json" } });
}

export const ToggleDriverVisibility = async (id: number) => {
    return instance.patch<DeliveryDriver>(`${apiUrl}/api/deliverydriver/${id}/toggleVisibility`, { headers: { "Content-Type": "application/json" } });
}

export const StartMultipleDeliveries = async (startMultipleDeliveriesDto: { deliveryDriverId: number, consignmentIds: number[] }) => {
    return instance.post(`${apiUrl}/api/deliverydriver/multiple-deliveries/start`, JSON.stringify(startMultipleDeliveriesDto), { headers: { "Content-Type": "application/json" } });
}

export const UpdateMultipleDeliveries = async (startMultipleDeliveriesDto: { deliveryDriverId: number, consignmentIds: number[] }) => {
    return instance.post(`${apiUrl}/api/deliverydriver/multiple-deliveries/update`, JSON.stringify(startMultipleDeliveriesDto), { headers: { "Content-Type": "application/json" } });
}

export const GetDeliveryDriverTransportRoute = async (deliveryDriverId: number, dateTime: string) => {
    return instance.get(`${apiUrl}/api/deliverydriver/get/transport-route?deliveryDriverId=${deliveryDriverId}&dateTime=${dateTime}`, { headers: { "Content-Type": "application/json" } });
}

export const CompleteDelivery = async (deliveryId: number) => {
    return instance.get(`${apiUrl}/api/InHouseConsignment/delivery/complete?deliveryId=${deliveryId}`, { headers: { "Content-Type": "application/json" } });
}
export const RemoveDeliveryFromLoadedConsignments = async (deliveryId: number) => {
    return instance.get(`${apiUrl}/api/InHouseConsignment/delivery/loaded/remove?deliveryId=${deliveryId}`, { headers: { "Content-Type": "application/json" } });
}
export const GetLoaderCalendarConsignments = async (dateTime: string) => {
    return instance.get(`${apiUrl}/api/InHouseConsignment/get/loader/calendar?dateTime=${dateTime}`, { headers: { "Content-Type": "application/json" } });
}

export const SearchInHouseConsignments = async (searchTerm: string, page: number, pageSize: number, sortBy: string, isDescending: boolean) => {
    return instance.get<DeliveryDriver>(`${apiUrl}/api/inhouseconsignment/search?searchTerm=${searchTerm}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&isDescending=${isDescending}`, { headers: { "Content-Type": "application/json" } });
}

export const GetDeliveryCoordinates = async (dateTime: string) => {
    return instance.get<DeliveryCoordinate[]>(`${apiUrl}/api/inhouseconsignment/delivery/get/coordinates?dateTime=${dateTime}`, { headers: { "Content-Type": "application/json" } });
}